import React, { useCallback, useEffect, useState } from 'react';
import BoxedContainer from 'components/BoxedContainer';
import Map from 'components/Map/AltMap';
import useUIContext from 'context/UIContext';
import area from '@turf/area';
import useActionMenuContext, {
  ActionMenuModuleTypes,
  Region,
} from 'context/ActionMenuContext';
import simplify from '@turf/simplify';
import * as turf from '@turf/helpers';
import { Typography } from '@mui/material';
import CustomDrawLayerStyle from 'components/Map/constants/layers/CustomDrawLayerStyle';
import booleanOverlap from '@turf/boolean-overlap';
import booleanWithin from '@turf/boolean-within';
import {
  useAISRegions,
  useCalculateAISRegionPrice,
} from 'hooks/aisRegions/useAISRegion';

export const AISRegions = () => {
  const [featuresToBeCreated, setFeaturesToBeCreated] = useState<any>(null);
  const [featureToBeDeleted, setFeatureToBeDeleted] = useState<any>(null);
  const [regions, setRegions] = useState<Region[] | null>(null);
  const {
    openRightSidebar,
    closeRightSidebar,
    fixRightSidebar,
    setFlashMessage,
  } = useUIContext();
  const {
    setActive,
    aisRegionData,
    upsertRegionInAisRegionData,
    removeRegionFromAisRegionData,
    setAisRegionSavedCount,
    setAisRegionDataDrawMode,
    setAisRegionData,
  } = useActionMenuContext();
  const { data, isLoading } = useAISRegions() as any;
  const { mutate: calculateRegionPrice, data: regionPrice } =
    useCalculateAISRegionPrice();

  useEffect(() => {
    setTimeout(() => {
      openRightSidebar();
      fixRightSidebar(true);
      setActive(ActionMenuModuleTypes.UpsertAISRegion);
    }, 100);
    return () => {
      fixRightSidebar(false);
      closeRightSidebar();
    };
  }, []);

  useEffect(() => {
    if (!data || isLoading) return;

    setAisRegionData(data);
    setAisRegionSavedCount(data.length);
    setFeaturesToBeCreated({
      type: 'FeatureCollection',
      features: data.map((f: any) => ({
        ...f,
        id: f.featureId,
        geometry: f.polygon.geometry,
      })),
    });
  }, [data]);

  useEffect(() => {
    if (!featureToBeDeleted) return;
    setFeatureToBeDeleted(null);
  }, [featureToBeDeleted]);

  useEffect(() => {
    if (!!aisRegionData?.deleteRegionId) {
      setFeatureToBeDeleted(aisRegionData?.deleteRegionId);
      removeRegionFromAisRegionData(null);
    }
  }, [aisRegionData?.deleteRegionId]);

  useEffect(() => {
    if (!!aisRegionData?.regions) {
      setRegions(aisRegionData?.regions);
    }
  }, [aisRegionData?.regions]);

  useEffect(() => {
    if (!regions || regions.length < 2) return;

    // if (!!featuresToBeCreated) setFeaturesToBeCreated(null);

    const region = (() => {
      if (!!aisRegionData?.lastUpdatedId) {
        return regions.find(r => r.featureId === aisRegionData?.lastUpdatedId);
      }
      return regions[regions.length - 1];
    })();

    if (!region) return;

    try {
      for (let i = 0; i < regions.length; i++) {
        const r = regions[i];
        if (r.featureId === region?.featureId) continue;

        if (
          booleanOverlap(r.polygon, region.polygon) ||
          booleanWithin(r.polygon, region.polygon) ||
          booleanWithin(region.polygon, r.polygon)
        ) {
          setFlashMessage({
            severity: 'error',
            message: 'Overlapping regions are not allowed',
          });
          throw new Error('Overlapping regions are not allowed');
        }
      }
    } catch (e) {
      setFeatureToBeDeleted(region.featureId);
      removeRegionFromAisRegionData(region.featureId);
    }
  }, [regions]);

  React.useEffect(() => {
    if (!regionPrice) return;

    upsertRegionInAisRegionData({
      featureId: regionPrice.featureId,
      area: regionPrice.area,
      polygon: regionPrice.polygon,
      priceInCents: regionPrice.priceInCents,
    });
  }, [regionPrice]);

  const handleOnDraw = useCallback((t: string, f: any) => {
    if (!t || !f || !f[0]) return;

    const simplifiedArea = simplify(
      turf.polygon([f[0].geometry?.coordinates[0]]),
      {
        tolerance: 0.0001,
        highQuality: true,
        mutate: true,
      }
    );

    if ('create' === t) {
      calculateRegionPrice({
        featureId: f[0].id,
        area: area(simplifiedArea),
        polygon: simplifiedArea,
      });
    }
    if ('delete' === t) {
      removeRegionFromAisRegionData(f[0].id);
    }
    setAisRegionDataDrawMode({
      drawMode: 'draw_disabled',
      selectedFeatureId: null,
    });
  }, []);

  return (
    <BoxedContainer style={{ height: '100%', width: 'calc( 100% - 480px )' }}>
      <Typography variant='h5'>AIS Regions</Typography>
      <br />
      <Map
        height={'85%'}
        projection={'mercator'}
        minZoom={0}
        zoom={1}
        drawCustomLayerStyle={CustomDrawLayerStyle}
        drawMode={aisRegionData?.drawMode || 'draw_disabled'}
        drawModeExtra={
          !!aisRegionData?.selectedFeatureId
            ? { featureIds: [aisRegionData?.selectedFeatureId] }
            : { featureIds: [] }
        }
        onDraw={handleOnDraw}
        drawCustomFeatures={!!featuresToBeCreated ? featuresToBeCreated : null}
        deleteFeatureById={featureToBeDeleted}
        initialBounds={[
          [-180, 90],
          [180, -90],
        ]}
        controlStyles={{
          bottomRight: `display: none`,
        }}
      />
      <div style={{ marginTop: '0.5em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          1. Should there be any adjustments to the pricing structure, we commit
          to providing you with a minimum of one month’s advance notice.
        </Typography>
      </div>
      <div>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          2. Price is determined based on the average traffic observed in the
          region.
        </Typography>
      </div>
    </BoxedContainer>
  );
};

export default AISRegions;
