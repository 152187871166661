import React from 'react';

const BoxedContainer = ({ children, style }: any) => {
  return (
    <div
      style={{
        width: `calc( 100% - 80px )`,
        ...style,
      }}>
      {children}
    </div>
  );
};

export default BoxedContainer;
