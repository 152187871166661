import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type InputProps = {
  open: boolean;
  onCancel?: Function;
  onConfirm?: Function;
  title: string;
  content: string | ReactNode;
  deleteText?: string;
  icon?: ReactNode;
  confirmButtonColor?: string;
  confirmButtonEnabled?: boolean;
};

export const ConfirmDeleteDialog = ({
  open,
  onCancel,
  onConfirm,
  title,
  content,
  deleteText = 'delete',
  icon,
  confirmButtonColor,
  confirmButtonEnabled = true,
}: InputProps) => {
  const { t } = useTranslation();

  const handleCancel = React.useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleDelete = React.useCallback(() => {
    if (onConfirm) onConfirm();
    handleCancel();
  }, [onConfirm, handleCancel]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth='xs'>
      {open && (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            {icon ? icon : <Delete sx={{ mr: 1 }} color='error' />} {t(title)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>{content}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleCancel}>
              {t('cancel')}
            </Button>
            <Button
              disabled={!confirmButtonEnabled}
              variant='contained'
              color={!confirmButtonColor ? 'error' : undefined}
              sx={{
                color: !confirmButtonColor ? 'white' : null,
                bgcolor: confirmButtonColor,
              }}
              onClick={handleDelete}>
              {t(`deleteText.${deleteText}`)}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
