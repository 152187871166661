import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useNotifications(): UseQueryResult {
  return useQuery(['notification-settings'], () =>
    apiClient.get(`notification/settings`)
  );
}

export function useNotification(variables?: {
  id: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['notification-setting', variables],
    () => apiClient.get(`notification/settings/${variables?.id}`),
    {
      enabled: !!variables?.id,
      staleTime: 0,
    }
  );
}

export function useUpdateNotificationSettingContacts(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'notification-setting',
    (variables: any) =>
      apiClient.put(`notification/settings/${variables?.notificationSettingId}/contacts`, variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['notification-settings'] });
        queryClient.invalidateQueries({ queryKey: ['notification-setting'] });
      },
    }
  );
}

export function useCheckTriggerForGroup(variables: {
  groupId: number | null;
  eventTrigger: string;
}): UseQueryResult {
  return useQuery(
    ['notification-settings-exist', variables],
    () => apiClient.get(`notification/settings/${variables?.groupId}/${variables?.eventTrigger}`),
    {
      enabled: !!variables?.groupId && !!variables?.eventTrigger,
      staleTime: 0,
    }
  )
}

export function useAddNotification(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'notification-settings',
    (variables: any) =>
      apiClient.post('notification/settings', {
        groupId: variables.groupId,
        eventTrigger: variables.eventTrigger,
        contacts: variables.contacts,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notification-settings');
        queryClient.invalidateQueries('contacts');
      },
    }
  );
}

export function useDeleteNotification(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'notification-settings',
    (notificationSettingId: string) => apiClient.delete(`notification/settings/${notificationSettingId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notification-settings');
        queryClient.invalidateQueries('contacts');
      },
    }
  );
}
