import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import SpeedIcon from '@mui/icons-material/Speed';
import { Box, Button, ButtonGroup } from '@mui/material';
import React from 'react';
import './popup.scss';
import { CloseTwoTone } from '@mui/icons-material';

const LocationPopup = (
  locationDetails: any,
  onClickListAssets: any,
  setPopup: any
) => {
  return (
    <div className='popup'>
      <div className='popup-inner'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3
            style={{
              marginBottom: 0,
              paddingBottom: 0,
              marginTop: '10px',
            }}>
            {locationDetails?.name || ''}{' '}
          </h3>
          <ButtonGroup
            size='small'
            variant='text'
            color='secondary'
            disableRipple={true}
            disableFocusRipple={true}
            sx={{
              '& .MuiButtonGroup-grouped:not(:last-of-type)': {
                borderColor: 'transparent',
              },
              '& .MuiButtonGroup-grouped': {
                minWidth: '30px',
                padding: 0,
                margin: '-2px',
              },
              marginRight: '6px',
            }}>
            <div style={{ width: 24 }}></div>
            {/* <Button onClick={() => setPopup(null)}>
              <CloseTwoTone fontSize='small' />
            </Button> */}
          </ButtonGroup>
        </div>
        {locationDetails?.description && (
          <div
            className='grid-container'
            style={{ marginTop: '4px', marginBottom: '4px' }}>
            {locationDetails?.description && (
              <>
                <div>{locationDetails?.description || 'None'}</div>
              </>
            )}
          </div>
        )}
        {locationDetails?.group && (
          <div
            className='grid-container'
            style={{ marginTop: '4px', marginBottom: '4px' }}>
            {locationDetails?.group?.name && (
              <>
                <div>Group: {locationDetails?.group?.name}</div>
              </>
            )}
          </div>
        )}
        {locationDetails?.exitAlert && (
          <Box sx={{ display: 'flex', alignContent: 'center', mt: 1, mb: 1 }}>
            <CenterFocusStrongIcon
              sx={{
                fontSize: '1.4em',
                mr: 0.5,
                color: 'rgba(255,255,255,0.75)',
              }}
            />
            Exit alert enabled
          </Box>
        )}
        {locationDetails?.entryAlert && (
          <Box sx={{ display: 'flex', alignContent: 'center', mb: 1 }}>
            <CenterFocusWeakIcon
              sx={{
                fontSize: '1.4em',
                mr: 0.5,
                color: 'rgba(255,255,255,0.75)',
              }}
            />
            Entry alert enabled
          </Box>
        )}
        {locationDetails?.speedingThreshold &&
        locationDetails?.speedingThreshold > 0 ? (
          <Box sx={{ display: 'flex', alignContent: 'center', mb: 1 }}>
            <SpeedIcon
              sx={{
                fontSize: '1.4em',
                mr: 0.5,
                color: 'rgba(255,255,255,0.75)',
              }}
            />
            {`Local Speed Limit: ${locationDetails?.speedingThreshold} Km/h`}
          </Box>
        ) : (
          ''
        )}
        {onClickListAssets && (
          <div className='grid-container'>
            {onClickListAssets && (
              <div>
                {(locationDetails?.assetCount || 0) > 0 ? (
                  <a
                    href='#'
                    onClick={onClickListAssets}
                    style={{
                      textDecoration: 'none',
                      color: '#70A8CC',
                      textTransform: 'capitalize',
                    }}>
                    {`${locationDetails?.assetCount || 0} asset${
                      locationDetails?.assetCount > 1 ? 's' : ''
                    } inside`}
                  </a>
                ) : (
                  'No assets inside'
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationPopup;
