import React from 'react';
import { SvgIcon } from "@mui/material";

const DashboardIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'medium'}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <path d="m7.503 10.44 2.304 -2.304a3.581 3.581 0 0 0 -0.125 -0.189l-2.362 2.36 0.182 0.133Z" />
      <path d="m7.112 10.156 2.415 -2.416a3.751 3.751 0 0 0 -0.146 -0.168L6.93 10.022l0.182 0.134Z" />
      <path d="m6.748 9.169 2.088 -2.088a4.226 4.226 0 0 0 -0.186 -0.13l-1.902 1.903v0.315h0Z" />
      <path d="m6.748 8.493 1.678 -1.677a3.884 3.884 0 0 0 -0.209 -0.106l-1.469 1.469v0.315Z" />
      <path d="m6.748 7.818 1.216 -1.217a4.06 4.06 0 0 0 -0.234 -0.081l-0.982 0.983v0.315Z" />
      <path d="m6.748 7.142 0.699 -0.7a4.287 4.287 0 0 0 -0.266 -0.049l-0.433 0.434v0.315Z" />
      <path d="m6.748 9.845 2.455 -2.455a3.628 3.628 0 0 0 -0.166 -0.15l-2.29 2.291v0.315Z" />
      <path d="M6.856 6.358c-0.036 -0.002 -0.072 -0.004 -0.108 -0.005v0.114l0.108 -0.108Z" />
      <path d="m7.893 10.726 2.147 -2.147a3.819 3.819 0 0 0 -0.102 -0.212l-2.228 2.227 0.182 0.133Z" />
      <path d="m8.675 11.297 1.659 -1.659a3.758 3.758 0 0 0 -0.044 -0.272l-1.797 1.797 0.182 0.134Z" />
      <path
        d="m8.285 11.011 1.935 -1.936c-0.023 -0.081 -0.047 -0.161 -0.075 -0.24l-2.043 2.043 0.183 0.132Z" />
      <path
        d="m9.456 11.868 0.768 -0.769c0.037 -0.132 0.066 -0.266 0.089 -0.403l-1.039 1.039 0.182 0.133Z" />
      <path
        d="m9.066 11.582 1.291 -1.29c0.003 -0.065 0.005 -0.131 0.005 -0.197 0 -0.041 -0.001 -0.08 -0.003 -0.121l-1.476 1.476 0.182 0.133Z" />
      <path d="M9.777 12.102c0.088 -0.138 0.167 -0.281 0.236 -0.43l-0.348 0.348 0.112 0.082Z" />
      <g transform="matrix(1.130227 0 0 1.18227 -1.5 -1.636)">
        <path
          d="M21.052 20.161 2.952 20.16C2.16 20.16 1.44 19.68 1.44 18.696V5.304C1.44 4.56 2.16 3.84 2.952 3.84h18.096c0.792 0 1.512 0.72 1.512 1.464v13.392c0 0.984 -0.864 1.464 -1.512 1.464l0.004 0.001ZM2.942 4.625a0.689 0.689 0 0 0 -0.688 0.688v13.375a0.689 0.689 0 0 0 0.688 0.688h18.117a0.689 0.689 0 0 0 0.688 -0.688V5.313a0.689 0.689 0 0 0 -0.688 -0.688H2.942Z"
          stroke={color}
          strokeWidth="0.5px" />
      </g>
      <path
        x="54.59" y="38.227" width="4.041" height="13.23"
        stroke={color}
        strokeWidth="0.5px"
        d="M13.102 9.174H14.071V12.35H13.102V9.174z" />
      <path
        x="61.964"
        y="39.329"
        width="4.04"
        height="12.126"
        stroke={color}
        strokeWidth="0.5px"
        d="M14.871 9.439H15.841V12.349H14.871V9.439z" />
      <path
        x="69.336"
        y="41.536"
        width="4.04"
        height="9.922"
        stroke={color}
        strokeWidth="0.5px"
        d="M16.641 9.969H17.61V12.35H16.641V9.969z" />
      <path
        x="76.709"
        y="36.022"
        width="4.04"
        height="15.434"
        stroke={color}
        strokeWidth="0.5px"
        d="M18.41 8.645H19.38V12.349H18.41V8.645z" />
      <path
        x="84.083"
        y="36.022"
        width="4.04"
        height="15.434"
        stroke={color}
        strokeWidth="0.5px"
        d="M20.18 8.645H21.15V12.349H20.18V8.645z" />
      <path
        points="87.92 60.516 66.7 70.944 58.974 66.269 45.045 72.6 22.477 74.591 13.587 80.909 31.489 80.909 50.142 80.909 69.205 80.909 75.671 80.909 87.858 80.909"
        stroke={color}
        strokeWidth="0.5px"
        d="M21.101 14.524L16.008 17.027L14.154 15.905L10.811 17.424L5.394 17.902L3.261 19.418L7.557 19.418L12.034 19.418L16.609 19.418L18.161 19.418L21.086 19.418Z" />
      <path
        d="M6.61 13.831c-2.067 0 -3.747 -1.68 -3.747 -3.746 0 -2.019 1.606 -3.67 3.606 -3.743v3.706h0.002L9.602 12.336h0c-0.684 0.907 -1.771 1.495 -2.992 1.495Z" />
    </svg>
  </SvgIcon>
}

export default DashboardIcon;