import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black,
    fontSize: '.9rem',
  },
}));

export const InfoHelper = ({ label, sx }: any) => {
  return (
    <>
      <CustomTooltip title={label} arrow>
        <InfoOutlinedIcon sx={{ opacity: 0.8, fontSize: '1.2rem', ...sx }} />
      </CustomTooltip>
    </>
  );
};

export default InfoHelper;
