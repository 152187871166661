import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { fieldToTextField } from 'formik-mui';
import React from 'react';

const AutoComplete = ({ textFieldProps, ...props }: any) => {
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name }: any = field;
  const { setFieldValue, setTouched } = useFormikContext() as any;

  return (
    <Autocomplete
      onChange={(_, value) => setFieldValue(name, value)}
      onBlur={() => setTouched({ [name]: true })}
      isOptionEqualToValue={(item: any, current: any) =>
        item?.id === current?.id
      }
      renderInput={props => (
        <TextField
          key={name}
          {...field}
          {...props}
          {...textFieldProps}
          // helperText={<>{JSON.stringify(helperText)}</>}
          // error={<>{JSON.stringify(error)}</>}
        />
      )}
      getOptionLabel={(option: any) => option.value || ''}
      {...props}
    />
  );
};

export default AutoComplete;
