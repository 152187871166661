import useUIContext from 'context/UIContext';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Label } from 'recharts';

const COLORS: {
  left: 'green' | 'amber' | 'red';
  grade: 'green' | 'amber' | 'red';
  right: 'green' | 'amber' | 'red';
} = { left: 'green', grade: 'amber', right: 'red' };

const GradeColors = {
  green: '#00b300',
  amber: '#ffbf00',
  red: '#FD6262',
};

export default function Gauge({
  label,
  grade,
  color,
  value,
}: {
  label: string;
  grade: 'A' | 'B' | 'C' | 'D' | 'F';
  color: 'green' | 'amber' | 'red';
  value?: any;
}) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const { isMobile } = useUIContext();

  if (!windowSize) return <></>;

  const getTextSize = (type: string) => {
    const width = windowSize[0];
    if (isMobile) {
      if ('label' === type) return '0.8rem';
      if ('grade' === type) return '1rem';
    }
    if (width < 1200) {
      if ('label' === type) return '1vw';
      if ('grade' === type) return '3vw';
    }
    if (width < 1536) {
      if ('label' === type) return '0.8vw';
      if ('grade' === type) return '2rem';
    }
    if ('label' === type) return '0.8rem';
    if ('grade' === type) return '2.5rem';
  };

  const getChartSize = () => {
    const width = windowSize[0];
    if (width < 1200) {
      return width * 0.175;
    }
    return width * 0.08;
  };

  const getCy = () => {
    const width = windowSize[0];
    if (isMobile) {
      return '40%';
    }
    if (width < 1200) {
      return '65%';
    }
    if (width < 1280) {
      return width * 0.035;
    }
    if (width < 1536) {
      return width * 0.04;
    }
    if (width < 1720) {
      return width * 0.04;
    }
    return width * 0.05;
  };

  const getArch = () => {
    const width = windowSize[0];
    if (isMobile) {
      return {
        innerRadius: '50%',
        outerRadius: '68%',
        cornerRadius: '36%',
      };
    }
    if (width < 1200) {
      return {
        innerRadius: '38%',
        outerRadius: '48%',
        cornerRadius: '16%',
      };
    }
    return {
      innerRadius: '50%',
      outerRadius: '68%',
      cornerRadius: '36%',
    };
  };

  return (
    <PieChart width={getChartSize()} height={getChartSize()}>
      <Pie
        cy={getCy()}
        valueKey={'name'}
        data={[
          {
            label,
            grade,
            value: parseFloat(value) || 1,
          },
        ]}
        animationBegin={250}
        animationDuration={2500}
        startAngle={215}
        endAngle={-35}
        innerRadius={getArch().innerRadius}
        outerRadius={getArch().outerRadius}
        cornerRadius={getArch().cornerRadius}
        paddingAngle={0}
        fill={GradeColors[color]}
        dataKey='value'>
        <Cell key={`cell-${label}`} fill={GradeColors[color]} />
        <Label
          value={grade}
          style={{ color: 'white' }}
          position='centerBottom'
          dy={isMobile ? 6 : 12}
          className='gauge-label'
          fill='white'
          fontSize={getTextSize('grade')}
          fontWeight='bold'
        />
        <Label
          value={
            isMobile
              ? t(`driver.behavior.short.${label}`)
              : t(`driver.behavior.${label}`)
          }
          style={{ fill: 'rgba(255,255,255,0.8)' }}
          position='centerBottom'
          dy={isMobile ? 24 : 44}
          className='gauge-description'
          fontSize={getTextSize('label')}
        />
      </Pie>
    </PieChart>
  );
}
