import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: [
      'all',
      ['has', 'speed'],
      ['has', 'heading'],
      ['>=', ['get', 'speed'], 5],
      ['>=', ['get', 'heading'], 0],
      ['has', 'assetType'],
      ['==', ['get', 'assetType'], 'vehicle'],
      ['!', ['has', 'point_count']],
    ],
    layout: {
      'icon-image': [
        'match',
        ['get', 'activeAlert'],
        'panic',
        'heading_panic',
        'heading',
      ],
      // 'icon-image': 'heading',
      "icon-rotate": ['get', 'heading'],
      "icon-rotation-alignment": "map",
      'icon-anchor': 'center',
      'icon-offset': [17, -24],
      'icon-allow-overlap': true,
      'icon-size': 0.3,
    },
    paint: {
      // 'icon-color': '#00a8ff',
      // 'icon-color': '#ffffff',
    },
  };
};
