import { DirectionsBoatFilledSharp, LocalShipping, Router } from "@mui/icons-material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import ContainerIcon from "components/CustomIcons/ContainerIcon";
import TrailerIcon from "components/CustomIcons/TrailerIcon";
import DashboardIcon from "../../CustomIcons/DashboardIcon";
import DriverStatusAltIcon from "../../CustomIcons/DriverStatusAltIcon";

export default [
  {
    name: 'Map',
    path: '/',
    icon: MapTwoToneIcon,
    className: 'main-nav-item-map',
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: SummarizeTwoToneIcon,
    className: 'main-nav-item-reports',
    hideOnMobile: true,
  },
  {
    name: 'Drivers',
    path: '/driver-status',
    userCapabilities: ['hasVehicles'],
    icon: DriverStatusAltIcon,
    className: 'main-nav-item-driver-status',
  },
  {
    name: 'Vehicles',
    path: '/vehicle-status',
    userCapabilities: ['hasVehicles'],
    icon: LocalShipping,
    className: 'main-nav-item-vehicle-status',
  },
  {
    name: 'Trailers',
    path: '/trailer-status',
    userCapabilities: ['hasTrailers'],
    icon: TrailerIcon,
    className: 'main-nav-item-trailers-status',
  },
  {
    name: 'Containers',
    path: '/container-status',
    userCapabilities: ['hasContainers'],
    icon: ContainerIcon,
    className: 'main-nav-item-containers-status',
  },
  {
    name: 'AIS Vessels',
    path: '/vessel-ais-status',
    userCapabilities: ['hasAISVessels'],
    icon: Router,
    className: 'main-nav-item-vessel-ais-status',
    hideOnMobile: true,
  },
  {
    name: 'Vessels',
    path: '/vessel-status',
    userCapabilities: ['hasVessels'],
    icon: DirectionsBoatFilledSharp,
    className: 'main-nav-item-vessel-status',
    hideOnMobile: true,
  },
  {
    name: 'Dashboards',
    path: '/dashboard',
    userCapabilities: ['hasVehicles', 'hasAISVessels', 'hasVessels'],
    someUserCapabilities: true,
    icon: DashboardIcon,
    className: 'main-nav-item-vehicle-dashboard',
    hideOnMobile: true,
  },
  {
    name: 'Help',
    action: 'helpCenter',
    icon: HelpOutlineIcon,
    alignment: 'bottom',
    className: 'main-nav-item-settings',
    hideOnMobile: true,
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: SettingsTwoToneIcon,
    alignment: 'bottom',
    className: 'main-nav-item-settings',
    hideOnMobile: true,
  },
];
