import React, { useState } from 'react';
// eslint-disable-next-line import/named
import { AddCircleOutline, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import {
  useDeleteNotification,
  useNotifications,
} from 'hooks/notifications/useNotifications';
import { useTranslation } from 'react-i18next';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
};

function getColumns({
  t,
  handleDeleteNotificationSetting,
  handleEditNotificationSetting,
  isDeleting,
  canWrite,
}: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'group',
      headerName: 'Group',
      ...commonColDefOptions,
      renderCell: r => r?.row?.group?.name,
    }, // @ts-ignore
    {
      field: 'eventTrigger',
      headerName: 'Notification trigger',
      ...commonColDefOptions,
      renderCell: r => t(`event_trigger.${r?.row?.eventTrigger}`),
    }, // @ts-ignore
    {
      field: 'contacts',
      headerName: '# Contacts',
      ...commonColDefOptions,
      renderCell: r => {
        if (!r?.row?.contacts || !r?.row?.contacts?.length) return 0;
        return (
          <Tooltip
            title={
              <ol style={{ paddingLeft: 16 }}>
                {r?.row?.contacts?.map((contact: any) => (
                  <li key={contact.contactId}>
                    {contact.firstName}&nbsp;{contact.lastName}&nbsp;(via&nbsp;
                    {contact.channels.join(', ')})
                  </li>
                ))}
              </ol>
            }>
            <div>{r?.row?.contacts.length.toString()}</div>
          </Tooltip>
        );
      },
    }, // @ts-ignore
    ...(!!canWrite('NOTIFICATION')
      ? [
          {
            headerName: '',
            ...commonColDefOptions,
            minWidth: 90,
            maxWidth: 100,
            align: 'center',
            flex: 0.5,
            renderCell: (r: any) => {
              return (
                <>
                  <Tooltip title='Edit Notification'>
                    <IconButton
                      disabled={false || !r?.row?.editable || isDeleting}
                      onClick={() =>
                        handleEditNotificationSetting(
                          r?.row.notificationSettingId
                        )
                      }>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete Notification'>
                    <IconButton
                      disabled={!r?.row?.editable || isDeleting}
                      onClick={() => handleDeleteNotificationSetting(r?.row)}>
                      <Delete fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ]
      : []),
  ];
}

export const Notifications = () => {
  const { data, isLoading } = useNotifications() as any;
  const { mutate, isLoading: isDeleting } = useDeleteNotification();
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialog, setDeleteDialog] = useState<any>(null);
  const { t } = useTranslation();
  const { setActive } = useActionMenuContext();
  const { openRightSidebar } = useUIContext();
  const { canWrite } = usePermissions();

  const handleAddNotificationSetting = () => {
    setActive(ActionMenuModuleTypes.AddNotificationSetting);
    openRightSidebar();
  };

  const handleEditNotificationSetting = (id: any) => {
    setActive(ActionMenuModuleTypes.EditNotificationSetting, {
      notificationId: id,
    });
    openRightSidebar();
  };

  const handleDeleteNotificationSetting = (notificationSetting: any) => {
    setDeleteDialog(notificationSetting);
  };

  return (
    <BoxedContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '30%' }}>
          {/* <Formik
            validateOnMount={true}
            initialValues={{
              search: '',
            }}
            validationSchema={GlobalSearchFormSchema(t)}
            validateOnChange={false}
            validateOnBlur={false}
            onReset={() => {
              setSearchTerm('');
            }}
            onSubmit={(values: any, { setSubmitting }: any) => {
              setSearchTerm(values.search);
              setSubmitting(false);
            }}>
            {(props: any) => (
              <SearchInputForm
                {...props}
                isLoading={isLoading}
                setSearchTerm={setSearchTerm}
                placeholder={t('notification_search.placeholder')}
              />
            )}
          </Formik> */}
        </Box>

        {canWrite('NOTIFICATION') && (
          <Button
            onClick={handleAddNotificationSetting}
            color='secondary'
            variant='outlined'
            startIcon={<AddCircleOutline />}>
            {t('add_notification')}
          </Button>
        )}
      </Box>
      <Table
        data={data}
        isLoading={isLoading}
        getRowId={(row: any) => row.notificationSettingId}
        columns={getColumns({
          t,
          handleDeleteNotificationSetting,
          handleEditNotificationSetting,
          isDeleting,
          canWrite,
        })}
        disableColumnFilter={true}
        disableColumnMenu={true}
        onPageChange={() => {}}
      />
      <div style={{ marginTop: '0.5em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          1. Notifications are rate limited to 1 notification per asset, per
          notification type, per minute for WhatsApp; and 1 notification per
          asset, per notification type, per 2 minutes for SMS and Email.
        </Typography>
      </div>
      <ConfirmDeleteDialog
        open={!!deleteDialog}
        onCancel={() => {
          setDeleteDialog(null);
        }}
        onConfirm={() => {
          if (deleteDialog) mutate(deleteDialog?.notificationSettingId);
        }}
        title={'Confirm to delete asset'}
        content={
          <>
            Are you sure you want to delete the{' '}
            <b>{t(`event_trigger.${deleteDialog?.eventTrigger}`)}</b>{' '}
            notification for <b>{deleteDialog?.group?.name}</b>?
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Notifications;
