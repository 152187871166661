import { Collapse } from '@mui/material';
import useActionMenuContext from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import {
  sortAlerts,
  useAlerts,
  useClearAlert,
  useClearAllAlertsByType,
  useCurrentUser,
  useForceClose,
} from 'hooks';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDeleteDialog from '../SystemDialogs/ConfirmDeleteDialog';
import OptionsButton from './OptionsButton';
import './notifications.scss';
// @ts-ignore
import PanicSound from 'assets/panic-alert.wav';
import { useQueryClient } from 'react-query';
import {
  CancelTwoTone,
  VisibilityTwoTone,
  WarningTwoTone,
} from '@mui/icons-material';

const NOTIFICATION_PANEL_WIDTH = 300;

const notificationStyle = {
  container: {
    right: 416,
    position: 'absolute',
    zIndex: 999,
    color: 'white',
    width: NOTIFICATION_PANEL_WIDTH,
    transitionDuration: '0.2s',
  },
  stack: {
    marginTop: 12,
    transition: 'top 200ms',
    overflow: 'visible',
    zIndex: 998,
  },
  stackedItem: {
    width: NOTIFICATION_PANEL_WIDTH - 10,
    marginLeft: 5,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxSizing: 'border-box',
    minHeight: 6,
    marginTop: -8,
  },
  stackedItemTwo: {
    width: NOTIFICATION_PANEL_WIDTH - 20,
    marginLeft: 10,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxSizing: 'border-box',
    minHeight: 5,
  },
  item: {
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: 8,
    flex: 1,
    marginBottom: 8,
    position: 'relative',
  },
  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  collapsing: {
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: 8,
    flex: 1,
    marginBottom: 8,
    position: 'relative',
    opacity: 0.5,
  },
} as const;

const Notification = ({
  index,
  type,
  title,
  text,
  actions,
  time,
  expanded = false,
  isCollapsing = false,
  onActionsShown,
  style,
  key,
  shouldBlur,
  className,
  parentStyle,
  status,
  endReportId,
  inClearingState,
  onActionClicked,
}: {
  index: number;
  type: string;
  title: string;
  text: string;
  actions?: any;
  time: string;
  expanded?: boolean;
  isCollapsing?: boolean;
  onActionsShown?: Function;
  style?: any;
  key?: any;
  shouldBlur?: boolean;
  className?: string;
  parentStyle?: any;
  status: string;
  endReportId?: any;
  inClearingState?: boolean;
  onActionClicked?: Function;
}) => {
  const uiContext = useUIContext();
  const [isHovered, setIsHovered] = React.useState(uiContext.isMobile);

  const isGreyedOut = status === 'cleared' && !endReportId;

  return (
    <Collapse
      in={!isCollapsing}
      timeout={200}
      collapsedSize={66}
      mountOnEnter
      unmountOnExit
      style={parentStyle}
      sx={{
        zIndex: 1001,
        '& .MuiCollapse-wrapperInner': {
          zIndex: 1001,
          ...parentStyle,
        },
      }}>
      <div
        style={{
          ...notificationStyle.item,
          ...parentStyle,
          cursor: expanded ? 'default' : 'pointer',
          display: 'block',
          backgroundColor: getNotificationColor(type, isGreyedOut).item,
        }}
        className={className}
        onMouseOver={() => {
          if (!uiContext.isMobile) setIsHovered(true);
        }}
        onMouseOut={() => {
          if (!uiContext.isMobile) setIsHovered(false);
        }}>
        <div style={style}>
          <div style={notificationStyle.header}>
            <div>
              <b style={{ fontSize: 14 }}>{title}</b>
            </div>
            <div>
              <small style={{ display: 'flex', alignItems: 'flex-start' }}>
                {time}
              </small>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            position: 'relative',
          }}>
          <div style={{ fontSize: 14, ...style, minHeight: '42px' }}>
            {text}
          </div>
          <OptionsButton
            onActionsShown={onActionsShown}
            actions={actions}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            expanded={expanded}
            inClearingState={inClearingState}
            onActionClicked={onActionClicked}
            zIndex={parentStyle?.zIndex}
          />
        </div>
      </div>
    </Collapse>
  );
};

const getNotificationColor = (type: string, isGreyedOut?: boolean) => {
  if (isGreyedOut) {
    return {
      item: 'rgba(185, 185, 185, 0.75)',
      stackedItem: 'rgba(126, 126, 126, 0.75)',
      stackedItemTwo: 'rgba(94, 94, 94, 0.75)',
    };
  }
  switch (type) {
    case 'panic':
      return {
        item: 'rgba(190, 91, 91, 0.75)',
        stackedItem: 'rgba(135, 64, 64, 0.75)',
        stackedItemTwo: 'rgba(103, 49, 49, 0.75)',
      };
    case 'location':
    case 'speeding':
      return {
        item: 'rgba(157, 142, 99, 0.75)',
        stackedItem: 'rgba(131, 124, 101, 0.75)',
        stackedItemTwo: 'rgba(90, 87, 77, 0.75)',
      };
    case 'high_pressure':
    case 'high_temperature':
    case 'low_pressure':
    case 'low_temperature':
      return {
        item: 'rgba(204, 151, 82, 0.75)',
        stackedItem: 'rgba(171, 130, 75, 0.75)',
        stackedItemTwo: 'rgba(117, 94, 63, 0.75)',
      };
    default:
      return {
        item: 'rgba(190, 91, 91, 0.75)',
        stackedItem: 'rgba(135, 64, 64, 0.75)',
        stackedItemTwo: 'rgba(103, 49, 49, 0.75)',
      };
  }
};

const formatPressureTempAlert = (alert: any) => {
  const type = alert.alertType.includes('pressure')
    ? 'pressure'
    : 'temperature';
  const sign = alert.alertType.includes('high') ? 'exceeded' : 'dropped below';
  const timestamp = moment(alert.startedAt).format('HH:mm:ss');
  const unit = alert.alertType.includes('pressure') ? ' psi' : '°C';

  return `${alert.asset?.name} ${sign} ${alert.details.assetDetails.maxPressure}${unit} (${alert.details?.report?.pressure}${unit}) at ${timestamp}`;
};

const NotificationStack = ({
  children,
  alertType,
  zIndex,
  shouldBlur,
  setShouldBlur,
}: {
  children: any;
  alertType: 'panic' | 'location' | 'pressure' | 'speeding' | 'accident';
  zIndex: number;
  shouldBlur: boolean;
  setShouldBlur: Function;
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [shouldCollapse, setShouldCollapse] = React.useState(false);
  const [visibleItems, setVisibleItems] = React.useState<number>(1);
  const delayTimeout = React.useRef<any>(null);
  const [clearAllDialog, setClearAllDialog] = React.useState<any>(null);
  const { mutate: clearAlerts } = useClearAllAlertsByType();
  const queryClient = useQueryClient();
  const uiContext = useUIContext();

  React.useEffect(() => {
    setVisibleItems(children.length);
  }, [children]);

  const handleMouseEnter = () => {
    if (uiContext.isMobile) return;
    if (delayTimeout.current) {
      clearTimeout(delayTimeout.current);
      delayTimeout.current = null;
    }
  };

  const handleMouseLeave = () => {
    if (uiContext.isMobile) return;
    setExpanded(false);
    setShouldCollapse(true);
    delayTimeout.current = setTimeout(() => {
      setShouldCollapse(false);
      queryClient.setQueryData('alerts', (old: any) => {
        return sortAlerts(old);
      });
    }, 10);
    // setShouldCollapse(true);
  };

  const handleClick = () => {
    setExpanded(true);
    setShouldCollapse(false);
    setVisibleItems(children.length);
  };

  if (children.length == 2 && !expanded && !shouldCollapse) {
    return (
      <div
        style={{
          ...notificationStyle.stack,
          WebkitFilter: shouldBlur ? 'blur(1px)' : 'none',
        }}
        onClick={() => handleClick()}>
        {React.cloneElement(children[0])}
        <div
          style={{
            ...notificationStyle.stackedItem,
            backgroundColor: getNotificationColor(
              children[1].props.type,
              children[1].props.status === 'cleared'
            ).stackedItem,
            ...(uiContext.isMobile && {
              width: 'calc( 100dvw - 64px - 10px)',
            }),
          }}></div>
      </div>
    );
  }

  if (children.length > 2 && !expanded && !shouldCollapse) {
    return (
      <div
        style={{
          ...notificationStyle.stack,
          WebkitFilter: shouldBlur ? 'blur(1px)' : 'none',
        }}
        onClick={() => handleClick()}>
        {React.cloneElement(children[0])}
        <div
          style={{
            ...notificationStyle.stackedItem,
            backgroundColor: getNotificationColor(
              children[1].props.type,
              children[1].props.status === 'cleared'
            ).stackedItem,
            ...(uiContext.isMobile && {
              width: 'calc( 100dvw - 64px - 10px)',
            }),
          }}></div>
        <div
          style={{
            ...notificationStyle.stackedItemTwo,
            backgroundColor: getNotificationColor(
              children[2].props.type,
              children[2].props.status === 'cleared'
            ).stackedItemTwo,
            ...(uiContext.isMobile && {
              width: 'calc( 100dvw - 64px - 20px)',
            }),
          }}></div>
      </div>
    );
  }

  const showLessClassName = () => {
    if (expanded) {
      return 'show-less-slide show-less-slide-in';
    }
  };

  return (
    <Collapse
      in={!shouldCollapse}
      timeout={200}
      collapsedSize={66}
      mountOnEnter
      unmountOnExit
      style={{
        zIndex,
      }}>
      <div
        onMouseEnter={() => {
          if (!uiContext.isMobile) handleMouseEnter();
        }}
        onMouseLeave={() => {
          if (!uiContext.isMobile) handleMouseLeave();
        }}
        style={{
          ...notificationStyle.stack,
          paddingTop: children.length > 1 ? 24 : 0,
          zIndex,
          ...(uiContext.isMobile && {
            overflowY: 'scroll',
          }),
        }}>
        {children.length > 1 && expanded && !shouldCollapse && (
          <div
            className={showLessClassName()}
            onClick={() => setClearAllDialog({ alertType })}
            style={{
              position: 'relative',
              marginTop: -16,
              marginBottom: 16,
              textTransform: 'uppercase',
              zIndex: 1001,
              fontSize: 10,
              backgroundColor: 'rgba(44, 50, 56, 0.6)',
              padding: 6,
              borderRadius: 16,
              cursor: 'pointer',
              width: 'auto',
              textAlign: 'center',
            }}>
            Clear all {alertType} alerts
          </div>
        )}
        {children.map((child: any, index: number) => {
          return React.cloneElement(child, {
            expanded: true,
            shouldBlur,
            isCollapsing: shouldCollapse,
            style: {
              top: 0,
              // transition: 'all .5s ease-in-out',
              WebkitFilter: shouldBlur ? 'blur(1px)' : 'none',
            },
            parentStyle: {
              zIndex: 9999 - index,
            },
            onActionsShown: (e: any) => !uiContext.isMobile && setShouldBlur(e),
          });
        })}
        {children.length > 1 && expanded && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}></div>
        )}
        <ConfirmDeleteDialog
          open={!!clearAllDialog}
          title={`Clear all ${clearAllDialog?.alertType} alerts`}
          content={`Are you sure that you want to clear all ${clearAllDialog?.alertType} alerts?`}
          onCancel={() => setClearAllDialog(null)}
          onConfirm={() => clearAlerts({ alertType })}
          deleteText={'clearAll'}
        />
      </div>
    </Collapse>
  );
};

const Notifications = () => {
  const [showAlerts, setShowAlerts] = React.useState<boolean>(false);
  const uiContext = useUIContext();
  const { data } = useAlerts() as any;
  const clearAlert = useClearAlert();
  const forceClose = useForceClose();
  const { setMainMapField } = useActionMenuContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [shouldBlur, setShouldBlur] = React.useState<boolean>(false);
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const { data: userData } = useCurrentUser() as any;
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [prevAlertCount, setPrevAlertCount] = React.useState<number>(0);

  const playAudibleAlert = (
    audibleAlertSetting: 'off' | 'once' | 'loop' = 'off'
  ) => {
    if (audibleAlertSetting === 'off' || !data || data?.length === 0) {
      audioPlayer?.current?.removeAttribute('loop');
      audioPlayer?.current?.pause();
      setIsPlaying(false);
      return;
    }

    if (!!data && data?.length > prevAlertCount) {
      if (audibleAlertSetting === 'loop') {
        audioPlayer?.current?.setAttribute('loop', 'true');
      } else {
        audioPlayer?.current?.removeAttribute('loop');
      }

      if (!isPlaying) {
        audioPlayer?.current
          ?.play()
          .then(() => setIsPlaying(true))
          .catch((e: any) => {
            console.log(e);
            setIsPlaying(false);
            setTimeout(() => {
              playAudibleAlert(audibleAlertSetting);
            }, 1000);
          });
      }
    }
  };

  useEffect(() => {
    playAudibleAlert(userData?.settings?.audibleAlertSetting);
    setPrevAlertCount(data?.length || 0);
  }, [data, userData]);

  const navigateIfNotMap = () => {
    if ('/' !== location.pathname) {
      navigate('/');
    }
  };

  const getNotificationDetailsByType = (alert: any) => {
    if (['panic', 'accident'].includes(alert.alertType)) {
      const isWithin5Minutes = !!moment(alert.clearedAt).isAfter(
        moment().subtract(5, 'minutes')
      );
      const canForceClose =
        !isWithin5Minutes &&
        alert.clearedCount >= 3 &&
        alert.alertType !== 'accident';
      const inClearingState = alert.clearedAt && isWithin5Minutes;
      return {
        title: `${t(`alert.types.${alert.alertType}`)} Alert`,
        text: alert.startReport?.formattedAddress
          ? `${alert.asset?.name} (near ${alert.startReport?.formattedAddress})`
          : alert.asset?.name,
        inClearingState,
        actions: [
          {
            title: t('Show'),
            icon: VisibilityTwoTone,
            onClick: () => {
              // setMainMapField('flyTo', alert.startReport?.position);
              setMainMapField('activeAssetId', alert.asset?.assetId);
              navigateIfNotMap();
            },
          },
          {
            title: t('Clear'),
            icon: CancelTwoTone,
            onClick: () => clearAlert.mutate(alert.alertId),
            disabled: inClearingState,
            disabledTooltip: t('AlertClearedRecently'),
          },
          ...(canForceClose
            ? [
                {
                  title: t('Force Close'),
                  icon: WarningTwoTone,
                  onClick: () => forceClose.mutate(alert.alertId),
                },
              ]
            : []),
          ,
        ],
      };
    }
    if (
      alert.alertType.includes('pressure') ||
      alert.alertType.includes('temperature')
    ) {
      return {
        title: `${t(`alert.types.${alert.alertType}`)} Alert`,
        text: formatPressureTempAlert(alert),
        actions: [
          {
            title: 'Show',
            icon: VisibilityTwoTone,
            onClick: () => {
              // setMainMapField('flyTo', alert.startReport?.position);
              setMainMapField('activeAssetId', alert.asset?.assetId);
              navigateIfNotMap();
            },
          },
          {
            title: 'Clear',
            icon: CancelTwoTone,
            onClick: () => clearAlert.mutate(alert.alertId),
          },
        ],
      };
    }
    if (['location', 'speeding'].includes(alert.alertType)) {
      let title = '';
      let text = '';

      switch (alert.alertType) {
        case 'location':
          title = 'Location Alert';
          text = `${alert.asset?.name} entered ${
            alert.details?.location?.locationName
          } at ${moment(alert.startedAt).format('HH:mm:ss')}`;
          break;

        case 'speeding':
          title = 'Speeding Alert';
          text = `${alert.asset?.name} exceeded the local speed limit in ${alert.details?.location?.locationName} (${alert.details.location.speedingThreshold}km/h)`;
          break;
      }

      return {
        title: title,
        text: text,
        actions: [
          {
            title: 'Show',
            icon: VisibilityTwoTone,
            onClick: () => {
              // setMainMapField('flyTo', alert.startReport?.position);
              setMainMapField('activeAssetId', alert.asset?.assetId);
              navigateIfNotMap();
            },
          },
          {
            title: 'Clear',
            icon: CancelTwoTone,
            onClick: () => clearAlert.mutate(alert.alertId),
          },
        ],
      };
    }
    return {
      title: '-',
      text: '-',
    };
  };

  const alertTypePriorities = [
    'panic',
    'location',
    'high_pressure',
    'low_pressure',
    'high_temperature',
    'low_temperature',
  ];
  const getDefaultAlertType = ():
    | 'panic'
    | 'location'
    | 'high_pressure'
    | 'low_pressure'
    | 'high_temperature'
    | 'low_temperature'
    | 'default' => {
    const alertTypes = new Set(data.map((alert: any) => alert.alertType));
    const res = Array.from(alertTypes).sort((a: any, b: any) => {
      return alertTypePriorities.indexOf(a) - alertTypePriorities.indexOf(b);
    });

    return (
      (res[0] as
        | 'panic'
        | 'location'
        | 'high_pressure'
        | 'low_pressure'
        | 'high_temperature'
        | 'low_temperature') || 'default'
    );
  };

  if (!data || data.length === 0) return <></>;

  return (
    <div
      onMouseEnter={() => {
        if (!uiContext.isMobile) setShowAlerts(true);
      }}
      onMouseLeave={() => {
        if (!uiContext.isMobile) setShowAlerts(false);
      }}
      style={{
        ...notificationStyle.container,
        ...('rtl' === uiContext.direction
          ? {
              left: uiContext.isMobile
                ? 44
                : uiContext.rightSideBarOpen
                ? 416
                : 81,
            }
          : {
              right: uiContext.isMobile
                ? 20
                : uiContext.rightSideBarOpen
                ? 416
                : 81,
            }),
        overflowY: 'visible',
        ...(uiContext.isMobile && {
          position: 'fixed',
          ...(showAlerts && {
            paddingBottom: '140px',
            height: '100dvh',
            overflowY: 'scroll',
          }),
        }),
        top: 0,
        width: uiContext.isMobile
          ? 'calc( 100dvw - 64px )'
          : NOTIFICATION_PANEL_WIDTH,
        zIndex: 1002,
      }}>
      <audio ref={audioPlayer} src={PanicSound} />
      {('/' !== location.pathname || uiContext.isMobile) && data && (
        <div
          style={{
            ...notificationStyle.stack,
          }}
          onClick={() => {
            if (uiContext.isMobile) setShowAlerts(!showAlerts);
          }}>
          <div
            style={{
              ...notificationStyle.item,
              cursor: 'pointer',
              display: 'block',
              backgroundColor: getNotificationColor(getDefaultAlertType()).item,
              textAlign: 'center',
            }}>
            {data.length} {t('alerts')}
          </div>
        </div>
      )}
      <Collapse
        in={('/' === location.pathname && !uiContext.isMobile) || showAlerts}
        timeout={300}
        collapsedSize={0}
        mountOnEnter
        unmountOnExit
        sx={{
          overflowY: 'visible',
        }}>
        <div>
          {data?.filter((alert: any) => 'panic' === alert.alertType).length >
            0 && (
            <NotificationStack
              alertType={'panic'}
              zIndex={1000}
              shouldBlur={shouldBlur}
              setShouldBlur={setShouldBlur}>
              {data
                ?.filter((alert: any) => 'panic' === alert.alertType)
                .map((alert: any, index: number) => (
                  <Notification
                    index={index}
                    key={alert.alertId}
                    type={alert.alertType}
                    title={getNotificationDetailsByType(alert).title}
                    text={getNotificationDetailsByType(alert).text}
                    actions={getNotificationDetailsByType(alert).actions}
                    inClearingState={
                      getNotificationDetailsByType(alert).inClearingState
                    }
                    time={moment(alert.startedAt).fromNow()}
                    status={alert.status}
                    endReportId={alert.endReportId}
                    onActionClicked={() => setShowAlerts(false)}
                  />
                ))}
            </NotificationStack>
          )}

          {data?.filter((alert: any) => 'accident' === alert.alertType).length >
            0 && (
            <NotificationStack
              alertType={'accident'}
              zIndex={999}
              shouldBlur={shouldBlur}
              setShouldBlur={setShouldBlur}>
              {data
                ?.filter((alert: any) => 'accident' === alert.alertType)
                .map((alert: any, index: number) => (
                  <Notification
                    index={index}
                    key={alert.alertId}
                    type={alert.alertType}
                    title={getNotificationDetailsByType(alert).title}
                    text={getNotificationDetailsByType(alert).text}
                    actions={getNotificationDetailsByType(alert).actions}
                    inClearingState={
                      getNotificationDetailsByType(alert).inClearingState
                    }
                    time={moment(alert.startedAt).fromNow()}
                    status={alert.status}
                    endReportId={alert.endReportId}
                    onActionClicked={() => setShowAlerts(false)}
                  />
                ))}
            </NotificationStack>
          )}
          {data?.filter(
            (alert: any) =>
              [
                'high_pressure',
                'high_temperature',
                'low_pressure',
                'low_temperature',
              ].indexOf(alert.alertType) >= 0
          ).length > 0 && (
            <NotificationStack
              alertType={'pressure'}
              zIndex={998}
              shouldBlur={shouldBlur}
              setShouldBlur={setShouldBlur}>
              {data
                ?.filter(
                  (alert: any) =>
                    [
                      'high_pressure',
                      'high_temperature',
                      'low_pressure',
                      'low_temperature',
                    ].indexOf(alert.alertType) >= 0
                )
                .map((alert: any, index: number) => (
                  <Notification
                    index={index}
                    key={alert.alertId}
                    type={alert.alertType}
                    title={getNotificationDetailsByType(alert).title}
                    text={getNotificationDetailsByType(alert).text}
                    actions={getNotificationDetailsByType(alert).actions}
                    time={moment(alert.startedAt).fromNow()}
                    status={alert.status}
                    endReportId={alert.endReportId}
                    onActionClicked={() => setShowAlerts(false)}
                  />
                ))}
            </NotificationStack>
          )}
          {data?.filter((alert: any) => 'location' === alert.alertType).length >
            0 && (
            <NotificationStack
              alertType={'location'}
              zIndex={997}
              shouldBlur={shouldBlur}
              setShouldBlur={setShouldBlur}>
              {data
                ?.filter((alert: any) => 'location' === alert.alertType)
                .map((alert: any, index: number) => (
                  <Notification
                    index={index}
                    key={alert.alertId}
                    type={alert.alertType}
                    title={getNotificationDetailsByType(alert).title}
                    text={getNotificationDetailsByType(alert).text}
                    actions={getNotificationDetailsByType(alert).actions}
                    time={moment(alert.startedAt).fromNow()}
                    status={alert.status}
                    endReportId={alert.endReportId}
                    onActionClicked={() => setShowAlerts(false)}
                  />
                ))}
            </NotificationStack>
          )}
          {data?.filter((alert: any) => 'speeding' === alert.alertType).length >
            0 && (
            <NotificationStack
              alertType={'speeding'}
              zIndex={996}
              shouldBlur={shouldBlur}
              setShouldBlur={setShouldBlur}>
              {data
                ?.filter((alert: any) => 'speeding' === alert.alertType)
                .map((alert: any, index: number) => (
                  <Notification
                    index={index}
                    key={alert.alertId}
                    type={alert.alertType}
                    title={getNotificationDetailsByType(alert).title}
                    text={getNotificationDetailsByType(alert).text}
                    actions={getNotificationDetailsByType(alert).actions}
                    time={moment(alert.startedAt).fromNow()}
                    status={alert.status}
                    endReportId={alert.endReportId}
                    onActionClicked={() => setShowAlerts(false)}
                  />
                ))}
            </NotificationStack>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default Notifications;
