import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';
import { Group } from 'types';

export function useGroups(
  variables?:
    | {
        groupIds: number[] | null | undefined;
      }
    | null
    | undefined
): UseQueryResult {
  const hasGroupIds = variables?.groupIds && variables?.groupIds?.length > 0;
  return useQuery(
    ['groups', variables],
    () =>
      apiClient.get('groups', {
        params: {
          groupIds: variables?.groupIds,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useGroup(variables?: {
  groupId: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['groups', variables],
    () => apiClient.get(`groups/${variables?.groupId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!variables?.groupId,
      staleTime: 0,
    }
  );
}

export function useAddGroup(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'groups',
    (variables: Group) =>
      apiClient.post('groups', {
        name: variables.name,
        parentGroupId: variables.parentGroupId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups');
        queryClient.invalidateQueries(['filters/groups']);
      },
    }
  );
}

export function useUpdateGroup(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'groups',
    (variables: Group) =>
      apiClient.put(`groups/${variables.groupId}`, {
        ...variables,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups');
        queryClient.invalidateQueries(['filters/groups']);
      },
    }
  );
}

export function useMoveGroup(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'groups',
    (variables: Group) =>
      apiClient.post(`groups/update/structure`, {
        updatedGroups: [
          {
            groupId: variables.groupId,
            ...(variables.index && {
              parentId: parseInt(variables.index.replace('group_', '')),
            }),
          },
        ],
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups');
        queryClient.invalidateQueries(['filters/groups']);
      },
    }
  );
}

export function useDeleteGroup(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'group_delete',
    (variables: Group) => apiClient.delete(`groups/${variables?.groupId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups');
        queryClient.invalidateQueries(['filters/groups']);
      },
    }
  );
}
