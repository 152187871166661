import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: [
      'all',
      ['has', 'heading'],
      ['>=', ['get', 'heading'], 0],
      ['has', 'assetType'],
      ['==', ['get', 'assetType'], 'security_camera'],
      ['!', ['has', 'point_count']],
    ],
    layout: {
      'icon-image': 'security_camera_fov_image',
      'icon-rotation-alignment': 'map',
      'icon-anchor': 'center',
      'icon-offset': [-2, -45],
      'icon-allow-overlap': true,
      'icon-size': 0.5,
      'icon-rotate': ['get', 'heading'],
    },
    paint: {
      'icon-opacity': ['interpolate', ['linear'], ['zoom'], 10, 0, 12, 1],
    },
  };
};
