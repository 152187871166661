// SchemaDetails.tsx
import React from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { schemaToExample } from './helpers';

interface SchemaDetailsProps {
  schema: any;
}

const SchemaDetails: React.FC<SchemaDetailsProps> = ({ schema }) => {
  if (!schema) {
    return <Typography>No schema provided.</Typography>;
  }

  const mediaType = Object.keys(schema)[0];
  const mediaSchema = schema[mediaType]?.schema || schema[mediaType];

  if (!mediaSchema) {
    return <Typography>No schema available.</Typography>;
  }

  return (
    <Box sx={{ mt: 2, overflow: 'visible', maxHeight: '70vh', width: '100%' }}>
      <Typography variant='subtitle2'>Schema ({mediaType})</Typography>{' '}
      <Paper
        sx={{
          mt: 1,
          overflowX: 'visible',
          backgroundColor: 'transparent',
          borderRadius: 1,
          width: '100%',
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
          <Button
            onClick={() =>
              navigator.clipboard.writeText(
                JSON.stringify(schemaToExample(mediaSchema), null, 2)
              )
            }>
            Copy JSON example
          </Button>
        </Box>
        <RenderSchema schema={mediaSchema} />
      </Paper>
    </Box>
  );
};

interface RenderSchemaProps {
  schema: any;
  level?: number;
}

const RenderSchema: React.FC<RenderSchemaProps> = ({ schema, level = 0 }) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  if (!schema) {
    return <Typography>No schema defined.</Typography>;
  }

  if (schema.$ref) {
    return <Typography>Reference to: {schema.$ref}</Typography>;
  }

  const backgroundColor = `rgba(0, 0, 0, 0.01)`;
  const indent = Math.max(4, 12 - level);

  if (schema.type === 'object') {
    return (
      <Box
        sx={{
          mb: 0.5,
          borderRadius: 1,
          backgroundColor,
          outline: '1px solid rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          padding: '8px',
          width: '100%',
        }}>
        <Box
          onClick={toggleExpanded}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
          }}>
          <IconButton size='small' sx={{ mr: 1 }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
            Object
          </Typography>
        </Box>
        {expanded && (
          <Box sx={{ mt: 1, pl: indent / 4 }}>
            <List dense sx={{ width: '100%' }}>
              {schema.properties &&
                Object.entries(schema.properties).map(
                  ([key, value]: [key: string, value: any]) => (
                    <ListItem key={key} sx={{ p: 0 }}>
                      <ListItemText
                        primary={
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 'bold' }}>
                            {key}{' '}
                            <Typography
                              variant='body2'
                              sx={{ display: 'inline' }}>
                              {value.type}{' '}
                              {value.format ? `(${value.format})` : ''}
                              {'array' === value?.type
                                ? `(of ${value.items?.type}s)` || ''
                                : ''}
                            </Typography>{' '}
                          </Typography>
                        }
                        secondary={
                          !['string', 'number', 'integer', 'boolean'].includes(
                            value.type
                          ) && (
                            <Box sx={{ mt: 1 }}>
                              <RenderSchema schema={value} level={level + 1} />
                            </Box>
                          )
                        }
                      />
                    </ListItem>
                  )
                )}
            </List>
          </Box>
        )}
      </Box>
    );
  } else if (schema.type === 'array') {
    return (
      <Box
        sx={{
          mb: 0.5,
          borderRadius: 1,
          backgroundColor,
          outline: '1px solid rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          padding: '8px',
          width: '100%',
        }}>
        <Box
          onClick={toggleExpanded}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
          }}>
          <IconButton size='small' sx={{ mr: 1 }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
            array (of {schema.items?.type || ''}s)
          </Typography>
        </Box>
        {expanded && (
          <Box sx={{ mt: 1, pl: indent / 4 }}>
            <RenderSchema schema={schema.items} level={level + 1} />
          </Box>
        )}
      </Box>
    );
  } else {
    // Primitive types (string, number, integer, boolean, etc.)
    return (
      <Typography variant='body2' sx={{ pl: indent / 4 }}>
        {schema.type} {schema.format ? `(${schema.format})` : ''}
      </Typography>
    );
  }
};

export default SchemaDetails;
