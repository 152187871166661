import { Box, Card, Skeleton } from '@mui/material';
import { useInvoices } from 'hooks';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const colors = ['#ffc658', '#8884d8', '#82ca9d', '#ff7300', '#ff3860'];

const getColor = (index: number) => {
  return colors[index % colors.length];
};

const preprocessData = (data: any) => {
  let lastMonth = moment().subtract(1, 'month').format('YYYY-MM');
  let processedData = data?.invoices
    ?.slice(0, 6)
    ?.reverse()
    .map((invoice: any) => {
      const formattedData = {
        month: invoice.period,
        ...invoice.lineItems.reduce((acc: any, item: any) => {
          if (item.lineTotal > 0) {
            acc[item.assetType] = item.lineTotal;
          }
          return acc;
        }, {}),
      };
      lastMonth = moment(invoice.period).format('YYYY-MM');
      return formattedData;
    });

  const monthsSet = new Set(processedData?.map((data: any) => data.month));

  while (processedData?.length < 6 || !processedData) {
    lastMonth = moment(lastMonth).subtract(1, 'month').format('YYYY-MM');
    if (!monthsSet.has(lastMonth)) {
      monthsSet.add(lastMonth);
      if (!processedData) processedData = [];
      processedData?.unshift({
        month: lastMonth,
      });
    }
  }

  return processedData;
};

const CustomActiveShape = (props: any) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        // stroke='#000'
        strokeWidth={2}
        fillOpacity={0.7}
      />
    </g>
  );
};

export default function CostHistory() {
  const { t } = useTranslation();
  const { data } = useInvoices() as any;

  const invoices = React.useMemo(() => preprocessData(data), [data]);
  const uniqueAssetTypes = React.useMemo(
    () => [
      ...new Set(
        data?.invoices
          ?.slice(0, 6)
          .flatMap((invoice: any) =>
            invoice.lineItems.map((item: any) => item.assetType)
          )
      ),
    ],
    [data]
  );

  if (!data) return <Skeleton variant='rectangular' height={200} />;

  return (
    <Card
      variant='outlined'
      sx={{
        boxShadow: 'lg',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid rgba(255,255,255,0.2)',
        height: '100%',
      }}>
      <Box sx={{ pt: 0, pr: 2, pb: 2 }}>
        <h2 style={{ paddingLeft: '32px' }}>Invoice History</h2>
        <ResponsiveContainer width='100%' height={200}>
          <BarChart
            data={invoices}
            margin={{
              top: 0,
              right: 0,
              left: 12,
              bottom: 0,
            }}>
            {/* <CartesianGrid /> */}
            <XAxis dataKey='month' />
            <YAxis tickFormatter={tick => `$${tick.toLocaleString()}`} />
            <Tooltip
              formatter={value => `$${value.toLocaleString()}`}
              wrapperStyle={{ color: 'black' }}
            />
            {uniqueAssetTypes.map((type: any, index: number) => (
              <Bar
                key={type}
                dataKey={type}
                stackId='a'
                fill={getColor(index)}
                shape={CustomActiveShape}
                name={t(`billing.assetTypes.${type}`)}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  );
}
