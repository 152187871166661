export default ({ id, data, extra }: any) => {
  return {
    id,
    data: {
      ...extra,
      type: 'geojson',
      data,
      generateId: true,
    },
  };
};
