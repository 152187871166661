import React from 'react';
import { SvgIcon } from '@mui/material';

const DriverStatusAltIcon = (props: any) => {
  return (
    <SvgIcon sx={{ color: 'rgb(255, 255, 255)', marginRight: '-6px' }}>
      <svg
        viewBox='0 0 18 18'
        width='18px'
        height='18px'
        xmlns='http://www.w3.org/2000/svg'>
        <g>
          <path
            cx='37.352'
            cy='17.643'
            r='17.373'
            d='M9.851 3.175a3.127 3.127 0 0 1 -3.127 3.127 3.127 3.127 0 0 1 -3.127 -3.127 3.127 3.127 0 0 1 6.254 0'
          />
          <path
            d='M11.755 8.524a4.583 4.583 0 0 0 -4.582 4.582c0 2.519 2.063 4.582 4.582 4.582s4.582 -2.063 4.582 -4.582 -2.041 -4.582 -4.582 -4.582Zm-3.279 2.628a3.15 3.15 0 0 1 0.586 -0.739c0.891 -0.891 2.128 -1.281 3.41 -1.064a3.525 3.525 0 0 1 1.39 0.565 3.75 3.75 0 0 1 1.172 1.238c0.087 0.152 0.044 0.369 -0.13 0.456l-0.152 0.065a2.948 2.948 0 0 1 -2.389 0.022 1.388 1.388 0 0 0 -0.608 -0.131 1.425 1.425 0 0 0 -0.608 0.131 2.948 2.948 0 0 1 -2.389 -0.022l-0.152 -0.065a0.35 0.35 0 0 1 -0.131 -0.456Zm2.714 4.995 -0.044 0.369a0.327 0.327 0 0 1 -0.391 0.283c-0.63 -0.174 -1.238 -0.5 -1.715 -0.977a3.795 3.795 0 0 1 -1.129 -2.693c0 -0.217 0.217 -0.369 0.413 -0.304l0.63 0.174c0.63 0.174 1.195 0.543 1.585 1.064l0.022 0.022a2.895 2.895 0 0 1 0.63 2.063Zm0.608 -2.281a0.765 0.765 0 0 1 -0.803 -0.803 0.753 0.753 0 0 1 0.717 -0.717 0.765 0.765 0 0 1 0.803 0.803c0 0.391 -0.326 0.695 -0.717 0.716Zm2.65 1.933c-0.478 0.478 -1.064 0.826 -1.694 0.977a0.318 0.318 0 0 1 -0.391 -0.282l-0.022 -0.369a2.985 2.985 0 0 1 0.63 -2.085l0.022 -0.022a2.955 2.955 0 0 1 1.585 -1.064l0.608 -0.174c0.196 -0.044 0.391 0.087 0.391 0.304 0 1.042 -0.391 1.998 -1.129 2.714Z'
            stroke='rgb(255,255,255)'
            strokeWidth='0.75'
          />
          <path d='M11.219 7.953c-0.521 -0.847 -1.259 -1.541 -2.15 -1.976a3.6 3.6 0 0 1 -2.345 0.868 3.69 3.69 0 0 1 -2.345 -0.847C2.662 6.889 1.468 8.67 1.381 10.777v1.78c0 0.717 2.302 1.304 5.146 1.325a5.25 5.25 0 0 1 -0.108 -1.021c0 -2.693 2.15 -4.864 4.799 -4.908' />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default DriverStatusAltIcon;
