/* eslint-disable react/jsx-key */
import React from 'react';
import { Link } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

const MediaIcon = ({ callback }: any) => {
  return (
    <Link
      href='#'
      underline='none'
      sx={{
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        fontSize: '.8rem',
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        callback();
      }}>
      <ImageIcon sx={{ width: '1.2rem', mr: 0.3 }} />
    </Link>
  );
};

export default MediaIcon;
