import { SOURCES, GeoJSON } from 'components/Map';

export default (data: any, id = SOURCES.LINE_STRING) =>
  GeoJSON({
    id,
    data: {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: data?.map(({ position }: any) => [
          parseFloat(position.lon),
          parseFloat(position.lat),
        ]),
      },
    },
  });
