import { User } from 'common-web/types';
import MapStyles from 'components/Map/constants/styles';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useUsers({
  page,
  pageSize,
  searchTerm,
  searchParams,
}: {
  page?: number;
  pageSize?: number;
  searchTerm: string;
  searchParams?: any;
}): UseQueryResult {
  return useQuery(['users', searchTerm, page, searchParams], ({ signal }) =>
    apiClient.get('users', { signal, params: { searchTerm, page, pageSize, ...searchParams } })
  );
}

export function useUser(variables?: {
  id: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['users', variables],
    () => apiClient.get(`users/${variables?.id}`),
    {
      enabled: !!variables?.id,
      staleTime: 0,
    }
  );
}

export function useCurrentUser(): UseQueryResult {
  return useQuery(
    'users/current',
    ({ signal }) => apiClient.get('users/current', { signal }),
    {
      refetchOnWindowFocus: 'always',
      onSuccess(data: any) {
        if (data?.settings?.mapStyle) {
          localStorage.setItem(
            'mapStyle',
            JSON.stringify(
              MapStyles[data.settings.mapStyle as keyof typeof MapStyles]
            )
          );
        }
      },
    }
  );
}

export function useCurrentUserOnboarding(): UseQueryResult {
  return useQuery('/users/current/onboarding', () => {
    apiClient.get('/users/current/onboarding');
  });
}

export function useUpdateCurrentUserOnboarding(): any {
  const queryClient = useQueryClient();
  return useMutation('/users/current/onboarding', (variables: any) =>
    apiClient.put('/users/current/onboarding', {
      isCompleted: variables.isCompleted,
      currentStep: variables.currentStep,
    })
  );
}

export function useRefreshCurrentUser(): any {
  const queryClient = useQueryClient();
  return queryClient.invalidateQueries('users/current');
}

export function useAddUser(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'users',
    (variables: any) =>
      apiClient.post('users', {
        email: variables.email,
        mobileNumber: variables.mobileNumber,
        groupId: variables.groupId,
        roleId: variables.roleId,
        name: variables.name,
        isOwner: variables.isOwner,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries('contacts');
      },
    }
  );
}

export function useEditUser(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'users',
    (variables: User) => apiClient.put(`users/${variables?.userId}`, variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries('contacts');
      },
    }
  );
}

export function useUpdateCurrentUser(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'users/current',
    (variables: any) => apiClient.put(`users/current`, variables),
    {
      onSuccess: data => {
        queryClient.setQueryData('users/current', data);
      },
    }
  );
}

export function useUpdateCurrentUserSettings(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'users/current/settings',
    (variables: any) => apiClient.put(`users/current/settings`, variables),
    {
      onSuccess: (_: any, variables: any) => {
        const currentData = queryClient.getQueryData('users/current') as any;
        queryClient.setQueryData('users/current', {
          ...currentData,
          settings: {
            ...currentData?.settings,
            ...variables,
          },
        });
      },
    }
  );
}

export function useDeleteUser(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'users',
    (variables: any) => apiClient.delete(`users/${variables.id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries({ queryKey: ['notification-settings'] });
      },
    }
  );
}

export function useResendActivationEmail(): any {
  const queryClient = useQueryClient();
  return useMutation(
    `users/resend-activation-email`,
    (variables: any) =>
      apiClient.post(`users/${variables.userUuid}/resend-invite`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    }
  );
}

export function useRegistration({
  userId,
  registrationId,
}: {
  userId?: number | string;
  registrationId?: string;
}): UseQueryResult {
  return useQuery(
    ['registration'],
    () => apiClient.post(`/register-info`, {
      userId,
      registrationId,
    }),
    {
      enabled: !!userId && !!registrationId,
    }
  );
}

export function usePasswordForgot({
  userId,
  forgotPasswordSecret,
}: {
  userId?: number | string;
  forgotPasswordSecret?: string;
}): UseQueryResult {
  return useQuery(
    ['reset-password'],
    () => apiClient.post(`/reset-password-info`, {
      userId,
      forgotPasswordSecret,
    }),
    {
      enabled: !!userId && !!forgotPasswordSecret,
    }
  );
}
