import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { useAuth } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginCredentials } from 'types';
import AuthWrappedComponent from '../wrapper';

const LoginForm = ({ handleSubmit, error, isLoggingIn }: any) => {
  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      noValidate
      style={{ textAlign: 'center' }}>
      <TextField
        margin='normal'
        required
        fullWidth
        id='email'
        label='Email Address'
        name='email'
        autoComplete='email'
        autoFocus
      />
      <TextField
        margin='normal'
        required
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        autoComplete='current-password'
      />
      <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item sm>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disabled={!error && !!isLoggingIn}>
            Sign In
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item sm style={{ padding: 4, paddingTop: 4 }}>
          <Link
            href='/forgot-password'
            variant='button'
            style={{
              color: 'white',
              textDecoration: 'none',
              textTransform: 'capitalize',
            }}>
            Forgot password
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

const TwoFaForm = ({
  handleSubmit,
  error,
  isLoggingIn,
  setRequireMfa,
}: any) => {
  const handleCancelMfa = () => {
    setRequireMfa(false);
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: 1 }}
      style={{ textAlign: 'center', width: '100%' }}>
      <TextField
        margin='normal'
        required
        fullWidth
        id='mfaCode'
        label='2FA code'
        name='mfaCode'
        autoFocus
        inputProps={{ maxLength: 6 }}
        onInput={(e: any) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }}
      />
      <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item sm>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disabled={!error && !!isLoggingIn}>
            Sign In
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Grid item sm>
          <Button type='button' fullWidth onClick={handleCancelMfa}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const Login = () => {
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [flashMessage, setFlashMessage] = React.useState<{} | null>(null);
  const { login, error } = useAuth();
  const [requireMfa, setRequireMfa] = React.useState(false);
  const [loginCredentials, setLoginCredentials] = React.useState<
    LoginCredentials | {}
  >({});
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoggingIn(true);
    const data = new FormData(event.currentTarget);
    try {
      const credentials: LoginCredentials = {
        email: data.get('email') as string,
        password: data.get('password') as string,
      };
      setLoginCredentials(credentials);

      const res: any = await login(credentials);

      // Handle MFA
      if (res.message === 'mfa_required') {
        setRequireMfa(true);
      } else {
        navigate(0);
      }
    } catch (err: any) {
      const errMsg = err?.response?.data?.message || err?.message;
      setFlashMessage({
        message:
          'Network Error' === errMsg
            ? errMsg + ' - please try again in a few minutes…'
            : errMsg,
        severity: 'error',
      });
    }
  };

  const handleSubmit2FA = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoggingIn(true);
    const data = new FormData(event.currentTarget);
    try {
      await login({
        ...loginCredentials,
        mfaCode: data.get('mfaCode'),
      } as any);
    } catch (err: any) {
      setFlashMessage({
        message: err?.response?.data?.message || err?.message,
        severity: 'error',
      });
    }
  };

  const component = (
    <>
      {requireMfa ? (
        <TwoFaForm
          {...{
            isLoggingIn,
            handleSubmit: handleSubmit2FA,
            error,
            setRequireMfa,
          }}
        />
      ) : (
        <LoginForm {...{ isLoggingIn, handleSubmit, error }} />
      )}
    </>
  );

  // const bottomComponent = (
  // <div style={{ marginTop: 64 }}>
  //   <Typography
  //     variant='body2'
  //     style={{
  //       color: 'white',
  //       textDecoration: 'none',
  //       fontWeight: 'bold',
  //     }}>
  //     New to SnapGIS?
  //   </Typography>
  //   <Link
  //     href='/register'
  //     variant='body1'
  //     style={{
  //       color: '#E96C67',
  //       textDecoration: 'none',
  //       fontWeight: 'bold',
  //     }}>
  //     Create an Account
  //   </Link>
  // </div>
  // );

  return AuthWrappedComponent({
    component,
    flashMessage,
    bottomComponent: null,
  });
};

export default Login;
