/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum AssetType {
  PERSON = 'person',
  VEHICLE = 'vehicle',
  VESSEL = 'vessel',
  VESSEL_AIS = 'vessel_ais',
  WELLHEAD = 'wellhead',
  PIPELINE = 'pipeline',
  SECURITY_CAMERA = 'security_camera',
  CONTAINER = 'container',
  TRAILER = 'trailer',
}
