import UploadIcon from '@mui/icons-material/Upload';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GroupFilter } from 'components/Filters';
import Search from 'components/Search';
import TableToolbar from 'components/TableToolbar';
import TableToolbarAction from 'components/TableToolbar/TableToolbarAction';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../../context/PermissionContext';

const LocationsToolbar = (props: any) => {
  const { t } = useTranslation();
  const { setSearchTerm, isExporting, handleImportLocation } = props;
  const { canWrite } = usePermissions();

  return (
    <TableToolbar grid={[4, 4, 4]}>
      <Search
        translationKey='location_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      <GroupFilter {...props} sx={{ ml: 1 }} multiple={true} />
      {canWrite('LOCATION') && (
        <TableToolbarAction>
          <Tooltip arrow={true} title={t('location.upload')}>
            <IconButton disabled={isExporting} onClick={handleImportLocation}>
              {isExporting ? (
                <CircularProgress size={24} sx={{ mr: 0.25 }} />
              ) : (
                <UploadIcon />
              )}
            </IconButton>
          </Tooltip>
        </TableToolbarAction>
      )}
    </TableToolbar>
  );
};

const LocationsToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <LocationsToolbar {...props} />
    </WithUrlFilters>
  );
};

export default LocationsToolbarWithUrlFilters;
