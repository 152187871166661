/* eslint-disable max-len */
import { GeoJSON, SOURCES } from 'components/Map';

export default (location: any) =>
  GeoJSON({
    id: SOURCES.ASSET_LOCATIONS,
    data: {
      type: 'Feature',
      properties: {
        name: location?.name,
        heading: location?.position?.heading || location?.heading || 0,
        speed: location?.position?.speed || location?.speed || 0,
        altitude: location?.position?.altitude || location?.altitude || 0,
        assetId: location?.assetId,
        assetUuid: location?.assetUuid,
        timestamp: location?.timestamp,
        assetType: location?.assetType,
        ignition: location?.ignition || null,
        idling: location?.idling || null,
        activeAlert: location?.activeAlert?.toString(),
        hasIgnitionLockout: location?.hasIgnitionLockout || false,
        stateIgnitionLockedOut: location?.stateIgnitionLockedOut || false,
        ignitionLockoutRequestedAt:
          location?.ignitionLockoutRequestedAt || null,
        ignitionLockoutRequestedState: location?.ignitionLockoutRequestedState,
        assetDevices: location?.assetDevices,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          location?.position?.lon || 0,
          location?.position?.lat || 0,
        ],
      },
    },
  });
