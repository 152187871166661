import { SOURCES } from './types';
import GeoJSON from 'components/Map/constants/sources/GeoJSON';

export default (data: any[]) =>
  GeoJSON({
    id: SOURCES.STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS,
    data: {
      type: 'FeatureCollection',
      features: data?.map((item: any) => ({
          type: 'Feature',
          properties: {
            assetId: item?.assetId,
            assetType: item?.assetType,
            center: {
              lon: item?.position?.lon,
              lat: item?.position?.lat,
            },
            color: item?.color || 'white',
          },
          geometry: {
            type: 'Point',
            coordinates: [item?.position?.lon, item?.position?.lat],
          },
        }),
      ),
    },
  });
