import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';

const types = [
  'device_reset',
  'panic_reset',
  'raw',
  'request_location',
  'request_image',
];

const ToMobileMessageTypeFilter = ({ updateFilters, filters }: any) => {
  const { t } = useTranslation();
  const [allTypes, setAllTypes] = React.useState<any>(null);

  useEffect(() => {
    setAllTypes(
      Object.values(types).map((key: any) => {
        return {
          /* @ts-ignore */
          name: t(`tm_message.type.${key}`),
          id: key,
        };
      })
    );
  }, []);

  return (
    <Filter
      data={allTypes}
      isLoading={!allTypes}
      multiple={true}
      filterName='toMobileMessageType'
      label='Type'
      keyProperty='id'
      labelProperty='name'
      filterKey={'messageTypes'}
      filters={filters}
      updateFilters={updateFilters}
      disableCloseOnSelect={true}
      limitTags={0}
    />
  );
};

export default ToMobileMessageTypeFilter;
