import SearchInputForm from 'components/ActionMenu/modules/GlobalSearch/SearchInputForm';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobalSearchFormSchema from 'schemas/GlobalSearch';

export const Search = ({ setSearchTerm, translationKey = '' }: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        search: '',
      }}
      validationSchema={GlobalSearchFormSchema(t)}
      validateOnChange={false}
      validateOnBlur={false}
      onReset={() => {
        setSearchTerm('');
      }}
      onSubmit={(values: any, { setSubmitting }: any) => {
        setSearchTerm(values.search);
        setSubmitting(false);
      }}>
      {(props: any) => (
        <SearchInputForm
          {...props}
          isLoading={false}
          setSearchTerm={setSearchTerm}
          placeholder={t(translationKey)}
        />
      )}
    </Formik>
  );
};

export default Search;
