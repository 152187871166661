/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useUIContext from 'context/UIContext';
import moment from 'moment';
import {
  convertUnitForAssetType,
  getDirectionFromDeg,
  getUnitForAssetType,
} from 'common-web/utils';

const MediaDialog = ({ asset, showMedia }: any) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleClose = () => {
    showMedia(null);
    setActiveStep(0);
  };

  return asset && asset.vehicleMedia && asset.vehicleMedia[0] ? (
    <Dialog
      open={!!asset}
      onClose={handleClose}
      fullWidth={false}
      maxWidth='lg'>
      <DialogContent>
        <img
          src={asset.vehicleMedia[0].fileUrl}
          style={{ display: 'block', width: '100%' }}
        />
        <Box
          sx={{
            padding: '1rem',
            background: '#131313',
            fontSize: '.75rem',
            color: '#e9e9e9',
            fontFamily: 'monospace',
            margin: 0,
          }}>
          {`${asset.assetName} | ${moment(asset.timestamp).format(
            'YYYY-MM-DD HH:mm:ss'
          )} | 
          ${asset.position.lat.toFixed(7)}, ${asset.position.lon.toFixed(7)} | 
          ${convertUnitForAssetType(
            'speed',
            asset.assetType,
            asset.speed || 0
          )} ${getUnitForAssetType('speed', asset.assetType)} | 
          ${
            asset.driverName.trim() !== '' ? asset.driverName : 'Unnamed driver'
          }
          `}
        </Box>
      </DialogContent>
    </Dialog>
  ) : (
    <></>
  );
};

export default MediaDialog;
