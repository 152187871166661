import { Box } from '@mui/material';
import CreateScheduledReport from 'components/ScheduledReports/CreateScheduledReport';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const CrudScheduledReport = () => {
  const { id: scheduledReportId } = useParams();
  const { t } = useTranslation();

  return (
    <Box>
      <h1>
        {!!scheduledReportId
          ? t('Edit Scheduled Report')
          : t('Create Scheduled Report')}
      </h1>
      <CreateScheduledReport />
    </Box>
  );
};

export default CrudScheduledReport;
