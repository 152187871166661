
// eslint-disable-next-line import/named
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export default (t: TFunction) => {
  return Yup.object().shape({
    mfaCode: Yup.string()
      .min(6, t('form.validation.field_is.too_short', { field: t('mfa_code') }))
      .max(6, t('form.validation.field_is.too_long', { field: t('mfa_code') }))
      .required(t('form.validation.field_is.required', { field: t('mfa_code') })),
  });
};
