import React from "react";
import { useDashboardWidget } from 'hooks';
import { Skeleton, Tooltip } from "@mui/material";
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const renderCountContent =
  ({
    resolveOnClick,
    data,
    item,
    t,
   } : {
    resolveOnClick: Function,
    data: any,
    item: any,
    t: any,
  }) => {
  const content = <div
    onClick={() => resolveOnClick(item.onClick)}
    className='widget-count__content'
    style={{ color: item.color }}
    key={item.dataKey}>
    <div className='widget-count__content--value'>
      {data[item.dataKey]}
    </div>
    <div className='widget-count__content--label'>{item.label}</div>
  </div>;

  if (!item.tooltip) return content;

  return <Tooltip title={t(item.tooltip)}>
    {content}
  </Tooltip>
}

export const CountWidget = ({
  assetType,
  mapping,
}: {
  assetType: string;
  mapping: any;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const resolveOnClick = (onClick: any) => {
    if (onClick && 'navigate' === onClick[0]) {
      navigate(onClick[1], { state: onClick[2] || null });
    }
  };

  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: 'statuses',
  }) as any;
  return (
    <div className='widget-count_container'>
      {isLoading &&
        Array(mapping.length)
          .fill(1)
          .map((e: any, i: number) => (
            <Skeleton key={i} variant='rounded' width={100} height={100} />
          ))}
      {data &&
        !isLoading &&
        mapping.map((item: any) => renderCountContent({
          resolveOnClick,
          data,
          item,
          t,
        }))}
    </div>
  );
};

export default CountWidget;
