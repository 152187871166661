import { Grid } from '@mui/material';
import { IntervalType } from 'components/Filters/DateFilter';
import CreateReportButton from 'components/ScheduledReports/CreateReportButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import React from 'react';
import { DateFilter, GroupFilter } from '../../../components/Filters';

const SpeedingFilters = (props: any) => {
  return (
    <Grid container spacing={2} sx={{ py: 0.5, mt: 2 }}>
      <Grid item xs={2}>
        <DateFilter
          {...props}
          sx={{ mr: 1 }}
          selectedDateOption={IntervalType.LAST_THIRTY_DAYS}
          dateOptions={[
            IntervalType.LAST_SEVEN_DAYS,
            IntervalType.LAST_THIRTY_DAYS,
            IntervalType.LAST_THREE_MONTHS,
          ]}
        />
      </Grid>
      <Grid item xs={3}>
        <GroupFilter {...props} sx={{ ml: 1 }} multiple={true} />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end' }}>
        <CreateReportButton type={ScheduledReportType.SPEEDING} />
        <props.exportComponent filters={props.filters} />
      </Grid>
    </Grid>
  );
};

const SpeedingToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <SpeedingFilters {...props} />
    </WithUrlFilters>
  );
};

export default SpeedingToolbarWithUrlFilters;
