import { ScheduleSendTwoTone } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import qs from 'qs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CreateReportButton = ({
  type,
  overwriteToMultiple,
}: {
  type: ScheduledReportType;
  overwriteToMultiple?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useComplexSearchParams() as any;

  const params = useMemo(() => {
    if (!!queryParams) {
      if (overwriteToMultiple) {
        if (queryParams.driverId) {
          queryParams.driverIds = [queryParams.driverId];
          delete queryParams.driverId;
        }
        if (queryParams.groupId) {
          queryParams.groupIds = [queryParams.groupId];
          delete queryParams.groupId;
        }
        if (queryParams.assetId) {
          queryParams.assetIds = [queryParams.assetId];
          delete queryParams.assetId;
        }
        if (queryParams.locationId) {
          queryParams.locationIds = [queryParams.locationId];
          delete queryParams.locationId;
        }
      }
      return {
        ...queryParams,
        scheduledReportType: type,
        dateRange: undefined,
        startTimestamp: undefined,
        endTimestamp: undefined,
      };
    }
    return null;
  }, [queryParams, type]);

  return (
    <Tooltip title={t('Schedule Report')}>
      <IconButton
        onClick={() =>
          navigate(
            `/reports/scheduler/create?${qs.stringify(params, {
              arrayFormat: 'brackets',
              encode: false,
            })}`
          )
        }>
        <ScheduleSendTwoTone />
      </IconButton>
    </Tooltip>
  );
};

export default CreateReportButton;
