import React from 'react';
import { Tooltip } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import Table from 'components/Table';
import { useAssetHistoricalMedia } from 'hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const commonColDefOptions: Partial<GridColDef> = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'timestamp',
      headerName: t('Time'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return moment(r.row.timestamp).fromNow();
      },
    }, // @ts-ignore
    {
      field: 'media_preview',
      headerName: t('Preview'),
      ...commonColDefOptions,
      flex: 0.2,
      renderCell: (r: any) => {
        if (r?.row?.fileUrl) {
          return (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: 'none',
                  },
                },
              }}
              title={
                <div className='minfarm-watermarked'>
                  <img src={r.row.fileUrl} />
                </div>
              }
              placement={'top'}>
              <span>
                <div className='minfarm-watermarked'>
                  <img src={r.row.fileUrl} height={80} />
                </div>
              </span>
            </Tooltip>
          );
        }
        return <></>;
      },
    }, // @ts-ignore
  ];
}

export const CameraImageHistory = ({ assetId }: { assetId: number }) => {
  const { data, isLoading } = useAssetHistoricalMedia({ assetId }) as any;
  const { t } = useTranslation();

  return (
    <>
      <Table
        data={data}
        isLoading={isLoading}
        columns={getColumns({ t })}
        onPageChange={() => {}}
        getRowId={(row: any) => row.reportId}
        rowHeight={100}
      />
    </>
  );
};

export default CameraImageHistory;
