import { createTheme } from '@mui/material/styles';
import './mui.d';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8A8A8A', // for CSS filter: filter: invert(15%) sepia(99%) saturate(2876%) hue-rotate(212deg) brightness(90%) contrast(90%);
    },
    secondary: {
      main: '#ffffff',
      light: '#3F4349'
    },
    common: {
      text: {
        white: '#fff',
        black: '#000'
      }
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: '#42a5f5',
          }
        }
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: '#42a5f5',
          }
        }
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            color: '#9CC8F5',
          }
        },
        track: {
          ".Mui-checked.Mui-checked + &": {
            backgroundColor: '#637990',
          }
        }
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

export default theme;