import {
  Alert,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import useUIContext from 'context/UIContext';
import { useAiPrompt } from 'hooks/aiPrompt/useAiPrompt';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Map from 'components/Map/AltMap';

export const AiDialog = () => {
  const [errorValue, setErrorValue] = useState<string>('');
  const { aiDialogOpen, closeAiDialog } = useUIContext();
  const { data, isLoading, mutate: doPrompt, reset, error } = useAiPrompt();

  const onSearch = (value: string) => {
    setErrorValue('');
    doPrompt({ prompt: value });
  };

  const handleClose = () => {
    reset();
    closeAiDialog();
    setErrorValue('');
  };

  useEffect(() => {
    if (error) {
      setErrorValue(error?.response?.data?.message);
    }
  }, [error]);

  if (!aiDialogOpen) {
    return <></>;
  }

  return (
    <Dialog
      open={!!aiDialogOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'md'}
      disableRestoreFocus={true}
      sx={{ mt: -8 }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}>
      <DialogContent sx={{ p: 1 }}>
        <CustomInputField
          isLoading={isLoading}
          onSearch={onSearch}
          onChange={() => (!!errorValue ? setErrorValue('') : null)}
        />
        {!!errorValue ? (
          <DialogContentText>
            <Card variant='outlined' sx={{ mt: 2 }}>
              <CardContent sx={{ mb: -1 }}>
                <Alert variant='outlined' severity='warning' sx={{ mb: 1 }}>
                  {errorValue}
                </Alert>
              </CardContent>
            </Card>
          </DialogContentText>
        ) : (
          !isLoading &&
          !!data && (
            <>
              <DialogContentText>
                <Card variant='outlined' sx={{ mt: 2 }}>
                  <CardContent sx={{ mb: -1 }}>
                    <AiDialogResultResolver data={data} />
                  </CardContent>
                </Card>
              </DialogContentText>
            </>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AiDialog;

const AiDialogResultResolver = ({ data }: any) => {
  let text = `I'm sorry, but I'm unable to interpret the result at this point. SnapGIS AI is constantly evolving and improving. We took note of your request and will reach out to you once we are able to process and visualize your request.`;
  if (
    data.sentence &&
    'count' === data.visualize &&
    !data.sentence.includes('___INSERT_VALUE___')
  ) {
    text = data?.sentence;
  } else if ('map' === data.visualize) {
    // what we need to know:
    // 1. is it one or multiple rows
    // 2. if there is only one, what type of point is it (asset? location?) and what is the data to represent?
    // 3. if there is multiple, is it current/historical reports of many or historical of one (location or asset)?
    const multiple = data?.result.length > 1;
    let layers, sources, images;

    if (!multiple) {
    }

    return (
      <div style={{ width: '100%', height: '60vh' }}>
        {data?.general_sentence && <p>{data?.general_sentence}</p>}
        <Map
          controlStyles={{
            bottomRight: `margin-right: -500px;`,
          }}
        />
      </div>
    );
  }
  return (
    <Typography
      sx={{ fontSize: 18, textAlign: 'center' }}
      color='text.primary'
      gutterBottom
      textAlign='center'>
      {text}
    </Typography>
  );
};

const CustomInputField = ({ isLoading, onSearch, onChange }: any) => {
  const [value, setValue] = useState<string>('');
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        onSearch(value);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [value, onSearch]);

  return (
    <Paper
      component='form'
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
      <InputBase
        autoFocus
        sx={{ ml: 1, flex: 1 }}
        placeholder={`Type what you're looking for or what you want SnapGIS AI to do.`}
        inputProps={{
          'aria-label': `type what you're looking for or what you want SnapGIS AI to do.`,
        }}
        fullWidth={true}
        disabled={isLoading}
        onChange={(e: any) => {
          setValue(e.target.value);
          onChange(e);
        }}
      />
      <IconButton
        type='button'
        sx={{ p: '10px' }}
        aria-label='search'
        onClick={() => onSearch(value)}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
