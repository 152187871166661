import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

type InputProps = {
  open: boolean;
  onCancel?: Function;
  onConfirm?: Function;
  title: string;
  content: string | ReactNode;
  maxWidth?: 'xs' | 'md';
};

export const ConfirmDialog = ({
    open,
    onCancel,
    onConfirm,
    title,
    content,
    maxWidth = 'xs',
                                    }: InputProps) => {
  const { t } = useTranslation();

  const handleCancel = React.useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleConfirm = React.useCallback(() => {
    if (onConfirm) onConfirm();
    handleCancel();
  }, [onConfirm, handleCancel]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={maxWidth}>
      {open && (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <CheckCircleOutlineIcon sx={{ mr: 1 }} color='success' /> {t(title)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>{content}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleCancel}>
              {t('cancel')}
            </Button>
            <Button variant='contained' color={'success'} onClick={handleConfirm}>
              {t('confirm')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
