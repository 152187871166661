/* eslint-disable max-len */
import { ShortHandLayer } from './types';

export default ({ id, source, color, size, opacity, dash }: ShortHandLayer) => { 
  return {
    id, 
    type: 'symbol',
    source,
    filter: [
      'all',
      ['==', ['get', 'isHighlighted'], true],
    ],
    layout: {
      'icon-image': 'pulsing-dot',
      'icon-size': 0.5,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    }
  }
};