import { Grid, Button } from '@mui/material';
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  startOfMonth,
} from 'date-fns';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

export const CalendarMonthView = ({ onChange }: { onChange: Function }) => {
  const [activeDate, setActiveDate] = useState<Date | null>(null);
  const [hoveredDate, setHoveredDate] = useState(null);

  const handleDateClick = (date: Date) => {
    setActiveDate(date);
    if (onChange) {
      onChange({
        target: {
          name: 'dayOfMonth',
          value: format(date, 'd'),
        },
      });
    }
  };

  const handleDateHover = (date: any) => {
    setHoveredDate(date);
  };

  const getCellStyle = useCallback(
    (date: Date) => {
      const momentDate = moment(date);

      const cellStyle = {
        backgroundColor: 'transparent',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        color: 'white',
      };

      if (momentDate.isSame(activeDate, 'day')) {
        cellStyle.backgroundColor = 'rgba(3, 138, 255, 1)';
        cellStyle.borderTopLeftRadius = 8;
        cellStyle.borderBottomLeftRadius = 8;
        cellStyle.borderTopRightRadius = 8;
        cellStyle.borderBottomRightRadius = 8;
      }

      return cellStyle;
    },
    [activeDate]
  );

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth('2024-01-01'),
    end: endOfMonth('2024-01-01'),
  });

  // Find the starting day of the week for the current month
  const startDay = getDay(startOfMonth('2024-01-01'));
  const endDay = getDay(endOfMonth('2024-01-01'));

  // Create an array of blank cells to adjust the start position of the first date
  const blankCellsBefore = Array(startDay === 0 ? 6 : startDay - 1).fill(null);
  const blankCellsAfter = Array(endDay === 0 ? 0 : 7 - endDay).fill(null);

  return (
    <Grid container>
      <Grid item container xs={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {blankCellsBefore.map((_, index) => (
          <Grid
            item
            xs
            sx={{ flexGrow: 1, maxWidth: '14.28%', minWidth: '14.28%' }}
            key={`blank-${index}`}>
            <Button disabled sx={{ visibility: 'hidden' }} />
          </Grid>
        ))}
        {daysInMonth.map((date, index) => (
          <Grid
            item
            xs
            sx={{
              flexGrow: 1,
              maxWidth: '14.28%',
              minWidth: '14.28%',
            }}
            style={{
              width: '14.28%',
            }}
            key={date.toString()}>
            <Button
              disabled={moment(date).isAfter(moment())}
              onClick={() => handleDateClick(date)}
              onMouseOver={() => handleDateHover(date)}
              onMouseOut={() => handleDateHover(null)}
              style={{ minWidth: '100%', maxWidth: '100%' }}
              sx={{ px: 0 }}>
              <span
                style={{
                  width: '100%',
                  height: '80%',
                  display: 'block',
                  ...getCellStyle(date),
                }}>
                {format(date, 'd')}
              </span>
            </Button>
          </Grid>
        ))}
        {blankCellsAfter.map((_, index) => (
          <Grid
            item
            xs
            sx={{ flexGrow: 1, maxWidth: '14.28%', minWidth: '14.28%' }}
            key={`blank-${index}`}>
            <Button disabled sx={{ visibility: 'hidden' }} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
