import axios from 'axios';
import EventEmitter from 'events';

export const authEvents = new EventEmitter();

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

apiClient.interceptors.request.use(request => {

  request.withCredentials = true;
  return request;
});

apiClient.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response && error.response.status === 401) {
      authEvents.emit('logout');
    }
    return Promise.reject(error);
});

export {
  apiClient
};