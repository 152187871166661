import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import { IntervalType } from 'components/Filters/DateFilter';
import SpeedingLineChart from 'components/Reports/Widgets/SpeedingLineChart';
import useActionMenuContext from 'context/ActionMenuContext';
import {
  SpeedingReportSortByType,
  SpeedingReportSortOrderType,
  useSpeedingReport,
} from 'hooks/reports/useSpeedingReport';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import ReportWrapper from '../ReportWrapper';
import AssetSpeedingReport, {
  ExportAssetSpeedingReport,
} from './AssetSpeedingReport';
import DriverSpeedingReport, {
  ExportDriverSpeedingReport,
} from './DriverSpeedingReport';
import SpeedingToolbarWithUrlFilters from './SpeedingToolbarWithUrlFilters';

const commonColDefOptions = {
  // resizable: false,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
  disableColumnMenu: true,
};

enum SpeedingReportMode {
  ASSET = 'asset',
  DRIVER = 'driver',
}

const formatHours = function (hours: number) {
  if (hours < 1) return `${(hours * 60).toFixed(0)} minutes`;
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h} hours ${m} minutes`;
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'assetName',
      headerName: 'Asset',
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'speedingTimeSeconds',
      headerName: 'Overspeeding Duration',
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        return formatHours(r?.row.speedingTimeSeconds / 3600);
      },
    }, // @ts-ignore
    {
      field: 'localSpeedingTimeSeconds',
      headerName: 'Local Overspeeding Duration',
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        return formatHours(r?.row.localSpeedingTimeSeconds / 3600);
      },
    }, // @ts-ignore
    {
      field: 'totalSpeedingTimeSeconds',
      headerName: 'Total Overspeeding Duration',
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        return formatHours(r?.row.totalSpeedingTimeSeconds / 3600);
      },
    }, // @ts-ignore
  ];
}

const Speeding = ({ assetType }: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams]: any = useComplexSearchParams();
  const [sortBy, setSortBy] = useState<SpeedingReportSortByType>(
    'totalSpeedingTimeSeconds'
  );
  const [sortOrder, setSortOrder] =
    useState<SpeedingReportSortOrderType>('DESC');

  const { data, isLoading } = useSpeedingReport({
    page: page + 1,
    filterBy: searchParams,
    sortBy,
    sortOrder,
  }) as any;
  const { reset } = useActionMenuContext();
  const [mode, setMode] = React.useState(SpeedingReportMode.ASSET);

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          <SpeedingToolbarWithUrlFilters
            type={'fuel'}
            assetType={assetType}
            exportComponent={
              mode == SpeedingReportMode.ASSET
                ? ExportAssetSpeedingReport
                : ExportDriverSpeedingReport
            }
          />
        </Box>
      </Box>
      <Box
        className='widget-container'
        key={1}
        sx={{ mb: 2, minHeight: '200px' }}>
        <div className='widget-content'>
          <SpeedingLineChart
            assetType='vehicle'
            widgetType='speeding'
            chartUnit='%'
            showTotal={false}
            startTimestamp={searchParams?.startTimestamp}
            endTimestamp={searchParams?.endTimestamp}
            groupIds={searchParams?.groupIds}
            interval={
              searchParams?.dateRange === IntervalType.LAST_THREE_MONTHS ? 6 : 0
            }
          />
        </div>
      </Box>
      <Box sx={{ mb: 2 }}>
        <ToggleButtonGroup
          size='small'
          color='primary'
          value={mode}
          exclusive
          onChange={(event, newMode) => (newMode ? setMode(newMode) : null)}
          aria-label='Platform'>
          <ToggleButton value={SpeedingReportMode.ASSET}>Assets</ToggleButton>
          <ToggleButton value={SpeedingReportMode.DRIVER}>Drivers</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {mode == SpeedingReportMode.ASSET && <AssetSpeedingReport />}
      {mode == SpeedingReportMode.DRIVER && <DriverSpeedingReport />}
      <div style={{ marginTop: '0.5em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          1. <b>Local Overspeeding</b> is calculated by comparing the GPS speed
          from individual position reports to the local speed limit configured
          for the location on the platform, therefore the duration may not be
          precisely accurate.
        </Typography>
      </div>
    </ReportWrapper>
  );
};

export default Speeding;
