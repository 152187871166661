export const OrgIndustries: any = [
  { id: 'transport_logistics', value: 'Transport & Logistics' },
  { id: 'oil_gas', value: 'Oil & Gas' },
  { id: 'mining', value: 'Mining' },
  { id: 'energy', value: 'Energy' },
  { id: 'maritime', value: 'Maritime' },
  { id: 'defence', value: 'Defence' },
  { id: 'security', value: 'Security' },
  { id: 'technology', value: 'Technology' },
  { id: 'ngo', value: 'NGO' },
  { id: 'other', value: 'Other' },
];

export interface Org {
  id: number;
  orgId: number;
  name: string;
  primaryContact: string;
  uuid: string;
  status: boolean;
  createdAt: Date;
  createdBy?: number;
  countryCode?: string;
  industry?: string;
  address?: string;
  phoneNumber?: string;
  mfaEnabled?: boolean;
  billingStatus?: number;
}

export interface AddOrg {
  name: string;
  email: string;
  captcha?: string;
  industry: string;
  countryCode: string;
  address: string;
  phoneNumber: string|any;
}

export type AddOrgDto = AddOrg;
