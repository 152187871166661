import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'fill',
    source,
    layout: {},
    paint: {
    // 'fill-color': Unselected: #7f8c8d, Selected: #3be867
    'fill-color': [
      'case',
      ['==', ['get', 'active'], true],
      '#3be867',
      '#7f8c8d',
    ],
    'fill-opacity': 0.1, // Unselected: 0.2, Selected: 0.1
    }
  };
};
