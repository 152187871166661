import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListSubHeader from '../List/ListSubHeader';

const ActivityFeedDateDivider = ({ date }: any) => {
  const { t } = useTranslation();

  function formatDate(today: string) {
    if (today === '') {
      return '_';
    }
    if (today === moment().format('YYYY-MM-DD')) {
      return t('activity_log.label.today');
    }
    return today;
  }

  return (
    <Box sx={{ backgroundColor: '#242628' }}>
      <ListSubHeader label={formatDate(date)} disableSticky={true} />
    </Box>
  );
};

export default ActivityFeedDateDivider;
