import React from 'react';
import Box from '@mui/material/Box';

export const TableToolbarAction = ({ children }: any) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{children}</Box>
  );
};

export default TableToolbarAction;
