import { ThemeProvider } from '@mui/material/styles';
import GlobalLoader from 'components/GlobalLoader';
import { PermissionContextProvider } from 'context/PermissionContext';
import { AuthProvider } from 'hooks/auth/useAuth';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from 'reportWebVitals';
import AppRouting from 'routing';
import 'style/index.scss';
import theme from 'style/theme';
import { Connectivity } from './components/Connectivity';
import './i18n';
import './instrument';
import * as Sentry from '@sentry/react';
import { Button, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /*
       * staleTime: Infinity
       *
       * From:
       * https://tkdodo.eu/blog/using-web-sockets-with-react-query#increasing-staletime
       * - Basically prevents "refetchOnWindowFocus"
       * - Helpful if all data is synced through websockets and only initial query is required
       */
      staleTime: Infinity, // 0 means, consider query immediately as stale
      retry: 1,
    },
  },
});

const FallbackComponent = ({ error, resetError }: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      color: '#565656',
    }}>
    <div
      style={{
        minWidth: '400px',
        width: '40vw',
        maxWidth: '60vw',
        backgroundColor: 'darkgrey',
        padding: '40px 20px',
        display: 'flex',
        border: '4px solid #d5d5d5',
        borderRadius: '30px',
      }}>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ErrorOutline
          sx={{ fontSize: '12em', color: 'rgba(190, 91, 91, 1)' }}
        />
      </div>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
          Oops, something went wrong…
        </Typography>
        <Typography variant='body1'>
          We&apos;ve been automatically notified about this issue and we&apos;ll
          be working to resolve it as soon as possible.
        </Typography>
        <Button
          onClick={() => (window.location.href = '/')}
          sx={{ marginTop: '20px' }}
          variant='contained'>
          Take me back
        </Button>
      </div>
    </div>
  </div>
);

root.render(
  <>
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary
        fallback={FallbackComponent}
        onReset={() => window.location.reload()}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<GlobalLoader />}>
            <GlobalLoader />
            <AuthProvider>
              <PermissionContextProvider>
                {/*<WebsocketManager />*/}
                <AppRouting />
              </PermissionContextProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen />
          </Suspense>
        </QueryClientProvider>
        <Connectivity />
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
