import React from 'react';
import TableToolbar from 'components/TableToolbar';
import Search from 'components/Search';
import TableToolbarActionButton from 'components/TableToolbar/TableToolbarActionButton';
import { GroupFilter } from 'components/Filters';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import AssetTypeFilter from 'components/Filters/AssetTypeFilter';
import DeviceTypeFilter from 'components/Filters/DeviceTypeFilter';
import usePermissions from 'context/PermissionContext';

const AssetsToolbar = (props: any) => {
  const { setSearchTerm, handleAddAsset } = props;
  const { canWrite } = usePermissions();

  return (
    <TableToolbar grid={[3, 3, 2, 2, 2]}>
      <Search
        translationKey='asset_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      <DeviceTypeFilter {...props} multiple />
      <AssetTypeFilter {...props} multiple />
      <GroupFilter {...props} multiple />
      {canWrite('ASSET') && (
        <TableToolbarActionButton
          minWidth={140}
          onClick={handleAddAsset}
          translationKey='add_asset'
        />
      )}
    </TableToolbar>
  );
};

const AssetToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <AssetsToolbar {...props} />
    </WithUrlFilters>
  );
};

export default AssetToolbarWithUrlFilters;
