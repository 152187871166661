import { useMutation, useQueryClient } from 'react-query';
import { apiClient } from 'services/axios';

export function useAiPrompt(): any {
  const queryClient = useQueryClient();
  return useMutation('aiPrompt', (params: any) =>
    apiClient.post('prompt', {
      signal: params.signal,
      prompt: params.prompt,
    })
  );
}
