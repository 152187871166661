import { Checkbox, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { OrgIndustries } from 'common-web/types/org/Org';
import AutoComplete from 'components/AutoComplete';
import CaptchaField from 'components/Forms/Fields/CaptchaField';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import {
  useAuth,
  useForgotPassword,
  useGeoIp,
  usePasswordForgot,
  useRegistration,
} from 'hooks';
import countries from 'i18n-iso-countries';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateOrgFormSchema from 'schemas/CreateOrgFormSchema';
import FinalizeOrgFormSchema from 'schemas/FinalizeOrgFormSchema';
import getTimeZoneOptions from 'services/timezones';
import ConfirmDialog from '../../../components/SystemDialogs/ConfirmDialog';
import ForgotPasswordFormSchema from '../../../schemas/ForgotPasswordFormSchema';
import { TERMS_OF_SERVICE_VERSION, TermsOfService } from '../TermsOfService';
import AuthWrappedComponent from '../wrapper';
const getCountryISO3 = require('country-iso-2-to-3');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function RegistrationConfirmation(props: any) {
  return (
    <Box>
      <Typography
        variant='h5'
        component='div'
        gutterBottom
        sx={{
          color: 'white',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}>
        Your account was created
      </Typography>
      <Typography variant='subtitle1' gutterBottom component='div'>
        Please check your email to finalize your account and get started.
      </Typography>
    </Box>
  );
}

const RegistrationForm = (props: any) => {
  const { t } = useTranslation();
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = React.useState(false);
  const { values, isSubmitting, isValid, setFieldValue, errors }: any =
    useFormikContext();
  const { data, isLoading } = useGeoIp() as any;
  const countriesList: any = Object.entries(
    countries.getNames('en', { select: 'official' })
  ).map(el => {
    return {
      id: el[0],
      value: el[1],
    };
  });

  useEffect(() => {
    if (data) {
      setFieldValue('settings.country', {
        id: data?.data?.countryCode,
        value: countries.getName(data?.data?.countryCode, 'en', {
          select: 'official',
        }),
      });
      setFieldValue('settings.timezone', data?.data?.timezone);
    }
  }, [data]);

  const setCaptchaValue = (value: string) => {
    setFieldValue('captchaValue', value);
  };

  return (
    <Form style={{ width: '100%' }}>
      {props.initial && (
        <>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='orgName'
              type='text'
              label={t('register.orgName')}
              placeholder={t('register.orgName')}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={AutoComplete}
              name='industry'
              value={values.industry}
              getOptionLabel={(option: any) => option.value}
              textFieldProps={{
                label: t('register.industry'),
                placeholder: t('register.industry'),
              }}
              options={OrgIndustries}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={AutoComplete}
              name='country'
              value={values.country}
              getOptionLabel={(option: any) => option.value}
              textFieldProps={{
                label: t('register.country'),
                placeholder: t('register.country'),
              }}
              options={countriesList}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='address'
              type='text'
              label={t('register.address')}
              placeholder={t('register.address')}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='email'
              type='email'
              label={t('email')}
              placeholder={t('email')}
            />
          </div>
          <div className='pv-10'>
            {!isLoading && (
              <MuiPhoneNumber
                defaultCountry={data?.data?.countryCode?.toLowerCase() || 'ae'}
                onChange={e => setFieldValue('phoneNumber', e)}
                variant='outlined'
                placeholder='Enter phone'
                disableAreaCodes={true}
                disableDropdown={false}
                fullWidth={true}
                error={errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            )}
          </div>
        </>
      )}
      {(props.confirmation || props.forgotPassword) && (
        <>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='password'
              type='password'
              label={t('register.password')}
              placeholder={t('register.password')}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='passwordConfirm'
              type='password'
              label={t('register.passwordConfirm')}
              placeholder={t('register.passwordConfirm')}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={AutoComplete}
              name='settings.country'
              value={
                values.settings?.country?.id
                  ? countriesList.find((option: any) => {
                      return option.id === values.settings?.country?.id;
                    })
                  : null
              }
              getOptionLabel={(option: any) => option.value}
              textFieldProps={{
                label: t('register.country'),
                placeholder: t('register.country'),
              }}
              options={countriesList}
            />
          </div>
          <div className='pv-10'>
            <Field
              component={AutoComplete}
              sx={{ width: '100%' }}
              onChange={(e: any, value: any) => {
                setFieldValue('settings.timezone', value.value);
              }}
              options={getTimeZoneOptions()}
              isOptionEqualToValue={(item: any, current: any) =>
                item?.value === current?.value
              }
              value={getTimeZoneOptions().find((option: any) => {
                return option.value === (values.settings?.timezone ?? 'UTC');
              })}
              name='settings.timezone'
              getOptionLabel={(option: any) => option.label}
              disableClearable={true}
              textFieldProps={{
                label: t('Timezone'),
              }}
            />
          </div>
        </>
      )}
      <div
        style={{
          textAlign: 'center',
          marginTop: 10,
          marginBottom: 15,
        }}>
        <CaptchaField setFieldValue={setCaptchaValue} />
      </div>
      {props.confirmation && (
        <div className='pv-10' style={{ textAlign: 'left' }}>
          <Checkbox
            checked={values?.agreeTermsOfService}
            onChange={(e: any) => {
              setFieldValue('agreeTermsOfService', e.target.checked);
            }}
          />
          <>
            {t('register.agreeTermsOfService')}&nbsp;
            <Link
              variant={'body1'}
              color={'secondary'}
              onClick={() => setIsTermsOfServiceOpen(true)}
              sx={{ cursor: 'pointer' }}>
              {t('register.termsOfService')}
            </Link>
          </>
          <ConfirmDialog
            maxWidth={'md'}
            open={isTermsOfServiceOpen}
            title={'Terms of Service'}
            content={<TermsOfService />}
            onCancel={() => setIsTermsOfServiceOpen(false)}
            onConfirm={() => {
              setIsTermsOfServiceOpen(false);
              setFieldValue('agreeTermsOfService', true);
            }}
          />
        </div>
      )}
      <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item sm>
          {isSubmitting && <LinearProgress />}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disabled={!isValid}>
            {t(`register.cta.${props.initial ? 'initial' : 'confirmation'}`)}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const Register = ({ initial, confirmation, forgotPassword }: any) => {
  const { t } = useTranslation();
  const [isRegistering, setIsRegistering] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false);
  const [captchaValue, setCaptchaValue] = React.useState<string | null>('');
  const [flashMessage, setFlashMessage] = React.useState<{} | null>(null);
  const { register, registerConfirm, login, error } = useAuth();
  const { mutate: sendForgotPassword } = useForgotPassword();
  const params = useParams();
  const { data: registrationData } = useRegistration({
    userId: params.userId,
    registrationId: params.registrationId,
  }) as any;
  const { data: forgotPasswordData } = usePasswordForgot({
    userId: params.userId,
    forgotPasswordSecret: params.forgotPasswordSecret,
  }) as any;
  const navigate = useNavigate();
  const getInitialValues = () => {
    if (initial) {
      return {
        orgName: '',
        email: '',
        captchaValue: '',
        address: '',
        phoneNumber: '',
      };
    } else if (confirmation) {
      return {
        password: '',
        passwordConfirm: '',
        captchaValue: '',
        agreeTermsOfService: false,
      };
    } else if (forgotPassword) {
      return {
        password: '',
        passwordConfirm: '',
        captchaValue: '',
      };
    }
    return {};
  };

  const component = (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}>
      {isRegistered && <RegistrationConfirmation />}

      {!isRegistered && (
        <Formik
          validateOnMount={true}
          initialValues={getInitialValues()}
          validationSchema={(() => {
            if (initial) {
              return CreateOrgFormSchema(t);
            } else if (confirmation) {
              return FinalizeOrgFormSchema(t);
            } else if (forgotPassword) {
              return ForgotPasswordFormSchema(t);
            }
          })()}
          onSubmit={async (values: any, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            if (initial) {
              setIsRegistering(true);
              setIsRegistered(false);
              try {
                await register({
                  name: values.orgName,
                  email: values.email,
                  captcha: values.captchaValue,
                  industry: values.industry.id,
                  countryCode: values.country.id,
                  address: values.address,
                  phoneNumber: values.phoneNumber,
                });
                setIsRegistered(true);
              } catch (err: any) {
                setFlashMessage({
                  message: err?.response?.data?.message
                    ? t('errors.' + err.response.data.message)
                    : err.message,
                  severity: 'error',
                });
              }
            }
            if (confirmation) {
              setIsRegistering(true);
              try {
                if (values.password !== values.passwordConfirm) {
                  setFlashMessage({
                    message: t('passwords_must_match'),
                  });
                  resetForm();
                  return;
                }
                await registerConfirm({
                  userId: params.userId,
                  registrationId: params.registrationId,
                  password: values.password,
                  captcha: values.captchaValue,
                  agreeTermsOfService: values.agreeTermsOfService,
                  termsOfServiceVersion: TERMS_OF_SERVICE_VERSION,
                  settings: {
                    countryCode: getCountryISO3(values.settings.country.id),
                    timezone: values.settings.timezone,
                  },
                });
                setFlashMessage({
                  message: t('register.success'),
                });
                if (!!registrationData) {
                  setTimeout(async () => {
                    await login({
                      email: registrationData?.email,
                      password: values.password,
                    });
                  }, 2000);
                }
              } catch (err: any) {
                setFlashMessage({
                  message: err?.response?.data?.message || err?.message,
                  severity: 'error',
                });
              }
            }
            if (forgotPassword) {
              setIsRegistering(true);
              try {
                if (values.password !== values.passwordConfirm) {
                  setFlashMessage({
                    message: t('passwords_must_match'),
                  });
                  resetForm();
                  return;
                }
                await sendForgotPassword({
                  userId: params.userId,
                  forgotPasswordSecret: params.forgotPasswordSecret,
                  password: values.password,
                  confirmPassword: values.passwordConfirm,
                  captcha: values.captchaValue,
                });
                setFlashMessage({
                  message: t('reset_password_success'),
                });
                if (!!forgotPasswordData) {
                  setTimeout(async () => {
                    await login({
                      email: forgotPasswordData?.email,
                      password: values.password,
                    });
                  }, 2000);
                }
              } catch (err: any) {
                setFlashMessage({
                  message: err?.response?.data?.message || err?.message,
                  severity: 'error',
                });
              }
            }
            resetForm();
          }}>
          <RegistrationForm
            initial={initial}
            confirmation={confirmation}
            forgotPassword={forgotPassword}
          />
        </Formik>
      )}
    </Box>
  );

  const bottomComponent = (
    <div style={{ marginTop: 64 }}>
      <Typography
        variant='body2'
        style={{
          color: 'white',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}>
        Already with SnapGIS?
      </Typography>
      <Link
        href='/'
        variant='body1'
        style={{
          color: '#E96C67',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}>
        Login to your Account
      </Link>
    </div>
  );

  return AuthWrappedComponent({
    component,
    flashMessage,
    bottomComponent,
  });
};

export default Register;
