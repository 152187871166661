import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export enum ScheduledReportType {
  ASSET_LOG = 'asset_log',
  UTILIZATION = 'utilization',
  TRIP = 'trips',
  FUEL = 'fuel',
  LOCATION_ACTIVITY = 'location_activity',
  SPEEDING = 'speeding',
  DRIVERS = 'drivers',
  ASSET_STATUS = 'asset_status',
  LOCAL_SPEEDING = 'local_speeding',
}

export enum ScheduledReportScheduleType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  FIRST_DAY_OF_MONTH = 'firstDayOfMonth',
  LAST_DAY_OF_MONTH = 'lastDayOfMonth',
  FIRST_MONDAY_OF_MONTH = 'firstMondayOfMonth',
  BUSINESS_DAYS = 'businessDays',
  CUSTOM = 'customInterval',
}

interface ScheduledReportCreateDto {
  reportType: ScheduledReportType;
  cronExpressionUtc: string;
  periodUnit: 'hour' | 'day' | 'week' | 'month' | 'year';
  periodAmount: number;
  contacts?: any[];
  filters?: any;
  scheduleType: ScheduledReportScheduleType;
  userTimezoneInfo?: string;
}

interface ScheduledReportUpdateDto {
  scheduledReportId: string;
  data: {
    reportType: ScheduledReportType;
    cronExpressionUtc: string;
    periodUnit: 'hour' | 'day' | 'week' | 'month' | 'year';
    periodAmount: number;
    contacts?: any[];
    filters?: any;
    scheduleType: ScheduledReportScheduleType;
    userTimezoneInfo?: string;
  };
}

export function useScheduledReports({
  page,
  pageSize,
  filterBy,
  sortBy,
  sortOrder,
  searchTerm,
}: {
  page?: number;
  pageSize?: number;
  filterBy?: any;
  sortBy?: string;
  sortOrder?: string;
  searchTerm?: string;
}): UseQueryResult {
  return useQuery(
    ['scheduled-reports', page, filterBy, searchTerm],
    ({ signal }) =>
      apiClient.get('/reports/scheduled', {
        params: {
          page,
          pageSize,
          sortBy,
          sortOrder,
          searchTerm,
          ...filterBy,
        },
      }),
    {
      // enabled: !!filterBy.startTimestamp && !!filterBy.endTimestamp,
    }
  );
}

export function useScheduledReport(
  scheduledReportId?: string | undefined
): UseQueryResult {
  return useQuery(
    ['scheduled-report', scheduledReportId],
    ({ signal }) => apiClient.get(`/reports/scheduled/${scheduledReportId}`),
    {
      enabled: !!scheduledReportId,
    }
  );
}

export function useCreateScheduledReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'scheduled-report-create',
    (variables: ScheduledReportCreateDto) =>
      apiClient.post('reports/scheduled', variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('scheduled-reports');
      },
    }
  );
}

export function useUpdateScheduledReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'scheduled-report-update',
    (variables: ScheduledReportUpdateDto) =>
      apiClient.put(
        `reports/scheduled/${variables.scheduledReportId}`,
        variables.data
      ),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('scheduled-reports');
        queryClient.invalidateQueries([
          'scheduled-report',
          variables.scheduledReportId,
        ]);
      },
    }
  );
}

export function useDeleteScheduledReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'scheduled-report-delete',
    (variables: ScheduledReportUpdateDto) =>
      apiClient.delete(`reports/scheduled/${variables.scheduledReportId}`),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('scheduled-reports');
      },
    }
  );
}
