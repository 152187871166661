import React, { useMemo } from 'react';
import { useApiDocs } from './useFetchDocs';
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Chip,
  Link,
  IconButton,
} from '@mui/material';
import SnapGISLogoSVGFull from 'assets/original-full.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import qs from 'qs';
import {
  contactEmail,
  contactName,
  contactUrl,
  getVersion,
  securitySchemes,
} from './helpers';
import CodePreview from './CodePreview';
import EndpointDetails from './EndpointDetails';

const drawerWidth = 360;
const DEFAULT_API_PATH = '/api/v1/external/';

export const ApiDocumentation = () => {
  const navigate = useNavigate();
  const { data } = useApiDocs() as any;
  const location = useLocation();
  const [open, setOpen] = React.useState<{ [key in string]: boolean } | null>(
    null
  );

  const parsed = useMemo(() => {
    if (!data) return;

    return data;
  }, [data]);

  const params: any = useMemo(() => {
    if (!location.search) return;

    return qs.parse(location.search, { ignoreQueryPrefix: true });
  }, [location?.search]);

  const renderChip = (method: string) => {
    switch (method) {
      case 'get':
        return (
          <Chip
            label={method.toUpperCase()}
            color='success'
            variant='outlined'
          />
        );
      case 'post':
        return (
          <Chip
            label={method.toUpperCase()}
            color='primary'
            variant='outlined'
          />
        );
      case 'put':
        return (
          <Chip
            label={method.toUpperCase()}
            color='warning'
            variant='outlined'
          />
        );
      case 'delete':
        return (
          <Chip label={method.toUpperCase()} color='error' variant='outlined' />
        );
      default:
        return <Chip label={method.toUpperCase()} variant='outlined' />;
    }
  };

  const renderCleanPathText = (path: string) => {
    const tmp = path.replace(DEFAULT_API_PATH, '').replace('-', ' ').split(' ');
    return tmp
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (!parsed) return <pre>{JSON.stringify(parsed, null, 2)}</pre>;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <Toolbar
          sx={{
            backgroundColor: '#3f4349',
          }}>
          <Typography variant='h6' noWrap component='div'>
            API Documentation{' '}
            <Chip
              label={getVersion(parsed)}
              color='success'
              variant='outlined'
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: '#3f4349',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
        variant='permanent'
        anchor='left'>
        <Toolbar>
          <img
            alt='SnapGIS logo'
            src={SnapGISLogoSVGFull}
            style={{ maxHeight: 30, cursor: 'pointer' }}
            onClick={() => navigate('/docs')}
          />
        </Toolbar>
        <Divider />
        <List>
          {Object.keys(parsed?.schema?.paths ?? {}).map((path, index) => (
            <>
              <ListItem key={path} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(`?path=${path}`);
                    setOpen({
                      ...open,
                      [path]: true,
                    });
                  }}>
                  <ListItemText primary={renderCleanPathText(path)} />
                  {path !== params?.path && (
                    <IconButton
                      sx={{ p: 0.5 }}
                      onClick={e => {
                        e.stopPropagation();
                        setOpen({
                          ...open,
                          [path]: !open?.[path],
                        });
                      }}>
                      {open?.[path] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </ListItemButton>
              </ListItem>
              <>
                <Collapse
                  in={open?.[path] || path === params?.path}
                  timeout='auto'
                  unmountOnExit>
                  <List component='div' disablePadding sx={{ pl: 4 }}>
                    {Object.keys(parsed?.schema?.paths[path]).map(
                      (method, index) => (
                        <ListItem key={method} disablePadding>
                          <ListItemButton
                            onClick={() =>
                              navigate(`?path=${path}&method=${method}`)
                            }>
                            {renderChip(method)}
                            <ListItemText primary={path} sx={{ pl: 1 }} />
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                  </List>
                </Collapse>
              </>
            </>
          ))}
        </List>
      </Drawer>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          backgroundColor: '#3f4349',
          p: 3,
        }}>
        <Toolbar />
        <Box>
          {!params?.path && !params?.method && (
            <>
              <Typography variant='h6' component='h6'>
                Welcome to the SnapGIS API Documentation
              </Typography>
              <Box p={2}>
                If you already have a SnapGIS account, log in and get your API
                Key{' '}
                <Link
                  onClick={() => navigate('/settings/integrations')}
                  sx={{ cursor: 'pointer' }}>
                  here
                </Link>
                .
              </Box>
              <Box p={2}>
                Contact us if you have any questions or need help with the API
                <div>{contactName(parsed)}</div>
                <div>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`mailto:${contactEmail(parsed)}`}
                    style={{
                      color: 'white',
                    }}>
                    {contactEmail(parsed)}
                  </a>
                </div>
                <div>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`${contactUrl(parsed)}`}
                    style={{
                      color: 'white',
                    }}>
                    {contactUrl(parsed)}
                  </a>
                </div>
              </Box>
            </>
          )}
          <Box mt={1}>
            {params?.path && !params?.method && (
              <>
                <Typography variant='h6' component='h6'>
                  {renderCleanPathText(params.path)}
                </Typography>
                <>
                  {Object.keys(parsed?.schema?.paths[params.path]).map(
                    (method, index) => (
                      <ListItem key={method} disablePadding>
                        <ListItemButton
                          onClick={() =>
                            navigate(`?path=${params.path}&method=${method}`)
                          }>
                          {renderChip(method)}
                          <ListItemText primary={params?.path} sx={{ pl: 1 }} />
                        </ListItemButton>
                      </ListItem>
                    )
                  )}
                </>
              </>
            )}
            {params?.path && params?.method && (
              <>
                <Typography variant='h6' component='h6'>
                  {renderCleanPathText(params.path)}
                </Typography>
                <EndpointDetails
                  spec={parsed?.schema}
                  path={params?.path}
                  method={params?.method}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Drawer
        sx={{
          width: drawerWidth * 2,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: '#3f4349',
            width: drawerWidth * 2,
            boxSizing: 'border-box',
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
        variant='permanent'
        anchor='right'>
        <Toolbar />
        <Divider />
        <Box p={3}>
          <CodePreview
            spec={parsed?.schema}
            path={params?.path}
            method={params?.method}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default ApiDocumentation;
