import React from 'react';
// eslint-disable-next-line import/named
// eslint-disable-next-line import/named
import BoxedContainer from 'components/BoxedContainer';
import { useLocation } from 'react-router-dom';

const ReportWrapper = ({ children }: any) => {
  const location = useLocation();

  return (
    <BoxedContainer>
      <>
        <h1
          style={{
            position: 'static',
            backgroundColor: 'transparent',
            width: '100%',
            marginTop: '.2em',
            marginBottom: 0,
            paddingTop: '.2em',
            paddingBottom: '.2em',
            display: 'block',
            fontSize: '1.8em',
          }}>
          {location.state?.title ?? 'Reports'}
        </h1>
        {children}
      </>
    </BoxedContainer>
  );
};

export default ReportWrapper;
