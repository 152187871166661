import { Tooltip } from '@mui/material';
import SnackbarAlert from 'components/SnackbarAlert';
import useUIContext from 'context/UIContext';
import React, { ReactElement, ReactNode, useState } from 'react';

type InputProps = {
  title: ReactNode;
  children: ReactElement;
  clipboard: string;
  onSuccess?: Function;
  onClose?: Function;
};

export const ToolTipClipboard = ({
  title,
  children,
  clipboard,
}: InputProps) => {
  const [tmpTitle, setTmpTitle] = useState<string | null>(null);
  const { setFlashMessage } = useUIContext();

  return (
    <>
      <Tooltip
        title={tmpTitle || title}
        onClick={async () => {
          setTmpTitle(`Copied ${clipboard}`);
          navigator.clipboard.writeText(clipboard).then(() => {
            setFlashMessage({ message: `Copied ${clipboard}` });
            setTimeout(() => {
              setTmpTitle(null);
            }, 1000);
          });
        }}>
        {children}
      </Tooltip>
    </>
  );
};

export default ToolTipClipboard;
