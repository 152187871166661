import { ShortHandLayer } from './types';

export default ({ id, source, opacity = 1 }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: [
      'all',
      ['has', 'assetType'],
      ['==', ['get', 'assetType'], 'wellhead'],
      ['!', ['has', 'point_count']],
    ],
    layout: {
      'icon-image': 'static_asset_wellhead_image',
      "icon-rotation-alignment": "map",
      'icon-anchor': 'center',
      'icon-offset': [0, 0],
      'icon-allow-overlap': true,
      'icon-size': 0.2,
    },
    paint: {
      'icon-opacity': opacity || 1,
    },
  };
};
