import * as Sentry from '@sentry/react';
import React from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import TwoFaResponse from 'types/TwoFaResponse';

export interface AuthProviderConfig<User = unknown, Error = unknown> {
  key?: string;
  loadUser: (data: any) => Promise<User>;
  loginFn: (data: any) => Promise<User | TwoFaResponse>;
  registerFn: (data: any) => Promise<User>;
  registerConfirmFn: (data: any) => Promise<User>;
  logoutFn: () => Promise<any>;
  waitInitial?: boolean;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error: Error | null }) => JSX.Element;
}

export interface AuthContextValue<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown,
  RegisterCredentials = unknown
> {
  user: User | undefined;
  login: UseMutateAsyncFunction<User | TwoFaResponse, any, LoginCredentials>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  register: UseMutateAsyncFunction<User, any, RegisterCredentials>;
  registerConfirm: UseMutateAsyncFunction<User, any, any>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRegistering: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<User, Error>>;
  error: Error | null;
  isLoading: boolean;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export function initReactQueryAuth<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown,
  RegisterCredentials = unknown
>(config: AuthProviderConfig<User, Error>) {
  const AuthContext = React.createContext<AuthContextValue<
    User,
    Error,
    LoginCredentials,
    RegisterCredentials
  > | null>(null);
  AuthContext.displayName = 'AuthContext';

  const {
    loadUser,
    loginFn,
    registerFn,
    registerConfirmFn,
    logoutFn,
    key = 'user',
    waitInitial = true,
    LoaderComponent = () => (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#141416',
        }}></div>
    ),
    ErrorComponent = (error: any) => (
      <div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>
    ),
  } = config;

  function AuthProvider({ children }: AuthProviderProps): JSX.Element {
    const queryClient = useQueryClient();

    function getCookie(key: string) {
      var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
      return b ? b.pop() : '';
    }

    const {
      data: user,
      error,
      status,
      isLoading,
      isIdle,
      isSuccess,
      refetch,
    } = useQuery<User | any, Error>({
      queryKey: key,
      queryFn: loadUser,
      retry: 0,
      refetchOnWindowFocus: false,
    });

    const setUser = React.useCallback(
      (user: User) => queryClient.setQueryData(key, user),
      [queryClient]
    );

    React.useEffect(() => {
      if (user) {
        Sentry.setUser({ id: user.userUuid });
      }
    }, [user]);

    const loginMutation = useMutation({
      mutationFn: loginFn,
      onSuccess: (response: any) => {
        if (response.name) {
          setUser(response);
        }
      },
    });

    const registerMutation = useMutation({
      mutationFn: registerFn,
      // onSuccess: user => {
      //   setUser(user);
      // },
    });

    const registerConfirmMutation = useMutation({
      mutationFn: registerConfirmFn,
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      onSuccess: () => {
        queryClient.clear();
      },
    });

    const value = React.useMemo(
      () => ({
        user,
        error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        logout: logoutMutation.mutateAsync,
        isLoggingOut: logoutMutation.isLoading,
        register: registerMutation.mutateAsync,
        isRegistering: registerMutation.isLoading,
        registerConfirm: registerConfirmMutation.mutateAsync,
        isRegisterConfirmLoading: registerConfirmMutation.isLoading,
        isLoading,
      }),
      [
        user,
        error,
        refetch,
        loginMutation.mutateAsync,
        loginMutation.isLoading,
        logoutMutation.mutateAsync,
        logoutMutation.isLoading,
        registerMutation.mutateAsync,
        registerMutation.isLoading,
        registerConfirmMutation.mutateAsync,
        registerConfirmMutation.isLoading,
        isLoading,
      ]
    );

    if (isSuccess || !waitInitial) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    }

    if (isLoading || isIdle) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
      // return <LoaderComponent />;
    }

    if (error) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
      // return <ErrorComponent error={error} />;
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return {
    AuthProvider,
    AuthConsumer: AuthContext.Consumer,
    useAuth,
  };
}
