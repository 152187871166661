import { SOURCES } from './types';
import { Location } from 'types';
import GeoJSON from 'components/Map/constants/sources/GeoJSON';

export default (locationData: Location[]) =>
  GeoJSON({
    id: SOURCES.LOCATIONS,
    data: {
      type: 'FeatureCollection',
      features: locationData?.flatMap((location: Location) => [
        {
          type: 'Feature',
          properties: {
            icon: 'location', // #3be867
            id: location?.locationId,
            name: location?.name,
            description: location?.description,
            locationId: location?.locationId,
            group: location?.group,
          },
          geometry: {
            type: 'Point',
            coordinates: [location?.position?.lon, location?.position?.lat],
          },
        },
        {
          type: 'Feature',
          properties: {
            id: location?.locationId,
            name: location?.name,
            locationId: location?.locationId,
            group: location?.group,
            active: false,
          },
          geometry: location.feature,
        },
      ]),
    },
  });
