import React from 'react';
import { Box, Divider, ListSubheader } from '@mui/material';

const ListSubHeader = ({ label, backgroundColor, disableSticky }: any) => {
  return (
    <>
      <ListSubheader
        disableSticky={disableSticky}
        sx={{
          textAlign: 'center',
          // backgroundColor: backgroundColor ?? '#1a1c1d',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '.9em',
          lineHeight: '1rem',
          // border: '1px solid #343434',
          pb: '1px',
          pl: 0,
          pr: 0,
        }}>
        <Box sx={{ p: '6px', pb: '3px', pl: '0', pr: 0, boxSizing: 'border-box' }}>{label}</Box>
        <Divider sx={{ mb: '3px' }}></Divider>
      </ListSubheader>
    </>
  );
};

export default ListSubHeader;
