import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { apiClient } from 'services/axios';

export function useDriverReport({
  page,
  pageSize,
  filterBy,
  sortBy,
  sortOrder,
  searchTerm
}: {
  page: number;
  pageSize: number;
  filterBy?: any;
  sortBy?: string;
  sortOrder?: string;
  searchTerm?: string;
}): UseQueryResult {
  return useQuery(
    ['driverReports', page, filterBy, searchTerm],
    ({ signal }) =>
      apiClient.get('/reports/drivers', {
        params: {
          page,
          pageSize,
          sortBy,
          sortOrder,
          searchTerm,
          ...filterBy,
        },
      }),
    {
      // enabled: !!filterBy.startTimestamp && !!filterBy.endTimestamp,
    }
  );
}

export function useExportDriversReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_drivers_export',
    (variables: any) =>
      apiClient.post(`reports/drivers/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        groupIds: variables.groupIds,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_drivers_export');
      },
    }
  );
}