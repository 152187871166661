import {
  CountWidget,
  LineChart,
  MapChart,
  PieChartWidget,
} from 'components/Dashboard/widgets';
import React from 'react';

export default [
  {
    title: 'Fleet Overview',
    component: (
      <CountWidget
        assetType='vessel_ais'
        mapping={[
          {
            label: 'Vessels',
            dataKey: 'total',
            onClick: ['navigate', '/reports/vessels_ais/status'],
          },
          {
            label: 'Reporting',
            dataKey: 'reporting',
            onClick: ['navigate', '/reports/vessels_ais/status'],
          },
          {
            label: 'On Voyage',
            dataKey: 'on_voyage',
            onClick: [
              'navigate',
              '/reports/vessels_ais/status?filterBy[moving]=true',
            ],
          },
          {
            label: 'In Port',
            dataKey: 'in_port',
            onClick: [
              'navigate',
              '/reports/vessels_ais/status?filterBy[stationary]=true',
            ],
          },
        ]}
      />
    ),
    structure: {
      w: 5,
      h: 1,
      x: 0,
      y: 0,
      i: 'graph1',
      minW: 3,
    },
  },
  {
    title: 'Total Distance (7 days)',
    component: (
      <LineChart
        assetType='vessel_ais'
        widgetType='distance'
        chartUnit='kilometers'
        dataKey='distance'
      />
    ),
    structure: {
      w: 7,
      h: 1,
      x: 5,
      y: 0,
      i: 'graph2',
      minW: 3,
    },
  },
  {
    title: 'Vessel Types',
    component: <PieChartWidget assetType='vessel_ais' widgetType='type' />,
    structure: {
      w: 5,
      h: 2,
      x: 0,
      y: 1,
      i: 'graph3',
    },
  },
  {
    title: 'Fleet Locations',
    component: <MapChart assetType='vessel_ais' widgetType='locations' />,
    structure: {
      w: 7,
      h: 2,
      x: 5,
      y: 1,
      i: 'graph4',
      minW: 7,
      maxW: 7,
      minH: 2,
      maxH: 2,
    },
  },
];
