import React from 'react';
import TableToolbarAction from './TableToolbarAction';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddCircleOutline } from '@mui/icons-material';

export const TableToolbarActionButton = ({
  onClick,
  color = 'secondary',
  variant = 'outlined',
  translationKey,
  minWidth,
}: any) => {
  const { t } = useTranslation();

  return (
    <TableToolbarAction>
      <Button
        startIcon={<AddCircleOutline />}
        onClick={onClick}
        color={color}
        variant={variant}
        sx={{
          minWidth: minWidth ? minWidth : 'auto',
        }}>
        {t(translationKey)}
      </Button>
    </TableToolbarAction>
  );
};

export default TableToolbarActionButton;
