import { Location } from 'types';
import GeoJSON from 'components/Map/constants/sources/GeoJSON';

export default (id: string, locationData: Location | null) =>
  GeoJSON({
    id,
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              locationData?.position?.lon,
              locationData?.position?.lat,
            ],
          },
        },
      ],
    },
  });
