import { CountWidget, LineChart, MapChart } from 'components/Dashboard/widgets';
import React from 'react';

export default [
  {
    title: 'Fleet Overview',
    component: (
      <CountWidget
        assetType='vessel'
        mapping={[
          {
            label: 'Vessels',
            dataKey: 'total',
            color: 'white',
            onClick: ['navigate', '/reports/vessels/status'],
          },
          {
            label: 'In Use',
            dataKey: 'in_use',
            color: '#cae9a7',
            tooltip: 'dashboard.vessel.count_widget.in_use_tooltip',
            onClick: [
              'navigate',
              '/reports/vessels/status?filterBy[ignition]=true',
            ],
          },
          {
            label: 'Idling',
            dataKey: 'idling',
            color: '#e3cd75',
            tooltip: 'dashboard.vessel.count_widget.idling_tooltip',
            onClick: [
              'navigate',
              '/reports/vessels/status?filterBy[idling]=true',
            ],
          },
          {
            label: 'Not Reporting',
            dataKey: 'not_reporting',
            color: '#FD6262',
            tooltip: 'dashboard.vessel.count_widget.not_reporting_tooltip',
            onClick: [
              'navigate',
              '/reports/vessels/status?filterBy[notReporting]=true',
            ],
          },
        ]}
      />
    ),
    structure: {
      w: 5,
      h: 1,
      x: 0,
      y: 0,
      i: 'graph1',
      minW: 3,
      maxH: 1,
    },
  },
  {
    title: 'Total Distance (7 days)',
    component: (
      <LineChart
        assetType='vessel'
        widgetType='distance'
        chartUnit='kilometers'
        dataKey='distance'
      />
    ),
    structure: {
      w: 7,
      h: 1,
      x: 5,
      y: 0,
      i: 'graph2',
      minW: 3,
    },
  },
  {
    title: 'Vessel Locations',
    component: <MapChart assetType='vessel' widgetType='locations' />,
    structure: {
      w: 5,
      h: 2.4,
      x: 0,
      y: 1,
      i: 'graph3',
    },
  },
  {
    title: 'Total Fuel Usage (7 days)',
    component: (
      <LineChart
        assetType='vessel'
        widgetType='fuel_used'
        chartUnit='liters'
        dataKey='fuelUsed'
      />
    ),
    legend:
      '* Fuel usage calculated only for devices which support sending fuel data.',
    structure: {
      w: 7,
      h: 1.2,
      x: 5,
      y: 1,
      i: 'graph4',
    },
  },
  {
    title: 'Total Carbon Emissions (7 days)',
    component: (
      <LineChart
        assetType='vessel'
        widgetType='carbon'
        chartUnit='kilograms'
        dataKey='carbon'
      />
    ),
    legend:
      '* Carbon emissions calculated only for devices which support sending fuel and carbon data.',
    structure: {
      w: 7,
      h: 1.2,
      x: 5,
      y: 2,
      i: 'graph5',
    },
  },
];
