import React from 'react';
import { useDashboardWidget } from 'hooks';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Cell,
  Legend,
} from 'recharts';
import { Skeleton } from '@mui/material';
import './style.scss';

// https://flatuicolors.com/palette/gb
const COLORS = [
  '#00a8ff',
  '#9c88ff',
  '#fbc531',
  '#4cd137',
  '#487eb0',
  '#e84118',
  '#7f8fa6',
  '#273c75',
  '#353b48',
  // '#2980B9',
  // '#16A085',
  // '#C0392B',
  // '#27AE60',
  // '#8E44AD',
  // '#2C3E50',
  // '#F39C12',
  // '#D35400',
  // '#27AE60',
  // '#BDC3C7',
  // '#7F8C8D',
];

const RADIAN = Math.PI / 180;

function truncate(str: string, n: number, useWordBoundary: boolean) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(' '))
      : subString) + '…'
  );
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
  value,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const countRadius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const countX = cx + countRadius * Math.cos(-midAngle * RADIAN);
  const countY = cy + countRadius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'>
        {/* {truncate(name, 20, true)} */}
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      {/* {percent > 0.1 && (
        <text
          x={countX}
          y={countY}
          fill='white'
          textAnchor={countX > cx ? 'start' : 'end'}
          dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )} */}
    </>
  );
};

export const PieChartWidget = ({
  assetType,
  widgetType,
}: {
  assetType: string;
  widgetType: string;
}) => {
  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
  }) as any;

  if (!data || isLoading)
    return <Skeleton variant='rounded' width={'100%'} height={'100%'} />;

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart>
        <Pie
          dataKey='value'
          isAnimationActive={false}
          data={Object.entries(data?.types).map((type: any) => ({
            name: type[0],
            value: type[1],
          }))}
          cx='50%'
          cy='50%'
          outerRadius={'60%'}
          innerRadius={'35%'}
          fill='#8884d8'
          labelLine={true}
          label={renderCustomizedLabel}>
          {Object.entries(data?.types).map((type: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          separator={`: `}
          formatter={(value: any, name: string, props: any) => [
            // `${value} vessels (${((value / data.total) * 100).toFixed(0)}%)`,
            `${value} vessels`,
            name,
          ]}
        />
        <Legend
          layout='vertical'
          align='right'
          verticalAlign='bottom'
          wrapperStyle={{
            height: '100%',
            overflowY: 'scroll',
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartWidget;
