import { ShortHandLayer } from './types';

const statusColorMapping = {
  ignition: '#00b300',
  idling: '#ffbf00',
};

// DOT CHAR: ●

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'asset_label_background',
      'text-field': [
        'format',
        ["case",
          ["==", ["get", "idling"], true], "●", 
          ["==", ["get", "ignition"], true], "●", 
          ""
        ],
        {
          "font-scale": [
            "case",
            ["==", ["get", "idling"], true], 1,
            ["==", ["get", "ignition"], true], 1,
            1
          ],
          "text-color": [
            "case",
            ["==", ["get", "idling"], true], statusColorMapping.idling,
            ["==", ["get", "ignition"], true], statusColorMapping.ignition,
            "transparent"
          ]
        },
        " ",
        ["get", "name"],
        {
          "text-color": "white"
        },
      ],
      'text-max-width': 10,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-anchor': 'top',
      "text-size": 12,
      "text-offset": [
        "case",
        [
          "all",
          [">", ["coalesce", ["get", "heading"], 0], 125],
          ["<", ["coalesce", ["get", "heading"], 0], 235]
        ],
        ["literal", [0, 2.5]],
        ["literal", [0, 2]]
      ],
      'icon-text-fit': 'both',
      'icon-text-fit-padding': [16, 12, 4, 12],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
    },
    paint: {
      'text-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        5, 0,
        6, 1
      ],
      'icon-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        5, 0,
        6, 1
      ],
      'text-halo-color': 'rgba(0,0,0,0.5)',
      'text-halo-blur': 2,
      'text-halo-width': 1,
    }
  };
};
