import { ShortHandLayer } from './types';

export default ({ id, source, color, size, opacity, dash }: ShortHandLayer) => {
  return {
    id,
    type: 'line',
    source,
    layout: {
      'line-join': 'bevel',
      'line-cap': 'round',
    },
    paint: {
      'line-color': 'rgba(255,255,255,0.3)',
      'line-width': [
        'case',
        ['==', ['get', 'Type'], 'Major'],
        3,
        ['==', ['get', 'Type'], 'Middle'],
        2,
        1,
      ],
      'line-opacity': 0.5,
      'line-dasharray': [1, 2],
    },
  };
};
