import { LayersOutlined } from '@mui/icons-material';
import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'line',
    source,
    layout: {
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#ffffff',
      'line-width': 2,
      'line-blur': 2,
      'line-opacity': .75,
      'line-dasharray': [2, 2],
    }
  };
};
