/* eslint-disable max-len */
import { ShortHandLayer } from './types';

export default ({ id, source, color, size, opacity, dash }: ShortHandLayer) => { 
  return {
    id, 
    type: 'symbol',
    source,
    layout: {
      'icon-image': 'place-marker',
      'icon-size': 0.5,
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
      "text-size": 13,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-ignore-placement': false,
      'text-allow-overlap': false,
    },
    paint: {
      'text-halo-color': '#000',
      'text-halo-blur': 0,
      'text-halo-width': 1,
      'text-color': '#3be867',
    }
  }
};