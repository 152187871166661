import { HarRequest } from './types/HarRequest';

export default class HttpSnippet {
  private request: HarRequest;

  constructor(request: HarRequest) {
    this.request = request;
  }

  async convert(language: string, options: any = {}): Promise<string | null> {
    switch (language) {
      case 'curl':
        const { generateCurl } = await import('./shell/curl');
        console.log('generateCurl', !!generateCurl);
        return generateCurl({ harRequest: this.request, options });
      case 'httpie':
        const { generateHttpie } = await import('./shell/httpie');
        console.log('generateHttpie', !!generateHttpie);
        return generateHttpie({ harRequest: this.request, options });
      default:
        console.warn(`Unsupported language: ${language}`);
        return null;
    }
  }
}
