import { makeStyles } from '@mui/styles';
// eslint-disable-next-line import/named
import { DataGrid, GridCallbackDetails, GridColDef } from '@mui/x-data-grid';
import React from 'react';

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
});

type InputProps = {
  data: any[];
  isLoading: boolean;
  columns: GridColDef<any, any, any>[];
  onPageChange: (page: number, details: GridCallbackDetails<any>) => void;
  getRowId: Function;
  pageSize?: number;
  disableColumnFilter?: boolean;
  disableColumnMenu?: boolean;
  sortingOrder?: any;
  onRowClick?: Function;
  onCellClick?: Function;
  paginationMode?: 'client' | 'server';
  rowCount?: number;
  rowHeight?: number;
  sx?: any;
};

export const Table = (props: InputProps) => {
  const classes = useStyles();
  const {
    data,
    isLoading,
    columns,
    onPageChange,
    getRowId,
    pageSize = 8,
    disableColumnFilter = true,
    disableColumnMenu = true,
    sortingOrder = ['desc', 'asc'],
    onRowClick = (row: any) => null,
    onCellClick = (row: any) => null,
    paginationMode = 'server',
    rowCount = data?.length || pageSize,
    rowHeight = 40,
    sx,
  } = props;

  return (
    <DataGrid
      disableSelectionOnClick={true}
      className={classes.root}
      rows={data || []}
      getRowId={row => getRowId(row)}
      hideFooterSelectedRowCount={true}
      rowCount={rowCount}
      rowHeight={rowHeight}
      disableColumnSelector={true}
      columns={columns}
      onPageChange={onPageChange}
      sortingOrder={sortingOrder}
      pageSize={pageSize}
      checkboxSelection={false}
      autoHeight={true}
      loading={isLoading}
      disableColumnFilter={disableColumnFilter}
      disableColumnMenu={disableColumnMenu}
      onRowClick={(row: any) => (onRowClick ? onRowClick(row) : null)}
      onCellClick={(row: any) => (onCellClick ? onCellClick(row) : null)}
      paginationMode={paginationMode}
      pagination
      sx={{
        ...sx,
        ...(onRowClick && {
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }),
      }}
    />
  );
};

export default Table;
