import React, { useEffect, useMemo, useState } from 'react';
import CodeMirror, { EditorState, EditorView } from '@uiw/react-codemirror';
import { atomone } from '@uiw/codemirror-theme-atomone';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CodeSnippet from './generators';
import moment from 'moment';

const API_BASE_URL = 'https://api-dev.snapgis.com';

const languageClients = {
  shell: ['curl', 'httpie'],
} as any;

const getClientsForLanguage = (
  language: any,
  setClient: (name: string) => void
) => {
  return (
    languageClients[language]?.map((client: any, index: number) => {
      return (
        <MenuItem key={client} onClick={() => setClient(client)} value={client}>
          {client}
        </MenuItem>
      );
    }) ?? []
  );
};

export const CodePreview = ({
  spec,
  path,
  method,
}: {
  spec: any;
  path: string;
  method: string;
}) => {
  const [code, setCode] = useState<string | null>(null);
  const [language, setLanguage] = useState('shell') as any;
  const [client, setClient] = useState('curl') as any;
  const [auth, setAuth] = useState<string[]>([]);

  const snippet = useMemo(() => {
    if (!method || !path) return;
    const snippet = new CodeSnippet({
      method: method?.toUpperCase(),
      url: `${API_BASE_URL}${path}`,
      queryString: [
        {
          name: 'fromTimestamp',
          value: moment().subtract(1, 'day').toISOString(),
        },
      ],
      headers: [
        { name: 'Content-Type', value: 'application/json' },
        { name: 'Authorization', value: `Basic ` },
      ],
    });
    return snippet;
  }, [spec, path, method]);

  useEffect(() => {
    if (!snippet || !client) {
      setCode('');
      return;
    }

    snippet
      .convert(client)
      .then(setCode)
      .catch(() => setCode(''));
  }, [snippet, client]);

  return (
    <>
      <Box sx={{ backgroundColor: '#272C35' }}>
        <Box p={2}>
          {/* <Box mb={2}>
            <CodeLanguageSelector
              language={language}
              setLanguage={(name: string) => {
                setLanguage(name);
                setClient(null);
              }}
            />
          </Box> */}
          <FormControl>
            <InputLabel id='api-client-selector-label'>Client</InputLabel>
            <Select
              labelId='api-client-selector-label'
              id='api-client-selector'
              value={client || languageClients?.[language]?.[0]}
              label='Client'
              size='small'
              onChange={(e: any) => setClient(e.target.value)}>
              {getClientsForLanguage(language, setClient)}
            </Select>
          </FormControl>
        </Box>
        <CodeMirror
          height='auto'
          value={code ?? ''}
          theme={atomone}
          extensions={[
            EditorState.readOnly.of(true),
            EditorView.lineWrapping,
            EditorView.theme({
              '&.cm-focused': {
                outline: 'none',
              },
            }),
            EditorView.contentAttributes.of({
              'data-gramm': 'false',
              'data-gramm_editor': 'false',
              'data-enable-grammarly': 'false',
              spellcheck: 'false',
            }),
          ]}
        />
        <Box sx={{ backgroundColor: '#272C35', py: 2, px: 2 }}>
          <Grid container display='flex' justifyContent={'flex-end'}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() =>
                  code ? navigator.clipboard.writeText(code) : {}
                }>
                Copy
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CodePreview;
