/* eslint-disable max-len */
const size = 200;

const pulsingDot: any = {
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),
  context: null,
  map: null,

  onAdd: function (map: any) {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
    this.map = map;
  },

  render: function () {
    const duration = 1000;
    const t = (performance.now() % duration) / duration;

    const radius = (size / 2) * 0.3;
    const outerRadius = (size / 2) * 0.7 * t + radius;
    const context = this.context;

    context.clearRect(0, 0, this.width, this.height);

    context.beginPath();
    context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2
    );
    context.fillStyle = `rgba(200, 255, 200, ${1 - t})`;
    context.fill();

    this.data = context.getImageData(
        0,
        0,
        this.width,
        this.height
    ).data;

    this.map.triggerRepaint();

    return true;
  }
}

export default pulsingDot;