import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import deviceTypes from 'common-web/definitions/deviceTypes.json';
import { getDirectionFromDeg } from 'common-web/utils';
import { LAYERS, PointSource, SOURCES } from 'components/Map';
import Map from 'components/Map/AltMap';
import SingleAssetPoint from 'components/Map/constants/layers/SingleAssetPoint';
import moment from 'moment';
import { useLocalStorage } from '../../../hooks';

const AssetLogRowPopover = (props: any) => {
  const [storedMapStyle, _] = useLocalStorage('mapStyle', '');
  const [locationsDataSource, setLocationsDataSource] = React.useState<any>();
  const assetPointLayer: any = SingleAssetPoint({
    id: LAYERS.ASSET_POINTS,
    source: SOURCES.ASSET_LOCATIONS,
  });
  const layers = [assetPointLayer];
  const { t } = useTranslation();

  React.useEffect(() => {
    if (props.row?.position) {
      setLocationsDataSource(PointSource(SOURCES.ASSET_LOCATIONS, props.row));
    }
  }, [props.row]);

  const rowStyle = {
    '& td': { border: 0, fontSize: '.85em', px: 0 },
    '& td:first-child': { width: '40%' },
  };

  return (
    <Box sx={{ width: '100%', p: 0, background: 'transparent' }}>
      <Grid container>
        <Grid item xs={7}>
          <Map
            hasControls={false}
            hasDraw={false}
            center={props.row?.position}
            sources={[locationsDataSource]}
            layers={layers}
            images={[]} // important otherwise won't render
            mapStyle={storedMapStyle}
            zoom={15}
            style={{ minHeight: '25rem' }}
            keyboard={false}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'top',
            alignItems: 'center',
            pl: 2,
            pr: 1,
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid grey',
              mb: 2,
            }}>
            <Typography variant='h6' sx={{ fontSize: '1.1em' }}>
              Report Details
            </Typography>
            <IconButton onClick={props.handleClose} sx={{ mr: -1 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TableContainer>
            <Table sx={{ width: '100%' }} size='small'>
              <TableBody>
                <TableRow sx={rowStyle}>
                  <TableCell>Asset</TableCell>
                  <TableCell align='right'>{props.row?.assetName}</TableCell>
                </TableRow>
                <TableRow sx={rowStyle}>
                  <TableCell>Device Type</TableCell>
                  <TableCell align='right'>
                    {`${
                      deviceTypes.deviceTypes?.filter(
                        (item: any) =>
                          props.row?.deviceIdentifiers?.[0].deviceType ===
                          item.id
                      )[0]?.manufacturer
                    } ${
                      deviceTypes.deviceTypes?.filter(
                        (item: any) =>
                          props.row?.deviceIdentifiers?.[0].deviceType ===
                          item.id
                      )[0]?.model
                    }`}
                  </TableCell>
                </TableRow>
                {/* Loop over props.row.deviceIdentifiers and display them */}
                {props.row?.deviceIdentifiers?.map(
                  (device: any, index: number) => (
                    <TableRow key={index} sx={rowStyle}>
                      <TableCell>{t(device.type)}</TableCell>
                      <TableCell align='right'>{device.identifier}</TableCell>
                    </TableRow>
                  )
                )}
                <TableRow sx={rowStyle}>
                  <TableCell>Type</TableCell>
                  <TableCell align='right'>
                    {t(`reports.report_type.${props.row.reportType}`)}
                  </TableCell>
                </TableRow>
                <TableRow sx={rowStyle}>
                  <TableCell>Reported At</TableCell>
                  <TableCell align='right'>
                    {moment(props.row?.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
                <TableRow sx={rowStyle}>
                  <TableCell>Speed</TableCell>
                  <TableCell align='right'>{props.row?.speed} km/h</TableCell>
                </TableRow>
                <TableRow sx={rowStyle}>
                  <TableCell>Heading</TableCell>
                  <TableCell align='right'>
                    {' '}
                    {props.row?.heading || 0}°{' '}
                    {`(${getDirectionFromDeg(props.row?.heading || 0)})`}
                  </TableCell>
                </TableRow>
                <TableRow sx={rowStyle}>
                  <TableCell>Address</TableCell>
                  <TableCell align='right'>
                    {props.row?.formattedAddress}
                  </TableCell>
                </TableRow>
                <TableRow sx={rowStyle}>
                  <TableCell>Transport</TableCell>
                  <TableCell align='right'>
                    {t(`reports.transport.${props.row?.transport}`)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              marginTop: 'auto',
              marginBottom: 0,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
            <ButtonGroup variant='text' aria-label='Basic button group'>
              <Button
                onClick={props.handleClickPrev}
                startIcon={<KeyboardArrowUpIcon />}>
                Prev
              </Button>
              <Button
                onClick={props.handleClickNext}
                endIcon={<KeyboardArrowDownIcon />}>
                Next
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetLogRowPopover;
