import FilterAutocomplete from 'components/ActionMenu/modules/ActivityFeed/ActivityFeedFilters/FilterAutocomplete';
import { useLocations } from 'hooks';
import React, { useEffect } from 'react';

const LocationFilter = ({
  updateFilters,
  filters,
  multiple = true,
  size = 'small',
  limitTags = 1,
}: any) => {
  const [state, setState] = React.useState<any>(multiple ? [] : null);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [allLocations, setAllLocations] = React.useState<any[]>([]);
  const delayTimeout = React.useRef<any>(null);
  const { data: locationsData, isLoading: locationsAreLoading } = useLocations({
    searchTerm,
    page: 1,
    pageSize: 1000,
  }) as any;

  const handleChange = (
    filterType: string,
    event: React.SyntheticEvent,
    value: any
  ) => {
    setState(value);
    handleUpdateFilters(value);
  };

  const onInputChange = (event: any) => {
    if (delayTimeout?.current) {
      clearTimeout(delayTimeout?.current);
      delayTimeout.current = null;
    }
    delayTimeout.current = setTimeout(() => {
      setSearchTerm(event?.target?.value);
    }, 300);
  };

  const handleUpdateFilters = (value: any) => {
    let locationIds: any;

    if (multiple) {
      locationIds = value.map((val: any) => val.locationId);
    } else {
      locationIds = value?.locationId ? [value.locationId] : null;
    }

    updateFilters((prevFilters: any) => ({
      ...prevFilters,
      locationIds,
    }));
  };

  useEffect(() => {
    if (!locationsData || !locationsData?.locations) return;

    const selectedLocations = allLocations?.filter((location: any) =>
      filters?.locationIds?.includes(location.locationId)
    );

    const combinedLocations = [
      ...selectedLocations,
      ...locationsData.locations,
    ];

    const uniqueLocations = Array.from(
      new Set(combinedLocations?.map((location: any) => location.locationId))
    ).map(id =>
      combinedLocations?.find((location: any) => location.locationId === id)
    );

    setAllLocations(uniqueLocations);

    const ids = uniqueLocations.filter((location: any) =>
      filters.locationIds?.includes(location.locationId)
    );
    if (ids) {
      const value = multiple ? ids : ids[0];
      setState(value);
    }
  }, [locationsData?.locations, filters]);

  return (
    <FilterAutocomplete
      fullWidth
      multiple={multiple}
      options={allLocations}
      state={state}
      loading={locationsAreLoading}
      onChange={handleChange}
      onInputChange={onInputChange}
      filterName='location'
      label='Location'
      keyProperty='locationId'
      labelProperty='name'
      size={size}
      limitTags={limitTags}
      limitOptions={100}
      disableCloseOnSelect={false}
    />
  );
};

export default LocationFilter;
