import { apiClient } from 'services/axios';
import { useMutation, useQueryClient } from "react-query";

const resetPassword = async ({ email, captcha }: any): Promise<any> => {
  return (await apiClient.post('/forgotpassword', {
    email,
    captcha,
  })) as any;
};

export function useForgotPassword(): any {
  return useMutation(
    'forgot-password',
    ({
       userId,
       captcha,
       forgotPasswordSecret,
       password,
       confirmPassword
    }: any) => {
      return apiClient.post(`/reset-password/${forgotPasswordSecret}`,
        {
        userId,
        password,
        confirmPassword,
        captcha,
      }) as any;
    }
  );
}

  export { resetPassword };
