import { GroupFilter } from 'components/Filters';
import Search from 'components/Search';
import TableToolbar from 'components/TableToolbar';
import TableToolbarActionButton from 'components/TableToolbar/TableToolbarActionButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import usePermissions from 'context/PermissionContext';
import React from 'react';

const ContactsToolbar = (props: any) => {
  const { setSearchTerm, handleAddContact } = props;
  const { canWrite } = usePermissions();

  return (
    <TableToolbar grid={[3, 3, 6]}>
      <Search
        translationKey='contact_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      <GroupFilter {...props} multiple />
      {canWrite('CONTACT') && (
        <TableToolbarActionButton
          minWidth={162}
          onClick={handleAddContact}
          translationKey='add_contact'
        />
      )}
    </TableToolbar>
  );
};

const ContactsToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <ContactsToolbar {...props} />
    </WithUrlFilters>
  );
};

export default ContactsToolbarWithUrlFilters;
