import React, { useEffect, useMemo } from 'react';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Chip, IconButton, Tooltip } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useAssetsById, useGroups, useLocationsById } from 'hooks';
import { useDriversFilter } from 'hooks/filters/useFilters';
import {
  useDeleteScheduledReport,
  useScheduledReports,
} from 'hooks/reports/useScheduledReport';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RRule } from 'rrule';

const commonColDefOptions: Partial<GridColDef> = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

function getColumns({
  t,
  navigate,
  canRead,
  groups,
  locations,
  assets,
  drivers,
  setShowDialog,
  isDeleting,
}: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'name',
      headerName: t('Name'),
      ...commonColDefOptions,
    }, // @ts-ignore
    {
      field: 'reportType',
      headerName: t('Report Type'),
      ...commonColDefOptions,
      valueFormatter: (row: any) => t(row.value),
    }, // @ts-ignore
    {
      field: 'filters',
      headerName: t('Filters'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        const filters = r.row.filters;
        if (!filters || Object.keys(filters).length === 0) return 'None';
        const getTooltipTitle = (type: string, values: any): any => {
          if ('reportTypes' === type) {
            return values.map((v: any) => (
              <li key={v}>{t('reports.report_type.' + v)}</li>
            ));
          } else if ('groupIds' === type) {
            return values
              ?.filter((v: any) => !!groups?.[v])
              .map((v: any) => <li key={v}>{groups?.[v]}</li>);
          } else if ('locationIds' === type) {
            return values
              ?.filter((v: any) => !!locations?.[v])
              .map((v: any) => <li key={v}>{locations?.[v]}</li>);
          } else if ('assetIds' === type) {
            return values
              ?.filter((v: any) => !!assets?.[v])
              .map((v: any) => <li key={v}>{assets?.[v]}</li>);
          } else if ('driverIds' === type) {
            return values
              ?.filter((v: any) => !!drivers?.[v])
              .map((v: any) => <li key={v}>{drivers?.[v]}</li>);
          } else if ('showAllTrips' === type) {
            return values === true ? 'True' : 'False';
          }
        };
        const getTooltipCount = (type: string, values: any) => {
          if ('reportTypes' === type) {
            return values.length;
          } else if ('groupIds' === type) {
            return values.filter((v: any) => !!groups?.[v]).length;
          } else if ('locationIds' === type) {
            return values.filter((v: any) => !!locations?.[v]).length;
          } else if ('assetIds' === type) {
            return values.filter((v: any) => !!assets?.[v]).length;
          } else if ('driverIds' === type) {
            return values.filter((v: any) => !!drivers?.[v]).length;
          } else if ('showAllTrips' === type) {
            console.log('showAllTrips', values);
            return 123123;
          }
        };
        const children = Object.keys(filters).map((value: any) => {
          const count = getTooltipCount(value, filters[value]);
          return (
            <Tooltip
              disableInteractive
              arrow={true}
              title={
                count ? (
                  <ul style={{ paddingLeft: 12 }}>
                    {getTooltipTitle(value, filters[value])}
                    {count < filters[value].length && (
                      <li>
                        <b>
                          {t('scheduledReports.filters.more', {
                            count: filters[value].length - count,
                          })}
                        </b>
                      </li>
                    )}
                  </ul>
                ) : (
                  <b>
                    {t('scheduledReports.filters.none', {
                      count: filters[value].length - count,
                    })}
                  </b>
                  // <ul style={{ paddingLeft: 12 }}>
                  //   {generateRandomStrings(filters[value].length).map(
                  //     (v: string, i: number) => {
                  //       return (
                  //         <li className='blurry-text' key={i}>
                  //           {v}
                  //         </li>
                  //       );
                  //     }
                  //   )}
                  // </ul>
                )
              }
              key={value}>
              <Chip
                size='small'
                sx={{ mr: 1, fontSize: 13, cursor: 'pointer' }}
                label={`${filters[value].length ?? ''} ${t(
                  'scheduledReports.filters.' + value
                )}`}
                variant='outlined'
              />
            </Tooltip>
          );
        });
        return <>{children}</>;
      },
    }, // @ts-ignore
    {
      field: 'rrule',
      headerName: t('Schedule'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        try {
          const rrule = RRule.fromString(r.row.rrule);
          const rruleText = rrule
            .toText()
            .replace('every year ', '')
            .replace('every month ', '')
            .replace('every week ', '')
            .replace('every day ', '')
            .replace('every year', '')
            .replace('every month', '')
            .replace('every week', '')
            .replace('every day', '');
          const timing = rrule.options.dtstart;
          const timingText = timing
            ? `at ${timing?.getHours()?.toString()?.padStart(2, '0')}:00`
            : '';
          return `${t(
            'scheduledReports.scheduleTypeShort.' + r.row.scheduleType
          )} ${rruleText} ${timingText}`;
        } catch (e) {
          console.error(e);
        }
        return 'None';
      },
    }, // @ts-ignore
    ...(canRead('ASSET')
      ? [
          {
            ...commonColDefOptions,
            align: 'center',
            headerName: '',
            minWidth: 90,
            maxWidth: 100,
            renderCell: (r: any) => {
              return (
                <div>
                  <Tooltip title={t('Edit asset')} disableInteractive>
                    <IconButton
                      disabled={false}
                      onClick={() =>
                        navigate(
                          `/reports/scheduler/edit/${r.row.scheduledReportUuid}`
                        )
                      }>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Delete asset')} disableInteractive>
                    <IconButton
                      disabled={!!isDeleting}
                      onClick={() =>
                        setShowDialog({
                          id: r.row?.scheduledReportUuid,
                          name: r.row?.name,
                        })
                      }>
                      <Delete fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            },
          },
        ]
      : []), // @ts-ignore
  ];
}

export const ScheduledReportsList = () => {
  const [pageSize, setPageSize] = React.useState(10);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [showDialog, setShowDialog] = React.useState<{
    id: string | null;
    name: string | null;
  } | null>(null);
  const { setFlashMessage } = useUIContext();
  const { data, isLoading } = useScheduledReports({ page, pageSize }) as any;
  const {
    mutate: deleteReport,
    isLoading: isDeleting,
    isSuccess: isDeleted,
  } = useDeleteScheduledReport() as any;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canRead } = usePermissions();
  const [allGroups, setAllGroups] = React.useState<Set<number>>(new Set());
  const [allLocations, setAllLocations] = React.useState<Set<number>>(
    new Set()
  );
  const [allAssets, setAllAssets] = React.useState<Set<number>>(new Set());
  const { data: groupsData } = useGroups({
    groupIds: [...allGroups],
  }) as any;
  const { data: locationsData } = useLocationsById([...allLocations]) as any;
  const { data: assetsData } = useAssetsById([...allAssets]) as any;
  const [allDrivers, setAllDrivers] = React.useState<Set<number>>(new Set());
  const { data: driversData } = useDriversFilter({
    driverIds: [...allDrivers],
  }) as any;

  useEffect(() => {
    if (!!data && data?.scheduledReports?.length > 0) {
      const copy = allGroups;
      data.scheduledReports.map((report: any) => {
        report.filters?.groupIds?.map((id: number) => copy.add(id));
      });
      setAllGroups(copy);
    }
  }, [data]);

  const groups = useMemo(() => {
    return groupsData?.reduce((acc: any, item: any) => {
      if (item.groupId !== undefined && item.name !== undefined) {
        acc[item.groupId] = item.name;
      }
      return acc;
    }, {});
  }, [groupsData]);

  useEffect(() => {
    if (!!data && data?.scheduledReports?.length > 0) {
      const copy = allLocations;
      data.scheduledReports.map((report: any) => {
        report.filters?.locationIds?.map((id: number) => copy.add(id));
      });
      setAllLocations(copy);
    }
  }, [data]);

  const locations = useMemo(() => {
    return locationsData?.locations.reduce((acc: any, item: any) => {
      if (item.locationId !== undefined && item.name !== undefined) {
        acc[item.locationId] = item.name;
      }
      return acc;
    }, {});
  }, [locationsData?.locations]);

  useEffect(() => {
    if (!!data && data?.scheduledReports?.length > 0) {
      const copy = allAssets;
      data.scheduledReports.map((report: any) => {
        report.filters?.assetIds?.map((id: number) => copy.add(id));
      });
      setAllAssets(copy);
    }
  }, [data]);

  const assets = useMemo(() => {
    return assetsData?.assets?.reduce((acc: any, item: any) => {
      if (item.assetId !== undefined && item.name !== undefined) {
        acc[item.assetId] = item.name;
      }
      return acc;
    }, {});
  }, [assetsData]);

  useEffect(() => {
    if (!!data && data?.scheduledReports?.length > 0) {
      const copy = allDrivers;
      data.scheduledReports.map((report: any) => {
        report.filters?.driverIds?.map((id: number) => copy.add(id));
      });
      setAllDrivers(copy);
    }
  }, [data]);

  const drivers = useMemo(() => {
    return driversData?.reduce((acc: any, item: any) => {
      if (item.driverId !== undefined && item.name !== undefined) {
        acc[item.driverId] = item.name;
      }
      return acc;
    }, {});
  }, [driversData]);

  useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  useEffect(() => {
    if (isDeleted) {
      setFlashMessage({
        message: t('scheduledReports.flashMessages.successfully_deleted'),
      });
    }
  }, [isDeleted, setFlashMessage, t]);

  const handleDeleteScheduledReport = (scheduledReportId: any) => {
    deleteReport({ scheduledReportId });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table
        data={data?.scheduledReports}
        isLoading={isLoading}
        getRowId={(row: any) => row.scheduledReportId}
        columns={getColumns({
          t,
          canRead,
          navigate,
          groups,
          locations,
          assets,
          drivers,
          setShowDialog,
          isDeleting,
        })}
        disableColumnFilter={true}
        disableColumnMenu={true}
        pageSize={pageSize}
        onPageChange={(newPage: number) => setPage(newPage + 1)}
        rowCount={rowCountState}
      />
      <ConfirmDeleteDialog
        open={!!showDialog}
        onCancel={() => {
          setShowDialog(null);
        }}
        onConfirm={() => {
          if (showDialog) handleDeleteScheduledReport(showDialog?.id);
        }}
        title={t('scheduledReports.confirm_delete')}
        content={t('scheduledReports.confirm_delete_message', {
          name: showDialog?.name,
        })}
      />
    </Box>
  );
};

export default ScheduledReportsList;
