import moment from 'moment';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { apiClient } from 'services/axios';

export function sortAlerts(alerts: any) {
  return alerts.sort((a: any, b: any) => {
    const aCleared = a.clearedAt ? moment(a.clearedAt) : null;
    const bCleared = b.clearedAt ? moment(b.clearedAt) : null;
    const aStarted = moment(a.startedAt);
    const bStarted = moment(b.startedAt);

    // Check if either 'clearedAt' is not null
    if (aCleared !== null && bCleared === null) {
      return 1; // a goes after b
    } else if (aCleared === null && bCleared !== null) {
      return -1; // a goes before b
    } else {
      // If both are null or both are not null, sort by 'startedAt' descending
      return bStarted.diff(aStarted);
    }
  });
}

export function useAlerts(): UseQueryResult {
  return useQuery(
    ['alerts'],
    () => apiClient.get(`alerts`),
    {
      refetchOnWindowFocus: 'always',
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 61,
    }
  );
}

export function useClearAlert(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'alerts',
    (id: any) => apiClient.post(`alerts/${id}/clear`),
    {
      onSuccess: (data: any) => {
        if (!!data.alertId && !!data.clearedAt && !!data.endReportId) {
          queryClient.setQueryData('alerts', (old: any) => old.filter((a: any) => a.alertId !== data.alertId));
        } else if (!!data.alertId) {
          queryClient.setQueryData('alerts', (old: any) => old.map((a: any) => {
            if (a.alertId === data.alertId) {
              return data;
            }
            return a;
          }));
        }
      },
    }
  );
}

export function useClearAllAlertsByType(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'alerts',
    ({ alertType }: { alertType: string }) =>
      apiClient.post(`alerts/type/${alertType}/clear`),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('alerts');
      },
    }
  );
}

export function useForceClose(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'alerts',
    (id: any) => apiClient.post(`alerts/${id}/force-close`),
    {
      onSuccess: (data: any) => {
        if (!!data.alertId && !!data.clearedAt && !!data.endReportId) {
          queryClient.setQueryData('alerts', (old: any) => old.filter((a: any) => a.alertId !== data.alertId));
        } else if (!!data.alertId) {
          queryClient.setQueryData('alerts', (old: any) => old.map((a: any) => {
            if (a.alertId === data.alertId) {
              return data;
            }
            return a;
          }));
        }
        queryClient.invalidateQueries({ queryKey: ['locations'] });
      },
    }
  );
}

export function useAssetAlerts({
  assetId,
  startTimestamp,
  endTimestamp,
  page,
  pageSize,
}: {
  assetId: number;
  startTimestamp?: Date;
  endTimestamp?: Date;
  page?: number;
  pageSize?: number;
}): UseQueryResult {
  return useQuery(
    ['alerts_for_assets', [assetId, startTimestamp, endTimestamp, page]],
    ({ signal }) =>
      apiClient.get(`alerts/asset/${assetId}`, {
        signal,
        params: { page, pageSize, startTimestamp, endTimestamp },
      })
  );
}
