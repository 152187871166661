import React, { useEffect } from 'react';
import { Box, CssBaseline, Grid, Link, Typography } from '@mui/material';
import SnackbarAlert from 'components/SnackbarAlert';
import ImageLogo from 'assets/original-full.svg';
import ImageExtractives from 'assets/extractives-unsplash.jpg';
import ImageFarming from 'assets/farming-unsplash.jpg';
import ImageHelmetsLocker from 'assets/helmets-locker-unsplash.jpg';
import ImageMaritimePort from 'assets/maritime-port-unsplash.jpg';
import ImageTrainSafetyVest from 'assets/train-safety-vest-unsplash.jpg';
import ImageTruckFleet from 'assets/truck-fleet-unsplash.jpg';

const images = [
  ImageExtractives,
  ImageFarming,
  ImageHelmetsLocker,
  ImageMaritimePort,
  ImageTrainSafetyVest,
  ImageTruckFleet,
];

function Copyright(props: any) {
  return (
    <Typography variant='body1' color='white' align='center' {...props}>
      {'© '}
      {new Date().getFullYear() + ' '}
      <Link color='inherit' href={process.env.REACT_APP_URL}>
        SnapGIS Technologies
      </Link>
      {'. All Rights Reserved'}
    </Typography>
  );
}

const AuthWrappedComponent = ({
  component,
  flashMessage,
  bottomComponent,
}: any) => {
  const [imageIndex] = React.useState<number>(
    Math.floor(Math.random() * images.length)
  );
  const [message, setMessage] = React.useState<any | null>({});

  useEffect(() => {
    setMessage(flashMessage);
  }, [flashMessage]);

  return (
    <>
      <SnackbarAlert
        severity={message?.severity ?? 'success'}
        message={message?.message}
        onClose={(event?: React.SyntheticEvent | Event, reason?: string) =>
          setMessage(null)
        }
      />
      <CssBaseline />
      <Grid
        container
        sx={{
          height: '100vh',
          width: '100vw',
          backgroundColor: '#3F4349',
        }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={4}
          xl={3}
          sx={{
            padding: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.25)',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}>
            <Box sx={{ height: '42px', mb: 4 }}>
              <img src={ImageLogo} height={'100%'} />
            </Box>
            {component}
          </Box>
          <Box
            sx={{
              marginTop: 'auto',
              marginBottom: 0,
              textAlign: 'center',
            }}>
            {bottomComponent}
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={7}
          lg={8}
          xl={9}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${images[imageIndex]})`,
            backgroundSize: 'cover',
            filter:
              'grayscale(60%) sepia(30%) blur(1px) brightness(85%) saturate(200%) opacity(40%) contrast(150%)',
            WebkitFilter:
              'grayscale(60%) sepia(30%) blur(1px) brightness(85%) saturate(200%) opacity(40%) contrast(150%)',
          }}></Grid>
      </Grid>
      <Copyright
        style={{
          position: 'absolute',
          bottom: 32,
          right: 64,
          fontWeight: '400',
        }}
      />
    </>
  );
};

export default AuthWrappedComponent;
