import React, { useEffect } from 'react';

function useCmdKeyPress(targetKey: any, shouldPreventDefault?: boolean) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = React.useState<boolean>(false);
  // If pressed key is our target key then set to true
  function downHandler(e: any) {
    const { metaKey, key } = e;
    if (metaKey && key === targetKey) {
      if (shouldPreventDefault) e.preventDefault();
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key, metaKey }: any) => {
    if (!metaKey || key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}

export default useCmdKeyPress;
