// eslint-disable-next-line import/named
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import 'yup-phone';

export default (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .min(1)
      .max(64)
      .required(t('form.validation.field_is.required', { field: t('name') })),
    mobileNumber: Yup.string().test(
      'mobileNumber',
      "This phone number doesn't seem to look valid",
      value => {
        if (value && value.length > 4)
          return Yup.string().phone().isValidSync(value);
        return true;
      }
    ),
  });
};
