import { useMutation, useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'services/axios';

export function useGeoIp(): UseQueryResult {
  return useQuery(['geo-ip'], () => apiClient.get(`geo-ip`));
}

export function useVerifyWhatsApp(): any {
  return useMutation('mobile-channel-verification', (variables: any) =>
    apiClient.post('/mobile-channel-verification', {
      token: variables.token,
    })
  );
}
