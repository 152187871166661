import { useGroupsFilter } from 'hooks/filters/useFilters';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Checkbox,
  Chip,
  Popper,
  Radio,
  TextField,
} from '@mui/material';
//@ts-ignore
import parse from 'autosuggest-highlight/parse';
//@ts-ignore
import match from 'autosuggest-highlight/match';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  RadioButtonChecked,
  RadioButtonUncheckedOutlined,
} from '@mui/icons-material';

const CheckboxIcon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const CheckboxCheckedIcon = <CheckBoxIcon fontSize='small' />;

const RadioIcon = <RadioButtonUncheckedOutlined fontSize='small' />;
const RadioCheckedIcon = <RadioButtonChecked fontSize='small' />;

interface Group {
  id?: number;
  lft?: number;
  rgt?: number;
  level: number;
  parentId: number | null;
  rootId: number;
  groupId: number;
  orgId?: number;
  name: string;
  createdAt?: string;
  createdBy?: string | null;
  uuid: string;
  status?: number;
}

const buildTree = (items: Group[], parentId: number | null = null): any[] => {
  return items
    ?.filter(item => item.parentId === parentId)
    .map(item => ({
      ...item,
      children: buildTree(items, item.groupId),
    }));
};

const getAllDescendants = (nodes: any[], groupId: number): Group[] => {
  const result: Group[] = [];

  const traverse = (node: any) => {
    result.push(node);
    if (node.children) {
      node.children.forEach((child: any) => traverse(child));
    }
  };

  const findNode = (nodes: any[]) => {
    for (const node of nodes) {
      if (node.groupId === groupId) {
        traverse(node);
        return;
      } else if (node.children) {
        findNode(node.children);
      }
    }
  };

  findNode(nodes);
  return result;
};

const getParent = (
  items: Group[],
  parentId: number | null
): Group | undefined => {
  return items.find(item => item.groupId === parentId);
};

const keyProperty: string = 'groupId';
const labelProperty: string = 'name';

const GroupFilter = ({
  updateFilters,
  filters,
  multiple,
  disableCloseOnSelect,
  size = 'small',
  fullWidth = true,
}: any) => {
  const [state, setState] = useState<any>(multiple ? [] : null);
  const [selectedGroups, setSelectedGroups] = useState<Set<number>>(new Set());
  const [indeterminateGroups, setIndeterminateGroups] = useState<Set<number>>(
    new Set()
  );
  const [focused, setFocused] = useState<boolean>(false);
  const { t } = useTranslation();
  const { data, isLoading } = useGroupsFilter() as any;
  const tree = useMemo(() => buildTree(data), [data]);
  const filterKey = multiple ? 'groupIds' : 'groupId';
  const label = multiple ? t('Groups') : t('Group');
  let limitTags = multiple ? 0 : 1;

  useEffect(() => {
    if (filters && filterKey && data) {
      const filterValue = filters[filterKey] ?? [];

      if (multiple) {
        const existing = data?.filter((item: any) =>
          filterValue?.includes(item[keyProperty])
        );

        setState(
          existing.map((item: any) => ({
            [keyProperty]: item[keyProperty],
            [labelProperty]: item[labelProperty],
          }))
        );
      } else {
        const existing = data.find(
          (item: any) => item[keyProperty] === filterValue
        );

        setState(
          existing
            ? {
                [keyProperty]: existing[keyProperty],
                [labelProperty]: existing[labelProperty],
              }
            : null
        );
      }
    }
  }, [filters, filterKey, multiple, data, keyProperty, labelProperty]);

  const handleChange = (value: any) => {
    if (!value || value.length === 0) {
      setSelectedGroups(new Set());
      setIndeterminateGroups(new Set());
      handleUpdateFilters(undefined);
      return;
    }

    const initialSelectedValues = new Set(selectedGroups);
    const selectedValues = new Set(selectedGroups);
    const indeterminateSet = new Set(indeterminateGroups);

    const selectGroup = (groupId: number) => {
      const descendants = getAllDescendants(tree, groupId);
      selectedValues.add(groupId);
      descendants.forEach(descendant => {
        selectedValues.add(descendant.groupId);
        indeterminateSet.delete(descendant.groupId);
      });
    };

    const deselectGroup = (groupId: number) => {
      const descendants = getAllDescendants(tree, groupId);
      selectedValues.delete(groupId);
      descendants.forEach(descendant => {
        selectedValues.delete(descendant.groupId);
        indeterminateSet.delete(descendant.groupId);
      });
    };

    const updateParentsIndeterminateState = (group: Group) => {
      let parent = getParent(data, group.parentId);
      while (parent) {
        const siblings = data.filter(
          (item: Group) => item.parentId === parent?.groupId
        );
        const allSiblingsSelected = siblings.every((sibling: Group) =>
          selectedValues.has(sibling.groupId)
        );
        const anySiblingSelected = siblings.some((sibling: Group) =>
          selectedValues.has(sibling.groupId)
        );
        if (allSiblingsSelected) {
          selectedValues.add(parent.groupId);
          indeterminateSet.delete(parent.groupId);
        } else if (anySiblingSelected) {
          indeterminateSet.add(parent.groupId);
        } else {
          selectedValues.delete(parent.groupId);
          indeterminateSet.delete(parent.groupId);
        }
        parent = getParent(data, parent.parentId);
      }
    };

    const processSelection = (val: any, deselectedGroups: Set<number>) => {
      const selectedGroup = data.find(
        (item: Group) => item.groupId === (val.groupId || val)
      );
      if (selectedGroup) {
        const isSelected = selectedValues.has(selectedGroup.groupId);
        if (isSelected && deselectedGroups.has(selectedGroup.groupId)) {
          deselectGroup(selectedGroup.groupId);
        } else if (
          !isSelected &&
          !deselectedGroups.has(selectedGroup.groupId)
        ) {
          selectGroup(selectedGroup.groupId);
        }
        // updateParentsIndeterminateState(selectedGroup);
      }
    };

    const deselectedGroups = new Set<number>(
      Array.from(initialSelectedValues).filter(
        groupId => !value.find((v: Group) => v.groupId === groupId)
      )
    );

    if (Array.isArray(value)) {
      value.forEach(val => processSelection(val, deselectedGroups));
    } else {
      processSelection(value, deselectedGroups);
    }

    deselectedGroups.forEach((groupId: number) => {
      deselectGroup(groupId);
      // updateParentsIndeterminateState(
      //   data.find((item: Group) => item.groupId === groupId)
      // );
    });

    setSelectedGroups(new Set(selectedValues));
    setIndeterminateGroups(new Set(indeterminateSet));
    handleUpdateFilters(Array.from(selectedValues));
  };

  const handleUpdateFilters = (value: any) => {
    if (!updateFilters) return;
    updateFilters({
      ...filters,
      [filterKey]: value,
    });
  };

  const renderTags = (
    value: any[],
    getTagProps: any,
    ownerState: any
  ): ReactNode => {
    if (!multiple && focused) return null;

    if (!multiple && value) {
      return <Chip label={value[labelProperty as any]} />;
    }

    const getLabelPlurality = (value: any) => {
      if (multiple && value?.length === 1) {
        if (label?.endsWith('s')) {
          return label.slice(0, -1);
        }
        return label;
      }
      return label?.endsWith('s') ? label : `${label}s`;
    };

    if (multiple && value.length === 0) {
      return <>{`${value?.length} ${getLabelPlurality(value)}`}</>;
    }

    // TODO: This is a hack to show the first chip when limitTags is 0
    // @Sven to review
    if (multiple && limitTags === 0) {
      limitTags = 1;
    }

    const chipStyle = {
      ...('small' === size && {
        height: 20,
        fontSize: 12,
        py: 1,
        '.MuiChip-deleteIcon': {
          mr: 0,
        },
      }),
    };

    return (
      <div
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          alignItems: 'center',
          height: 'small' === size ? '40px' : '56px',
        }}>
        {value?.slice(0, limitTags).map((option: any, index: number) => (
          <Chip
            key={index}
            label={option[labelProperty]}
            {...getTagProps({ index })}
            IconProps={{ style: { marginRight: 0 } }}
            sx={chipStyle}
          />
        ))}
        {limitTags && value?.length > limitTags && (
          <Chip label={`+${value.length - limitTags}`} sx={chipStyle} />
        )}
      </div>
    );
  };

  return (
    <Autocomplete
      size={size}
      multiple={multiple}
      limitTags={limitTags}
      id='checkboxes-tags-demo'
      isOptionEqualToValue={(option, value) =>
        option?.[keyProperty] === value?.[keyProperty] ||
        option?.[keyProperty] === value
      }
      onChange={(event, value) => handleChange(value)}
      options={data || []}
      value={state}
      loading={isLoading}
      disableCloseOnSelect={disableCloseOnSelect ?? !!multiple}
      getOptionLabel={option => {
        const opt = data?.filter(
          (o: any) => o[keyProperty] === option[keyProperty]
        )[0];
        return option[labelProperty] || opt?.[labelProperty] || '';
      }}
      fullWidth={fullWidth}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option[labelProperty], inputValue, {
          insideWords: true,
        });
        const parts = parse(option[labelProperty], matches);
        return (
          <li {...props} key={option[keyProperty]}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}>
              {multiple ? (
                <Checkbox
                  icon={CheckboxIcon}
                  checkedIcon={CheckboxCheckedIcon}
                  style={{ marginLeft: 24 * option.level, marginRight: 8 }}
                  checked={
                    selected ||
                    Array.from(indeterminateGroups)?.includes(option.groupId)
                  }
                  indeterminate={Array.from(indeterminateGroups)?.includes(
                    option.groupId
                  )}
                />
              ) : (
                <Radio
                  icon={RadioIcon}
                  checkedIcon={RadioCheckedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              )}
              <div>
                {parts?.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          </li>
        );
      }}
      renderTags={renderTags}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            style: {
              height: 'small' === size ? '40px' : '56px',
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      )}
      clearOnBlur={true}
      clearOnEscape={false}
      defaultValue={[]}
      PopperComponent={CustomPopper}
    />
  );
};

function CustomPopper(props: any) {
  return (
    <Popper
      {...props}
      style={{
        minWidth: props.anchorEl ? props.anchorEl.clientWidth : undefined,
        maxWidth: 'fit-content',
      }}
      placement='bottom-start'
    />
  );
}

export default GroupFilter;
