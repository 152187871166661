import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { apiClient } from 'services/axios';

export type SpeedingReportSortByType = 'totalSpeedingTimeSeconds';
export type SpeedingReportSortOrderType = 'ASC' | 'DESC';

export function useSpeedingReport({
  page,
  filterBy,
  sortBy,
  sortOrder,
}: {
  page: number;
  filterBy?: any;
  sortBy?: SpeedingReportSortByType;
  sortOrder?: SpeedingReportSortOrderType;
}): UseQueryResult {
  return useQuery(
    ['report_speeding', page, filterBy, sortBy, sortOrder],
    ({ signal }) =>
      apiClient.get('reports/assets/speeding', {
        signal,
        params: {
          page,
          sortBy,
          sortOrder,
          ...filterBy,
        },
      })
  );
}

export function useDriverSpeedingReport({
  page,
  filterBy,
  sortBy,
  sortOrder,
}: {
  page: number;
  filterBy?: any;
  sortBy?: SpeedingReportSortByType;
  sortOrder?: SpeedingReportSortOrderType;
}): UseQueryResult {
  return useQuery(
    ['report_driver_speeding', page, filterBy, sortBy, sortOrder],
    ({ signal }) =>
      apiClient.get('reports/drivers/speeding', {
        signal,
        params: {
          page,
          sortBy,
          sortOrder,
          ...filterBy,
        },
      })
  );
}


export function useExportSpeedingReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_speeding_export',
    (variables: any) =>
      apiClient.post(`reports/assets/speeding/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        groupIds: variables.groupIds,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_speeding_export');
      },
    }
  );
}

export function useExportDriverSpeedingReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_speeding_export',
    (variables: any) =>
      apiClient.post(`reports/drivers/speeding/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        groupIds: variables.groupIds,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_speeding_export');
      },
    }
  );
}

export function useLocalSpeedingReport({
  page,
  filterBy,
  sortBy,
  sortOrder,
}: {
  page: number;
  filterBy?: any;
  sortBy?: SpeedingReportSortByType;
  sortOrder?: SpeedingReportSortOrderType;
}): UseQueryResult {
  console.log(page, )
  return useQuery(
    ['report_speeding', page, filterBy, sortBy, sortOrder],
    ({ signal }) =>
      apiClient.get('reports/assets/local-speeding', {
        signal,
        params: {
          page,
          sortBy,
          sortOrder,
          ...filterBy,
        },
      })
  );
}

export function useExportLocalSpeedingReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_local_speeding_export',
    (variables: any) =>
      apiClient.post(`reports/assets/local-speeding/export`, {
        ...variables,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_local_speeding_export');
      },
    }
  );
}