import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
    ],
    layout: {
      'icon-image': [
        'concat',
        'ais_region_vessel_',
        ['get', 'color']
      ],
      "icon-rotate" : {
        "type" : "identity",
        "property": "heading",
        "default" : 90
      },
      'icon-anchor': 'center',
      'icon-offset': [0, 0],
      // 'icon-allow-overlap': ['step', ['zoom'], false, 4, true],
      'icon-allow-overlap': true,
      'icon-size': [
        'case', // Use a case expression to set opacity based on color attribute
        ['==', ['get', 'color'], null], // Check if color property exists
        0.2,
        0.24,
      ],
      'symbol-sort-key': [
        'case',
        ['==', ['get', 'color'], null], // Check if color property exists
        0,
        1,
      ]
    },
    paint: {
      'icon-opacity': [
        'case', // Use a case expression to set opacity based on color attribute
        ['==', ['get', 'color'], null], // Check if color property exists
        0.6, // If color doesn't exist, set opacity to 0.6
        1, // If color exists, set opacity to 1
      ],
    }
  };
};
