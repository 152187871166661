import React from 'react';
import CardComponent from 'components/Card';
import { useNavigate } from 'react-router-dom';

const ReportCard = ({ Icon, title, description, url, className }: any) => {
  const navigate = useNavigate();

  return (
    <CardComponent
      className={className}
      Icon={Icon}
      title={title}
      description={description}
      button={'View Report'}
      onClick={() => {
        navigate(url, { state: { title, description } });
      }}
    />
  );
};

export default ReportCard;
