import styled from '@emotion/styled';
import React from 'react';

const LoaderEl = styled('div')`
  z-index: 99999;
  width: 60px;

  img {
    height: 100%;
    width: 100%;
  }
`;

function InlineLoader() {
  return (
    <LoaderEl>
      <img src={'/logo.gif'} alt='Loading' />
    </LoaderEl>
  );
}

export default InlineLoader;
