import { Box, Link, ListItem, Tooltip } from '@mui/material';
import useActionMenuContext from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export function formatLabel(activityLog: any, onClick: Function, t: any) {
  const labels = { ...activityLog.labels };

  let label = `activity_log.actions.${activityLog.type}`;
  if (activityLog.type == 'alert_start' || activityLog.type == 'alert_end') {
    label += `.${labels?.alertTypeName}`;
  }

  if (['tm_message', 'ignition_lockout'].includes(activityLog.type)) {
    labels.commandType = t(labels.commandType).toLowerCase();
    labels.lockoutState = t(
      `activity_log.label.${activityLog.type}.${
        activityLog.labels.lockoutState === true ? 'true' : 'false'
      }`
    );
  }

  const getSuffixObject = (labels: any, activityLog: any) => {
    if (labels.locationName) {
      return (
        <Link
          onClick={() => onClick({ clicked: 'location', ...activityLog })}
          href='#'
          underline='none'
          color='#6AEA8A'>
          {labels.locationName}
        </Link>
      );
    }

    if (activityLog.type == 'tm_message' && labels.assetName) {
      return (
        <Link
          onClick={() => onClick({ clicked: 'asset', ...activityLog })}
          href='#'
          underline='none'
          color='#a7d3e9'>
          {labels.assetName}
        </Link>
      );
    }
  };

  return (
    <Box sx={{}}>
      <Tooltip title={activityLog?.labels?.userEmail}>
        <Link
          onClick={() =>
            onClick({
              clicked: getActorType(activityLog),
              ...activityLog,
            })
          }
          href='#'
          underline='none'
          color={getActorTypeColor(activityLog, labels?.alertTypeName)}>
          {getActorLabel(activityLog)}
        </Link>
      </Tooltip>
      <span>
        {' '}
        <Trans
          i18nKey={label} // optional -> fallbacks to defaults if not provided
          values={{ ...labels }}
        />{' '}
      </span>
      {getSuffixObject(labels, activityLog)}
    </Box>
  );
}

function getActorType(activityLog: any) {
  switch (activityLog.type) {
    case 'message_sent':
    case 'tm_message':
      return 'user';
    default:
      return 'asset';
  }
}
function getActorLabel(activityLog: any) {
  switch (activityLog.type) {
    case 'message_sent':
    case 'tm_message':
      return activityLog?.labels?.userName;
    default:
      return activityLog?.labels?.assetName;
  }
}
function getActorTypeColor(activityLog: any, alertTypeName: any = null) {
  if (activityLog.type == 'alert_start' || activityLog.type == 'alert_end') {
    switch (alertTypeName) {
      case 'panic':
      case 'accident':
        return '#a7d3e9';
      case 'location':
        return '#a7d3e9';
    }
  }
  if (
    activityLog.type == 'location_enter' ||
    activityLog.type == 'location_exit'
  ) {
    return '#a7d3e9';
  }
  const actorType = getActorType(activityLog);
  switch (actorType) {
    case 'user':
      return '#CF9FFF';
    default:
      return '#a7d3e9';
  }
}
function getIconColor(activityLogType: any, alertTypeName: any = null) {
  switch (activityLogType) {
    case 'message_sent':
      return '#CF9FFF';
    case 'alert_start':
    case 'alert_end':
      if (alertTypeName) {
        switch (alertTypeName) {
          case 'accident':
          case 'panic':
            return '#FD6262';
          case 'location':
            return '#FFBF00';
        }
      }
      return '#FFBF00';
    case 'location_enter':
    case 'location_exit':
      return '#6AEA8A';
    case 'tm_message':
    case 'ignition_lockout':
      return '#FFBF00';
    default:
      return '#FFBF00';
  }
}

const ActivityFeedItem = ({ activityLog, fullDate, className }: any) => {
  const { t } = useTranslation();
  const { setMainMapField } = useActionMenuContext();
  const { isMobile, closeRightSidebar } = useUIContext();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateIfNotMap = () => {
    if ('/' !== location.pathname) {
      navigate('/');
    }
  };

  const onClick = (e: any) => {
    if (e?.clicked === 'asset') {
      setMainMapField('activeAssetId', e?.assetId);
      navigateIfNotMap();
      if (isMobile) closeRightSidebar();
      return;
    }
    if (e?.clicked === 'location') {
      setMainMapField('activeLocationId', e?.locationId);
      navigateIfNotMap();
      if (isMobile) closeRightSidebar();
      return;
    }
  };

  return (
    <>
      <ListItem sx={{ m: 0, p: 0, my: 1 }} className={className}>
        <Box
          sx={{
            fontWeight: 'regular',
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pl: 1,
            borderLeft: '3px solid',
            borderLeftColor: getIconColor(
              activityLog.type,
              activityLog.labels?.alertTypeName
            ),
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              whiteSpace: 'break-spaces',
              wordBreak: 'break-word',
            }}>
            {formatLabel(activityLog, onClick, t)}
            <Box
              sx={{ fontSize: 12, color: '#ccc' }}
              title={moment(activityLog.updatedAt).format(
                'YYYY-MM-DD HH:mm:ss'
              )}>
              {moment(activityLog.updatedAt).format(
                fullDate ? 'YYYY-MM-D HH:mm:ss' : 'HH:mm:ss'
              )}
            </Box>
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

export default ActivityFeedItem;
