import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import useActionMenuContext from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import {
  SpeedingReportSortByType,
  SpeedingReportSortOrderType,
  useDriverSpeedingReport,
  useExportDriverSpeedingReport,
} from 'hooks/reports/useSpeedingReport';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import ExportReportButton from '../ExportReportButton';

const commonColDefOptions = {
  // resizable: false,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
  disableColumnMenu: true,
};

const formatHours = function (hours: number) {
  if (hours < 1) return `${(hours * 60).toFixed(0)} minutes`;
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h} hours ${m} minutes`;
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'driverName',
      headerName: 'Driver',
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'speedingTimeSeconds',
      headerName: 'Overspeeding Duration',
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        return formatHours(r?.row.speedingTimeSeconds / 3600);
      },
    }, // @ts-ignore
    {
      field: 'localSpeedingTimeSeconds',
      headerName: 'Local Overspeeding Duration',
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        return formatHours(r?.row.localSpeedingTimeSeconds / 3600);
      },
    }, // @ts-ignore
    {
      field: 'totalSpeedingTimeSeconds',
      headerName: 'Total Overspeeding Duration',
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        return formatHours(r?.row.totalSpeedingTimeSeconds / 3600);
      },
    }, // @ts-ignore
  ];
}

export const ExportDriverSpeedingReport = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const [closePopover, setClosePopover] = React.useState<boolean>(false);

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportDriverSpeedingReport();

  const handleExport = (fileType: any) => {
    setClosePopover(true);
    exportReport({ ...filters, fileType });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <ExportReportButton
        isExporting={isExporting}
        handleExport={handleExport}
      />
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

const DriverSpeedingReport = ({}: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams]: any = useComplexSearchParams();
  const [sortBy, setSortBy] = useState<SpeedingReportSortByType>(
    'totalSpeedingTimeSeconds'
  );
  const [sortOrder, setSortOrder] =
    useState<SpeedingReportSortOrderType>('DESC');
  const { data, isLoading } = useDriverSpeedingReport({
    page: page + 1,
    filterBy: searchParams,
    sortBy,
    sortOrder,
  }) as any;
  const { reset } = useActionMenuContext();
  const { canWrite, canRead } = usePermissions();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  return (
    <DataGrid
      rows={data && data.reports ? data.reports : []}
      getRowId={row => row.driverId}
      columns={getColumns({ t })}
      pageSize={10}
      checkboxSelection={false}
      autoHeight
      loading={isLoading}
      rowHeight={40}
      rowsPerPageOptions={[10]}
      rowCount={rowCountState}
      onPageChange={newPage => setPage(newPage)}
      paginationMode='server'
      pagination
      sortingOrder={['desc', 'asc']}
      sortingMode='server'
      onSortModelChange={({ 0: sorting }) => {
        if (!sorting) {
          setSortBy('totalSpeedingTimeSeconds');
          setSortOrder('DESC');
          return;
        }
        const { field, sort } = sorting;
        if (field) setSortBy(field as SpeedingReportSortByType);
        if (sort)
          setSortOrder(sort.toUpperCase() as SpeedingReportSortOrderType);
      }}
    />
  );
};

export default DriverSpeedingReport;
