import React from 'react';
import MapStyles from '../constants/styles';
import useDebounce from 'hooks/useDebounce';

const StyleSelectorControl = ({
  outerActiveStyle,
  onClick,
  direction,
  onMouseOut,
  isMobile,
}: {
  outerActiveStyle: string;
  onClick?: Function;
  direction?: 'ltr' | 'rtl';
  onMouseOut?: Function;
  isMobile?: boolean;
}) => {
  const [activeStyle, setActiveStyle] = React.useState<string | null>(null);
  const [selectorOff, setSelectorOff] = React.useState<boolean>(false);

  useDebounce(
    () => {
      if (onMouseOut && !!selectorOff) onMouseOut();
    },
    [selectorOff],
    1000
  );

  React.useEffect(() => {
    setActiveStyle(outerActiveStyle);
  }, [outerActiveStyle]);

  const setClassIfStyleIsActive = (style: string) => {
    return activeStyle === style ? 'control-style-selector-item-active' : '';
  };

  return (
    <div
      id='control-style-selector-menu'
      onMouseOver={() => setSelectorOff(false)}
      onMouseOut={() => setSelectorOff(true)}
      style={{
        ...('rtl' === direction ? { left: 41 } : { right: 41 }),
        ...(isMobile && {
          bottom: 20,
        }),
      }}>
      <div>
        <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.maritime
          )}`}
          onClick={() => (onClick ? onClick(MapStyles.maritime) : null)}>
          Maritime
        </button>
        <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.streets
          )}`}
          onClick={() => (onClick ? onClick(MapStyles.streets) : null)}>
          Streets
        </button>
        {/* <button value='mapbox://styles/mapbox/outdoors-v11'>outdoors</button> */}
        <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.light
          )}`}
          onClick={() => (onClick ? onClick(MapStyles.light) : null)}>
          Light
        </button>
        <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.dark
          )}`}
          onClick={() => (onClick ? onClick(MapStyles.dark) : null)}>
          Dark
        </button>
        <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.satellite
          )}`}
          onClick={() => (onClick ? onClick(MapStyles.satellite) : null)}>
          Satellite
        </button>
        <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.googleSatellite
          )}`}
          onClick={() => (onClick ? onClick(MapStyles.googleSatellite) : null)}>
          Google
        </button>
        {/* <button
          className={`control-style-selector-item ${setClassIfStyleIsActive(
            MapStyles.satelliteHybrid
          )}`}
          value={MapStyles.satelliteHybrid}>
          Satellite Streets
        </button> */}
        {/* <button value='mapbox://styles/mapbox/satellite-streets-v11'>satellite-streets</button> */}
        {/* <button value='mapbox://styles/mapbox/navigation-day-v1'>navigation-day</button> */}
        {/* <button value='mapbox://styles/mapbox/navigation-night-v1'>navigation-night</button> */}
      </div>
    </div>
  );
};

export default StyleSelectorControl;
