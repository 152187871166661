import { Box, Grid, Skeleton } from '@mui/material';
import { useActivityLog } from 'hooks';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';
import { ActionMenuProps } from '../../module.d';
import ActivityFeedChatBox from './ActivityFeedChatBox';
import ActivityFeedDateDivider from './ActivityFeedDateDivider';
import ActivityFeedItem from './ActivityFeedItem';
import './activityFeed.scss';

const ITEMS_PER_GROUP = 100;
const FIRST_ITEM_INDEX = 99_999;

const ActivityFeedActionMenu = ({ extra }: ActionMenuProps) => {
  const { filters } = extra;
  const { data, fetchNextPage, isFetchingNextPage, status } = useActivityLog(
    filters
  ) as any;
  const [firstItemIndex, setFirstItemIndex] = React.useState(FIRST_ITEM_INDEX);
  const [itemsPerGroup, setItemsPerGroup] = React.useState(ITEMS_PER_GROUP);
  const [latestPageLength, setLatestPageLength] =
    React.useState<number>(ITEMS_PER_GROUP);

  const [groupCounts, setGroupCounts] = React.useState<any>([]);
  const [groupTitles, setGroupTitles] = React.useState<any>([]);
  const [allRows, setAllRows] = React.useState<any>([]);

  const loadMore = React.useCallback(() => {
    if (isFetchingNextPage) return;
    if (latestPageLength < ITEMS_PER_GROUP) return;
    fetchNextPage();
  }, [isFetchingNextPage, latestPageLength, fetchNextPage]);

  useEffect(() => {
    if (!isFetchingNextPage && data && data.pages.length > 0) {
      const flatRows = data.pages.map((group: any) => group.activity).flat();
      const lastPage = data.pages[data.pages.length - 1];
      const lastPageLength = lastPage.activity.length;
      const firstPage = data.pages[0];
      const firstPageLength = firstPage.activity.length;
      setLatestPageLength(firstPageLength);
      setAllRows(flatRows);
      const sortedRows = sortData(flatRows);
      setGroupTitles(Object.keys(sortedRows));
      setGroupCounts(
        Object.keys(sortedRows).map((k: any) => sortedRows[k].length)
      );
      const newFirstItemIndex =
        data.pages.length > 1
          ? FIRST_ITEM_INDEX - (flatRows.length - lastPageLength)
          : FIRST_ITEM_INDEX;
      setFirstItemIndex(newFirstItemIndex);
      setItemsPerGroup(lastPageLength);
    }
  }, [data, isFetchingNextPage]);

  function sortData(data: any) {
    return data.reduce(function (r: any, a: any) {
      const today = moment(a.updatedAt).format('YYYY-MM-DD');
      r[today] = r[today] || [];
      r[today].push(a);
      return r;
    }, Object.create(null));
  }

  return (
    <Box
      sx={{
        height: 'calc( 100% - 56px - 1rem )',
        minHeight: 0,
        overflow: 'hidden',
      }}>
      {status === 'loading' && (
        <>
          {[...Array(15)].map((x, i) => (
            <Grid key={i} container spacing={2} sx={{ py: 0.5 }}>
              <Grid item xs={12}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem' }} />
                <Skeleton
                  variant='text'
                  sx={{ fontSize: '.5rem', width: '50%' }}
                />
              </Grid>
            </Grid>
          ))}
        </>
      )}
      {status !== 'loading' &&
        allRows.length == 0 &&
        Object.keys(filters).every(key => filters[key].length > 0) && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Box sx={{ fontSize: '1rem' }}>No result matches your filters</Box>
          </Box>
        )}
      {status !== 'loading' && allRows.length > 0 && (
        <Box
          sx={{
            position: 'relative',
            maxHeight: '100%',
            minHeight: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <GroupedVirtuoso
            className='custom-scrollbar'
            useWindowScroll={false}
            firstItemIndex={firstItemIndex}
            startReached={loadMore}
            initialTopMostItemIndex={allRows.length || 100}
            context={{ groupTitles }}
            groupCounts={groupCounts}
            increaseViewportBy={{ top: 20, bottom: 20 }}
            followOutput='smooth'
            style={{ marginBottom: '1.5rem' }}
            itemContent={index => {
              const elementIndex =
                index - FIRST_ITEM_INDEX + (allRows.length - itemsPerGroup);
              const item = allRows[elementIndex];
              if (!item) return <>&nbsp;</>;
              return (
                <ActivityFeedItem
                  key={`${item.updatedAt}_${item.activityLogId}`}
                  activityLog={item}
                />
              );
            }}
            groupContent={(index, { groupTitles }) => (
              <ActivityFeedDateDivider date={groupTitles[index]} />
            )}
          />
          <ActivityFeedChatBox></ActivityFeedChatBox>
        </Box>
      )}
    </Box>
  );
};

export default ActivityFeedActionMenu;
