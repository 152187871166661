import { GroupFilter } from 'components/Filters';
import Search from 'components/Search';
import TableToolbar from 'components/TableToolbar';
import TableToolbarActionButton from 'components/TableToolbar/TableToolbarActionButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import usePermissions from 'context/PermissionContext';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UsersToolbar = (props: any) => {
  const { t } = useTranslation();
  const { canWrite } = usePermissions();
  const { setSearchTerm, handleAddUser } = props;

  return (
    <TableToolbar grid={[4, 4, 4]}>
      <Search
        translationKey='user_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      <GroupFilter {...props} sx={{ ml: 1 }} multiple={true} />
      {canWrite('USER') && (
        <TableToolbarActionButton
          minWidth={140}
          onClick={handleAddUser}
          translationKey='add_user'
        />
      )}
    </TableToolbar>
  );
};

const UsersToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <UsersToolbar {...props} />
    </WithUrlFilters>
  );
};

export default UsersToolbarWithUrlFilters;
