import React from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import Loader from './Loader';

function GlobalLoader() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  return isFetching || isMutating ? <Loader /> : null;
}

export default GlobalLoader;
