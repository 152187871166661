import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'circle',
    source,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
    ],
    paint: {
      'circle-blur': 0.05,
      'circle-color': [
        'case',
        ['==', ['feature-state', 'color'], null],
        'white',
        'green',
      ],
      'circle-radius': 20,
      'circle-opacity': 0.4
    },
  };
};
