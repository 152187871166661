import useUIContext from 'context/UIContext';
import { useRequestIgnitionLockout } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


const useIgnitionLockout = () => {
  const { setFlashMessage } = useUIContext();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const {
    data: response,
    mutate: requestIgnitionLockout,
    error: requestError,
    reset: resetRequest,
    isLoading,
    isSuccess,
  } = useRequestIgnitionLockout();

  const handleClick = (assetId: number, lockoutState: boolean) => {
    setDisabled(true);
    requestIgnitionLockout({ assetId: assetId, lockoutState: lockoutState});
  };

  useEffect(() => {
    if (isSuccess) {
      setDisabled(false);
      setFlashMessage({
        message: t(`asset.request_ignition_lockout.${response.message}`),
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (requestError) {
      setDisabled(false);
      setFlashMessage({
        message: t(
          `asset.request_ignition_lockout.${requestError.response.data.message}`
        ),
        severity: 'error',
      });
    }
  }, [requestError]);

  return {
    handleClick,
    disabled,
    isLoading
  };
};

export default useIgnitionLockout;
