import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CaptchaField from 'components/Forms/Fields/CaptchaField';
import { resetPassword } from 'hooks';
import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrappedComponent from '../wrapper';

const ResetPassword = () => {
  const [isSending, setIsSending] = React.useState(false);
  const [flashMessage, setFlashMessage] = React.useState<{} | null>(null);
  const [captchaValue, setCaptchaValue] = React.useState<string | null>(null);
  const recaptchaRef: any = React.createRef();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSending(true);
    const data = new FormData(event.currentTarget);
    try {
      const resp = await resetPassword({
        email: data.get('email') as string,
        captcha: captchaValue,
      });
      setFlashMessage({
        message: t(`success.${resp.message}`),
      });
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err: any) {
      setFlashMessage({
        message: t(`errors.${err?.response?.data?.message}`) || err?.message,
        severity: 'error',
      });
      recaptchaRef.current?.reset();
    }
  };

  const component = (
    <Box
      component='form'
      onSubmit={handleSubmit}
      noValidate
      sx={{ textAlign: 'center', width: '100%' }}>
      <TextField
        margin='normal'
        required
        fullWidth
        id='email'
        label='Email Address'
        name='email'
        autoComplete='email'
        autoFocus
      />
      <div
        style={{
          textAlign: 'center',
          marginTop: 10,
          marginBottom: 15,
        }}>
        <CaptchaField setFieldValue={setCaptchaValue} ref={recaptchaRef} />
      </div>
      <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item sm>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disabled={isSending}>
            Reset my password
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const bottomComponent = (
    <div style={{ marginTop: 64 }}>
      <Typography
        variant='body2'
        style={{
          color: 'white',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}>
        Already with SnapGIS?
      </Typography>
      <Link
        href='/'
        variant='body1'
        style={{
          color: '#E96C67',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}>
        Login to your Account
      </Link>
    </div>
  );

  return AuthWrappedComponent({
    component,
    flashMessage,
    bottomComponent,
  });
};

export default ResetPassword;
