import { useQuery, UseQueryResult } from 'react-query';
import * as OpenAPIParser from '@scalar/openapi-parser';
import axios from 'axios';

export function useApiDocs(): UseQueryResult {
  return useQuery(['api-docs'], async () => {
    const res = await axios.get(getUrl());
    return await OpenAPIParser.validate(res.data);
  });
}

const getUrl = () => {
  if (window.location.hostname === 'spark.snapgis.com') {
    return 'https://api.snapgis.com/api/v1/openapi';
  }
  return 'https://api-dev.snapgis.com/api/v1/openapi';
};
