import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';

const types = ['queued', 'sending', 'sent', 'failed', 'cancelled', 'expired'];

const ToMobileMessageStatusFilter = ({ updateFilters, filters }: any) => {
  const { t } = useTranslation();
  const [allTypes, setAllTypes] = React.useState<any>(null);

  useEffect(() => {
    setAllTypes(
      Object.values(types).map((key: any) => {
        return {
          /* @ts-ignore */
          name: t(`tm_message.status.${key}`),
          id: key,
        };
      })
    );
  }, []);

  return (
    <Filter
      data={allTypes}
      isLoading={!allTypes}
      multiple={true}
      filterName='toMobileMessageStatus'
      label='Status'
      keyProperty='id'
      labelProperty='name'
      filterKey={'messageStatuses'}
      filters={filters}
      updateFilters={updateFilters}
      disableCloseOnSelect={true}
      limitTags={0}
    />
  );
};

export default ToMobileMessageStatusFilter;
