import { Lock, LockOpen, MoreVert } from '@mui/icons-material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import useIgnitionLockout from 'hooks/assets/useIgnitionLockout';
import useReboot from 'hooks/assets/useReboot';
import useRequestLocation from 'hooks/assets/useRequestLocation';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: '#242628',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ButtonMenu({ assetDetails }: { assetDetails: any }) {
  const { t } = useTranslation();
  const {
    handleClick: requestLocation,
    disabled,
    isLoading,
  } = useRequestLocation();
  const {
    handleClick: requestIgnitionLockout,
    disabled: requestIgnitionLockoutDisabled,
    isLoading: requestIgnitionLockoutLoading,
  } = useIgnitionLockout();
  const { handleClick: requestReboot } = useReboot();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [rebootDevice, setRebootDevice] = React.useState<any>();
  const [lockoutDevice, setLockoutDevice] = React.useState<any>();

  return (
    <>
      <Button onClick={handleClick}>
        <MoreVert fontSize='small' />
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            requestLocation(assetDetails?.assetId);
            handleClose();
          }}
          disableRipple>
          <ListItemIcon>
            {isLoading ? (
              <CircularProgress size={20} sx={{ mr: 0.65 }} />
            ) : (
              <Tooltip arrow={true} title={`Request location update`}>
                <>
                  <TrackChangesIcon />
                </>
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: '.85rem' }}>
            Request location update
          </ListItemText>
        </MenuItem>
        {assetDetails?.hasIgnitionLockout &&
          (() => {
            const isRequestPending = isPending({
              lastRequestedState: assetDetails?.ignitionLockoutRequestedState,
              lastRequestedAt: assetDetails?.ignitionLockoutRequestedAt,
              currentState: assetDetails?.stateIgnitionLockedOut,
            });
            return (
              <MenuItem
                onClick={() =>
                  assetDetails?.stateIgnitionLockedOut
                    ? requestIgnitionLockout(assetDetails?.assetId, false)
                    : setLockoutDevice(assetDetails)
                }
                disabled={isRequestPending}
                disableRipple>
                <ListItemIcon>
                  {isRequestPending ? (
                    <CircularProgress size={15} sx={{ mr: 2 }} />
                  ) : (
                    <>
                      {assetDetails?.stateIgnitionLockedOut ? (
                        <LockOpen sx={{ color: '#ffbf00 !important' }} />
                      ) : (
                        <Lock sx={{ color: '#ffbf00 !important' }} />
                      )}
                    </>
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '.85rem' }}
                  sx={{ color: '#ffbf00' }}>
                  {isRequestPending
                    ? assetDetails?.stateIgnitionLockedOut
                      ? `Disengaging lockout`
                      : `Engaging lockout`
                    : assetDetails?.stateIgnitionLockedOut
                    ? 'Disengage lockout'
                    : 'Engage lockout'}
                </ListItemText>
              </MenuItem>
            );
          })()}
        <MenuItem
          onClick={() => {
            setRebootDevice({
              assetId: assetDetails?.assetId,
              assetName: assetDetails?.name,
            });
            handleClose();
          }}
          disabled={disabled}>
          <ListItemIcon>
            {isLoading ? (
              <CircularProgress size={20} sx={{ ml: 0 }} />
            ) : (
              <RestartAltIcon
                fontSize='small'
                sx={{ color: '#FD6262 !important' }}
              />
            )}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: '.85rem', color: '#FD6262' }}>
            Reboot Device
          </ListItemText>
        </MenuItem>
      </StyledMenu>
      <ConfirmDeleteDialog
        deleteText={'engage'}
        confirmButtonColor='#ffbf00'
        icon={<Lock sx={{ color: '#ffbf00 !important', mr: 1 }} />}
        open={!!lockoutDevice}
        onCancel={() => {
          setLockoutDevice(null);
        }}
        onConfirm={() => {
          if (!!lockoutDevice) {
            requestIgnitionLockout(lockoutDevice?.assetId, true);
            handleClose();
          }
        }}
        title={t('Confirm to engage lockout')}
        content={t(
          'Are you sure you want to engage remote lockout for {{name}}?',
          { name: lockoutDevice?.name }
        )}
      />
      <ConfirmDeleteDialog
        deleteText={'reboot'}
        icon={<RestartAltIcon sx={{ mr: 1 }} color='error' />}
        open={!!rebootDevice}
        onCancel={() => {
          setRebootDevice(null);
        }}
        onConfirm={() => {
          if (rebootDevice) requestReboot(rebootDevice?.assetId);
        }}
        title={'Confirm to reboot device'}
        content={
          <>
            Are you sure you want to reboot <b>{rebootDevice?.assetName}</b>?
          </>
        }
      />
    </>
  );
}

const isPending = (args: any) => {
  const { lastRequestedAt, lastRequestedState, currentState } = args;
  const requestedAt = moment(lastRequestedAt);
  const fiveMinsAgo = moment().subtract(5, 'minutes');
  if (
    lastRequestedAt !== null &&
    lastRequestedAt !== undefined &&
    lastRequestedState !== null &&
    lastRequestedState !== undefined &&
    requestedAt.isAfter(fiveMinsAgo) &&
    currentState !== lastRequestedState
  ) {
    return true;
  }
  return false;
};
