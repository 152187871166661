import {
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { apiClient } from 'services/axios';
import ActivityLog from 'types/ActivityLog';

export function useActivityLog(filters: any): UseQueryResult {
  return useInfiniteQuery({
    queryKey: ['activity', filters],
    queryFn: ({ pageParam = 0, signal }) =>
      apiClient.get(`activity`, {
        signal,
        params: { page: pageParam + 1, ...formatFilters(filters) },
      }),
    getNextPageParam: (lastPage: any, pages: any) => {
      return lastPage?.activity?.length > 0 ? lastPage.page : undefined;
    },
    select: data => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse(),
    }),
    refetchOnWindowFocus: 'always',
  });
}

export function useAddActivityLog(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'activity',
    (variables: ActivityLog) =>
      apiClient.post('activity', {
        type: variables.type,
        message: variables.message,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('activity');
      },
    }
  );
}

function formatFilters(filters: any) {
  return {
    groupIds: filters.groupIds,
    types: filters.activityLogTypes,
  };
}
