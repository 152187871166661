import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const rotate = keyframes`
    100% {
        transform: rotate(360deg)
    }
`;

const LoaderEl = styled('div')`
  position: fixed;
  top: 15px;
  right: 15px;
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: ${rotate} 1s infinite linear;
  z-index: 99999;
`;

function Loader() {
  return <LoaderEl />;
}

export default Loader;
