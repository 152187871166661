import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import SchemaDetails from './SchemaDetails';
import ParameterDetails from './ParameterDetails';

interface EndpointDetailsProps {
  spec: any;
  path: string;
  method: string;
}

const EndpointDetails: React.FC<EndpointDetailsProps> = ({
  spec,
  path,
  method,
}) => {
  if (!spec) {
    return <Typography>Loading...</Typography>;
  }

  if (!method || !path) return <></>;

  const operation = spec?.paths?.[path]?.[method.toLowerCase()];

  if (!operation) {
    return <Typography>Operation not found.</Typography>;
  }

  return (
    <Box>
      <Typography variant='h6'>
        {method.toUpperCase()} {path}
      </Typography>
      {operation.summary && (
        <Typography variant='subtitle1'>{operation.summary}</Typography>
      )}
      <Divider sx={{ my: 2 }} />

      {/* Parameters */}
      {operation.parameters && operation.parameters.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant='h6'>Parameters</Typography>
          {operation.parameters.map((param: any) => (
            <ParameterDetails key={param.name} param={param} />
          ))}
        </Box>
      )}

      {/* Request Body */}
      {operation.requestBody && (
        <Box sx={{ mb: 4 }}>
          <Typography variant='h6'>Request Body</Typography>
          <SchemaDetails schema={operation.requestBody.content} />
        </Box>
      )}

      {/* Responses */}
      {operation.responses && (
        <Box>
          <Typography variant='h6'>Responses</Typography>
          {Object.entries(operation.responses).map(
            ([statusCode, response]: [string, any]) => (
              <Box key={statusCode} sx={{ mt: 2 }}>
                <Typography variant='subtitle1'>Status {statusCode}</Typography>
                <Typography variant='body2'>{response.description}</Typography>
                {response.content && (
                  <SchemaDetails schema={response.content} />
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default EndpointDetails;
