import useUIContext from 'context/UIContext';
import { useSetVirtualOdometer } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


const useVirtualOdometer = () => {
  const { setFlashMessage } = useUIContext();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const {
    data: response,
    mutate: setVirtualOdometer,
    error: requestError,
    reset: resetRequest,
    isLoading,
    isSuccess,
  } = useSetVirtualOdometer();

  const handleClick = (assetId: number, odometerReading: number) => {
    setDisabled(true);
    setVirtualOdometer({ assetId: assetId, odometerReading: odometerReading});
  };

  useEffect(() => {
    if (isSuccess) {
      setDisabled(false);
      setFlashMessage({
        message: t(`asset.set_virtual_odometer.${response.message}`),
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (requestError) {
      setDisabled(false);
      setFlashMessage({
        message: t(
          `asset.set_virtual_odometer.${requestError.response.data.message}`
        ),
        severity: 'error',
      });
    }
  }, [requestError]);

  return {
    handleClick,
    disabled,
    isLoading
  };
};

export default useVirtualOdometer;
