import { GroupFilter } from 'components/Filters';
import Search from 'components/Search';
import TableToolbar from 'components/TableToolbar';
import TableToolbarActionButton from 'components/TableToolbar/TableToolbarActionButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import usePermissions from 'context/PermissionContext';
import React from 'react';

const DriversToolbar = (props: any) => {
  const { setSearchTerm, handleAddDriver } = props;
  const { canWrite } = usePermissions();

  return (
    <TableToolbar grid={[3, 3, 6]}>
      <Search
        translationKey='driver_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      <GroupFilter {...props} multiple />
      {canWrite('DRIVER') && (
        <TableToolbarActionButton
          minWidth={150}
          onClick={handleAddDriver}
          translationKey='add_driver'
        />
      )}
    </TableToolbar>
  );
};

const DriversToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <DriversToolbar {...props} />
    </WithUrlFilters>
  );
};

export default DriversToolbarWithUrlFilters;
