import Co2Icon from '@mui/icons-material/Co2';
import DownloadIcon from '@mui/icons-material/Download';
import GpsFixed from '@mui/icons-material/GpsFixed';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MovingIcon from '@mui/icons-material/Moving';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  // eslint-disable-next-line import/named
  GridCellParams,
  // eslint-disable-next-line import/named
  GridColDef,
  // eslint-disable-next-line import/named
  GridEventListener,
  // eslint-disable-next-line import/named
  MuiEvent,
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import DoorClosedIcon from 'components/CustomIcons/DoorClosed';
import DoorOpenIcon from 'components/CustomIcons/DoorOpen';
import useActionMenuContext from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useAuth } from 'hooks';
import {
  AssetStatusSortByType,
  AssetStatusSortOrderType,
  useAssetStatusExportReport,
  useAssetsStatus,
} from 'hooks/assets/useAssets';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AssetStatusToolbarWithUrlFilters from './AssetStatusFilters';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

function getColumns({ assetType, t, isMobile }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'name',
      headerName: 'Name',
      ...commonColDefOptions,
      sortable: true,
      renderCell: (r: any) => {
        if (['vehicle'].includes(assetType)) return r.row.name;
        return (
          <Tooltip
            arrow={true}
            title={
              r.row.callsign || r.row.classification ? (
                <div>
                  {r.row.callsign && (
                    <div>
                      <b>Callsign:</b> {r.row.callsign}
                    </div>
                  )}
                  {r.row.classification && (
                    <div>
                      <b>Type:</b> {r.row.classification}
                    </div>
                  )}
                </div>
              ) : null
            }>
            <Box>{r.row.name}</Box>
          </Tooltip>
        );
      },
    }, // @ts-ignore
    {
      field: 'driverName',
      headerName: 'Driver',
      ...commonColDefOptions,
      hide: !['vehicle'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (!r?.row.stateIgnition) return '';
        else return r.row.driverName;
      },
    }, // @ts-ignore
    {
      field: 'formattedAddress',
      headerName: 'Location',
      ...commonColDefOptions,
      hide: !!isMobile,
      renderCell: (r: any) => {
        if (!r.row?.formattedAddress && !r.row.position) {
          return '-';
        }
        if (!r.row?.formattedAddress && r.row.position) {
          return `${r?.row.position?.lat.toFixed(
            7
          )}, ${r?.row.position?.lon.toFixed(7)}`;
        }

        return (
          <Tooltip arrow={true} title={r?.row.formattedAddress}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '.8rem',
                alignItems: 'center',
                mb: 0.5,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {r?.row?.formattedAddress}
            </Box>
          </Tooltip>
        );
      },
    },
    // @ts-ignore
    {
      field: 'destination',
      headerName: 'Destination',
      ...commonColDefOptions,
      hide: !['vessel_ais'].includes(assetType) || !!isMobile,
    },
    // @ts-ignore
    {
      field: 'eta',
      headerName: 'ETA',
      ...commonColDefOptions,
      sortable: true,
      hide: !['vessel_ais'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (!r.row.eta || moment() > moment(r.row.eta).add(6, 'hours'))
          return '-';
        return (
          <Tooltip arrow={true} title={moment(r.row.eta).fromNow(true)}>
            <div>{moment(r.row.eta).format('YYYY-MM-DD HH:mm:ss')}</div>
          </Tooltip>
        );
      },
    },
    // @ts-ignore
    {
      field: 'size',
      headerName: 'Size',
      ...commonColDefOptions,
      hide: !['container'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (r.row.size === null) return '-';
        return t(`asset.container.size.${r.row.size}`);
      },
    },
    // @ts-ignore
    {
      field: 'type',
      headerName: 'Type',
      ...commonColDefOptions,
      hide: !['trailer'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (r.row.type === null) return '-';
        return t(`asset.trailer.size.${r.row.type}`);
      },
    },
    // @ts-ignore
    {
      field: 'length',
      headerName: 'Length',
      ...commonColDefOptions,
      hide: !['trailer'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (r.row.length === null) return '-';
        return `${r.row.length} ft`;
      },
    },
    // @ts-ignore
    {
      field: 'weight',
      headerName: 'Weight',
      ...commonColDefOptions,
      hide: !['trailer'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (r.row.weight === null) return '-';
        return `${r.row.weight} mt`;
      },
    },
    // @ts-ignore
    {
      field: 'internalTemp',
      headerName: 'Internal',
      ...commonColDefOptions,
      hide: !['trailer', 'container'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (!r.row.internalTemp) return '-';
        return `${r.row.internalTemp} °C`;
      },
    },
    // @ts-ignore
    {
      field: 'ambientTemp',
      headerName: 'Ambient',
      ...commonColDefOptions,
      hide: !['trailer', 'container'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        if (!r.row.ambientTemp) return '-';
        return `${r.row.ambientTemp} °C`;
      },
    },
    // @ts-ignore
    {
      field: 'doorOpen',
      headerName: 'Door Status',
      ...commonColDefOptions,
      hide: !['trailer', 'container'].includes(assetType) || !!isMobile,
      flex: 0.6,
      renderCell: (r: any) => {
        if (r.row.doorOpen === null) return '';
        return (
          <Tooltip
            arrow={true}
            title={r.row.doorOpen ? 'Door Open' : 'Door Closed'}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
              }}>
              {r.row.doorOpen ? (
                <DoorOpenIcon
                  color='#CCC'
                  size='18'
                  sx={{ fontSize: '1.6rem' }}
                />
              ) : (
                <DoorClosedIcon
                  color='#CCC'
                  size='18'
                  sx={{ fontSize: '1.6rem' }}
                />
              )}
            </Box>
          </Tooltip>
        );
      },
    },
    // @ts-ignore
    {
      field: 'stateMoving',
      headerName: 'Status',
      ...commonColDefOptions,
      hide: !!['trailer', 'container'].includes(assetType),
      flex: 0.4,
      renderCell: (r: any) => {
        let status = 'Stationary';
        let color = 'text.disabled';
        let icon = <GpsFixed sx={{ width: '1.2rem', mr: 1 }} />;

        if (r.row.stateMoving || r.row.speed > 0) {
          status = 'Moving';
          color = 'success';
          icon = <MovingIcon color={'success'} sx={{ width: '2rem', mr: 1 }} />;
        }
        if (!r.row.stateMoving && r.row.stateIdling) {
          status = 'Idling';
          color = 'warning';
          icon = <Co2Icon color={'warning'} sx={{ width: '2rem', mr: 1 }} />;
        }
        return (
          <Tooltip arrow={true} title={status}>
            <Box
              sx={{
                color,
                width: '100%',
                textAlign: 'center',
              }}>
              {icon}
            </Box>
          </Tooltip>
        );
      },
    }, // @ts-ignore
    {
      field: 'stateIgnition',
      headerName: 'Ignition',
      ...commonColDefOptions,
      flex: 0.4,
      hide: !['vehicle'].includes(assetType),
      renderCell: (r: any) => {
        return (
          <>
            {r?.row.hasIgnitionLockout &&
              r?.row.stateIgnitionLockedOut !== null && (
                <Tooltip
                  arrow={true}
                  title={`Ignition Lockout ${
                    r?.row.stateIgnitionLockedOut ? 'Engaged' : 'Disengaged'
                  }`}>
                  <Box
                    sx={{
                      width: '100%',
                      textAlign: 'center',
                    }}>
                    {r?.row.stateIgnitionLockedOut ? (
                      <LockIcon
                        sx={{
                          fontSize: '1.3rem',
                          color: r?.row.stateIgnitionLockedOut
                            ? '#ffbf00'
                            : 'text.disabled',
                        }}
                      />
                    ) : (
                      <LockOpenIcon
                        sx={{
                          fontSize: '1.3rem',
                          color: r?.row.stateIgnitionLockedOut
                            ? '#ffbf00'
                            : 'text.disabled',
                        }}
                      />
                    )}
                  </Box>
                </Tooltip>
              )}
            <Tooltip
              arrow={true}
              title={r?.row.stateIgnition ? 'Ignition On' : 'Ignition Off'}>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                }}>
                {r?.row.stateIgnition ? (
                  <KeyIcon sx={{ fontSize: '1.6rem' }} color={'success'} />
                ) : (
                  <KeyOffIcon
                    sx={{ fontSize: '1.6rem', color: 'text.disabled' }}
                  />
                )}
              </Box>
            </Tooltip>
          </>
        );
      },
    }, // @ts-ignore
    {
      field: 'odometer',
      headerName: 'Odometer',
      ...commonColDefOptions,
      flex: 0.5,
      hide: !['vehicle'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        return (
          <>
            {r?.row.odometer !== null ? `${r?.row.odometer} kms` : '--'}
            {r?.row.odometer !== null && r?.row.hasVirtualOdometer && (
              <sup style={{ fontSize: '1.2em', marginLeft: '2px' }}>*</sup>
            )}
          </>
        );
      },
    }, // @ts-ignore
    {
      field: 'fuelLevel',
      headerName: 'Fuel Level',
      ...commonColDefOptions,
      flex: 0.5,
      hide: !['vehicle'].includes(assetType) || !!isMobile,
      renderCell: (r: any) => {
        return r?.row.fuelLevel ? `${r?.row.fuelLevel} %` : '--';
      },
    }, // @ts-ignore
    {
      field: 'timestamp',
      headerName: 'Report Age',
      ...commonColDefOptions,
      sortable: true,
      renderCell: (r: any) => {
        if (!r.value) return 'Never Reported';
        return (
          <Tooltip
            arrow={true}
            title={moment(r.row.timestamp).format('YYYY-MM-DD HH:mm:ss')}>
            <div>{moment(r.value).fromNow(true)}</div>
          </Tooltip>
        );
      },
    }, // @ts-ignore
  ];
}

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
    '&.MuiDataGrid-root': {
      cursor: 'pointer',
    },
  },
});

const AssetsStatus = ({ assetType }: any) => {
  const [searchParams, stringify] = useComplexSearchParams() as any;
  const { isMobile } = useUIContext();
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortBy, setSortBy] = useState<AssetStatusSortByType>('timestamp');
  const [sortOrder, setSortOrder] = useState<AssetStatusSortOrderType>('DESC');
  const pageSize = 12;
  const { data, isLoading, refetch, isRefetching } = useAssetsStatus(
    searchTerm,
    assetType,
    page + 1,
    sortBy,
    sortOrder,
    stringify(searchParams),
    pageSize
  ) as any;
  const { reset } = useActionMenuContext();
  const { canRead } = usePermissions();
  const navigate = useNavigate();
  const classes = useStyles();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  React.useEffect(() => {
    if (searchTerm) setPage(0);
  }, [searchTerm]);

  const handleEvent: GridEventListener<'rowClick'> = params => {
    navigate(`/assets/${params.row?.assetUuid}`);
  };

  return (
    <div
      style={{
        width: isMobile ? '92vw' : 'calc( 100% - 120px )',
        ...(isMobile && {
          margin: 'auto',
          paddingBottom: '120px',
        }),
      }}>
      {canRead('ASSET') && (
        <AssetStatusToolbarWithUrlFilters
          refetch={refetch}
          setSearchTerm={setSearchTerm}
          exportComponent={isRefetching ? Fragment : ExportAssetStatusReport}
          gridSx={isMobile ? { display: 'none' } : {}}
          assetType={assetType}
        />
      )}
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick={true}
        onCellClick={(
          params: GridCellParams,
          event: MuiEvent<React.MouseEvent>
        ) => {
          event.defaultMuiPrevented = true;
        }}
        className={classes.root}
        rows={data && data.assetStatus ? data.assetStatus : []}
        getRowId={row => row.assetId}
        columns={getColumns({ assetType, t, isMobile })}
        onRowClick={handleEvent}
        pageSize={pageSize}
        checkboxSelection={false}
        autoHeight
        loading={isLoading || isRefetching}
        rowHeight={40}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pagination
        sortingOrder={['desc', 'asc']}
        sortingMode='server'
        onSortModelChange={({ 0: sorting }) => {
          if (!sorting) {
            setSortBy('name');
            setSortOrder('ASC');
            return;
          }
          const { field, sort } = sorting;
          if (field) setSortBy(field as AssetStatusSortByType);
          if (sort)
            setSortOrder(sort.toUpperCase() as AssetStatusSortOrderType);
        }}
      />
      {assetType === 'vehicle' && (
        <div style={{ marginTop: '0.1em', lineHeight: '1em' }}>
          <Typography
            variant={'caption'}
            sx={{ color: 'rgba(255,255,255,0.5)' }}>
            * {t('virtual_odometer_info')}
          </Typography>
        </div>
      )}
    </div>
  );
};

const ExportAssetStatusReport = ({ filters, assetType }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const { user } = useAuth() as any;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [canExport, setCanExport] = React.useState<boolean>(true);
  const [prevSearchParams, setPrevSearchParams] = React.useState<any>(null);
  const [searchParams] = useComplexSearchParams();

  React.useEffect(() => {
    if (JSON.stringify(searchParams) !== JSON.stringify(prevSearchParams)) {
      setCanExport(true);
      setPrevSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useAssetStatusExportReport();

  const handleExport = (fileType: any) => {
    handleClose();
    setCanExport(false);
    exportReport({ ...filters, fileType, assetType });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip
        arrow={true}
        title={
          <div style={{ textAlign: 'center' }}>
            {user?.hasAISVessels ? (
              <>
                {t('reports.export_report')}
                <br />
                {t('reports.export_report_ais_note')}
              </>
            ) : (
              t('reports.export_report')
            )}
          </div>
        }>
        <IconButton disabled={isExporting || !canExport} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

export default AssetsStatus;
