import React from 'react';
import { SvgIcon } from "@mui/material";

const VehicleTripIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'large'}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.50014 0 0 1.50014 .957 .428)">
        <g>
          <path
            d="M14.28 1.2H3.016a0.24 0.24 0 0 0 -0.24 0.24v2.16c0.002 0.004 0 3.838 0.001 3.842 0.001 0.312 0.479 0.315 0.48 0V3.84H14.04v8.88H5.17a0.24 0.24 0 1 0 0 0.48H14.28a0.24 0.24 0 0 0 0.24 -0.24V1.44a0.24 0.24 0 0 0 -0.24 -0.24Zm-11.024 2.16V1.68H14.04v1.68H3.256Z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M11.76 8.64a0.24 0.24 0 0 0 0.2 -0.107c0.115 -0.172 1.12 -1.701 1.12 -2.374C13.08 5.41 12.488 4.8 11.76 4.8s-1.32 0.61 -1.32 1.36c0 0.672 1.006 2.201 1.12 2.374A0.24 0.24 0 0 0 11.76 8.64zm-0.84 -2.48c0.03 -1.16 1.65 -1.16 1.68 0 0 0.336 -0.456 1.179 -0.84 1.796 -0.384 -0.617 -0.84 -1.46 -0.84 -1.796z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M2.905 8.32c-1.227 0 -2.226 1.04 -2.226 2.319 0 1.188 1.818 3.979 2.025 4.293 0.088 0.142 0.313 0.142 0.401 0 0.207 -0.314 2.025 -3.105 2.025 -4.293 0 -1.279 -0.998 -2.319 -2.225 -2.319Zm0 6.037c-0.625 -0.99 -1.746 -2.928 -1.746 -3.718 0 -1.014 0.783 -1.839 1.746 -1.839s1.745 0.825 1.745 1.839c0 0.789 -1.12 2.728 -1.745 3.718Z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M2.905 9.497c-1.483 0.047 -1.483 2.216 0 2.263 1.483 -0.047 1.483 -2.216 0 -2.263Zm0 1.783c-0.847 -0.022 -0.847 -1.281 0 -1.303 0.847 0.022 0.847 1.281 0 1.303Z"
            stroke={color}
            strokeWidth="0.2px" />
          <path
            d="M6.8 11.28h-0.84a0.24 0.24 0 1 0 0 0.48h0.84c0.595 0 1.08 -0.485 1.08 -1.08 0 -0.331 0.269 -0.6 0.6 -0.6h1.2c0.595 0 1.08 -0.485 1.08 -1.08V8.4a0.24 0.24 0 1 0 -0.48 0v0.6c0 0.331 -0.269 0.6 -0.6 0.6h-1.2a1.081 1.081 0 0 0 -1.08 1.08c0 0.331 -0.269 0.6 -0.6 0.6Z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M4.68 5.76h0.96c0.314 -0.001 0.314 -0.479 0 -0.48h-0.96c-0.314 0.001 -0.314 0.479 0 0.48Z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M4.68 6.96h2.4c0.313 -0.001 0.314 -0.479 0 -0.48h-2.4c-0.313 0.001 -0.314 0.479 0 0.48Z"
            stroke={color}
            strokeWidth="0.5px" />
        </g>
      </g>
    </svg>
  </SvgIcon>
}

export default VehicleTripIcon;