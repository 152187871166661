import { ScheduleSendOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import ScheduledReportsList from 'components/ScheduledReports/ScheduledReportsList';
import React from 'react';
import { Link } from 'react-router-dom';

export const ScheduledReports = () => {
  return (
    <Box>
      <Box
        justifyContent={'space-between'}
        alignItems={'center'}
        display={'flex'}>
        <h1>Scheduled Reports</h1>
        <Link to='/reports/scheduler/create'>
          <Button
            color='secondary'
            variant='outlined'
            startIcon={<ScheduleSendOutlined />}>
            Create
          </Button>
        </Link>
      </Box>
      <ScheduledReportsList />
    </Box>
  );
};

export default ScheduledReports;
