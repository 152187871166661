import React from 'react';
import { SvgIcon } from "@mui/material";

const UtilizationIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'large'}>
    <svg viewBox={`0 0 ${size} ${size}`} fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.201 13.342a6.573 6.573 0 0 1 -1.235 2.467 0.44 0.44 0 0 0 0.069 0.619 0.439 0.439 0 0 0 0.618 -0.069 7.464 7.464 0 0 0 1.401 -2.798 0.442 0.442 0 0 0 -0.317 -0.536 0.441 0.441 0 0 0 -0.536 0.317Z"
        stroke={color}
        strokeWidth="0.5px"
        fill={color}/>
      <path
        d="M12.777 5.798a0.44 0.44 0 0 1 -0.44 0.437c-3.157 0 -5.72 2.563 -5.72 5.72 0 3.157 2.563 5.72 5.72 5.72 0.243 0 0.44 0.197 0.44 0.44V23.395a0.44 0.44 0 0 1 -0.44 0.44c-0.621 0 -1.243 -0.049 -1.858 -0.146a0.441 0.441 0 0 1 -0.357 -0.324l-0.415 -1.592a10.132 10.132 0 0 1 -2.452 -1.015l-1.419 0.833a0.441 0.441 0 0 1 -0.481 -0.024 11.883 11.883 0 0 1 -2.628 -2.629 0.438 0.438 0 0 1 -0.024 -0.481l0.833 -1.42a10.106 10.106 0 0 1 -1.016 -2.452l-1.592 -0.415a0.44 0.44 0 0 1 -0.324 -0.357 11.888 11.888 0 0 1 0 -3.717 0.441 0.441 0 0 1 0.324 -0.357l1.592 -0.415a10.127 10.127 0 0 1 1.016 -2.452l-0.833 -1.42a0.438 0.438 0 0 1 0.024 -0.481 11.864 11.864 0 0 1 2.628 -2.628 0.439 0.439 0 0 1 0.481 -0.024l1.419 0.833a10.108 10.108 0 0 1 2.452 -1.016l0.415 -1.592A0.441 0.441 0 0 1 10.478 0.222a11.886 11.886 0 0 1 1.858 -0.146c0.243 0 0.44 0.197 0.44 0.44v5.282Zm0.88 -4.764c5.45 0.653 9.68 5.297 9.68 10.921 0 5.625 -4.23 10.269 -9.68 10.921V20.211c3.987 -0.633 7.04 -4.091 7.04 -8.256 0 -4.165 -3.053 -7.623 -7.04 -8.256V1.034Z"
        fill={color}/>
      <path
        d="M12.434 9.758c1.6 -1.055 4.071 -2.577 4.071 -2.577a0.441 0.441 0 0 1 0.606 0.606s-1.522 2.471 -2.576 4.071c0.001 0.032 0.002 0.065 0.002 0.097 0 1.215 -0.986 2.2 -2.2 2.2 -1.214 0 -2.2 -0.986 -2.2 -2.2 0 -1.214 0.986 -2.2 2.2 -2.2 0.033 0 0.066 0.001 0.098 0.003Z"
        fill={color}/>
      <path
        stroke={color}
        strokeWidth="0.5px"
        d="M19.289 11.691a0.44 0.44 0 1 1 -0.88 0 0.44 0.44 0 0 1 0.88 0Z"
        fill={color}/>
    </svg>
  </SvgIcon>
}

export default UtilizationIcon;