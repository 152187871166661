/* eslint-disable max-len */
import { GeoJSON, SOURCES } from 'components/Map';

export default (data: any[]) =>
  GeoJSON({
    id: SOURCES.TRIP_EVENTS_POINTS,
    data: {
      type: 'FeatureCollection',
      features: data?.flatMap((location: any) => {
        return {
          type: 'Feature',
          properties: {
            name: location?.assetName,
            heading: location?.position?.heading || location?.heading || 0,
            speed: location?.position?.speed || location?.speed || 0,
            assetId: location?.assetId,
            assetUuid: location?.assetUuid,
            timestamp: location?.timestamp,
            assetType: location?.assetType,
            ignition: location?.stateIgnition || null,
            idling: location?.stateIdling || null,
            reportType: location?.reportType || null,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              location?.position?.lon || 0,
              location?.position?.lat || 0,
            ],
          },
        };
      }),
    },
  });
