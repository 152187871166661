import {
  RadioButtonChecked,
  RadioButtonUncheckedOutlined,
} from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  Chip,
  Radio,
  TextField,
  createFilterOptions,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
//@ts-ignore
import parse from 'autosuggest-highlight/parse';
//@ts-ignore
import match from 'autosuggest-highlight/match';

const CheckboxIcon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const CheckboxCheckedIcon = <CheckBoxIcon fontSize='small' />;

const RadioIcon = <RadioButtonUncheckedOutlined fontSize='small' />;
const RadioCheckedIcon = <RadioButtonChecked fontSize='small' />;

const FilterAutocomplete = ({
  options,
  state,
  loading,
  onChange,
  filterName,
  label = 'Items',
  keyProperty,
  labelProperty,
  fullWidth = false,
  multiple = true,
  size = 'small',
  limitTags = 1,
  onInputChange,
  onClose,
  inputValue,
  disableCloseOnSelect = null,
  limitOptions = 10,
}: any) => {
  const [focused, setFocused] = useState(false);

  const renderTags = (
    value: any[],
    getTagProps: any,
    ownerState: any
  ): ReactNode => {
    if (!multiple && focused) return null;

    if (!multiple && value) {
      return <Chip label={value[labelProperty]} />;
    }

    const getLabelPlurality = (value: any) => {
      if (multiple && value?.length === 1) {
        if (label?.endsWith('s')) {
          return label.slice(0, -1);
        }
        return label;
      }
      return label?.endsWith('s') ? label : `${label}s`;
    };

    if (multiple && value.length === 0) {
      return <>{`${value?.length} ${getLabelPlurality(value)}`}</>;
    }

    // TODO: This is a hack to show the first chip when limitTags is 0
    // @Sven to review
    if (multiple && limitTags === 0) {
      limitTags = 1;
    }

    const chipStyle = {
      ...('small' === size && {
        height: 20,
        fontSize: 12,
        py: 1,
        '.MuiChip-deleteIcon': {
          mr: 0,
        },
      }),
    };

    return (
      <div
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          alignItems: 'center',
          height: 'small' === size ? '40px' : '56px',
        }}>
        {value?.slice(0, limitTags).map((option: any, index: number) => (
          <Chip
            key={index}
            label={option[labelProperty]}
            {...getTagProps({ index })}
            IconProps={{ style: { marginRight: 0 } }}
            sx={chipStyle}
          />
        ))}
        {limitTags && value?.length > limitTags && (
          <Chip label={`+${value.length - limitTags}`} sx={chipStyle} />
        )}
      </div>
    );
  };

  return (
    <Autocomplete
      size={size}
      multiple={multiple}
      limitTags={limitTags}
      id='checkboxes-tags-demo'
      isOptionEqualToValue={(option, value) =>
        option?.[keyProperty] === value?.[keyProperty] ||
        option?.[keyProperty] === value
      }
      onChange={(event, value) => {
        onChange ? onChange(filterName, event, value, keyProperty) : null;
      }}
      options={options || []}
      value={state}
      loading={loading}
      disableCloseOnSelect={disableCloseOnSelect ?? !!multiple}
      getOptionLabel={option => {
        const opt = options?.filter(
          (o: any) => o[keyProperty] === option[keyProperty]
        )[0];
        return option[labelProperty] || opt?.[labelProperty] || '';
      }}
      fullWidth={fullWidth}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option[labelProperty], inputValue, {
          insideWords: true,
        });
        const parts = parse(option[labelProperty], matches);
        return (
          <li {...props} key={option[keyProperty]}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}>
              {multiple ? (
                <Checkbox
                  icon={CheckboxIcon}
                  checkedIcon={CheckboxCheckedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              ) : (
                <Radio
                  icon={RadioIcon}
                  checkedIcon={RadioCheckedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              )}
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          </li>
        );
      }}
      renderTags={renderTags}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            style: {
              height: 'small' === size ? '40px' : '56px',
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      )}
      onInputChange={onInputChange}
      inputValue={inputValue}
      clearOnBlur={true}
      clearOnEscape={false}
      defaultValue={[]}
      filterOptions={createFilterOptions({
        limit: limitOptions,
      })}
    />
  );
};

export default FilterAutocomplete;
