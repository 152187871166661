import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'circle',
    source,
    filter: [
      'all',
      ['has', 'assetType'],
      ['==', ['get', 'assetType'], 'security_camera'],
      ['!', ['has', 'point_count']],
    ],
    paint: {
      'circle-blur': 0.05,
      'circle-color': '#00a8ff',
      'circle-radius': 6,
    },
  };
};
