import React from 'react';
import { SvgIcon } from "@mui/material";

const LocationActivityIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'large'}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.969 2.81c-3.114 0 -5.637 2.524 -5.637 5.637 0 1.606 1.409 4.097 2.589 5.9 0.754 1.18 1.442 2.064 1.803 2.523 0.951 1.212 1.049 1.212 1.246 1.212 0.196 0 0.295 0 1.245 -1.18 0.36 -0.459 1.048 -1.376 1.803 -2.524 1.18 -1.802 2.589 -4.293 2.589 -5.932 0 -3.113 -2.523 -5.637 -5.637 -5.637Zm0 14.191c-1.082 -1.245 -4.752 -6.293 -4.752 -8.587 0 -2.622 2.13 -4.752 4.752 -4.752 2.622 0 4.752 2.13 4.752 4.752 0 2.294 -3.671 7.341 -4.752 8.587Z"
        stroke={color}
        strokeWidth="0.5px" />
      <path
        d="M11.969 5.497c-1.41 0 -2.491 1.114 -2.491 2.491 0 1.409 1.115 2.491 2.491 2.491 1.409 0 2.491 -1.081 2.491 -2.491 0 -1.409 -1.081 -2.491 -2.491 -2.491Zm0 4.097c-0.885 0 -1.606 -0.721 -1.606 -1.606s0.72 -1.606 1.606 -1.606c0.917 0 1.606 0.721 1.606 1.606 0 0.885 -0.721 1.606 -1.606 1.606Z"
        stroke={color}
        strokeWidth="0.5px" />
      <path
        d="M11.969 19.945c-1.278 0 -7.735 -0.098 -7.735 -1.737 0 -0.295 0 -1.081 3.769 -1.507 0.229 -0.033 0.459 0.131 0.492 0.393 0.033 0.23 -0.131 0.459 -0.393 0.492 -1.77 0.197 -2.59 0.492 -2.884 0.656 0.59 0.327 2.884 0.852 6.752 0.852 3.867 0 6.162 -0.525 6.751 -0.852 -0.262 -0.132 -1.016 -0.426 -3.015 -0.656 -0.229 -0.033 -0.426 -0.229 -0.393 -0.492 0.033 -0.229 0.229 -0.426 0.492 -0.393 3.441 0.393 3.9 0.983 3.9 1.507 0 1.639 -6.424 1.737 -7.734 1.737Zm6.882 -1.639Z"
        stroke={color}
        strokeWidth="0.5px" />
      <path
        d="M12.002 21.819c-5.604 0 -11.537 -1.246 -11.537 -3.573 0 -2.196 5.178 -3.048 6.785 -3.277 0.229 -0.033 0.459 0.131 0.491 0.393 0.033 0.23 -0.131 0.459 -0.393 0.492 -3.933 0.524 -6.03 1.606 -6.03 2.392 0 1.115 4.064 2.688 10.652 2.688 6.588 0 10.651 -1.573 10.651 -2.688 0 -0.786 -2.032 -1.835 -5.899 -2.359 -0.229 -0.033 -0.393 -0.263 -0.361 -0.492 0.033 -0.229 0.262 -0.426 0.492 -0.36 1.573 0.196 6.653 1.049 6.653 3.245 0.033 2.294 -5.899 3.54 -11.503 3.54Z"
        stroke={color}
        strokeWidth="0.5px" />
    </svg>
  </SvgIcon>
}

export default LocationActivityIcon;