import { QueryClient } from 'react-query';

/**
 * __addToCacheFromWebsocketEvent__
 * @param {QueryClient} q The QueryClient to access client-side Cache
 * @param {string} eventType The eventType string coming from the websocket (i.e. event name)
 * @param {string} key The key (string) of the cache data of react-query
 **/
export const addToCacheFromWebsocketEvent = (
  q: QueryClient,
  eventType: string,
  key: string[],
  append: boolean = false
) => {
  return {
    key: eventType,
    fn: (data: any) =>
      q.setQueryData(key, (old: any) =>
        append ? [data, ...(old ? old : [])] : [...(old ? old : []), data]
      ),
  };
};

/**
 * __addToInfiniteQueryCacheFromWebsocketEvent__
 * @param {QueryClient} q The QueryClient to access client-side Cache
 * @param {string} eventType The eventType string coming from the websocket (i.e. event name)
 * @param {string} key The key (string) of the cache data of react-query
 **/
export const addToInfiniteQueryCacheFromWebsocketEvent = (
  q: QueryClient,
  eventType: string,
  key: any[],
) => {
  return {
    key: eventType,
    fn: (data: any) =>
      q.setQueryData(key, (old: any) => ({
        pages: [
          {
            ...old.pages[0],
            activity: [...old.pages[0].activity, data],
          },
        ],
        pageParams: [...old.pageParams, { cursor: data.id }],
      }))
  };
};

/**
 * __updateInCacheFromWebsocketEvent__
 * @param {QueryClient} q The QueryClient to access client-side Cache
 * @param {string} eventType The eventType string coming from the websocket (i.e. event name)
 * @param {string} key The key (string) of the cache data of react-query
 * @param {string} field The field to use as comparator when iterating existing data vs. new item
 **/
export const updateInCacheFromWebsocketEvent = (
  q: QueryClient,
  eventType: string,
  key: any,
  field: string,
  withQueryParams: boolean = false
) => {
  return {
    key: eventType,
    fn: (data: any) => {
      return q.setQueryData(
        withQueryParams ? [key, { [key]: data[field] }] : [key],
        (old: any) => {
          if (Array.isArray(old)) {
            // TODO: Allow insert of new data if no old data found
            return old.map((item: any) => {
              if (item[field] === data[field]) {
                item = {
                  ...item,
                  ...data,
                };
              }
              return item;
            });
          } else {
            if (old) {
              return {
                ...old,
                ...data,
              };
            }
          }
        }
      );
    },
  };
};

/**
 * __updateInCacheFromWebsocketEvent__
 * @param {QueryClient} q The QueryClient to access client-side Cache
 * @param {string} eventType The eventType string coming from the websocket (i.e. event name)
 * @param {string} key The key (string) of the cache data of react-query
 * @param {string} field The field to use as comparator when iterating existing data vs. new item
 **/
export const upsertCacheFromWebsocketEvent = (
  q: QueryClient,
  eventType: string,
  key: any,
  field: string,
  withQueryParams: boolean = false
) => {
  return {
    key: eventType,
    fn: (data: any) => {
      if ('pressure_report' === data?.reportType) {
        return;
      }
      return q.setQueryData(
        withQueryParams ? [key, { [key]: data[field] }] : [key],
        (old: any) => {
          if (Array.isArray(old)) {
            const found = old.find((item: any) => item[field] === data[field]);
            if (!found) {
              return [...old, data];
            }
            return old.map((item: any) => {
              if (item[field] === data[field]) {
                item = {
                  ...item,
                  ...data,
                };
              }
              return item;
            });
          } else {
            return {
              ...old,
              ...data,
            };
          }
        }
      );
    }
  };
};

/**
 * __removeFromCacheFromWebsocketEvent__
 * @param {QueryClient} q The QueryClient to access client-side Cache
 * @param {string} eventType The eventType string coming from the websocket (i.e. event name)
 * @param {string} key The key (string) of the cache data of react-query
 * @param {string} field The field find the item to be removed (i.e. unique ID)
 **/
export const removeFromCacheFromWebsocketEvent = (
  q: QueryClient,
  eventType: string,
  key: any,
  field: string
) => {
  return {
    key: eventType,
    fn: (data: any) =>
      q.setQueryData(key, (old: any) =>
        old.filter((item: any) => item[field] !== data[field])
      ),
  };
};
