import { SOURCES, GeoJSON } from 'components/Map';

export default (data: any) =>
  GeoJSON({
    id: SOURCES.STATIC_ASSET_PIPELINE_SOURCE,
    data: {
      type: 'FeatureCollection',
      features: data?.map((item: any) => ({
        type: 'Feature',
        properties: {
          pipelineId: item?.pipelineId,
          fromAssetId: item?.assetFrom?.assetId,
          toAssetId: item?.assetTo?.assetId,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [item?.assetFrom?.position?.lon, item?.assetFrom?.position?.lat],
            [item?.assetTo?.position?.lon, item?.assetTo?.position?.lat],
          ],
        },
      }))
    }
  });
