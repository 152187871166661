import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import useActionMenuContext from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import {
  useExportReport,
  useLocationActivity,
} from 'hooks/reports/useLocationActivity';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import ReportWrapper from '../ReportWrapper';
import { LocationActivityToolbarWithUrlFilters } from './LocationActivityFilters';

const commonColDefOptions = {
  resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
};

function getColumns(): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'assetName',
      headerName: 'Asset',
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'locationName',
      headerName: 'Location',
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'enteredAt',
      headerName: 'Entered At',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return (
          <Box>
            <Typography sx={{ fontSize: '.85rem' }}>
              {moment(r?.row.entryAt).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          </Box>
        );
        return;
      },
    }, // @ts-ignore
    {
      field: 'exitedAt',
      headerName: 'Exited At',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return (
          <Box>
            <Typography sx={{ fontSize: '.85rem' }}>
              {r?.row.exitAt
                ? moment(r?.row.exitAt).format('YYYY-MM-DD HH:mm:ss')
                : ''}
            </Typography>
          </Box>
        );
      },
    }, // @ts-ignore
    {
      headerName: 'Dwell Time',
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        const exitedAt = r.row.exitAt ?? moment();
        const days = moment(exitedAt).diff(moment(r?.row?.entryAt), 'days');
        const hours =
          (moment(exitedAt).diff(moment(r?.row?.entryAt), 'hours') % 24) + '';
        const minutes =
          (moment(exitedAt).diff(moment(r?.row?.entryAt), 'minutes') % 60) + '';
        return (
          <>
            {`${days > 0 ? days + 'd' : ''} ${hours}h ${minutes.padStart(
              2,
              '0'
            )}m`}
            {!r.row.exitAt && (
              <Skeleton
                sx={{ ml: 0.5, bgcolor: 'rgb(253, 98, 98)' }}
                variant='circular'
                width={8}
                height={8}
                animation='pulse'
              />
            )}
          </>
        );
      },
    }, // @ts-ignore
  ];
}

const getFiltersState = () => {
  return {
    assetIds: [],
    groupIds: [],
  };
};

const ExportLocationActivity = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportReport();

  const handleExport = (fileType: any) => {
    handleClose();
    exportReport({
      ...filters,
      locationIds: filters?.locationIds,
      fileType,
      groupIds: filters.groupIds,
    });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip arrow={true} title={t('reports.export_report')}>
        <IconButton disabled={isExporting} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

const LocationActivity = ({ assetType }: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams] = useComplexSearchParams();

  const { data, isLoading } = useLocationActivity(
    page + 1,
    searchParams
  ) as any;
  const { reset } = useActionMenuContext();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(prevRowCountState =>
      data?.numRecords !== undefined ? data?.numRecords : prevRowCountState
    );
  }, [data?.numRecords, setRowCountState]);

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          <LocationActivityToolbarWithUrlFilters
            assetType={assetType}
            exportComponent={ExportLocationActivity}
          />
        </Box>
      </Box>
      <DataGrid
        rows={data && data.reports ? data.reports : []}
        getRowId={row => row.reportId}
        columns={getColumns()}
        pageSize={10}
        checkboxSelection={false}
        autoHeight
        loading={isLoading}
        rowHeight={40}
        rowsPerPageOptions={[10]}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pagination
        components={{
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              {'No results'}
            </Stack>
          ),
        }}
      />
    </ReportWrapper>
  );
};

export default LocationActivity;
