import React, { useEffect } from 'react';
import AssetTypes from 'common-web/definitions/assetTypes.json';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';

const AssetTypeFilter = ({ updateFilters, filters, multiple }: any) => {
  const { t } = useTranslation();
  return (
    <Filter
      data={Object.values(AssetTypes.assetTypes).map((key: any) => {
        return {
          /* @ts-ignore */
          name: key.name,
          value: key.id,
        };
      })}
      isLoading={false}
      multiple={multiple}
      filterName='assetType'
      label={multiple ? t('asset_types') : t('asset_type')}
      filterKey={multiple ? 'assetTypes' : 'assetType'}
      keyProperty='value'
      labelProperty='name'
      filters={filters}
      updateFilters={updateFilters}
      limitTags={multiple ? 0 : 1}
    />
  );
};

export default AssetTypeFilter;
