import { SOURCES, GeoJSON } from 'components/Map';

export default (data: any) =>
  GeoJSON({
    id: SOURCES.CONSTANT_SHIPPING_LANE_SOURCE,
    data: {
      type: 'FeatureCollection',
      features: data.features,
    },
  });
