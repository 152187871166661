import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
} from 'react-query';

type Report = {
  position: {
    lon: number;
    lat: number;
  };
};

type DirectionData = any;

export function useMapMatching(
  accessToken: string,
  coordinates: number[][],
  shouldMapMatch: boolean
) {
  return useQuery<any>(
    ['mapMatching', coordinates],
    async () => {
      const response = await fetch(
        `https://api.mapbox.com/matching/v5/mapbox/driving/${coordinates
          .map(coord => coord.join(','))
          .join(';')}?geometries=geojson&access_token=${accessToken}`
      );
      return response.json();
    },
    {
      enabled: !!shouldMapMatch,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}

function chunkArray<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export function useInfiniteMatchedData(
  apiKey: string,
  reports: Report[],
  shouldMapMatch: boolean
): UseInfiniteQueryResult<any, any> {
  return useInfiniteQuery({
    queryKey: ['matching', reports],
    queryFn: async ({ pageParam = 0, signal }) => {
      const chunkedReports = chunkArray(reports, 100);

      if (pageParam >= chunkedReports.length) return [];

      const currentChunk = chunkedReports[pageParam];
      const radiuses = new Array(currentChunk.length).fill(50).join(';');
      const response = await fetch(
        `https://api.mapbox.com/matching/v5/mapbox/driving/${currentChunk
          .map(report => [report.position.lon, report.position.lat].join(','))
          .join(
            ';'
          )}?radiuses=${radiuses}&geometries=geojson&overview=full&access_token=${apiKey}`,
        { signal }
      );

      if (!response.ok) throw new Error('Failed to fetch map-matching');
      return response.json();
    },
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length < Math.ceil(reports.length / 100)) {
        return allPages.length;
      }
      return undefined;
    },
    enabled: shouldMapMatch && reports.length > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

function createOverlappingChunks(
  array: number[][],
  size: number
): number[][][] {
  if (array.length <= size) return [array];

  const chunks = [];
  let index = 0;

  while (index < array.length) {
    const chunk = array.slice(index, index + size);
    chunks.push(chunk);

    index += size - 1;
  }

  return chunks;
}

export function useInfiniteDirectionData(
  apiKey: string,
  coordinates: number[][],
  shouldMapMatch: boolean
): UseInfiniteQueryResult<any, any> {
  return useInfiniteQuery({
    queryKey: ['directions', coordinates],
    queryFn: async ({ pageParam = 0, signal }) => {
      if (pageParam > 0) return [];
      // Create overlapping chunks with the last coordinate of each chunk being the first of the next
      const chunkedReports = createOverlappingChunks(coordinates, 25);

      if (pageParam >= chunkedReports.length) return [];

      const currentChunk = chunkedReports[pageParam];
      const approaches = new Array(currentChunk.length).fill('unrestricted').join(';');
      const radiuses = new Array(currentChunk.length).fill(50).join(';');
      const response = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${currentChunk
          .map(coords => coords.join(','))
          .join(
            ';'
          )}?approaches=${approaches}&continue_straight=true&avoid_maneuver_radius=100&radiuses=${radiuses}&steps=true&annotations=distance&geometries=geojson&overview=full&access_token=${apiKey}`,
        { signal }
      );

      if (!response.ok) throw new Error('Failed to fetch directions');
      return response.json();
    },
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length < Math.ceil(coordinates.length / 25)) {
        return allPages.length;
      }
      return undefined;
    },
    enabled: shouldMapMatch && coordinates.length > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
