import React from 'react';
import { SvgIcon } from "@mui/material";

const VesselTripIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'large'}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.50014 0 0 1.50014 .957 .428)">
        <g>
          <path
            d="M14.28 1.2H3.016a0.24 0.24 0 0 0 -0.24 0.24v2.16c0.002 0.004 0 3.838 0.001 3.842 0.001 0.312 0.479 0.315 0.48 0V3.84H14.04v8.88H5.17a0.24 0.24 0 1 0 0 0.48H14.28a0.24 0.24 0 0 0 0.24 -0.24V1.44a0.24 0.24 0 0 0 -0.24 -0.24Zm-11.024 2.16V1.68H14.04v1.68H3.256Z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M11.76 8.64a0.24 0.24 0 0 0 0.2 -0.107c0.115 -0.172 1.12 -1.701 1.12 -2.374C13.08 5.41 12.488 4.8 11.76 4.8s-1.32 0.61 -1.32 1.36c0 0.672 1.006 2.201 1.12 2.374A0.24 0.24 0 0 0 11.76 8.64zm-0.84 -2.48c0.03 -1.16 1.65 -1.16 1.68 0 0 0.336 -0.456 1.179 -0.84 1.796 -0.384 -0.617 -0.84 -1.46 -0.84 -1.796z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M2.905 8.32c-1.227 0 -2.226 1.04 -2.226 2.319 0 1.188 1.818 3.979 2.025 4.293 0.088 0.142 0.313 0.142 0.401 0 0.207 -0.314 2.025 -3.105 2.025 -4.293 0 -1.279 -0.998 -2.319 -2.225 -2.319Zm0 6.037c-0.625 -0.99 -1.746 -2.928 -1.746 -3.718 0 -1.014 0.783 -1.839 1.746 -1.839s1.745 0.825 1.745 1.839c0 0.789 -1.12 2.728 -1.745 3.718Z"
            stroke={color}
            strokeWidth="0.5px" />
          <path
            d="M2.905 9.497c-1.483 0.047 -1.483 2.216 0 2.263 1.483 -0.047 1.483 -2.216 0 -2.263Zm0 1.783c-0.847 -0.022 -0.847 -1.281 0 -1.303 0.847 0.022 0.847 1.281 0 1.303Z"
            stroke={color}
            strokeWidth="0.2px" />
          <path
            d="M6.8 11.28h-0.84a0.24 0.24 0 1 0 0 0.48h0.84c0.595 0 1.08 -0.485 1.08 -1.08 0 -0.331 0.269 -0.6 0.6 -0.6h1.2c0.595 0 1.08 -0.485 1.08 -1.08V8.4a0.24 0.24 0 1 0 -0.48 0v0.6c0 0.331 -0.269 0.6 -0.6 0.6h-1.2a1.081 1.081 0 0 0 -1.08 1.08c0 0.331 -0.269 0.6 -0.6 0.6Z"
            stroke={color}
            strokeWidth="0.5px" />
          <g transform="matrix(.54163 0 0 .54163 4.5 4.75)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="m7.134 4.477 -0.521 -0.855c-0.16 -0.261 -0.42 -0.261 -0.579 0l-0.521 0.855c-0.159 0.261 -0.029 0.475 0.289 0.475h0.123c0 0.959 -0.646 1.591 -1.605 1.768V2.792c0 0 -0.001 0 -0.001 0C4.877 2.594 5.28 2.066 5.28 1.44a1.44 1.44 0 0 0 -2.88 0c0 0.626 0.403 1.154 0.961 1.352 0 0 -0.001 0 -0.001 0V6.72c-0.959 -0.177 -1.604 -0.81 -1.604 -1.768h0.122c0.319 0 0.449 -0.214 0.29 -0.475l-0.521 -0.855c-0.159 -0.261 -0.42 -0.261 -0.579 0l-0.521 0.855c-0.159 0.261 -0.029 0.475 0.289 0.475h0.122C0.957 6.456 2.25 7.68 3.84 7.68c1.589 0 2.882 -1.224 2.882 -2.728h0.123c0.318 0 0.449 -0.214 0.289 -0.475zM3.84 1.92c-0.265 0 -0.48 -0.215 -0.48 -0.48 0 -0.265 0.215 -0.48 0.48 -0.48s0.48 0.215 0.48 0.48c0 0.265 -0.215 0.48 -0.48 0.48z"
                  stroke={color}
                  strokeWidth="0.5px" />
          </g>
          <g display="none" transform="matrix(.54163 0 0 .54163 18.164 18.43)">
            <path display="inline"
                  d="M7.119 0.574A1.906 1.906 0 0 0 5.761 0.012c-0.513 0 -0.995 0.199 -1.357 0.562l-1.697 1.697c-0.749 0.749 -0.749 1.966 0 2.715 0.121 0.121 0.259 0.216 0.403 0.3l0.728 -0.727a0.957 0.957 0 0 1 -0.452 -0.251 0.954 0.954 0 0 1 -0.24 -0.416v-0.001a0.955 0.955 0 0 1 -0.031 -0.163c0 -0.002 0 -0.004 0 -0.006a0.993 0.993 0 0 1 -0.003 -0.16l0.001 -0.013a0.965 0.965 0 0 1 0.025 -0.155c0.001 -0.004 0.003 -0.008 0.004 -0.013a0.955 0.955 0 0 1 0.245 -0.432c2.969 -3.944 2.134 -2.322 1.697 -1.697a0.959 0.959 0 0 1 0.679 -0.281c0.245 0 0.491 0.094 0.678 0.281a0.96 0.96 0 0 1 0 1.358l-0.59 0.59c0.016 0.041 0.026 0.084 0.039 0.126l0 0.001c0.003 0.011 0.006 0.023 0.01 0.034 0.082 0.267 0.117 0.544 0.104 0.821v0c-0.001 0.014 -0.002 0.027 -0.004 0.041 -0.004 0.067 -0.007 0.135 -0.017 0.202l1.135 -1.135c0.748 -0.749 0.748 -1.967 0 -2.715ZM4.571 2.394l-0.728 0.728a0.956 0.956 0 0 1 0.452 0.25c0.12 0.12 0.196 0.264 0.24 0.416v0.001a0.941 0.941 0 0 1 0.032 0.168c0.005 0.054 0.006 0.107 0.003 0.16l-0.001 0.013c-0.004 0.052 -0.012 0.103 -0.025 0.154 -0.001 0.005 -0.003 0.009 -0.004 0.013a0.955 0.955 0 0 1 -0.245 0.432l-1.697 1.697a0.957 0.957 0 0 1 -0.679 0.281 0.96 0.96 0 0 1 -0.679 -1.639l0.59 -0.59c-0.016 -0.041 -0.026 -0.084 -0.039 -0.126v0c-0.004 -0.011 -0.007 -0.023 -0.011 -0.034a2.392 2.392 0 0 1 -0.103 -0.82v0c0 -0.014 0.002 -0.028 0.003 -0.041 0.004 -0.067 0.007 -0.135 0.017 -0.202l-1.135 1.136c-0.749 0.749 -0.749 1.966 0 2.715a1.908 1.908 0 0 0 1.358 0.563 1.908 1.908 0 0 0 1.357 -0.562l1.697 -1.697c0.748 -0.749 0.748 -1.967 0 -2.715 -0.121 -0.121 -0.259 -0.217 -0.402 -0.3Z"
                  stroke={color}
                  strokeWidth="2px" />
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
}

export default VesselTripIcon;