import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import {
  Dialog,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { makeStyles } from '@mui/styles';
import {
  // eslint-disable-next-line import/named
  GridCellParams,
  // eslint-disable-next-line import/named
  GridColDef,
  // eslint-disable-next-line import/named
  GridEventListener,
  // eslint-disable-next-line import/named
  GridSelectionModel,
  // eslint-disable-next-line import/named
  MuiEvent,
} from '@mui/x-data-grid';
import {
  convertUnitForAssetType,
  getDirectionFromDeg,
  getUnitForAssetType,
} from 'common-web/utils';
import MediaDialog from 'components/Reports/VehicleMedia/MediaDialog';
import MediaIcons from 'components/Reports/VehicleMedia/MediaIcons';
import useActionMenuContext from 'context/ActionMenuContext';
import { useAssetsLog } from 'hooks/assets/useAssets';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import ReportWrapper from '../ReportWrapper';
import AssetLogFilters from './AssetLogFilters';
import AssetLogRowPopover from './AssetLogRowPopover';
import ExportAssetLog from './ExportAssetLog';
import ToMobileLog from './ToMobileLog/index';

const commonColDefOptions = {
  resizable: false,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
};

enum AssetLogMode {
  FROM_MOBILE = 'fromMobile',
  TO_MOBILE = 'toMobile',
}

function getColumns({ t, showMediaInDialog }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'assetName',
      headerName: 'Asset',
      ...commonColDefOptions,
      flex: 1,
      sortable: true,
    }, // @ts-ignore
    {
      field: 'reportType',
      headerName: 'Type',
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        return t(`reports.report_type.${r?.row.reportType}`);
      },
    }, // @ts-ignore
    {
      field: 'timestamp',
      headerName: 'Reported At',
      ...commonColDefOptions,
      sortable: true,
      renderCell: (r: any) => {
        return moment(r?.row.timestamp).format('YYYY-MM-DD HH:mm:ss');
      },
    }, // @ts-ignore
    {
      field: 'speed',
      headerName: 'Speed',
      ...commonColDefOptions,
      flex: 0.5,
      renderCell: (r: any) => {
        return (
          <>
            {convertUnitForAssetType(
              'speed',
              r?.row.assetType,
              r?.row.speed || 0
            )}{' '}
            {getUnitForAssetType('speed', r?.row.assetType)}
          </>
        );
      },
    }, // @ts-ignore
    {
      field: 'heading',
      headerName: 'Heading',
      ...commonColDefOptions,
      flex: 0.5,
      renderCell: (r: any) => {
        return (
          <>
            {r?.row.heading || 0}°{' '}
            {`(${getDirectionFromDeg(r?.row.heading || 0)})`}
          </>
        );
      },
    }, // @ts-ignore
    {
      field: 'transport',
      headerName: 'Transport',
      ...commonColDefOptions,
      flex: 0.5,
      renderCell: (r: any) => {
        return t(`reports.transport.${r?.row.transport}`);
      },
    }, // @ts-ignore
    {
      field: 'position',
      headerName: 'Coordinates',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        if ('vessel_ais' === r?.row?.assetType) return <></>;
        return `${r?.row.position.lat.toFixed(
          7
        )}, ${r?.row.position.lon.toFixed(7)}`;
      },
    }, // @ts-ignore
    {
      field: 'formattedAddress',
      headerName: 'Address',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return (
          <Tooltip arrow={true} title={r?.row?.formattedAddress}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '.8rem',
                alignItems: 'center',
                mb: 0.5,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {r?.row?.formattedAddress}
            </Box>
          </Tooltip>
        );
      },
    }, // @ts-ignore
    {
      field: 'media',
      headerName: '',
      ...commonColDefOptions,
      align: 'center',
      flex: 0.5,
      renderCell: (r: any) => {
        if (r?.row.vehicleMedia && r?.row.vehicleMedia.length > 0) {
          return <MediaIcons asset={r.row} callback={showMediaInDialog} />;
        }
      },
    }, // @ts-ignore
  ];
}

export type AssetLogSortByType = 'name' | 'timestamp';
export type AssetLogSortOrderType = 'ASC' | 'DESC';

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },
});

const AssetLog = ({ assetType }: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState<number>(0);
  const [searchParams] = useComplexSearchParams();
  const [assetToShowInDialog, showMediaInDialog] = React.useState(null);
  const [mode, setMode] = React.useState(AssetLogMode.FROM_MOBILE);
  const [sortBy, setSortBy] = React.useState<AssetLogSortByType>('timestamp');
  const [sortOrder, setSortOrder] =
    React.useState<AssetLogSortOrderType>('DESC');
  const { data, isLoading, refetch, isRefetching } = useAssetsLog({
    page: page + 1,
    filterBy: searchParams,
    sortBy,
    sortOrder,
    enabled: mode === AssetLogMode.FROM_MOBILE,
  }) as any;
  const searchParamsRef = React.useRef(JSON.stringify(searchParams));
  const { reset } = useActionMenuContext();
  const classes = useStyles();
  const [showPopover, setShowPopover] = React.useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<any>({}); // eslint-disable-line
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
    if (event.key === 'ArrowUp' && showPopover) {
      handleClickPrev();
    }
    if (event.key === 'ArrowDown' && showPopover) {
      handleClickNext();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [data, selectedRow, showPopover]);

  React.useEffect(() => {
    setRowCountState(prevRowCountState => {
      if (data?.numRecords !== undefined) {
        return data?.numRecords < 0 ? Number.MAX_VALUE : data?.numRecords;
      }
      return prevRowCountState;
    });
  }, [data?.numRecords, setRowCountState]);

  const handleRowClick: GridEventListener<'rowClick'> = (
    params,
    event: any,
    details
  ) => {
    if (params.row) {
      setSelectedRow(params.row);
      setShowPopover(true);
    }
  };

  const handleClose = () => {
    setShowPopover(false);
    setSelectionModel([]);
  };

  const handleClickNext = () => {
    const index = data.reports.findIndex(
      (report: any) => report.reportId === selectedRow.reportId
    );
    if (index === data.reports.length - 1) {
      return;
    }
    setSelectedRow(data.reports[index + 1]);
    setSelectionModel([data.reports[index + 1].reportId]);
  };

  const handleClickPrev = () => {
    const index = data.reports.findIndex(
      (report: any) => report.reportId === selectedRow.reportId
    );
    if (index === 0) {
      return;
    }
    setSelectedRow(data.reports[index - 1]);
    setSelectionModel([data.reports[index - 1].reportId]);
  };

  const toggleButtons = (
    <ToggleButtonGroup
      size='small'
      color='primary'
      value={mode}
      exclusive
      onChange={(event, newMode) => (newMode ? setMode(newMode) : null)}
      aria-label='Platform'>
      <ToggleButton value={AssetLogMode.FROM_MOBILE}>From Mobile</ToggleButton>
      <ToggleButton value={AssetLogMode.TO_MOBILE}>To Mobile</ToggleButton>
    </ToggleButtonGroup>
  );

  React.useEffect(() => {
    if (JSON.stringify(searchParams) !== searchParamsRef.current) {
      setPage(0);
    }
    searchParamsRef.current = JSON.stringify(searchParams);
  }, [searchParams, data]);

  if (mode == AssetLogMode.TO_MOBILE) {
    return (
      <ToMobileLog
        page={page}
        setPage={setPage}
        toggleButtons={toggleButtons}
      />
    );
  }

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          {toggleButtons}
          <AssetLogFilters
            assetType={assetType}
            exportComponent={isRefetching ? Fragment : ExportAssetLog}
            onRefreshClicked={() => refetch()}
          />
        </Box>
      </Box>
      <>
        <MediaDialog
          asset={assetToShowInDialog}
          showMedia={showMediaInDialog}
        />
        <Dialog
          open={showPopover}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={'md'}
          disableRestoreFocus={true}
          sx={{ mt: -8 }}>
          <DialogContent sx={{ p: 1 }}>
            <AssetLogRowPopover
              row={selectedRow}
              handleClose={handleClose}
              handleClickNext={handleClickNext}
              handleClickPrev={handleClickPrev}
            />
          </DialogContent>
        </Dialog>
        <DataGrid
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          disableColumnFilter
          disableColumnSelector
          rows={data && data.reports ? data.reports : []}
          getRowId={row => row.reportId}
          columns={getColumns({ t, showMediaInDialog })}
          pageSize={100}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          autoHeight
          loading={isLoading}
          rowHeight={40}
          rowsPerPageOptions={[100]}
          rowCount={rowCountState}
          page={page}
          onPageChange={newPage => setPage(newPage)}
          paginationMode='server'
          pagination
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} of ${
                  count === Number.MAX_VALUE ? 'many' : count
                }`,
            },
          }}
          sortingOrder={['asc', 'desc']}
          sortingMode='server'
          onSortModelChange={({ 0: sorting }) => {
            if (!sorting) {
              setSortBy('name');
              setSortOrder('ASC');
              return;
            }
            const { field, sort } = sorting;
            if (field) setSortBy(field as AssetLogSortByType);
            if (sort) setSortOrder(sort.toUpperCase() as AssetLogSortOrderType);
          }}
          onCellClick={(
            params: GridCellParams,
            event: MuiEvent<React.MouseEvent>
          ) => {
            event.defaultMuiPrevented = true;
          }}
          onRowClick={handleRowClick}
          className={classes.root}
        />
      </>
    </ReportWrapper>
  );
};

export default AssetLog;
