import { ReportType } from 'common-web/types/report/ReportType';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';

const types = [
  ReportType.POSITION_REPORT,
  ReportType.MOVING_START,
  ReportType.MOVING_END,
  ReportType.IGNITION_ON,
  ReportType.IGNITION_OFF,
  ReportType.PANIC_START,
  ReportType.PANIC_END,
  ReportType.PANIC_EVENT,
  ReportType.EXCESS_IDLING_START,
  ReportType.EXCESS_IDLING_END,
  ReportType.HARSH_TURN,
  ReportType.HARSH_BRAKE,
  ReportType.REBOOT,
  ReportType.TURN,
  ReportType.DISTANCE,
  ReportType.HARSH_ACCELERATION,
  ReportType.SPEEDING_START,
  ReportType.SPEEDING_END,
  ReportType.GPS_JAMMING_START,
  ReportType.GPS_JAMMING_END,
  ReportType.ACCIDENT_DETECTED,
  ReportType.POWER_DISCONNECTED,
  ReportType.POWER_CONNECTED,
  ReportType.ANTENNA_DISCONNECTED,
  ReportType.ANTENNA_CONNECTED,
];

const ReportTypeFilter = ({ updateFilters, filters, size }: any) => {
  const { t } = useTranslation();
  const [allTypes, setAllTypes] = React.useState<any>(null);

  useEffect(() => {
    setAllTypes(
      Object.values(ReportType)
        .filter((type: any) => types.includes(type))
        .map((key: any) => {
          return {
            /* @ts-ignore */
            name: t(`reports.report_type.${ReportType[key.toUpperCase()]}`),
            id: key,
          };
        })
    );
  }, []);

  return (
    <Filter
      data={allTypes}
      isLoading={!allTypes}
      multiple={true}
      filterName='reportTypes'
      label='Report Types'
      keyProperty='id'
      labelProperty='name'
      filterKey={'reportTypes'}
      filters={filters}
      updateFilters={updateFilters}
      disableCloseOnSelect={true}
      limitTags={0}
      limitOptions={allTypes?.length}
      size={size}
    />
  );
};

export default ReportTypeFilter;
