/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ActivityLogType {
  MESSAGE_SENT='message_sent'
}

export default interface ActivityLog {
  type: ActivityLogType,
  message: string,
}
