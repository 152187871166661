/* eslint-disable max-len */
import { GeoJSON, SOURCES } from 'components/Map';
import { Location } from 'types';

export default (
  assetLocationData: Location[],
  zoomLevel: number = 14,
  cluster: boolean = true
) =>
  GeoJSON({
    id: SOURCES.ASSET_LOCATIONS,
    extra: cluster
      ? {
          cluster: true,
          clusterMaxZoom: zoomLevel, // Max zoom to cluster points on
          clusterRadius: 15, // Radius of each cluster when clustering points (defaults to 50)
          clusterProperties: {
            activeAlert: ['+', ['match', ['get', 'activeAlert'], ['all', 'panic'], 1, 0]],
          },
        }
      : {},
    data: {
      type: 'FeatureCollection',
      features: assetLocationData?.flatMap((location: any) => {
        return {
          type: 'Feature',
          properties: {
            name: location?.name,
            heading: location?.position?.heading || location?.heading || 0,
            speed: location?.position?.speed || location?.speed || 0,
            altitude: location?.position?.altitude || location?.altitude || 0,
            assetId: location?.assetId,
            assetUuid: location?.assetUuid,
            timestamp: location?.timestamp,
            assetType: location?.assetType,
            ignition: location?.ignition || null,
            idling: location?.idling || null,
            activeAlert: location?.activeAlert?.toString(),
            hasIgnitionLockout: location?.hasIgnitionLockout || false,
            stateIgnitionLockedOut: location?.stateIgnitionLockedOut || false,
            ignitionLockoutRequestedAt: location?.ignitionLockoutRequestedAt || null,
            ignitionLockoutRequestedState: location?.ignitionLockoutRequestedState,
            assetDevices: location?.assetDevices,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              location?.position?.lon || 0,
              location?.position?.lat || 0,
            ],
          },
        };
      }),
    },
  });
