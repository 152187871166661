import React from 'react';
import './hoverPopup.scss';

const getAssetTypeDom = (assetType?: string) => {
  if (!assetType) return;
  if ('wellhead' !== assetType && 'pipeline' !== assetType) return;

  const text = (() => {
    if ('wellhead' === assetType) return 'Wellhead';
    if ('pipeline' === assetType) return 'Pipeline Monitor';
  })();

  return <small>{text}</small>;
};

const statusColorMapping = {
  ignition: '#2ecc71',
  idling: '#f39c12',
};

const getDotColor = (details: any) => {
  if (details?.ignition) return statusColorMapping.ignition;
  if (details?.idling) return statusColorMapping.idling;
  return 'transparent';
};

const HoverPopup = (details: any, basic: boolean = false) => {
  const isPanic =
    details?.activeAlerts && details?.activeAlerts[0]?.alertType === 'panic';

  return (
    <div
      className={`hover-popup ${isPanic ? 'popup--panic' : 'popup--normal'}`}>
      <div className='hover-popup-inner'>
        <div>
          <h3
            style={{
              marginBottom: 0,
              paddingBottom: 0,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
            }}>
            {(details?.ignition || details?.idling) && (
              <span
                style={{
                  fontSize: 24,
                  color: getDotColor(details),
                }}>
                ●&nbsp;
              </span>
            )}
            <span>
              {details?.name ||
                (details?.callsign && `Callsign: ${details?.callsign}`) ||
                (details?.mmsi && `MMSI: ${details?.mmsi}`) ||
                ''}
            </span>
          </h3>
          {getAssetTypeDom(details?.assetType)}
        </div>
      </div>
    </div>
  );
};

export default HoverPopup;
