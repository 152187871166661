// eslint-disable-next-line import/named
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export default (t: TFunction) => {
  return Yup.object().shape({
    password: Yup.string().required('Password is required').matches(
      /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})$/,
      "Password must be at least 8 characters in length and contain a lowercase letter, an uppercase letter and a number"
    ),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    captchaValue: Yup.string().required(),
  });
};
