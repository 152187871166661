import React from "react";
import "./popup.scss";
import moment from "moment";
import {
  convertUnitForAssetType,
  getDirectionFromDeg,
  getUnitForAssetType
} from "common-web/utils";
import Divider from "@mui/material/Divider";
import ColorSelector from "./ColorSelector/ColorSelector";

const AISRegionVesselPopup = (assetDetails: any, setColor: Function) => {
  return (
    <div className={`popup popup--normal`}>
      <div className="popup-inner">
        <div>
          <h3>{
            assetDetails?.name
            || (assetDetails?.callsign && `Callsign: ${assetDetails?.callsign}`)
            || (assetDetails?.mmsi && `MMSI: ${assetDetails?.mmsi}`)
            || ""
          }</h3>
        </div>
        <div className="grid-container">
          {assetDetails?.callsign && (
            <>
              <div>
                <b>Callsign:</b>
              </div>
              <div>{assetDetails?.callsign}</div>
            </>
          )}
          {assetDetails?.classification && (
            <>
              <div>
                <b>Class:</b>
              </div>
              <div>{assetDetails?.classification}</div>
            </>
          )}
          {assetDetails?.destination && (
            <>
              <div>
                <b>Destination:</b>
              </div>
              <div>{assetDetails?.destination}</div>
            </>
          )}
          {assetDetails?.eta && (
            <>
              <div>
                <b>ETA:</b>
              </div>
              <div>{moment(assetDetails?.eta).fromNow()}</div>
            </>
          )}
          <div>
            <b>Speed:</b>
          </div>
          <div>
            {convertUnitForAssetType(
              'speed',
              assetDetails?.assetType,
              parseFloat(assetDetails?.position?.speedOverGround) || 0
            )}{" "}
            {getUnitForAssetType('speed', 'vessel')}
          </div>
          <div>
            <b>Heading:</b>
          </div>
          <div>
            {assetDetails?.heading || 0}°{" "}
            {`(${getDirectionFromDeg(parseFloat(assetDetails?.position?.courseOverGround) || 0)})`}
          </div>
          <div>
            <b>Report Age:</b>
          </div>
          <div>{moment(assetDetails?.position?.timestamp).fromNow() || "Unknown"}</div>
        </div>
        <Divider sx={{my: 1}} />
        <ColorSelector
          selected={assetDetails?.color}
          onChange={(color: string) => setColor({
            mmsi: assetDetails?.mmsi.toString(),
            color
          })} />
      </div>
    </div>
  );
};

export default AISRegionVesselPopup;
