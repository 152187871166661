import GeoJSON from 'components/Map/constants/sources/GeoJSON';
import { SOURCES } from './types';

export default (places: any[]) =>
  GeoJSON({
    id: SOURCES.PLACES,
    data: {
      type: 'FeatureCollection',
      features: places?.flatMap((place: any) => [
        {
          type: 'Feature',
          properties: {
            id: place?.id,
            name: place?.name,
            description: place?.description,
            isHighlighted: !!place?.isHighlighted,
          },
          geometry: {
            type: 'Point',
            coordinates: [place?.position?.lng, place?.position?.lat],
          },
        }
      ]),
    },
  });
