import {
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { useDashboardWidget } from 'hooks';
import moment from 'moment';
import React from 'react';
import {
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const UtilizationLineChart = ({
  assetType,
  widgetType,
  chartUnit,
  showTotal = true,
  startTimestamp,
  endTimestamp,
  groupIds,
}: {
  assetType: string;
  widgetType: string;
  chartUnit: string;
  showTotal?: boolean;
  startTimestamp?: Date;
  endTimestamp?: Date;
  groupIds?: [];
}) => {
  const DATA_KEY_UTILIZATION = 'utilizationAll';
  const DATA_KEY_UTILIZATION_PERCENT = 'utilizationPercent';
  const DATA_KEY_EXPECTED = 'expected';
  const config: any = {
    [DATA_KEY_UTILIZATION]: {
      name: 'Utilization Hours',
      unit: 'h',
      legend: 'Utilization Hours',
    },
    [DATA_KEY_UTILIZATION_PERCENT]: {
      name: 'Utilization Percent',
      unit: '%',
      legend: 'Utilization %',
    },
    [DATA_KEY_EXPECTED]: {
      name: 'Expected Hours',
      unit: 'h',
      legend: 'Expected Hours',
    },
  };

  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    groupIds: groupIds,
  }) as any;

  const formatHours = function (value: number) {
    var hours = Math.floor(value);
    var minutes = Math.floor((value - Math.floor(value)) * 60);
    return `${hours.toString().padStart(2, '0')}h ${minutes
      .toString()
      .padStart(2, '0')}m`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card variant='outlined'>
          <CardContent>
            <Typography>{moment(label).format('ddd MMMM Do YYYY')}</Typography>
            <Divider sx={{ my: 1 }} />
            {payload.map((payload: any) => (
              <Typography
                key={payload.name}
                variant='body2'
                sx={{ color: payload.stroke, mb: 0.4 }}>
                <b>{config[payload.name].name}:</b>{' '}
                {config[payload.name].unit == 'h' && (
                  <span>{formatHours(payload.value)}</span>
                )}
                {config[payload.name].unit != 'h' && (
                  <span>
                    {payload.value}
                    {config[payload.name].unit}
                  </span>
                )}
              </Typography>
            ))}
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  if (!data || isLoading)
    return <Skeleton variant='rounded' width={'100%'} height={'100%'} />;

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <ResponsiveContainer
        width={`${showTotal ? '80%' : '100%'}`}
        aspect={16 / 3}
        height='100%'>
        <RechartsLineChart
          data={data?.data.map((item: any) => ({
            ...item,
            name: item.date,
            unit: chartUnit,
          }))}>
          {/* <CartesianGrid strokeDasharray='3 3' /> */}
          <XAxis
            padding={{ left: 10, right: 10 }}
            dataKey='name'
            angle={-35}
            interval={0}
            // @ts-ignore
            tick={{ fontSize: 12, textAnchor: 'end', dy: 4 }}
            tickFormatter={(value: any, index: number) =>
              value && !!moment(value) ? moment(value).format('DD/MM') : ''
            }
          />
          <YAxis
            orientation='left'
            allowDataOverflow
            type='number'
            unit={config[DATA_KEY_UTILIZATION].unit}
            yAxisId='left'
            tick={{ fontSize: 14, fill: '#ffbf00' }}
            tickFormatter={value => `${Math.round(value)}`}
            tickLine={{ stroke: '#ffbf00' }}></YAxis>
          <YAxis
            yAxisId='right'
            orientation='right'
            type='number'
            unit={config[DATA_KEY_UTILIZATION_PERCENT].unit}
            domain={[
              0,
              (dataMax: any) => {
                if (dataMax < 100) {
                  return 100;
                }
                return Math.ceil(dataMax / 25) * 25;
              },
            ]}
            allowDataOverflow={true}
            tick={{ fontSize: 14, fill: '#4BA5F8' }}
            tickFormatter={value => `${Math.round(value)}`}
            tickLine={{ stroke: '#4BA5F8' }}></YAxis>
          <YAxis
            yAxisId='right2'
            orientation='right'
            type='number'
            unit={config[DATA_KEY_UTILIZATION].unit}
            domain={[
              0,
              (dataMax: any) => {
                if (dataMax < 1) {
                  return 10;
                }
                // find the highest percentage
                let maxPercent = 0;
                data.data.forEach((item: any) => {
                  if (item[DATA_KEY_UTILIZATION_PERCENT] > maxPercent) {
                    maxPercent = item[DATA_KEY_UTILIZATION_PERCENT];
                  }
                });
                // find the highest bound of the percentage axis, with intervals of 25
                maxPercent = Math.ceil(maxPercent / 25) * 25;
                return dataMax * (maxPercent / 100);
              },
            ]}
            allowDataOverflow={true}
            tick={{ fontSize: 14, fill: '#ffffff' }}
            tickFormatter={value => `${Math.round(value)}`}
            tickLine={{ stroke: '#ffffff' }}></YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            iconType='plainline'
            wrapperStyle={{ position: 'relative', top: -20 }}
            formatter={(value, entry, index) => {
              return config[value].legend;
            }}
          />
          <Line
            type='monotone'
            dataKey={DATA_KEY_UTILIZATION}
            stroke='#ffbf00'
            activeDot={{ r: 4 }}
            yAxisId='left'
          />
          <Line
            type='monotone'
            dataKey={DATA_KEY_UTILIZATION_PERCENT}
            stroke='#4BA5F8'
            activeDot={{ r: 4 }}
            yAxisId='right'
          />
          <Line
            type='monotone'
            dataKey={DATA_KEY_EXPECTED}
            stroke='#ccc'
            activeDot={{ r: 4 }}
            yAxisId='right2'
            strokeDasharray='3 3 3'
          />
        </RechartsLineChart>
      </ResponsiveContainer>
      {showTotal && (
        <div style={{ width: '20%', alignSelf: 'center', textAlign: 'center' }}>
          {data?.total && (
            <div style={{ fontSize: '4vh' }}>
              {data.total?.toLocaleString()}
            </div>
          )}
          <div>{chartUnit.charAt(0).toUpperCase() + chartUnit.slice(1)}</div>
        </div>
      )}
    </div>
  );
};

export default UtilizationLineChart;
