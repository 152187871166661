import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useProjectedMonthlyBill(): UseQueryResult {
  return useQuery(
    ['billing-projected'],
    () => apiClient.get(`billing/projected`),
    {
      refetchOnWindowFocus: 'always',
      staleTime: 1000 * 60 * 2,
    }
  );
}

export function useBillingSettings(): UseQueryResult {
  return useQuery(
    ['billing-settings'],
    () => apiClient.get(`billing/settings`),
    {
      refetchOnWindowFocus: 'always',
      staleTime: 1000 * 60 * 2,
    }
  );
}

export function useUpdateBillingSettings(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'billing-settings',
    (payload: any) => apiClient.put(`billing/settings`, payload),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('billing-settings');
      },
    }
  );
}

export function useInvoices(): UseQueryResult {
  return useQuery(['invoices'], ({ signal }) =>
    apiClient.get('billing/invoices', {})
  );
}

export function useInvoicePdf(invoiceId: any): UseQueryResult {
  return useQuery(['invoice-pdf', invoiceId], ({ signal }) =>
    apiClient.get(`billing/invoices/${invoiceId}/pdf`, {responseType: 'arraybuffer'}),
  {
    enabled: !!invoiceId,
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
        return false;
    }
  }
  );
}

export function useInvoiceDetailedReport(invoiceId: any): UseQueryResult {
  return useQuery(['invoice-detailed-report', invoiceId], ({ signal }) =>
    apiClient.get(`billing/invoices/${invoiceId}/detailed-report`, {responseType: 'arraybuffer'}),
  {
    enabled: !!invoiceId,
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
        return false;
    }
  }
  );
}