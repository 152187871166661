import * as React from 'react';
import { Box, Card, Skeleton } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function sumProjectedCost(data: any): number {
  if (!data || data?.length === 0) {
    return 0;
  }

  // Calculate the sum of projectedCost
  let totalCost = data.reduce(
    (sum: number, item: any) => sum + item.totalEstimatedCost,
    0
  );

  // Return the total cost
  return totalCost;
}

export default function CostProjection({
  projection,
  isLoading,
  billingSettings,
}: {
  projection?: any;
  isLoading?: boolean;
  billingSettings?: any;
}) {
  const { t } = useTranslation();
  const sum = sumProjectedCost(projection);
  return (
    <Card
      variant='outlined'
      sx={{
        boxShadow: 'lg',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid rgba(255,255,255,0.2)',
        height: '100%',
        position: 'relative',
      }}>
      <Box
        sx={{
          px: 4,
          pt: 0,
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}>
        <h2 style={{ paddingBottom: 0, marginBottom: 0 }}>{`${moment().format(
          'MMMM YYYY'
        )}`}</h2>
        <h3>{t('billing.estimated_total_bill')}</h3>
        <Box sx={{ pl: 1 }}>
          <h1 style={{ fontSize: 60, marginTop: 0, marginBottom: 0 }}>
            {isLoading ? (
              <Skeleton variant='text' animation='wave' height='80px' />
            ) : (
              <>
                ${(sum / 100).toFixed(2)}
                {billingSettings?.billingStatus === 'trial' ? (
                  <>
                    &nbsp;
                    <small style={{ fontSize: 16, verticalAlign: 'middle' }}>
                      (Trial)
                    </small>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </h1>
        </Box>
        {/* <Accordion
          sx={{
            backgroundColor: 'rgba(255,255,255,0.02)',
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreSharp />}
            aria-controls='panel1-content'
            id='panel1-header'>
            Show breakdown
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                pl: 1,
                pr: 1,
              }}>
              {projection?.map((item: any, index: number) => (
                <Box key={`container-${index}`}>
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Typography>
                      {getBreakdown('sum', item)}{' '}
                      {t(`billing.assetTypes.${item.assetType}`)}
                    </Typography>
                    <Typography>
                      ${(item.totalEstimatedCost / 100).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion> */}
        <div
          style={{
            lineHeight: '14px',
            fontSize: '12px',
            marginBottom: 'auto',
            marginTop: 'auto',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}>
          {t('billing.costProjection.disclaimer')}
        </div>
      </Box>
    </Card>
  );
}
