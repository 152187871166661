import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import qs from 'qs';
import { Box } from '@mui/material';

export const ToolbarWithFilters = (props: any) => {
  const [searchParams] = useComplexSearchParams();
  const [filters, updateFilters] = useState<any>(props.filters ?? {});
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    updateFilters({
      ...filters,
      ...props.filters,
      ...searchParams,
    });
  }, []);

  React.useEffect(() => {
    updateFilters({
      ...filters,
      ...props.filters,
      ...searchParams,
    });
  }, [props.filters]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) return;
    const outsideFilters = {
      ...searchParams,
      ...props?.filters,
    };
    navigate(
      `?${qs.stringify(
        { ...outsideFilters, ...filters },
        {
          arrayFormat: 'brackets',
          encode: false,
        }
      )}`,
      {
        state: location.state,
        replace: true,
      }
    );
  }, [filters]);

  return (
    <Box sx={{ width: props.fullWidth ? '100%' : 'auto' }}>
      {React.cloneElement(props.children, {
        filters,
        updateFilters,
      })}
    </Box>
  );
};

export default ToolbarWithFilters;
