import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default function useComplexSearchParams() {
  const location = useLocation();

  const stringify = (obj: any) => {
    return qs.stringify(obj);
  };

  const res = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder: customDecoder,
  });

  return [res, stringify];
}

const customDecoder = (str: string, decoder: any, charset: any) => {
  function isObject(val: string) {
    return val.constructor === Object;
  }

  function isNumber(val: any) {
    return !isNaN(parseFloat(val)) && isFinite(val);
  }

  function isBoolean(val: string) {
    return val === 'false' || val === 'true';
  }

  function isArray(val: string) {
    return Array.isArray(val);
  }

  function parseValue(val: string) {
    if (typeof val == 'undefined' || val == '') {
      return null;
    } else if (isBoolean(val)) {
      return parseBoolean(val);
    } else if (isArray(val)) {
      return parseArray(val);
    } else if (isObject(val)) {
      return parseObject(val);
    } else if (isNumber(val)) {
      return parseNumber(val);
    } else {
      return val;
    }
  }

  function parseObject(obj: any) {
    let result: any = {};
    let key: string;
    let val: any;
    for (key in obj) {
      val = parseValue(obj[key]);
      if (val !== null) result[key] = val; // ignore null values
    }
    return result;
  }

  function parseArray(arr: any): any {
    var result = [];
    for (var i = 0; i < arr.length; i++) {
      result[i] = parseValue(arr[i]);
    }
    return result;
  }

  function parseNumber(val: string) {
    return Number(val);
  }

  function parseBoolean(val: string) {
    return val === 'true';
  }

  return parseValue(str);
};
