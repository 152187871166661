import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'circle',
    source,
    paint: {
      'circle-blur': 0.05,
      'circle-color': '#00a8ff',
      'circle-radius': 6,
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 1,
    },
  };
};
