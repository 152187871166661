import { isLatLong } from 'common-web/utils';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useDoGlobalSearch(searchTerm: string = ''): UseQueryResult {
  return useQuery(
    ['globalSearch', searchTerm],
    ({ signal }) => apiClient.post('search', { term: searchTerm }, { signal }),
    {
      enabled: !!searchTerm && searchTerm.length > 0 && !isLatLong(searchTerm),
    }
  );
}

export function useLoadMore(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'globalSearch',
    (params: any) =>
      apiClient.post('search', {
        term: params.searchTerm,
        type: params.type,
        page: params.page,
      }),
    {
      onSuccess: (data: any, params: any) => {
        queryClient.setQueryData(
          ['globalSearch', params.searchTerm],
          (old: any) => {
            const hasMoreData = data[params.type]
              ? data[params.type].hasMoreData
              : false;
            const newData = data[params.type]
              ? [...old[params.type].data, ...data[params.type]?.data]
              : [...old[params.type]?.data];

            // console.log('hasMoreData', hasMoreData);

            return {
              ...old,
              [params.type]: {
                hasMoreData,
                data: newData,
              },
            };
          }
        );
      },
    }
  );
}
