import { Contact } from 'common-web/types';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useContacts({page, pageSize, searchTerm, filterBy}: {
  page: number,
  pageSize?: number,
  searchTerm?: string,
  filterBy?: any,
}): UseQueryResult {
  return useQuery(['contacts', page, pageSize, searchTerm, filterBy], () => apiClient.get(`contacts`, { params: { page, pageSize, searchTerm, ...filterBy } }));
}

export function useContactsForGroup(variables: {
  groupId?: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['contacts-by-group', variables?.groupId], 
    () => apiClient.get(`contacts-by-group/${variables?.groupId}`), 
    {
      enabled: !!variables?.groupId,
    }
  );
}

export function useContact(variables?: {
  id: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['contact', variables?.id],
    () => apiClient.get(`contacts/${variables?.id}`),
    {
      enabled: !!variables?.id,
      staleTime: 0,
    }
  );
}

export function useEditContact(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'contacts',
    (variables: Contact) =>
      apiClient.put(`contacts/${variables?.contactId}`, variables),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries(['contact', data?.contactId]);
      },
    }
  );
}

export function useResendMobileVerificationForContact(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'resend-mobile-channel-verification',
    (variables: any) =>
      apiClient.post(`resend-mobile-channel-verification`, variables)
  );
}

export function useAddContact(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'contacts',
    (variables: any) =>
      apiClient.post('contacts', {
        firstName: variables.firstName,
        lastName: variables.lastName,
        email: variables.email !== '' ? variables.email : null,
        mobileNumber: variables.mobileNumber !== '' && variables.mobileNumber.length > 4 ? variables.mobileNumber : null,
        preferredMobileChannel: variables.mobileNumber !== '' && variables.mobileNumber.length > 4 ? variables.preferredMobileChannel : null,
        groupId: variables.groupId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries('notification-settings');
      },
    }
  );
}

export function useDeleteContact(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'contacts',
    (contactId: string) => apiClient.delete(`contacts/${contactId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries('notification-settings');
      },
    }
  );
}
