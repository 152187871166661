import { FormControl } from '@mui/material';
import DateTimeRangePicker, {
  IntervalType,
} from 'components/DateTimeRangePicker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export { IntervalType } from 'components/DateTimeRangePicker';

const DateFilter = ({
  updateFilters,
  sx,
  filters,
  dateOptions,
  selectedDateOption = null,
  props,
}: any) => {
  const { t } = useTranslation();
  const [dateInterval, setDateInterval] = useState<IntervalType>(() => {
    if (filters && filters.dateRange) {
      return filters.dateRange;
    }
    return selectedDateOption && dateOptions?.includes(selectedDateOption)
      ? selectedDateOption
      : dateOptions
      ? dateOptions[0]
      : IntervalType.TODAY;
  });

  const handleUpdateFilters = (dateFilters: any) => {
    updateFilters({
      ...filters,
      ...dateFilters,
    });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     // handleUpdateFilters(formatDates(filters.dateRange || dateInterval));
  //   }, 0);
  // }, []);

  const handleChange = (event: any) => {
    setDateInterval(event?.dateRange);
    handleUpdateFilters(event);
  };

  // useEffect(() => {
  //   if (dateInterval) {
  //     // handleUpdateFilters(formatDates(dateInterval));
  //   }
  // }, [dateInterval]);

  useEffect(() => {
    if (filters?.dateRange) {
      setDateInterval(filters.dateRange);
    }
  }, [filters?.dateRange]);

  return (
    <FormControl fullWidth sx={{ ...sx }}>
      <DateTimeRangePicker
        activeOption={dateInterval}
        startDate={filters?.startTimestamp}
        endDate={filters?.endTimestamp}
        sx={{ ...sx }}
        handleChange={handleChange}
      />
    </FormControl>
  );
};

export default DateFilter;
