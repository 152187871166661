import * as Constants from '../constants';
import { DrawCustomMode } from "@mapbox/mapbox-gl-draw";

const NoFeatureInteractionMode: DrawCustomMode = {
  toDisplayFeatures: () => {}
};

NoFeatureInteractionMode.onSetup = function() {
  // In this mode, we don't need any state.
  this.map.dragPan.enable();
  this.updateUIClasses({ mouse: 'drag' });
  return {};
};

NoFeatureInteractionMode.onStop = function() {
  // We don't need to enable/disable doubleClickZoom in this mode.
  this.updateUIClasses({ mouse: 'drag' });
  this.map.dragPan.enable();
};

NoFeatureInteractionMode.onMouseMove = function() {
  // Simply return without doing anything when the mouse moves.
  this.updateUIClasses({ mouse: 'drag' });
  return true;
};

NoFeatureInteractionMode.onMouseOut = function() {
  // Do nothing when the mouse leaves the canvas.
  this.updateUIClasses({ mouse: 'drag' });
  return true;
};

NoFeatureInteractionMode.onTap = NoFeatureInteractionMode.onClick = function() {
  // Do nothing when the map or features are clicked or tapped.
  this.updateUIClasses({ mouse: 'drag' });
  this.map.dragPan.enable();
};

NoFeatureInteractionMode.onMouseDown = function() {
  // Do nothing on mouse down.
  this.updateUIClasses({ mouse: 'drag' });
  this.map.dragPan.enable();
};

NoFeatureInteractionMode.onTouchStart = function() {
  // Do nothing on touch start.
  this.updateUIClasses({ mouse: 'drag' });
  this.map.dragPan.enable();
};

NoFeatureInteractionMode.onDrag = function() {
  // Do nothing on drag.
  this.updateUIClasses({ mouse: 'drag' });
  this.map.dragPan.enable();
};

NoFeatureInteractionMode.onTouchEnd =
  NoFeatureInteractionMode.onMouseUp = function() {
  // Do nothing on touch end or mouse up.
    this.updateUIClasses({ mouse: 'drag' });
    this.map.dragPan.enable();
};

NoFeatureInteractionMode.toDisplayFeatures = function(state, geojson, display) {
  // Simply display the features without changing their active state.
  this.updateUIClasses({ mouse: 'drag' });
  display(geojson);
};

NoFeatureInteractionMode.onTrash = function() {
  // Do nothing when the trash action is triggered.
};

NoFeatureInteractionMode.onCombineFeature = function() {
  // Do nothing when the combine features action is triggered.
};

NoFeatureInteractionMode.onUncombineFeature = function() {
  // Do nothing when the uncombine features action is triggered.
};

export default NoFeatureInteractionMode;
