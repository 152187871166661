import React, { useState } from "react";
import "./ColorSelector.css"; // Create a CSS file for styling

const ColorSelector = (
  {
    selected = "transparent",
    onChange
  }:
    {
      selected?: string,
      onChange?: any
    }) => {
  const colors = ["transparent", "purple", "orange", "pink", "teal", "yellow"];

  const [selectedColor, setSelectedColor] = useState<string>(selected);

  const handleColorClick = (color: string) => {
    if (onChange) onChange(color);
    setSelectedColor(color);
  };

  return (
    <div className="color-selector">
      {colors.map((color, index) => (
        <div
          key={index}
          className={`color-circle ${color} ${selectedColor === color ? "selected" : ""}`}
          onClick={() => handleColorClick(color)}
        ></div>
      ))}
    </div>
  );
};

export default ColorSelector;
