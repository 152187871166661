import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { Box, LinearProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { useAddActivityLog } from 'hooks';
import SendChatMessageFormSchema from 'schemas/SendChatMessage';
import { useTranslation } from 'react-i18next';
import { ActivityLogType } from 'types/ActivityLog';

export default function ActivityFeedChatBox() {
  const addActivityLog = useAddActivityLog();
  const { t } = useTranslation();

  const sendMessage = (data: any) => {
    addActivityLog.mutate({
      ...data,
      type: ActivityLogType.MESSAGE_SENT,
    });
  };

  return (
    <div>
      <Formik
        validateOnMount={true}
        initialValues={{
          message: '',
        }}
        validationSchema={SendChatMessageFormSchema(t)}
        onSubmit={(values: any, { setSubmitting, resetForm }: any) => {
          setSubmitting(false);
          sendMessage(values);
          resetForm();
        }}>
        {({ submitForm, isSubmitting, isValid }: any) => (
          <Form>
            <Box
              sx={{
                p: '2px 4px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'stretch',
                width: '100%',
                fontSize: '.8rem',
              }}>
              <Field
                component={FormikTextField}
                sx={{ width: '100%' }}
                name='message'
                variant='standard'
                type='text'
                placeholder={t('send_new_message')}
                InputProps={{
                  sx: { fontSize: '.9rem', width: '100%' },
                }}
              />
              {isSubmitting && <LinearProgress />}
              <IconButton
                type='button'
                sx={{ p: '10px' }}
                aria-label='submit'
                onClick={submitForm}
                disabled={!isValid}>
                <SendIcon />
              </IconButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
