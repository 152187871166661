import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useDeleteDriver, useDrivers } from 'hooks/drivers/useDrivers';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DriversToolbarWithUrlFilters from './DriversToolbar';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
};

function getColumns({
  t,
  handleEditDriver,
  handleDeleteDriver,
  isDeleting,
  canWrite,
}: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'name',
      headerName: 'Name',
      ...commonColDefOptions,
      renderCell: r => {
        return r?.row?.firstName
          ? `${r?.row?.firstName} ${r?.row?.lastName || ''}`
          : 'Unnamed Driver';
      },
    }, // @ts-ignore
    {
      field: 'status',
      headerName: 'Status',
      ...commonColDefOptions,
    }, // @ts-ignore
    {
      field: 'groupName',
      headerName: 'Group',
      ...commonColDefOptions,
      renderCell: r => {
        return r?.row?.groupName || '';
      },
    }, // @ts-ignore
    {
      field: 'identifier',
      headerName: 'Identifier',
      ...commonColDefOptions,
    }, // @ts-ignore
    {
      field: 'lastReported',
      headerName: 'Last Event At',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return r?.row?.lastReported ? (
          <Tooltip
            arrow={true}
            title={moment(r?.row?.lastReported).format('YYYY-MM-DD HH:mm:ss')}>
            <Box>{moment(r?.row?.lastReported).fromNow()}</Box>
          </Tooltip>
        ) : (
          ''
        );
      },
    }, // @ts-ignore
    ...(canWrite('DRIVER')
      ? [
          {
            headerName: '',
            ...commonColDefOptions,
            minWidth: 90,
            maxWidth: 100,
            align: 'center',
            flex: 0.5,
            renderCell: (r: any) => {
              return (
                <>
                  <Tooltip title='Edit driver'>
                    <IconButton
                      disabled={false}
                      onClick={() => handleEditDriver(r?.row?.driverUuid)}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete driver'>
                    <IconButton
                      disabled={false}
                      onClick={() => handleDeleteDriver(r?.row)}>
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ]
      : []), // @ts-ignore
  ];
}

const Drivers = () => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [searchParams] = useComplexSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data, isLoading } = useDrivers({
    page: page + 1,
    pageSize: 12,
    searchTerm,
    filterBy: searchParams,
  }) as any;
  const { openRightSidebar, setFlashMessage } = useUIContext();
  const { setActive, reset } = useActionMenuContext();
  const [deleteDialog, setDeleteDialog] = useState<any>(null);
  const {
    mutate: deleteUser,
    isLoading: isDeleting,
    isSuccess: isDeleteSuccess,
  } = useDeleteDriver();
  const navigate = useNavigate();
  const { canWrite } = usePermissions();
  const [rowCountState, setRowCountState] = React.useState(0);

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  const handleAddDriver = () => {
    setActive(ActionMenuModuleTypes.AddDriver);
    openRightSidebar();
  };

  const handleDeleteDriver = (driver: any) => {
    setDeleteDialog(driver);
  };

  const handleEditDriver = (id: number) => {
    setActive(ActionMenuModuleTypes.EditDriver, { driverId: id });
    openRightSidebar();
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setFlashMessage({ message: t('success.delete_driver') });
    }
  }, [isDeleteSuccess]);

  return (
    <BoxedContainer>
      <DriversToolbarWithUrlFilters
        handleAddDriver={handleAddDriver}
        setSearchTerm={setSearchTerm}
      />
      <Table
        data={data && data.drivers}
        isLoading={isLoading}
        getRowId={(row: any) => row.driverId}
        columns={getColumns({
          t,
          handleEditDriver,
          handleDeleteDriver,
          isDeleting,
          canWrite,
        })}
        onCellClick={(params: any, event: any, details: any) => {
          if (params.field) {
            navigate(`/drivers/${params.row.driverUuid}`);
          }
        }}
        pageSize={12}
        rowCount={rowCountState}
        paginationMode='server'
        onPageChange={newPage => {
          setPage(newPage);
        }}
      />
      <ConfirmDeleteDialog
        open={!!deleteDialog}
        onCancel={() => {
          setDeleteDialog(null);
        }}
        onConfirm={() => {
          if (deleteDialog) deleteUser({ id: deleteDialog?.driverUuid });
        }}
        title={'Confirm to delete asset'}
        content={
          <>
            Are you sure you want to delete <b>{deleteDialog?.name}</b>?
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Drivers;
