import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useTrips({
  page,
  assetType,
  filterBy,
  pageSize,
}: {
  page: number;
  assetType: string | null;
  filterBy?: any;
  pageSize: number;
}): UseQueryResult {
  return useQuery(
    ['trips', page, assetType, filterBy],
    ({ signal }) =>
      apiClient.get('/reports/trips', {
        signal,
        params: {
          page,
          pageSize,
          assetType,
          ...filterBy,
        },
      }),
    {
      enabled: !!filterBy.startTimestamp,
    }
  );
}

export function useTripReports(
  tripId: number | string | undefined,
  includeAllReportTypes: boolean,
  enabled: boolean
): UseQueryResult {
  return useInfiniteQuery({
    queryKey: ['trips', tripId, includeAllReportTypes],
    queryFn: ({ pageParam = 0, signal }) =>
      apiClient.get(`/reports/trips/${tripId}/reports`, {
        signal,
        params: { page: pageParam + 1, includeAllReportTypes },
      }),
    enabled: !!enabled && !!tripId,
    getNextPageParam: (lastPage: any, pages: any) => {
      return lastPage?.reports?.length > 0 ? lastPage.page : undefined;
    },
  });
}

export function useAllTripReports(
  tripId: number | string | undefined,
  enabled: boolean
): UseQueryResult {
  return useQuery({
    queryKey: ['trips', tripId],
    queryFn: ({ signal }: any) =>
      apiClient.get(`/reports/trips/${tripId}/reports`, {
        signal,
        params: {
          page: 1,
          pageSize: -1,
          includeAllReportTypes: true,
          sortOrder: 'ASC',
        },
      }),
    enabled: !!enabled && !!tripId,
  });
}

export function useExportReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'trip_export',
    (variables: any) =>
      apiClient.post(`reports/trips/${variables.tripId}/export`, {
        includeAllReportTypes: variables.includeAllReportTypes,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('trip_export');
      },
    }
  );
}

export function useExportTripsReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'trips_export',
    (variables: any) =>
      apiClient.post(`reports/trips/export`, {
        ...variables,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('trips_export');
      },
    }
  );
}

export function useTrip(tripId: any): UseQueryResult {
  return useQuery(['trip', tripId], ({ signal }) =>
    apiClient.get(`/reports/trips/${tripId}`, { signal })
  );
}

export function useTripLocations(
  tripId: any,
  enabled: boolean
): UseQueryResult {
  return useQuery(
    ['tripLocations', tripId],
    ({ signal }) =>
      apiClient.get(`/reports/trips/${tripId}/locations`, { signal }),
    {
      enabled,
    }
  );
}
