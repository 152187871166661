import moment from 'moment-timezone';

const getTimeZoneOptions = function () {
  const deprecatedTimeZones = ["UCT", "PST8PDT", "GB", "MST7MDT", "EST5EDT", "W-SU", "CST6CDT", "HST", "MST", "Universal", "EET", "WET", "EST", "CET", "MET", "GMT", "Etc"];
  const deprecatedTimeZonesRegex = `^${deprecatedTimeZones.join("|^")}`;

  const allowedTimeZones = moment.tz.names()
      .filter(timezone => timezone.startsWith("A") || !new RegExp(deprecatedTimeZonesRegex).test(timezone))
      .sort((timezoneA, timezoneB) => timezoneA.localeCompare(timezoneB))
      .map(timezone => timezone);

      const offsetTmz = [];
  for (let i in allowedTimeZones) {
    const tz = allowedTimeZones[i];
    const tzOffset = moment.tz(tz).format('Z');
    const value = parseInt(tzOffset
      .replace(':00', '.00')
      .replace(':15', '.25')
      .replace(':30', '.50')
      .replace(':45', '.75')).toFixed(2);
    const timeZoneOption = {
      label: `(UTC${tzOffset}) ${tz}`,
      value: tz,
      offset: value
    };
    offsetTmz.push(timeZoneOption);
  }
  offsetTmz.sort((a: any, b: any) => a.offset - b.offset);
  return offsetTmz;
};

export default getTimeZoneOptions;