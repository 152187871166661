import { SvgIcon } from '@mui/material';
import React from 'react';

const ContainerIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return (
    <SvgIcon sx={{ color }} fontSize={'medium'}>
      <path
        fillRule='evenodd'
        d='m9.405 4.37 14.38 2.615c.098.02.195.078.195.195v9.522c0 .118-.097.176-.195.196l-14.38 2.634c-.117.02-.195-.098-.195-.195V4.566c0-.117.078-.215.195-.195zM.137 5.679l1.95-.332v13.19l-1.95-.331c-.078-.02-.137-.059-.137-.137V5.815c0-.078.059-.137.137-.137zm2.712-.468 2.224-.371v14.205l-2.224-.37zm2.985-.508 2.556-.429c.078 0 .137.059.137.137v15.044c0 .078-.059.156-.137.136l-2.556-.429zm17.19 3.122v8.196h.254V7.824zM10.478 5.951v11.961h.761V6.185zm2.146.332V17.6h.703V6.38zm1.913.273v10.712h.643V6.673zm1.717.254v10.244h.566V6.927zm1.6.273v9.697h.507V7.2zm1.463.176v9.287h.449v-9.19zm1.327.214v8.898h.37V7.59zm1.249.176v8.527h.312V7.805z'
        style={{ fill: color }}
      />
    </SvgIcon>
  );
};

export default ContainerIcon;
