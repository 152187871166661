import { Link, LinkOff } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import CardComponent from 'components/Card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getCardIcon = ({
  configured,
  error,
  t,
  isLoading,
}: {
  configured: boolean;
  error: any;
  t: any;
  isLoading: boolean;
}) => {
  if (isLoading)
    return (
      <CircularProgress
        size={12}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: 'rgba(255,255,255,0.5)',
        }}
      />
    );
  if (!configured)
    return (
      <LinkOff
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: 'rgba(255,255,255,0.5)',
        }}
      />
    );

  if (!!error) {
    const errorColor = (() => {
      if ('auth_error' === error) return '#ff5b5b';
      return '#ffbf00';
    })();
    return (
      <Tooltip
        title={
          <div>
            <b>{t('integrations.headline')}</b>
            <div>{t(`integrations.error.${error}`)}</div>
          </div>
        }
        placement='top'>
        <LinkOff
          sx={{ position: 'absolute', top: 10, right: 10, color: errorColor }}
        />
      </Tooltip>
    );
  }

  return (
    <Link sx={{ position: 'absolute', top: 10, right: 10, color: '#00b300' }} />
  );
};

const IntegrationCard = ({
  Image,
  title,
  description,
  url,
  button,
  imgBackground,
  onClick,
  configured,
  error,
  isLoading,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CardComponent
      Image={Image}
      title={title}
      description={description}
      button={button ? button : configured ? t('update') : t('connect')}
      imgBackground={imgBackground}
      onClick={onClick}
      topRightIcon={getCardIcon({ configured, error, t, isLoading })}
    />
  );
};

export default IntegrationCard;
