import React, { useEffect } from 'react';
import SnackbarAlert from 'components/SnackbarAlert';
import useUIContext from 'context/UIContext';

const FlashMessages = () => {
  const [message, setMessage] = React.useState<any | null>({
    severity: 'success',
  });
  const { flashMessage, setFlashMessage } = useUIContext();

  useEffect(() => {
    setMessage(flashMessage);
    if (flashMessage) {
      setTimeout(() => {
        resetMessage();
      }, 3000);
    }
  }, [flashMessage]);

  const resetMessage = () => {
    setFlashMessage(null);
    setMessage(null);
  };

  if (!message) return <></>;

  return (
    <SnackbarAlert
      severity={message?.severity}
      message={message?.message}
      onClose={(event?: React.SyntheticEvent | Event, reason?: string) =>
        resetMessage()
      }
    />
  );
};

export default FlashMessages;
