import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id: id,
    type: 'circle',
    source: source,
    filter: ['has', 'point_count'],
    paint: {
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 1,
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      // 'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
      // 'circle-color': '#00a8ff',
      'circle-color': [
        'case',
        ['>', ['get', 'activeAlert'], 0],
        '#ff5b5b',
        '#00a8ff',
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        10,
        12,
        25,
        15,
        100,
        17,
        250,
        20,
        500,
        22,
        1000,
        25,
      ],
    },
  };
};
