import {
  Box,
  MenuItem,
  TextField,
  Typography,
  capitalize,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule } from 'rrule';
import { CalendarMonthView } from './CalendarMonthView';

type ScheduleType =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'firstDayOfMonth'
  | 'lastDayOfMonth'
  | 'firstMondayOfMonth'
  | 'businessDays'
  | 'customInterval';

interface Schedule {
  type: ScheduleType | '';
  dayOfWeek: string;
  dayOfMonth: string;
  hours: string;
  minutes: string;
  intervalValue: string;
  intervalUnit: string;
  timeZone: string;
}

const daysOfWeek = [
  { label: 'Monday', value: 'MO' },
  { label: 'Tuesday', value: 'TU' },
  { label: 'Wednesday', value: 'WE' },
  { label: 'Thursday', value: 'TH' },
  { label: 'Friday', value: 'FR' },
  { label: 'Saturday', value: 'SA' },
  { label: 'Sunday', value: 'SU' },
];

const daysOfMonth = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
const hours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0')
);
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0')
);
const timeZones = moment.tz.names();

const ScheduleRRuleConverter = ({
  setScheduleText,
  setRruleString,
  setScheduleType,
  scheduledReportData,
}: {
  setScheduleText: Function;
  setRruleString: Function;
  setScheduleType: Function;
  scheduledReportData?: any;
}) => {
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState<Schedule>({
    type: 'firstDayOfMonth',
    dayOfWeek: 'MO',
    dayOfMonth: '1',
    hours: '09',
    minutes: '00',
    intervalValue: '2',
    intervalUnit: 'weeks',
    timeZone: moment.tz.guess(),
  });

  const [rruleString, setRRuleString] = useState<string>('');

  useEffect(() => {
    convertToRRule();
  }, [schedule]);

  useEffect(() => {
    if (!!scheduledReportData) {
      if (
        !scheduledReportData.rrule ||
        !RRule.fromString(scheduledReportData.rrule)
      ) {
        return;
      }
      const serverRRule = RRule.fromString(scheduledReportData.rrule);
      setScheduleType(scheduledReportData.scheduleType);
      setSchedule({
        ...schedule,
        type: scheduledReportData.scheduleType,
        dayOfWeek:
          ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][
            serverRRule.options.byweekday?.[0]
          ] || 'MO',
        dayOfMonth: serverRRule.options.bymonthday
          ? serverRRule.options.bymonthday?.[0]?.toString()
          : '1',
        hours: serverRRule.options.dtstart
          .getHours()
          .toString()
          .padStart(2, '0'),
        minutes: '00',
        intervalValue: serverRRule.options.interval?.toString() ?? '1',
        intervalUnit: Frequency[serverRRule.options.freq],
      });
    }
  }, [scheduledReportData]);

  useEffect(() => {
    if (!!RRule.fromString(rruleString) && schedule.hours && schedule.minutes) {
      const localTime = moment.tz(
        { hour: parseInt(schedule.hours), minute: parseInt(schedule.minutes) },
        schedule.timeZone
      );
      setRruleString(rruleString);
      setScheduleText(
        `${RRule.fromString(rruleString).toText()} at ${localTime
          .hours()
          .toString()
          .padStart(2, '0')}:${localTime
          .minutes()
          .toString()
          .padStart(2, '0')} (${schedule.timeZone})`
      );
    } else {
      setScheduleText('');
    }
  }, [rruleString, schedule]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setSchedule(prev => ({ ...prev, [name]: value }));
  };

  const convertToRRule = () => {
    const {
      type,
      dayOfWeek,
      dayOfMonth,
      hours,
      minutes,
      intervalValue,
      intervalUnit,
      timeZone,
    } = schedule;
    const localTime = moment.tz(
      { hour: parseInt(hours), minute: parseInt(minutes) },
      timeZone
    );
    const utcTime = localTime.clone().tz('UTC');

    let rule: RRule | null = null;

    if (!type) {
      setRRuleString('');
      return;
    }
    if ('weekly' === type && !dayOfWeek) {
      setRRuleString('');
      return;
    }
    if ('monthly' === type && !dayOfMonth) {
      setRRuleString('');
      return;
    }

    switch (type) {
      case 'daily':
        rule = new RRule({
          freq: RRule.DAILY,
          dtstart: utcTime.toDate(),
        });
        break;
      case 'weekly':
        rule = new RRule({
          freq: RRule.WEEKLY,
          // @ts-ignore
          byweekday: [RRule[dayOfWeek.toUpperCase()]],
          dtstart: utcTime.toDate(),
        });
        break;
      case 'monthly':
        rule = new RRule({
          freq: RRule.MONTHLY,
          bymonthday: parseInt(dayOfMonth) >= 29 ? -1 : parseInt(dayOfMonth),
          dtstart: utcTime.toDate(),
        });
        break;
      case 'firstDayOfMonth':
        rule = new RRule({
          freq: RRule.MONTHLY,
          bymonthday: 1,
          dtstart: utcTime.toDate(),
        });
        break;
      case 'lastDayOfMonth':
        rule = new RRule({
          freq: RRule.MONTHLY,
          bymonthday: -1,
          dtstart: utcTime.toDate(),
        });
        break;
      case 'firstMondayOfMonth':
        rule = new RRule({
          freq: RRule.MONTHLY,
          byweekday: [RRule.MO.nth(1)],
          dtstart: utcTime.toDate(),
        });
        break;
      case 'businessDays':
        rule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
          dtstart: utcTime.toDate(),
        });
        break;
      case 'customInterval':
        const interval = parseInt(intervalValue);
        const freqMap = {
          days: RRule.DAILY,
          weeks: RRule.WEEKLY,
          months: RRule.MONTHLY,
        };
        rule = new RRule({
          freq: freqMap[intervalUnit as keyof typeof freqMap],
          interval,
          dtstart: utcTime.toDate(),
        });

        if (intervalUnit === 'weeks') {
          rule.options.byweekday = [parseInt(dayOfWeek)];
        } else if (intervalUnit === 'months') {
          rule.options.bymonthday = [parseInt(dayOfMonth)];
        }
        break;
      default:
        rule = null;
    }

    if (rule) {
      setRRuleString(rule.toString());
    }
  };

  // const loadScheduleFromDB = (preset: any, rrule: any, userTimeZone: any) => {
  //   const rule = rrulestr(rrule);
  //   const nextOccurrence = rule.after(new Date(), true);
  //   const localTime = moment.tz(nextOccurrence, 'UTC').tz(userTimeZone);

  //   setSchedule(prev => ({
  //     ...prev,
  //     type: preset,
  //     hours: localTime.hours().toString().padStart(2, '0'),
  //     minutes: localTime.minutes().toString().padStart(2, '0'),
  //     timeZone: userTimeZone,
  //   }));
  //   setRRuleString(rrule);
  // };

  // useEffect(() => {
  //   const presetFromDB = 'firstMondayOfMonth';
  //   const rruleFromDB = 'FREQ=MONTHLY;BYDAY=1MO;DTSTART=20240101T140000Z';
  //   const userTimeZone = moment.tz.guess();

  //   loadScheduleFromDB(presetFromDB, rruleFromDB, userTimeZone);
  // }, []);

  return (
    <Box>
      {t('Select report schedule:')}
      <TextField
        select
        label='Schedule Type'
        name='type'
        value={schedule.type}
        onChange={event => {
          handleChange(event);
          setScheduleType(event.target.value as ScheduleType);
        }}
        fullWidth
        margin='normal'>
        <MenuItem value='daily'>{t('Daily')}</MenuItem>
        <MenuItem value='weekly'>{t('Weekly')}</MenuItem>
        <MenuItem value='monthly'>{t('Monthly')}</MenuItem>
        <MenuItem value='firstDayOfMonth'>
          {t('First Day of every Month')}
        </MenuItem>
        <MenuItem value='lastDayOfMonth'>
          {t('Last Day of every Month')}
        </MenuItem>
        <MenuItem value='firstMondayOfMonth'>
          {t('First Monday of every Month')}
        </MenuItem>
        <MenuItem value='businessDays'>{t('Every Business Day')}</MenuItem>
        {/* <MenuItem value='customInterval'>{t('Custom Interval')}</MenuItem> */}
      </TextField>
      {schedule.type === 'weekly' && (
        <TextField
          select
          label={t('Day of Week')}
          name='dayOfWeek'
          value={schedule.dayOfWeek}
          onChange={handleChange}
          fullWidth
          margin='normal'>
          {daysOfWeek.map(day => (
            <MenuItem key={day.value} value={day.value}>
              {day.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {schedule.type === 'monthly' && (
        <Box>
          <Typography variant='h6' gutterBottom>
            {t('Please select the Day of the Month:')}
          </Typography>
          <CalendarMonthView onChange={handleChange} />
        </Box>
      )}
      {schedule.type === 'customInterval' && (
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <TextField
            select
            label={t('Interval Unit')}
            name='intervalUnit'
            value={schedule.intervalUnit}
            onChange={handleChange}
            fullWidth
            margin='normal'>
            <MenuItem value='days'>{t('Days')}</MenuItem>
            <MenuItem value='weeks'>{t('Weeks')}</MenuItem>
            <MenuItem value='months'>{t('Months')}</MenuItem>
          </TextField>
          <TextField
            label={`Every ${schedule.intervalValue} ${
              parseInt(schedule.intervalValue) > 1
                ? schedule.intervalUnit
                : schedule.intervalUnit.slice(0, -1)
            }`}
            name='intervalValue'
            type='number'
            value={schedule.intervalValue}
            onChange={handleChange}
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            margin='normal'
          />
        </Box>
      )}
      {(schedule.type === 'daily' ||
        schedule.type === 'weekly' ||
        schedule.type === 'monthly' ||
        schedule.type === 'firstDayOfMonth' ||
        schedule.type === 'lastDayOfMonth' ||
        schedule.type === 'firstMondayOfMonth' ||
        schedule.type === 'businessDays' ||
        schedule.type === 'customInterval') && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mt: 0,
            alignItems: 'center',
            my: 2,
          }}>
          <Box display='flex' alignItems='center' textAlign={'right'}>
            {capitalize(RRule.fromString(rruleString).toText())}
            {` ${'at'} `}
          </Box>
          <TextField
            select
            variant='standard'
            name='hours'
            value={schedule.hours}
            onChange={handleChange}>
            {hours.map(hour => (
              <MenuItem key={hour} value={hour}>
                {hour}:00
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
      <TextField
        select
        label={t('Time Zone')}
        name='timeZone'
        value={schedule.timeZone}
        onChange={handleChange}
        fullWidth
        margin='normal'>
        {timeZones.map(tz => (
          <MenuItem key={tz} value={tz}>
            {tz.replaceAll('_', ' ')}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default ScheduleRRuleConverter;
