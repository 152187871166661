import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { useDashboardWidget } from 'hooks';
import moment from 'moment';
import React from 'react';
import {
  Area,
  Legend,
  Line,
  ComposedChart as RechartsComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const ContainerLineChart = ({
  assetType,
  widgetType,
  chartUnit,
  showTotal = true,
  startTimestamp,
  endTimestamp,
  assetId,
  interval = 0,
  includeAllReports = false,
}: {
  assetType: string;
  widgetType: string;
  chartUnit: string;
  showTotal?: boolean;
  startTimestamp?: Date;
  endTimestamp?: Date;
  assetId?: number;
  interval?: number;
  includeAllReports?: boolean;
}) => {
  const DATA_KEY_TEMP_INT = 'internalTemp';
  const DATA_KEY_TEMP_EXT = 'ambientTemp';
  const DATA_KEY_HUMIDITY = 'internalHumidity';
  const DATA_DOOR_OPEN = 'doorOpen';
  const config: any = {
    [DATA_KEY_TEMP_INT]: {
      name: 'Internal Temperature',
      unit: '°C',
      legend: 'Internal Temperature',
      color: '#4BA5F8',
    },
    [DATA_KEY_TEMP_EXT]: {
      name: 'Ambient Temperature',
      unit: '°C',
      legend: 'Ambient Temperature',
      color: '#4BA5F8',
    },
    [DATA_KEY_HUMIDITY]: {
      name: 'Internal Humidity',
      unit: '%',
      legend: 'Internal Humidity',
      color: '#ffbf00',
    },
    [DATA_DOOR_OPEN]: {
      name: 'Door Status',
      unit: '',
      legend: 'Door Status',
      color: '#373737',
      stroke: '#CCC',
      formatValue: (value: any) => (value ? 'Open' : 'Closed'),
      enabled: !!includeAllReports,
    },
  };

  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    assetId: assetId,
    includeAllReports: includeAllReports,
  }) as any;

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card variant='outlined'>
          <CardContent>
            <Typography>
              {moment(label).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            {payload.map((item: any, index: number) => {
              if (item.dataKey === 'doorOpen' && item.fill !== 'transparent') {
                return null;
              }
              let color = item.stroke;
              if (color === 'transparent') {
                color = config[item.name].stroke;
              }
              if (!color || color === 'transparent') {
                return null;
              }

              if (config[item.name].enabled === false) {
                return null;
              }

              return (
                <Typography
                  key={`${item.name}_${index}`}
                  variant='body2'
                  sx={{ color: color, mb: 0.4 }}>
                  <b>{config[item.name].name}:</b>{' '}
                  <span>
                    {config[item.name].formatValue
                      ? config[item.name].formatValue(item.value)
                      : item.value}
                    {config[item.name].unit}
                  </span>
                </Typography>
              );
            })}
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  function splitData(
    data: any[],
    key: 'internalTemp' | 'ambientTemp' | 'internalHumidity'
  ): any[][] {
    const continuousSegments: any[][] = [];
    let currentSegment: any[] = [];

    data.forEach(item => {
      if (item[key] === 0) {
        if (currentSegment.length > 0) {
          continuousSegments.push(currentSegment);
          currentSegment = [];
        }
      } else {
        currentSegment.push({
          startTime: item.startTime,
          [key]: item[key],
        });
      }
    });

    if (currentSegment.length > 0) {
      continuousSegments.push(currentSegment);
    }

    return continuousSegments;
  }

  function processDoorData(data: any[]): any[] {
    const key = 'doorOpen';
    let lastValue: any = null;

    data.forEach(item => {
      if (item[key] === null) {
        item.doorOpen = lastValue === true ? 100 : 0;
      } else {
        lastValue = item[key];
        item.doorOpen = item[key] ? 100 : 0;
      }
    });

    return data;
  }

  if (!data || isLoading)
    return <Skeleton variant='rounded' width={'100%'} height={'100%'} />;

  const formattedData = data?.data.map((item: any) => ({
    ...item,
    startTime: moment(item.startTime).toDate().getTime(),
    endTime: moment(item.endTime).toDate().getTime(),
    name: item.tripDate,
    unit: chartUnit,
  }));

  const internalSegments = splitData(formattedData, DATA_KEY_TEMP_INT);
  const externalSegments = splitData(formattedData, DATA_KEY_TEMP_EXT);
  const humiditySegments = splitData(formattedData, DATA_KEY_HUMIDITY);
  const doorData = processDoorData(formattedData);

  const CustomLegend: React.FC<any> = ({ payload, ...props }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {payload.map((entry: any, index: any) => {
          if (config[entry.id].enabled === false) {
            return null;
          }
          return (
            <Box
              key={`item-${index}`}
              sx={{
                listStyle: 'none',
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                color: entry.color ?? 'red',
                fontSize: '.9rem',
              }}>
              <Box
                sx={{
                  display: 'inline-block',
                  width: '10px',
                  height: '2px',
                  backgroundColor: entry.color ?? 'red',
                  marginRight: '5px',
                }}></Box>
              {entry.value}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
      }}>
      <ResponsiveContainer
        width={`${showTotal ? '80%' : '100%'}`}
        height='100%'>
        <RechartsComposedChart data={formattedData}>
          <XAxis
            padding={{ left: 10, right: 10 }}
            tickCount={-1}
            tickSize={0}
            tick={false}
            height={10}
            dataKey='startTime'
            allowDuplicatedCategory={false}
            type='number'
            scale='time'
            interval={10}
            domain={[
              moment(formattedData[0].startTime).toDate().getTime(),
              moment(formattedData[formattedData.length - 1].startTime)
                .toDate()
                .getTime(),
            ]}
          />
          <YAxis
            yAxisId='left'
            scale='linear'
            unit={config[DATA_KEY_TEMP_INT].unit}
            interval={0}
            type='number'
            domain={[
              (dataMin: number) => Math.min(0, Math.floor(dataMin / 5) * 5),
              (dataMax: number) => Math.ceil(dataMax / 5) * 5,
            ]}
            tick={{ fontSize: 11, fill: config[DATA_KEY_TEMP_INT].color }}
            tickLine={{ stroke: config[DATA_KEY_TEMP_INT].color }}></YAxis>
          <YAxis
            tick={{ fontSize: 12, fill: config[DATA_KEY_HUMIDITY].color }}
            tickLine={{ stroke: config[DATA_KEY_HUMIDITY].color }}
            orientation='right'
            type='number'
            domain={[0, 100]}
            unit={config[DATA_KEY_HUMIDITY].unit}
            yAxisId='right'></YAxis>
          <Tooltip
            wrapperStyle={{ zIndex: 1000 }}
            content={<CustomTooltip />}
            filterNull={false}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          <Legend
            verticalAlign='bottom'
            margin={{ top: 10, right: 0, left: 0, bottom: 15 }}
            payload={[
              {
                value: config[DATA_KEY_TEMP_INT].name,
                type: 'line',
                color: config[DATA_KEY_TEMP_INT].color,
                id: DATA_KEY_TEMP_INT,
              },
              {
                value: config[DATA_KEY_TEMP_EXT].name,
                type: 'line',
                color: config[DATA_KEY_TEMP_EXT].color,
                id: DATA_KEY_TEMP_EXT,
              },
              {
                value: config[DATA_KEY_HUMIDITY].name,
                type: 'line',
                color: config[DATA_KEY_HUMIDITY].color,
                id: DATA_KEY_HUMIDITY,
              },
            ]}
            content={<CustomLegend />}
          />
          <Area
            key={`doorOpen`}
            yAxisId='right'
            type='stepAfter'
            fill={config[DATA_DOOR_OPEN].color}
            dot={false}
            stroke='transparent'
            dataKey={'doorOpen'}
            data={doorData}
          />
          {internalSegments.map((segment, index) => (
            <Line
              yAxisId='left'
              type='monotone'
              dataKey={DATA_KEY_TEMP_INT}
              data={segment}
              dot={false}
              // activeDot={false}
              key={`pressure-${index}`}
              stroke={config[DATA_KEY_TEMP_INT].color}
            />
          ))}
          {externalSegments.map((segment, index) => (
            <Line
              yAxisId='left'
              type='monotone'
              dataKey={DATA_KEY_TEMP_EXT}
              data={segment}
              dot={false}
              // activeDot={false}
              key={`temperature-${index}`}
              stroke={config[DATA_KEY_TEMP_EXT].color}
              strokeDasharray='3 3 3'
            />
          ))}
          {humiditySegments.map((segment, index) => (
            <Line
              yAxisId='right'
              type='monotone'
              dataKey={DATA_KEY_HUMIDITY}
              data={segment}
              dot={false}
              // activeDot={false}
              key={`humidity-${index}`}
              stroke={config[DATA_KEY_HUMIDITY].color}
            />
          ))}

          <Line
            yAxisId='left'
            type='monotone'
            dataKey={DATA_KEY_TEMP_INT}
            data={formattedData}
            dot={false}
            activeDot={false}
            key={`tooltip-line-temp`}
            stroke='transparent'
          />
          <Line
            yAxisId='right'
            type='monotone'
            dataKey={DATA_KEY_HUMIDITY}
            data={formattedData}
            dot={false}
            activeDot={false}
            key={`tooltip-line-humidity`}
            stroke='transparent'
          />
          <Line
            yAxisId='right'
            type='stepAfter'
            dataKey={DATA_DOOR_OPEN}
            data={doorData}
            dot={false}
            fill='transparent'
            activeDot={false}
            key={`tooltip-line-door`}
            stroke='transparent'
          />
        </RechartsComposedChart>
      </ResponsiveContainer>
      {showTotal && (
        <div style={{ width: '20%', alignSelf: 'center', textAlign: 'center' }}>
          {data?.total && (
            <div style={{ fontSize: '4vh' }}>
              {data.total?.toLocaleString()}
            </div>
          )}
          <div>{chartUnit.charAt(0).toUpperCase() + chartUnit.slice(1)}</div>
        </div>
      )}
    </div>
  );
};

export default ContainerLineChart;
