import useUIContext from 'context/UIContext';
import { useTranslation } from 'react-i18next';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';
import { Driver } from 'types';
import { DriverEvent } from 'types/Driver';

export function useDrivers(vars: {
  page?: number,
  pageSize?: number,
  searchTerm?: string,
  groupId?: number,
  filterBy?: any;
}): UseQueryResult {
  const { page, searchTerm, pageSize, groupId, filterBy }: any = vars;
  return useQuery(['drivers', page, searchTerm, groupId, filterBy], ({ signal }) =>
    apiClient.get('drivers', { signal, params: { page, pageSize, searchTerm: searchTerm, groupId,...filterBy } })
  );
}

export function useDriver(variables?: { id: number }): UseQueryResult {
  return useQuery(
    ['drivers', variables],
    () => apiClient.get(`drivers/${variables?.id}`),
    {
      enabled: !!variables?.id,
      staleTime: 0,
    }
  );
}

export function useAddDriver(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'drivers',
    (variables: Driver) =>
      apiClient.post('drivers', {
        firstName: variables.firstName,
        lastName: variables.lastName,
        identifier: variables.identifier,
        groupId: variables.group?.groupId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('drivers');
      },
    }
  );
}

export function useEditDriver(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'drivers',
    (variables: DriverEvent) =>
      apiClient.put(`drivers/${variables?.driverId}`, variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('drivers');
      },
    }
  );
}

export function useDeleteDriver(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'drivers',
    (variables: any) => apiClient.delete(`drivers/${variables.id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('drivers');
      },
    }
  );
}


export function useDriverScorecardSettings(): UseQueryResult {
  return useQuery('driverScorecardSettings', () =>
    apiClient.get('driver-behavior')
  );
}

export function useUpdateDriverScorecardSettings(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'driverScorecardSettings',
    (variables: Driver) => apiClient.put('driver-behavior', variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('driverScorecardSettings');
      },
    }
  );
}

export function useDriverScorecard(variables?: { 
  id: number, 
  startTimestamp: Date|null 
}): UseQueryResult {
  return useQuery(
    ['driverScorecard', variables],
    () => apiClient.get(`drivers/${variables?.id}/behavior`, { params: { startTimestamp: variables?.startTimestamp } }),
    {
      enabled: !!variables?.id,
      staleTime: 0,
      onSettled(data, error) {
        return data;
      },
    }
  );
}

export default function useDriverPictureUploader() {
  const queryClient = useQueryClient();
  const { setFlashMessage } = useUIContext();
  const { t } = useTranslation();
  return useMutation(
    async ({ request, setFileUploadCompleted }: any) => {
      return await uploadFile({ request, setFileUploadCompleted });
    },
    {
      onError: (e: any) => {
        setFlashMessage({ message: t(`errors.${e.message}`), severity: 'error' });
      },
      onSuccess: () => {
        setFlashMessage({
          message: 'Picture updated successfully',
          severity: 'success',
        });
        queryClient.invalidateQueries('drivers');
      },
    }
  );
}

export const uploadFile = async ({ request, setFileUploadCompleted }: any) => {
  const url = `drivers/${request.id}/picture`;

  const config = {
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setFileUploadCompleted(percentCompleted);
    },
  };

  const data = new FormData();
  data.append('file', request.file);

  try {
    const res = await apiClient.put(url, data, config);
    return res;
  } catch (error) {
    // @ts-ignore
    const errorMessage = error?.response?.data?.message;
    throw new Error(errorMessage);
  }
};
