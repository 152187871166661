import React, { useEffect } from 'react';
import mapboxgl, { GeoJSONSource, IControl, LngLatLike } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './map.scss';
import DrawLayerStyle from './constants/layers/DrawLayerStyle';
import MapCustomControls from './controls/MapCustomControls';
import DrawLocationControl from './controls/DrawLocationControl';
import ZoomInControl from './controls/ZoomInControl';
import ZoomOutControl from './controls/ZoomOutControl';
/* @ts-ignore */
import FreehandMode from 'mapbox-gl-draw-freehand-mode';
import { Source } from './constants';

interface IProps {
  center: LngLatLike;
  layers?: mapboxgl.AnyLayer[] | null;
  sources?: Source[] | null;
  images?: any[];
  width?: string;
  height?: string;
  onClick?: any;
  zoom?: number;
  onLoad?: Function;
  onDraw?: Function;
  bounds?: any;
  drawControl?: boolean;
  popup?: any;
  style?: any;
  controlStyles?: any;
  drawMode?: string | null;
  onDrawModeEntered?: Function;
  onDrawModeExited?: Function;
  onDrawModeChanged?: Function;
  resetDrawnFeatures?: boolean;
}

const Draw = new MapboxDraw({
  defaultMode: 'simple_select',
  // @ts-ignore
  modes: Object.assign(
    {
      draw_freehand: FreehandMode,
    },
    MapboxDraw.modes
  ),
  userProperties: true,
  styles: DrawLayerStyle,
});

let customMapControl: IControl;

function Map({
  center,
  width = '100%',
  height = '100%',
  layers,
  sources,
  images,
  onClick,
  zoom = 9,
  onLoad,
  onDraw,
  bounds,
  drawControl = false, // TODO: implement this or clean it up
  popup,
  style,
  controlStyles,
  drawMode,
  onDrawModeEntered, // TODO: implement this
  onDrawModeExited, // TODO: implement this
  onDrawModeChanged,
  resetDrawnFeatures = false,
}: IProps) {
  const mapContainerRef = React.useRef<any>(null);
  const popupRef = React.useRef<HTMLDivElement>();
  const [map, setMap] = React.useState<mapboxgl.Map | null>(null);
  const [isStyleLoaded, setIsStyleLoaded] = React.useState<boolean>(false);
  const [isDrawAttached, setIsDrawAttached] = React.useState<boolean>(false);
  const [imagesLoadedCounter, setImagesLoadedCounter] =
    React.useState<number>(0);

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY!;

  const changeDrawMode = (mode: string) => {
    if (onDrawModeChanged) onDrawModeChanged(mode);
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/dark-v11',
      center,
      zoom,
      dragRotate: false,
    });
    setMap(map);
  }, []);

  useEffect(() => {
    map?.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    map?.setCenter(center);
  }, [center]);

  useEffect(() => {
    if (bounds) map?.fitBounds(bounds);
  }, [bounds]);

  // useEffect(() => {
  //   if (!resetDrawnFeatures) return;
  //   if (!Draw) return;
  //   if (!map) return;

  //   removeCustomMapControl(map);
  //   addCustomMapControl(map, true);
  //   setTimeout(() => {
  //     // FIXME: this is a hack to delete all drawn features
  //     Draw.deleteAll();
  //   }, 0);
  // }, [resetDrawnFeatures]);

  // useEffect(() => {
  //   if (!map || !popup || !popupRef.current) return;

  //   const p = new mapboxgl.Popup({
  //     closeOnClick: true,
  //     closeOnMove: false,
  //     closeButton: true,
  //     anchor: popup.anchor,
  //     offset: [0, 8],
  //   })
  //     .setDOMContent(popupRef.current)
  //     .setLngLat(popup.lngLat)
  //     .addTo(map);
  // }, [map, popup, popupRef]);

  // const isFeaturePoint = (feature: any) => {
  //   return feature && feature.geometry && feature.geometry.type === 'Point';
  // };

  const drawModeClicked = () => {
    if (!map) return;
    removeCustomMapControl(map);
    addCustomMapControl(map, false);
    changeDrawMode('draw_point');
  };

  const addCustomMapControl = (map: mapboxgl.Map, enabled: boolean = true) => {
    const divider = document.createElement('hr');
    divider.className = 'mapboxgl-controls-custom-divider';
    // TODO: MapCustomControls probably needs some refactoring to be more extensible and flexible
    customMapControl = new MapCustomControls(
      [
        ...(enabled
          ? [DrawLocationControl(null, enabled, drawModeClicked), divider]
          : []),
        ZoomInControl(map),
        ZoomOutControl(map),
      ],
      controlStyles?.bottomRight
    );

    map.addControl(customMapControl, 'bottom-right');
  };

  const removeCustomMapControl = (map: mapboxgl.Map) => {
    if (customMapControl) {
      map.removeControl(customMapControl);
    }
  };

  useEffect(() => {
    if (!map) return;

    map.on('load', () => {
      map?.resize();
      addImages();
      if (onLoad) onLoad(map);
      setIsStyleLoaded(true);
      //     if (!isDrawAttached) {
      //       setIsDrawAttached(true);
      //       map.addControl(Draw, 'top-left');
      //       addCustomMapControl(map);
      //       map.on('draw.create', e => {
      //         let { features } = e;
      //         features.map((feature: any) => {
      //           if (isFeaturePoint(feature)) {
      //             const { geometry } = feature;
      //             feature.projection = map.project(geometry.coordinates);
      //           }
      //         });
      //         if (onDraw) onDraw('create', features, Draw.getAll());
      //       });
      //       map.on('draw.update', e => {
      //         if ('move' === e.action) {
      //           let { features } = e;
      //           if (onDraw) onDraw('move', features, Draw.getAll());
      //         }
      //       });
      //       map.on('draw.delete', e => {
      //         let { features } = e;
      //         features.map((feature: any) => {
      //           if (isFeaturePoint(feature)) {
      //             changeDrawMode('simple_select');
      //             removeCustomMapControl(map);
      //             addCustomMapControl(map);
      //             setTimeout(() => {
      //               Draw.deleteAll();
      //             }, 0);
      //           }
      //         });
      //         if (onDraw) onDraw('delete', features, Draw.getAll());
      //       });
      //     }
    });
    //   map.on('click', e => {
    //     const features = map.queryRenderedFeatures(e.point);
    //     if (onClick) onClick(e, features);
    //   });
  }, [map]);

  // useEffect(() => {
  //   const el = document.querySelector(
  //     '.mapboxgl-ctrl-bottom-right .mapboxgl-control-custom-controls'
  //   ) as HTMLElement;
  //   if (el) {
  //     el.style.cssText = controlStyles.bottomRight;
  //     el.style.transitionDuration = '0.2s';
  //   }
  // }, [controlStyles]);

  // useEffect(() => {
  //   if (!map || !isStyleLoaded || !isDrawAttached || !drawMode) return;
  //   /* @ts-ignore */
  //   Draw.changeMode(drawMode);
  // }, [map, isStyleLoaded, isDrawAttached, drawMode]);

  const addImages = () => {
    if (
      map &&
      images &&
      images.length > 0 &&
      imagesLoadedCounter !== images.length
    ) {
      images.map(image => {
        if (!map.hasImage(image.id)) {
          try {
            map.loadImage(image.src, (err, img: any) => {
              map.addImage(image.id, img);
              setImagesLoadedCounter(imagesLoadedCounter + 1);
            });
          } catch (e: any) {}
        }
      });
    }
  };

  const upsertSources = () => {
    if (map && sources && sources.length > 0 && isStyleLoaded) {
      sources.map(source => {
        if (!map.getSource(source.id)) {
          try {
            map.addSource(source.id, source.data);
          } catch (e: any) {}
        } else {
          (map.getSource(source.id) as GeoJSONSource).setData(source.data.data);
        }
      });
      addLayers();
    }
  };

  const addLayers = () => {
    if (
      map &&
      layers &&
      layers.length > 0 &&
      sources &&
      sources.length > 0 &&
      isStyleLoaded &&
      imagesLoadedCounter !== images?.length
    ) {
      layers.map(layer => {
        // @ts-ignore
        if (!map.getLayer(layer.id) && map.getSource(layer.source)) {
          try {
            map.addLayer(layer);
          } catch (e: any) {}
        }
      });
    }
  };

  useEffect(upsertSources, [map, sources, isStyleLoaded]);

  return (
    <>
      <div
        ref={el => (mapContainerRef.current = el)}
        className='map-container'
        style={{ width, height, ...style }}
      />
      <div>
        {popup && popupRef.current !== null && (
          <div ref={el => (popupRef.current = el!)}>{popup.el}</div>
        )}
      </div>
    </>
  );
}

export default Map;
export * from './constants';
