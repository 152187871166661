import { CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getOptionActionClassName = (disabled: boolean) => {
  return `options-action ${disabled ? 'disabled-option' : ''}`;
};

export const OptionsButton = ({
  actions,
  isHovered,
  expanded,
  onActionsShown,
  setIsHovered,
  inClearingState,
  onActionClicked,
  zIndex,
}: {
  actions?: any;
  isHovered: boolean;
  expanded: boolean;
  onActionsShown?: Function;
  setIsHovered?: Function;
  inClearingState?: boolean;
  onActionClicked?: Function;
  zIndex?: number;
}) => {
  const [actionsShown, showActions] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isHovered) {
      showActions(false);
    }
  }, [isHovered]);

  React.useEffect(() => {
    if (!expanded) {
      showActions(false);
    }
  }, [expanded]);

  React.useEffect(() => {
    if (onActionsShown) onActionsShown(actionsShown);
  }, [actionsShown]);

  const triggerAction = (fn: any) => {
    if (onActionClicked) onActionClicked();
    showActions(false);
    if (setIsHovered) setIsHovered(false);
    fn();
  };

  if (!!inClearingState && !isHovered) {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div className='clearing-status'>
          <span className='clearing-status-title'>{t('Clearing')} </span>
          <CircularProgress
            size={12}
            color={'secondary'}
            sx={{ marginBottom: '6px', marginRight: '2px' }}
          />
           
        </div>
      </div>
    );
  }

  return (
    <div style={{ marginTop: '64px', zIndex }}>
      <div
        onClick={() => showActions(!actionsShown)}
        className='options-button'
        style={{
          opacity: isHovered && expanded ? 1 : 0,
          zIndex,
        }}>
        {isHovered && expanded ? (
          <span>{t('Options')}</span>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>

      {actionsShown && (
        <div className={'options-actions'} style={{ zIndex }}>
          {actions?.map((action: any) => {
            if (!action.disabled || !action.disabledTooltip) {
              return (
                <div
                  className={getOptionActionClassName(!!action.disabled)}
                  style={{ zIndex }}
                  key={action.title}
                  onClick={() => triggerAction(action.onClick)}>
                  {action.icon && (
                    <div style={{ marginTop: '2px' }}>
                      <action.icon size='small' sx={{ fontSize: 16 }} />
                      {' '}
                    </div>
                  )}
                  {action.title}
                </div>
              );
            }
            return (
              <Tooltip title={action.disabledTooltip} key={action.title}>
                <div
                  className={getOptionActionClassName(!!action.disabled)}
                  onClick={() =>
                    !!action.disabled ? null : triggerAction(action.onClick)
                  }>
                  {action.title}
                </div>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OptionsButton;
