import { LngLatLike } from 'mapbox-gl';
import React, { useContext, useReducer } from 'react';

interface Action {
  type: string;
  payload: any;
}

export enum Integration {
  OrbcommIgwsGateway = 'Integration.OrbcommIgwsGateway',
  OrbcommXmlGateway = 'Integration.OrbcommXmlGateway',
  SnapgisWebhook = 'Integration.SnapgisWebhook',
  SnapgisRestApi = 'Integration.SnapgisRestApi',
  TwilioAccount = 'Integration.TwilioAccount',
}

export enum ActionMenuModuleTypes {
  Default = '',
  AddLocation = 'Add Location',
  AddGroup = 'Add Group',
  AddAsset = 'Add Asset',
  AddDriver = 'Add Driver',
  AddUser = 'Add User',
  AddRole = 'Add Role',
  ImportLocation = 'Import Location',
  ListAssets = 'Assets in location',
  EditAsset = 'Edit Asset',
  EditUser = 'Edit User',
  EditGroup = 'Edit Group',
  EditDriver = 'Edit Driver',
  EditLocation = 'Edit Location',
  AddIntegration = 'Add Integration',
  EditIntegration = 'Edit Integration',
  AddContact = 'Add Contact',
  EditContact = 'Edit Contact',
  AddNotificationSetting = 'Add Notification',
  EditNotificationSetting = 'Edit Notification',
  UpsertAISRegion = 'AIS Regions',
}

export const ActionMenuContextProps = {
  active: ActionMenuModuleTypes.Default,
  extra: {},
  mainMap: {
    title: '',
    description: '',
  },
  rootGroupId: 0,
  aisRegionData: {
    lastUpdatedId: null,
    deleteRegionId: null,
    selectedFeatureId: null,
    drawMode: null,
    regions: null,
    savedItemsCount: 0,
  },
};

export type MainMapActionMenu = {
  point: any;
  polygon: any;
  drawMode: string;
  title: string;
  description: string;
  addLocationData: any;
  resetDrawnFeatures: boolean;
  flyTo: LngLatLike;
  activeLocationId: number;
  activeAssetId: number;
  places: any;
  bounds: any;
};

export type Region = {
  featureId: string;
  name: string;
  area: number;
  polygon: any;
  priceInCents?: number;
};

export type AisRegionData = {
  lastUpdatedId: string | null;
  deleteRegionId?: string | null;
  selectedFeatureId?: string | null;
  drawMode?: string | null;
  regions?: Region[] | null;
  savedItemsCount?: number | null;
};

export type ActionMenuContextPropsTypes = {
  active: ActionMenuModuleTypes;
  setActive: Function;
  reset: Function;
  setMainMapField: Function;
  activateAddLocation: Function;
  setAddLocationData: Function;
  setAddGroupData: Function;
  mainMap: MainMapActionMenu;
  resetDrawnFeatures: boolean;
  setResetDrawnFeatures: Function;
  setDrawMode: Function;
  rootGroupId: number;
  extra: any;
  aisRegionData: AisRegionData;
  setAisRegionData: Function;
  upsertRegionInAisRegionData: Function;
  removeRegionFromAisRegionData: Function;
  setAisRegionDataDrawMode: Function;
  setAisRegionSavedCount: Function;
};

const ActionMenuContext = React.createContext(ActionMenuContextProps);

export const ActionMenuContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, ActionMenuContextProps);

  const setActive = (type: ActionMenuModuleTypes, extra: any) => {
    // console.log('setactive', type, extra);
    dispatch({ type: 'setActive', payload: { type: type, extra: extra } });
  };

  const reset = () => {
    dispatch({
      type: 'setActive',
      payload: { type: ActionMenuModuleTypes.Default },
    });
  };

  const setMainMapField = (field: string, value: any) => {
    dispatch({ type: 'setMainMapField', payload: { field, value } });
  };

  const setResetDrawnFeatures = (payload: boolean = true) => {
    dispatch({ type: 'setResetDrawnFeatures', payload });
  };

  const activateAddLocation = () => {
    dispatch({
      type: 'setActive',
      payload: { type: ActionMenuModuleTypes.AddLocation },
    });
  };

  const setDrawMode = (payload: string) => {
    dispatch({
      type: 'setDrawMode',
      payload,
    });
  };

  const setLocationTitle = (payload: string) => {
    dispatch({
      type: 'setLocationTitle',
      payload,
    });
  };

  const setAddLocationData = (payload: any) => {
    dispatch({
      type: 'setAddLocationData',
      payload,
    });
  };

  const setAddGroupData = (payload: any) => {
    dispatch({
      type: 'setAddGroupData',
      payload,
    });
  };

  const setAisRegionData = (regions: Region[]) => {
    dispatch({
      type: 'setAisRegionData',
      payload: {
        regions,
      },
    });
  };

  const upsertRegionInAisRegionData = (region: Region) => {
    dispatch({
      type: 'upsertRegionInAisRegionData',
      payload: {
        region,
      },
    });
  };

  const removeRegionFromAisRegionData = (regionId: string) => {
    dispatch({
      type: 'removeRegionFromAisRegionData',
      payload: {
        regionId,
      },
    });
  };

  const setAisRegionDataDrawMode = ({
    drawMode,
    selectedFeatureId,
  }: {
    drawMode: string;
    selectedFeatureId?: string;
  }) => {
    dispatch({
      type: 'setAisRegionDataDrawMode',
      payload: {
        drawMode,
        selectedFeatureId,
      },
    });
  };

  const setAisRegionSavedCount = (savedItemsCount: string) => {
    dispatch({
      type: 'setAisRegionSavedCount',
      payload: {
        savedItemsCount,
      },
    });
  };

  const value = {
    active: state.active,
    mainMap: state.mainMap,
    aisRegionData: state.aisRegionData,
    setActive: React.useCallback(
      (payload: any, extra: any) => setActive(payload, extra),
      []
    ),
    reset: React.useCallback(() => reset(), []),
    setMainMapField: React.useCallback(
      (field: string, value: any) => setMainMapField(field, value),
      []
    ),
    setAddLocationData: React.useCallback(
      (payload: any) => setAddLocationData(payload),
      []
    ),
    setAddGroupData: React.useCallback(
      (payload: any) => setAddGroupData(payload),
      []
    ),
    setAisRegionData: React.useCallback(
      (payload: any) => setAisRegionData(payload),
      []
    ),
    upsertRegionInAisRegionData: React.useCallback(
      (payload: any) => upsertRegionInAisRegionData(payload),
      []
    ),
    removeRegionFromAisRegionData: React.useCallback(
      (payload: any) => removeRegionFromAisRegionData(payload),
      []
    ),
    setAisRegionDataDrawMode: React.useCallback(
      (payload: any) => setAisRegionDataDrawMode(payload),
      []
    ),
    setAisRegionSavedCount: React.useCallback(
      (payload: any) => setAisRegionSavedCount(payload),
      []
    ),
    activateAddLocation: React.useCallback(() => activateAddLocation(), []),
    setDrawMode: React.useCallback((payload: any) => setDrawMode(payload), []),
    setLocationTitle: React.useCallback(
      (payload: any) => setLocationTitle(payload),
      []
    ),
    setResetDrawnFeatures: React.useCallback(
      (payload: boolean) => setResetDrawnFeatures(payload),
      []
    ),
    rootGroupId: state.rootGroupId,
    extra: state.extra,
  };

  return (
    <ActionMenuContext.Provider value={value}>
      {children}
    </ActionMenuContext.Provider>
  );
};

const actions: any = {
  setActive: (state: any, { payload }: any) => {
    // console.log(state, payload);
    return {
      ...state,
      active: payload.type,
      extra: payload.extra,
    };
  },
  setMainMapField: (state: any, { payload }: any) => {
    return {
      mainMap: {
        ...state.mainMap,
        [payload.field]: payload.value,
      },
    };
  },
  setDrawMode: (state: any, { payload }: Action) => {
    return {
      mainMap: {
        ...state.mainMap,
        drawMode: payload,
      },
    };
  },
  setLocationTitle: (state: any, { payload }: Action) => {
    return {
      mainMap: {
        ...state.mainMap,
        title: payload,
      },
    };
  },
  setAddLocationData: (state: any, { payload }: Action) => {
    return {
      mainMap: {
        ...state.mainMap,
        addLocationData: payload,
      },
    };
  },
  setAddGroupData: (state: any, { payload }: Action) => {
    return {
      rootGroupId: payload.rootGroupId ?? 0,
    };
  },
  setResetDrawnFeatures: (state: any, { payload }: Action) => {
    return {
      mainMap: {
        ...state.mainMap,
        resetDrawnFeatures: payload,
      },
    };
  },
  setAisRegionData: (state: any, { payload }: Action) => {
    return {
      aisRegionData: {
        ...state.aisRegionData,
        regions: payload.regions,
        lastUpdatedId: null,
        savedItemsCount: payload.savedItemsCount,
      },
    };
  },
  upsertRegionInAisRegionData: (state: any, { payload }: Action) => {
    const newRegions = state.aisRegionData.regions
      ? [...state.aisRegionData.regions]
      : [];
    const index = newRegions.findIndex(
      (r: Region) => r.featureId === payload.region.featureId
    );

    if (index >= 0) {
      // Update the existing region
      newRegions[index] = {
        ...newRegions[index],
        ...payload.region,
      };
    } else {
      // Add the new region
      newRegions.push(payload.region);
    }

    return {
      aisRegionData: {
        ...state.aisRegionData,
        regions: newRegions,
        lastUpdatedId: payload?.region?.featureId,
      },
    };
  },
  removeRegionFromAisRegionData: (state: any, { payload }: Action) => {
    return {
      aisRegionData: {
        ...state.aisRegionData,
        deleteRegionId: payload.regionId,
        regions:
          payload.regionId && state.aisRegionData.regions
            ? [...state.aisRegionData.regions].filter(
                (r: Region) => r.featureId !== payload.regionId
              )
            : [...state.aisRegionData.regions],
        lastUpdatedId: null,
      },
    };
  },
  setAisRegionDataDrawMode: (state: any, { payload }: Action) => {
    return {
      aisRegionData: {
        ...state.aisRegionData,
        drawMode: payload.drawMode,
        selectedFeatureId: payload.selectedFeatureId,
      },
    };
  },
  setAisRegionSavedCount: (state: any, { payload }: Action) => {
    return {
      aisRegionData: {
        ...state.aisRegionData,
        savedItemsCount: payload.savedItemsCount,
      },
    };
  },
};

export const reducer = (state: any, action: Action) => {
  if (Object.keys(actions).includes(action.type)) {
    const res = actions[action.type](state, action);
    return {
      ...state,
      ...res,
    };
  }
  throw new Error(
    `No case for type ${action.type} found in ActionMenuContext Reducer.`
  );
};

const useActionMenuContext = () => {
  const context = useContext(ActionMenuContext) as ActionMenuContextPropsTypes;

  if (context === undefined) {
    throw new Error(
      'useActionMenuContext must be used within ActionMenuContext'
    );
  }

  return context;
};

export default useActionMenuContext;
