import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { apiClient } from 'services/axios';

export function useLocationActivity(
  page: number,
  filterBy: any
): UseQueryResult {
  return useQuery(
    [
      'report_location_activity',
      page,
      filterBy,
    ],
    ({ signal }) =>
      apiClient.get('reports/location-activity', {
        signal,
        params: { page, ...filterBy },
      })
  );
}

export function useExportReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_location_activity_export',
    (variables: any) =>
      apiClient.post(`reports/location-activity/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        locationIds: variables.locationIds,
        fileType: variables.fileType,
        groupIds: variables.groupIds,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_location_activity_export');
      },
    }
  );
}
