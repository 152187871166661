import React from 'react';
import { SvgIcon } from "@mui/material";

const DriverStatusAltIcon = (props: any) => {
  return <SvgIcon sx={{color: 'rgb(255, 255, 255)'}}>
    <svg viewBox="0 0 24 24" width="24px" height="24px"
         xmlns="http://www.w3.org/2000/svg">
      <g>
        <path cx="37.352" cy="17.643" r="17.373"
              d="M13.134 4.234a4.17 4.17 0 0 1 -4.17 4.17A4.17 4.17 0 0 1 4.795 4.234a4.17 4.17 0 0 1 8.339 0z" />
        <path
          d="M15.673 11.366c-3.388 0 -6.109 2.751 -6.109 6.109 0 3.359 2.751 6.109 6.109 6.109 3.359 0 6.109 -2.751 6.109 -6.109s-2.721 -6.109 -6.109 -6.109Zm-4.372 3.504a4.162 4.162 0 0 1 0.781 -0.985c1.188 -1.188 2.838 -1.708 4.546 -1.419a4.732 4.732 0 0 1 1.853 0.753 4.995 4.995 0 0 1 1.563 1.651c0.116 0.203 0.058 0.492 -0.173 0.608l-0.203 0.087a3.934 3.934 0 0 1 -3.185 0.029c-0.232 -0.116 -0.521 -0.174 -0.81 -0.174a1.938 1.938 0 0 0 -0.81 0.174 3.934 3.934 0 0 1 -3.185 -0.029l-0.203 -0.087c-0.203 -0.116 -0.289 -0.405 -0.174 -0.608Zm3.619 6.66 -0.058 0.492c-0.029 0.261 -0.289 0.434 -0.521 0.377 -0.84 -0.232 -1.651 -0.666 -2.287 -1.303 -0.956 -0.956 -1.506 -2.229 -1.506 -3.591 0 -0.289 0.289 -0.492 0.55 -0.406l0.84 0.232c0.84 0.232 1.593 0.724 2.114 1.419l0.029 0.029a3.861 3.861 0 0 1 0.84 2.751Zm0.81 -3.041a1.018 1.018 0 0 1 -1.071 -1.071 1.004 1.004 0 0 1 0.956 -0.956 1.018 1.018 0 0 1 1.071 1.071c0 0.521 -0.434 0.926 -0.956 0.955Zm3.533 2.577c-0.637 0.637 -1.419 1.101 -2.259 1.303a0.424 0.424 0 0 1 -0.521 -0.376l-0.029 -0.492c-0.087 -1.014 0.232 -1.998 0.84 -2.78l0.029 -0.029c0.521 -0.695 1.274 -1.188 2.114 -1.419l0.81 -0.232c0.261 -0.058 0.521 0.116 0.521 0.406 0 1.39 -0.521 2.664 -1.506 3.619Z"
          stroke="rgb(255,255,255)"
          strokeWidth="0.25px" />
        <path
          d="M14.958 10.604c-0.695 -1.129 -1.679 -2.055 -2.866 -2.635a4.786 4.786 0 0 1 -3.127 1.158 4.917 4.917 0 0 1 -3.127 -1.129C3.55 9.186 1.957 11.56 1.841 14.369v2.374c0 0.956 3.07 1.738 6.862 1.766a7.03 7.03 0 0 1 -0.144 -1.361c0 -3.591 2.866 -6.486 6.399 -6.544Z" />
      </g>
    </svg>
  </SvgIcon>
}

export default DriverStatusAltIcon;