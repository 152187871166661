import { ShortHandLayer } from './types';

export default ({ id, source, icon, size, anchor }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    layout: {
      'icon-image': icon || '{icon}', // #3be867
      'icon-allow-overlap': true,
      'icon-anchor': anchor || 'center',
      'icon-size': size || 0.5,
    },
  };
};
