import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'services/axios';

export function useGroupsFilter(variables?: {
  searchTerm?: string;
}): UseQueryResult {
  return useQuery(['filters/groups', variables], ({ signal }) =>
    apiClient.get('filters/groups', {
      signal,
      params: variables,
    })
  );
}

export function useAssetsFilter(variables?: {
  assetTypes: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['asset', variables],
    () =>
      apiClient.get(`filters/assets`, {
        params: variables,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useAssetsFilterWithSearch(variables?: {
  searchTerm?: string;
  assetTypes?: string;
}): UseQueryResult {
  return useQuery(
    ['asset', variables],
    () =>
      apiClient.get(`filters/assets`, {
        params: variables,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useLocationsFilter(variables?: {
  searchTerm?: string;
}): UseQueryResult {
  return useQuery(
    ['filters/locations', variables],
    () => apiClient.get('filters/locations', { params: variables }),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useDriversFilter(variables?: {
  searchTerm?: string;
  driverIds?: any;
}): UseQueryResult {
  return useQuery(
    ['filters/drivers', variables],
    async () => {
      const drivers: [any] = await apiClient.get('filters/drivers', {
        params: variables,
      });
      // ts-ignore
      drivers.unshift({
        driverId: -1,
        firstName: 'No',
        lastName: 'Driver',
        name: 'No Driver',
      });
      return drivers;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
