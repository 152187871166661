import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useAISRegions(): UseQueryResult {
  return useQuery(
    ['ais-regions'],
    () => apiClient.get(`ais/regions`),
    {
      refetchInterval: 1000 * 60 * 5,
      refetchOnWindowFocus: true,
    },
  );
}

export function useUpsertAISRegions(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'ais-regions',
    (variables: any) =>
      apiClient.put('ais/regions', variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ais-regions');
      },
    }
  );
}

export function useAISRegionVesselColors(): UseQueryResult {
  return useQuery(
    ['ais-regions-vessel-colors'],
    () => apiClient.get(`ais/regions/vessel/colors`),
    {
      refetchInterval: 1000 * 60 * 5,
      refetchOnWindowFocus: true,
    },
  );
}

export function useUpsertAISRegionVesselColor(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'ais-regions-vessel-colors',
    (variables: any) =>
      apiClient.put('ais/regions/vessel/color', variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ais-regions-vessel-colors');
      }
    }
  );
}

export function useCalculateAISRegionPrice(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'ais-region-price',
    (variables: any) =>
      apiClient.post('ais/regions/price/calculate', variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ais-region-price');
      },
    }
  );
}
