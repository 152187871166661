import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'circle',
    source,
    filter: [
      'all',
      ['has', 'assetType'],
      ['in', ['get', 'assetType'], ['literal', ['vehicle', 'trailer', 'container']]],
      ['!', ['has', 'point_count']],
    ],
    paint: {
      'circle-blur': 0.05,
      'circle-color': [
        'match',
        ['get', 'activeAlert'],
        'panic',
        'rgba(255, 91, 91, 1)',
        '#00a8ff',
      ],
      'circle-radius': 6,
      // 'circle-stroke-color': '#ffffff',
      // 'circle-stroke-width': 1,
    },
  };
};
