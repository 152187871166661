export enum SOURCES {
  LOCATIONS = 'locations',
  ASSET_LOCATIONS = 'assetLocations',
  TRIP_VEHICLE = 'tripVehicle',
  LINE_STRING = 'lineString',
  TRIP_START_POINT = 'tripStartPoint',
  TRIP_END_POINT = 'tripEndPoint',
  TRIP_DETAIL_POINT = 'tripDetailPoint',
  TRIP_EVENTS_POINTS = 'tripEventsPoints',
  TRIP_LINE_MAP_MATCHED = 'tripLineMapMatched',
  AIS_REGION_VESSEL_LOCATION = 'aisRegionVesselLocations',
  AIS_REGIONS = 'aisRegions',
  STATIC_ASSET_WELLHEAD_LOCATIONS = 'staticAssetWellheadLocations',
  STATIC_ASSET_PIPEMONITOR_LOCATIONS = 'staticAssetPipeMonitorLocations',
  STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS = 'staticAssetPipelineConnectorBounds',
  STATIC_ASSET_PIPELINE_SOURCE = 'staticAssetPipelineSource',
  GENERIC_STATIC_ASSET_SOURCE = 'genericStaticAssetSource',
  STATIC_ASSET_CAMERA_LOCATIONS = 'staticAssetCameraLocations',
  CONSTANT_SHIPPING_LANE_SOURCE = 'constantShippingLanes',
  PLACES = 'places',
}

export interface Source {
  id: string;
  data: any;
}

export default SOURCES;
