import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, IconButton, Tooltip, capitalize } from '@mui/material';
import {
  // eslint-disable-next-line import/named
  GridColDef,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import useUIContext from 'context/UIContext';
import { useInvoiceDetailedReport, useInvoicePdf, useInvoices } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const commonColDefOptions = {
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

function getColumns({
  handleGetInvoicePdf,
  handleGetDetailedReport,
}: any): GridColDef[] {
  return [
    //@ts-ignore
    {
      field: 'period',
      headerName: 'Period',
      ...commonColDefOptions,
      flex: 0.8,
      valueGetter: ({ row }) => {
        return moment(row.period, 'YYYY-MM').format('MMMM YYYY');
      },
    }, //@ts-ignore
    {
      field: 'createdAt',
      headerName: 'Invoice Date',
      ...commonColDefOptions,
      flex: 0.8,
      valueGetter: ({ row }) => {
        return moment(row.createdAt).format('DD/MM/YYYY');
      },
    }, //@ts-ignore
    {
      field: 'dueAt',
      headerName: 'Due Date',
      ...commonColDefOptions,
      flex: 0.8,
      valueGetter: ({ row }) => {
        return moment(row.dueAt).format('DD/MM/YYYY');
      },
    }, //@ts-ignore
    {
      field: 'status',
      headerName: 'Status',
      ...commonColDefOptions,
      flex: 0.8,
      renderCell: (params: any) => {
        let status = 'outstanding';
        let color = 'text.disabled';
        if (params.row.status === 'paid') {
          status = 'paid';
          color = 'success.main';
        }
        if (
          moment() > moment(params.row.dueAt) &&
          params.row.status !== 'paid'
        ) {
          status = 'overdue';
          color = 'warning.main';
        }
        return (
          <Box fontSize={14} sx={{ color }}>
            {capitalize(status)}
          </Box>
        );
      },
    }, //@ts-ignore
    {
      field: 'total',
      headerName: 'Total',
      ...commonColDefOptions,
      flex: 0.8,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.currency.toUpperCase()} {params.row.total}
          </>
        );
      },
    }, //@ts-ignore
    {
      headerName: '',
      ...commonColDefOptions,
      minWidth: 90,
      maxWidth: 100,
      align: 'center',
      flex: 0.5,
      renderCell: (r: any) => {
        return (
          <>
            <Tooltip title='Download PDF'>
              <IconButton onClick={() => handleGetInvoicePdf(r?.row)}>
                <PictureAsPdfIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title='See details'>
              <IconButton onClick={() => handleGetDetailedReport(r?.row)}>
                <ListAltIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
}

const InvoiceList = () => {
  const { data, isLoading } = useInvoices() as any;
  // const [selectedInvoiceId, setSelectedInvoiceId] = useState<any>({});
  const [selectedInvoice, setSelectedInvoice] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(12);
  const { data: pdfData, error: pdfError } = useInvoicePdf(
    selectedInvoice?.pdf?.id
  );
  const { data: reportData, error: reportError } = useInvoiceDetailedReport(
    selectedInvoice?.report?.id
  );
  const { setFlashMessage } = useUIContext();
  const [rowCountState, setRowCountState] = React.useState(0);

  const handleGetInvoicePdf = (invoice: any) => {
    setSelectedInvoice({ pdf: invoice });
  };

  const handleGetDetailedReport = (invoice: any) => {
    setSelectedInvoice({ report: invoice });
  };

  useEffect(() => {
    if (pdfData && selectedInvoice.pdf) {
      downloadFile(
        pdfData,
        'application/pdf',
        `Invoice - ${selectedInvoice.pdf.orgName} - ${selectedInvoice.pdf.period}.pdf`
      );
      setSelectedInvoice({ ...selectedInvoice, pdf: undefined });
    }
  }, [pdfData, selectedInvoice, selectedInvoice.pdf]);

  useEffect(() => {
    if (reportData && selectedInvoice.report) {
      downloadFile(
        reportData,
        'text/csv',
        `Detailed Billing Record - ${selectedInvoice.report.orgName} - ${selectedInvoice.report.period}.csv`
      );
      setSelectedInvoice({ ...selectedInvoice, report: undefined });
    }
  }, [reportData, selectedInvoice, selectedInvoice.report]);

  const downloadFile = (rawData: any, mimeType: string, filename: string) => {
    const blob = new Blob([rawData as BlobPart], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (pdfError) {
      setFlashMessage({
        message: 'Error while fetching the PDF',
        severity: 'error',
      });
    }
  }, [pdfError, setFlashMessage]);

  useEffect(() => {
    if (reportError) {
      setFlashMessage({
        message: 'Error while fetching the report',
        severity: 'error',
      });
    }
  }, [reportError, setFlashMessage]);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  return (
    <Table
      data={data?.invoices}
      isLoading={isLoading}
      getRowId={(row: any) => row.id}
      columns={getColumns({
        handleGetInvoicePdf,
        handleGetDetailedReport,
      })}
      disableColumnFilter={true}
      disableColumnMenu={true}
      pageSize={pageSize}
      onPageChange={(newPage: number) => setPage(newPage + 1)}
      rowCount={rowCountState}
    />
  );
};

export default InvoiceList;
