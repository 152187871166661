import FilterAutocomplete from 'components/ActionMenu/modules/ActivityFeed/ActivityFeedFilters/FilterAutocomplete';
import React, { useEffect, useMemo } from 'react';

const GenericFilterDumb = ({
  useHookForData = () => {},
  updateFilters,
  filterName,
  label,
  keyProperty,
  labelProperty,
  filters,
  multiple = true,
  size = 'small',
  limitTags = 1,
  presetFilters,
  responseDataField = null,
}: any) => {
  const [state, setState] = React.useState<any>(multiple ? [] : null);
  const { data, isLoading } = useHookForData() as any;

  useEffect(() => {
    const value = (() => {
      const r = filters?.[getKeyPlurality(keyProperty, multiple)] ?? [];
      if (multiple) {
        if (responseDataField) {
          return (
            data?.[responseDataField]?.filter((d: any) =>
              r?.includes(d[keyProperty])
            ) ?? []
          );
        }
        return data?.filter((d: any) => r?.includes(d[keyProperty])) ?? [];
      }
      return undefined;
    })();
    setState(value);
  }, [filters, data]);

  const getKeyPlurality = (value: string, multiple: boolean) => {
    if (value.endsWith('s')) {
      return value;
    }
    return multiple ? value + 's' : value;
  };

  const keyName: string = useMemo(() => {
    return getKeyPlurality(keyProperty, multiple);
  }, [keyProperty, multiple]);

  const handleChange = (_: string, __: React.SyntheticEvent, value: any) => {
    handleUpdateFilters(value);
  };

  const handleUpdateFilters = (value: any) => {
    let v: any = {
      [keyName]: null,
    };

    if (multiple) {
      v[keyName] = value.map((val: any) => val?.[keyProperty]);
    } else {
      v[keyName] = value?.[keyProperty] ? [value?.[keyProperty]] : null;
    }

    updateFilters((prevFilters: any) => ({
      ...prevFilters,
      [keyName]: v[keyName],
    }));
  };

  const options = useMemo(() => {
    if (responseDataField) {
      return data?.[responseDataField] || [];
    }
    return data || [];
  }, [data]);

  return (
    <FilterAutocomplete
      fullWidth
      multiple={multiple}
      options={options}
      state={state}
      loading={isLoading}
      onChange={handleChange}
      filterName={filterName}
      label={label}
      keyProperty={keyProperty}
      labelProperty={labelProperty}
      size={size}
      limitTags={limitTags}
    />
  );
};

export default GenericFilterDumb;
