import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import useUIContext from 'context/UIContext';
import React, { useState } from 'react';

export const HelpCenter = () => {
  const [errorValue, setErrorValue] = useState<string>('');
  const { helpCenterOpen, closeHelpCenter } = useUIContext();

  const handleClose = () => {
    closeHelpCenter();
    setErrorValue('');
  };

  if (!helpCenterOpen) {
    return <></>;
  }

  return (
    <Dialog
      open={!!helpCenterOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
      disableRestoreFocus={true}
      sx={{ mt: -8 }}>
      <DialogContent sx={{ p: 3 }}>
        <Typography variant='h6'>Help Center</Typography>
        <List>
          <ListItemButton
            onClick={() =>
              window.open(
                'https://www.youtube.com/playlist?list=PL6lCgSJb5NYAZ-zOU7_hMgOlYhUQlWgFY'
              )
            }>
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary='YouTube training videos' />
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() =>
              window.open('https://www.snapgis.com/knowledge-base')
            }>
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary='Knowledge Base' />
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() => window.open('mailto:support@snapgis.com')}>
            <ListItemIcon>
              <AlternateEmailIcon />
            </ListItemIcon>
            <ListItemText primary='Contact us' />
          </ListItemButton>
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default HelpCenter;
