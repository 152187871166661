// eslint-disable-next-line import/named
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export default (t: TFunction) => {
  return Yup.object().shape({
    message: Yup.string()
      .min(2, t('form.validation.field_is.too_short', { field: t('chat_message') }))
      .max(150, t('form.validation.field_is.too_long', { field: t('chat_message') }))
      .required(t('form.validation.field_is.required', { field: t('chat_message') })),
  });
};
