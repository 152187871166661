import { SvgIcon } from '@mui/material';
import React from 'react';

const DoorClosedIcon = ({ color = '#FFF', size = 24 }: any) => {
  return (
    <SvgIcon
      sx={{ color, height: size, width: size }}
      viewBox={`0 0 ${size} ${size}`}
      fontSize={'medium'}>
      <svg
        viewBox='31.487 41.85 437.051 401.179'
        xmlns='http://www.w3.org/2000/svg'>
        <rect
          x='31.487'
          y='41.85'
          width='437.051'
          height='401.179'
          rx='15'
          ry='15'
          style={{
            fill: 'transparent',
            stroke: color,
            strokeWidth: '26px',
          }}
        />
        <line
          style={{
            fill: 'transparent',
            stroke: color,
            strokeWidth: '26px',
          }}
          x1='249.061'
          y1='51.543'
          x2='249.239'
          y2='432.545'
        />
        <line
          style={{
            fill: 'transparent',
            stroke: color,
            strokeLinecap: 'round',
            strokeWidth: '26px',
          }}
          x1='212.736'
          y1='207.566'
          x2='212.443'
          y2='263.373'
        />
        <line
          style={{
            fill: 'transparent',
            stroke: color,
            strokeLinecap: 'round',
            strokeWidth: '26px',
          }}
          x1='285.171'
          y1='207.896'
          x2='284.878'
          y2='263.703'
        />
      </svg>
    </SvgIcon>
  );
};

export default DoorClosedIcon;
