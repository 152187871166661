// eslint-disable-next-line import/named
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export default (t: TFunction) => {
  return Yup.object().shape({
    orgName: Yup.string()
      .min(
        2,
        t('form.validation.field_is.too_short', {
          field: t('register.orgName'),
        })
      )
      .max(
        50,
        t('form.validation.field_is.too_long', { field: t('register.orgName') })
      )
      .required(
        t('form.validation.field_is.required', { field: t('register.orgName') })
      ),
    email: Yup.string()
      .email()
      .required(
        t('form.validation.field_is.required', { field: t('register.email') })
      ),
    captchaValue: Yup.string().required(),
    industry: Yup.object()
      .shape({
        id: Yup.string().required(),
        value: Yup.string().required(),
      })
      .nullable()
      .required(
        t('form.validation.field_is.required', {
          field: t('register.industry'),
        })
      ),
    country: Yup.object()
      .shape({
        id: Yup.string().required(),
        value: Yup.string().required(),
      })
      .nullable()
      .required(
        t('form.validation.field_is.required', { field: t('register.country') })
      ),
    address: Yup.string()
      .min(
        2,
        t('form.validation.field_is.too_short', {
          field: t('register.address'),
        })
      )
      .max(
        50,
        t('form.validation.field_is.too_long', { field: t('register.address') })
      )
      .required(
        t('form.validation.field_is.required', { field: t('register.address') })
      ),
    phoneNumber: Yup.string().test(
      'phoneNumber',
      "This phone number doesn't seem to look valid",
      value => {
        if (value && value.length > 4)
          return Yup.string().phone().isValidSync(value);
        return true;
      }
    ),
  });
};
