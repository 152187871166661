import React, { useEffect } from 'react';
import DeviceTypes from 'common-web/definitions/deviceTypes.json';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';

const DeviceTypeFilter = ({ updateFilters, filters, multiple }: any) => {
  const { t } = useTranslation();
  return (
    <Filter
      data={Object.values(DeviceTypes.deviceTypes).map((key: any) => {
        return {
          /* @ts-ignore */
          name: key?.manufacturer + ' ' + key?.model,
          value: key?.id,
        };
      })}
      isLoading={false}
      multiple={multiple}
      filterName='deviceType'
      label={multiple ? t('device_types') : t('device_type')}
      filterKey={multiple ? 'deviceTypes' : 'deviceType'}
      keyProperty='value'
      labelProperty='name'
      filters={filters}
      updateFilters={updateFilters}
      limitTags={multiple ? 0 : 1}
    />
  );
};

export default DeviceTypeFilter;
