import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
// eslint-disable-next-line import/named
import useUIContext from 'context/UIContext';
import { useExportReport } from 'hooks/assets/useAssets';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import { useAuth } from '../../../hooks';

const ExportAssetLog = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const { user } = useAuth() as any;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [canExport, setCanExport] = React.useState<boolean>(true);
  const [prevSearchParams, setPrevSearchParams] = React.useState<any>(null);
  const [searchParams] = useComplexSearchParams();

  React.useEffect(() => {
    if (JSON.stringify(searchParams) !== JSON.stringify(prevSearchParams)) {
      setCanExport(true);
      setPrevSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportReport();

  const handleExport = (fileType: any) => {
    handleClose();
    setCanExport(false);
    exportReport({ ...filters, fileType });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip
        arrow={true}
        title={
          <div style={{ textAlign: 'center' }}>
            {user?.hasAISVessels ? (
              <>
                {t('reports.export_report')}
                <br />
                {t('reports.export_report_ais_note')}
              </>
            ) : (
              t('reports.export_report')
            )}
          </div>
        }>
        <IconButton disabled={isExporting || !canExport} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

export default ExportAssetLog;
