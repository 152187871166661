import { SOURCES } from './types';
import { Location } from 'types';
import GeoJSON from 'components/Map/constants/sources/GeoJSON';

export default (regionData: any[]) =>
  GeoJSON({
    id: SOURCES.AIS_REGIONS,
    data: {
      type: 'FeatureCollection',
      features: regionData?.flatMap((region: any) => [
        {
          type: 'Feature',
          properties: {
            id: region?.featureId,
            name: region?.name,
            area: region?.area,
          },
          geometry: region.polygon?.geometry,
        },
      ]),
    },
  });
