import React, { useContext, useReducer } from 'react';

interface Action {
  type: string;
  payload: any;
}

export type PermissionContextPropTypes = {
  setPermissions: Function;
  isAdmin: Function;
  canRead: Function;
  canWrite: Function;
  permissions: any;
};

const PermissionContext = React.createContext({});

export const PermissionContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, {});

  const setPermissions = (payload: any, isOwner: boolean) => {
    dispatch({ type: 'setPermissions', payload: { ...payload, isOwner } });
  };

  const isAdmin = (permission: string) => {
    return (
      state['isOwner'] ||
      (state['*'] && state['*'] === '*') ||
      (state[permission] && state[permission] === '*')
    );
  };

  const canRead = (permission: string) => {
    return (
      state[permission] && ['READ', 'READWRITE'].includes(state[permission])
    );
  };

  const canWrite = (permission: string) => {
    return state[permission] && ['READWRITE'].includes(state[permission]);
  };

  const value = {
    setPermissions: React.useCallback(
      (payload: any, isOwner: boolean = false) =>
        setPermissions(payload, isOwner),
      []
    ),
    canRead,
    canWrite,
    permissions: state,
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

const actions: any = {
  setPermissions: (state: any, { payload }: any) => {
    return {
      ...state,
      ...payload,
    };
  },
};

export const reducer = (state: any, action: Action) => {
  if (Object.keys(actions).includes(action.type)) {
    const res = actions[action.type](state, action);
    return {
      ...state,
      ...res,
    };
  }
  throw new Error(
    `No case for type ${action.type} found in PermissionContext Reducer.`
  );
};

const usePermissions = () => {
  const context = useContext(PermissionContext) as PermissionContextPropTypes;

  if (context === undefined) {
    throw new Error('usePermissions must be used within PermissionContext');
  }

  return context;
};

export default usePermissions;
