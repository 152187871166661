import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TableToolbar = ({
  grid,
  children,
  spacing = 2,
  sx = { py: 0.5 },
}: {
  grid: Array<number>;
  children: any;
  spacing?: number;
  sx?: any;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (grid.length !== children.length) {
      console.warn('Not all children sizes were specified in grid');
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
      <Grid container spacing={spacing} sx={sx}>
        {children.map((child: any, index: number) => {
          return (
            <Grid item md={grid[index] * 2} lg={grid[index]} key={index}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TableToolbar;
