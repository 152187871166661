import React, { useEffect } from 'react';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named

const ExportReportButton = ({ isExporting, handleExport }: any) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isExporting === true) {
      handleClose();
    }
  }, [isExporting]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip arrow={true} title={t('reports.export_report')}>
        <IconButton disabled={isExporting} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default ExportReportButton;
