/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Socket, io } from 'socket.io-client';
import {
  addToCacheFromWebsocketEvent,
  addToInfiniteQueryCacheFromWebsocketEvent,
  upsertCacheFromWebsocketEvent
} from './query-helpers';

export function useWebsockets(user: any) {
  const queryClient = useQueryClient();
  const orgSocket = useRef<any>(null);
  const userSocket = useRef<any>(null);

  // State to manage connection status
  const [orgConnected, setOrgConnected] = useState(false);
  const [userConnected, setUserConnected] = useState(false);

  useEffect(() => {
    if (user && user.orgUuid && !orgConnected) {
      // console.log('initSocket ORG');
      orgSocket.current = initSocket(
        { 'org-uuid': user.orgUuid },
        () => setOrgConnected(true),
        (e: any) => {
          // console.log(e);
          return setOrgConnected(false);
        }
      );
    }
    // Cleanup
    return () => {
      // console.log('cleanup ORG');
      orgSocket.current?.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (user && user.userUuid && !userConnected) {
      // console.log('initSocket USER');
      userSocket.current = initSocket(
        { 'user-uuid': user.userUuid },
        () => setUserConnected(true),
        (e: any) => {
          // console.log(e);
          return setUserConnected(false);
        },
        [
          addToCacheFromWebsocketEvent(
            queryClient,
            'LOCATION_ADD',
            ['locations', ''],
            false
          ),
          addToCacheFromWebsocketEvent(
            queryClient,
            'GROUP_ADD',
            ['groups', ''],
            false
          ),
          addToInfiniteQueryCacheFromWebsocketEvent(
            queryClient,
            'ACTIVITY_LOG',
            ['activity', {}]
          ),
          upsertCacheFromWebsocketEvent(
            queryClient,
            'DEVICE_REPORT',
            'assetLocations',
            'assetId'
          ),
          upsertCacheFromWebsocketEvent(
            queryClient,
            'DEVICE_REPORT',
            'asset',
            'assetId',
            true
          ),
          upsertCacheFromWebsocketEvent(
            queryClient,
            'IGNITION_LOCKOUT_REQUEST',
            'assetLocations',
            'assetId'
          ),
          {
            key: 'DEVICE_REPORT',
            fn: (data: any) => {
              try {
                queryClient.setQueryData(
                  ['assetLocations'],
                  (old: any) => {
                    if(!old?.map) return old;
                    return old.map((item: any) => {
                      if (item.assetId === data.assetId) {
                        return {
                          ...item,
                          stateIgnitionLockedOut: data.vehicleState?.ignitionLockedOut,
                        };
                      }
                      return item;
                    });
                  }
                );
                queryClient.setQueryData(
                  ['asset', {id: data.assetUuid}],
                  (old: any) => {
                    return {
                      ...old,
                      assetRecent: {
                        ...old?.assetRecent,
                        stateIgnitionLockedOut: data.vehicleState?.ignitionLockedOut,
                        ignitionState: data.vehicleState?.ignitionOn,
                        timestamp: data.receivedTime,
                        speed: data.position.speed,
                        heading: data.position.heading,
                        idlingState: data.vehicleState?.idling,
                        movingState: data.vehicleState?.moving,
                        position: {
                          lat: data.position.latitude,
                          lon: data.position.longitude,
                        }
                      }
                    };
                  }
                );
              } catch (e) {
                console.log(e);
              }
              try {
                  queryClient.setQueryData(
                  ['assetTripHistory', { asset: data.assetId }],
                  (old: any) => {
                    if ('pressure_report' === data?.reportType) return;
                    if (!data.position) return old;

                    if (old && Array.isArray(old)) {
                      return [
                        {
                          lat: data.position?.latitude || data.position?.lat,
                          lon: data.position?.longitude || data.position?.lon,
                        },
                        ...old,
                      ];
                    }
                    return [
                      {
                        lat: data.position?.latitude || data.position?.lat,
                        lon: data.position?.longitude || data.position?.lon,
                      },
                    ];
                  }
                );
              } catch (e) {
                // console.log(e);
              }
              if (
                !data ||
                'panic_end' !== data?.reportType ||
                !!data?.activeAlert
              )
                return;
              // remove item from list if already cleared and no longer in panic
              try {
                const { assetId } = data;
                const alertData = queryClient.getQueryData('alerts') as any[];
                queryClient.setQueryData(
                  'alerts',
                  alertData.filter(
                    (a: any) =>
                      a.asset?.assetId !== assetId ||
                      ('cleared' !== a.status && a.asset?.assetId === assetId)
                  )
                );
              } catch (e) {}
            },
          },
          {
            key: 'IGNITION_LOCKOUT_REQUEST',
            fn: (data: any) => {
              try {
                queryClient.setQueryData(
                  ['asset', {id: data.assetUuid}],
                  (old: any) => {
                    return {
                      ...old,
                      ignitionLockoutRequestedAt: data.ignitionLockoutRequestedAt,
                      ignitionLockoutRequestedState: data.ignitionLockoutRequestedState,
                    };
                  }
                );
              } catch (e) {
                console.log(e);
              }
            },
          },
          addToCacheFromWebsocketEvent(
            queryClient,
            'ALERT_START',
            ['alerts'],
            true
          ),
          {
            key: 'ALERT_END',
            fn: (data: any) => {
              try {
                const { alertId } = data;
                const alertData = queryClient.getQueryData('alerts') as any[];
                queryClient.setQueryData(
                  'alerts',
                  alertData.map((alert: any) => {
                    if (alert.alertId === alertId) {
                      return { ...alert, ...data };
                    }
                    return alert;
                  })
                );
              } catch (e) {}
            },
          },
          {
            key: 'ALERT_CLEARED',
            fn: (data: any) => {
              try {
                const { alertId } = data;
                const alertData = queryClient.getQueryData('alerts') as any[];
                queryClient.setQueryData(
                  'alerts',
                  alertData.map((alert: any) => {
                    if (alert.alertId === alertId) {
                      return { ...alert, ...data };
                    }
                    return alert;
                  })
                );
                queryClient.invalidateQueries({ queryKey: ['assetLocations'] });
              } catch (e) {}
            },
          },
          {
            key: 'ASSET_MEDIA',
            fn: (data: any) => {
              try {
                const {
                  assetId,
                  assetUuid,
                  reportId,
                  reportType,
                  filename,
                  timestamp,
                  fileUrl,
                } = data;
                try {
                  // find this assetId in "static-assets" and replace its assetMedia:
                  const staticAssetData = queryClient.getQueryData(
                    'static-assets'
                  ) as any[];
                  if (!!staticAssetData) {
                    queryClient.setQueryData(
                      'static-assets',
                      staticAssetData.map((asset: any) => {
                        if (asset.assetId === assetId) {
                          return {
                            ...asset,
                            assetMedia: [
                              {
                                assetId,
                                fileUrl,
                                filename,
                                reportId,
                                reportType,
                                timestamp,
                              },
                            ],
                          };
                        }
                        return asset;
                      })
                    );
                  }
                } catch (e) {
                  // console.error(`Failed to update static asset media (maybe no data to be updated): ${e}`);
                }

                // find this assetId by assetUuid "asset" (with id: assetUuid) and replace its assetMedia:
                try {
                  const assetDetails = queryClient.getQueryData([
                    'asset',
                    { id: assetUuid },
                  ]) as any[];
                  if (!!assetDetails) {
                    queryClient.setQueryData(['asset', { id: assetUuid }], {
                      ...assetDetails,
                      assetMedia: [
                        {
                          reportId,
                          reportType,
                          assetId,
                          filename,
                          timestamp,
                          fileUrl,
                        },
                      ],
                    });
                  }
                } catch (e) {
                  // console.error(`Failed to update asset media (maybe no data to be updated): ${e}`);
                }

                try {
                  const assetMediaHistory = queryClient.getQueryData([
                    'asset-historical-media',
                    assetId,
                    1,
                    50,
                  ]) as any[];
                  queryClient.setQueryData(
                    ['asset-historical-media', assetId, 1, 50],
                    [
                      {
                        reportId,
                        reportType,
                        assetId,
                        filename,
                        timestamp,
                        fileUrl,
                      },
                      ...assetMediaHistory,
                    ]
                  );
                } catch (e) {
                  // console.error(`Failed to update asset media history (maybe no data to be updated): ${e}`);
                }
              } catch (e) {
                console.error(`Failed to update asset media: ${e}`);
              }
            },
          },
        ]
      );
    }
    // Cleanup
    return () => {
      // console.log('cleanup USER');
      userSocket.current?.disconnect();
    };
  }, [user]);

  function initSocket(
    query: any = {},
    onConnect: any,
    onDisconnect: any,
    events: any[] = []
  ): Socket | void {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL!, {
      query,
      transports: ['websocket'],
    });

    socket.on('connect_error', err => {});

    socket.on('connect', onConnect);

    socket.on('disconnect', onDisconnect);

    socket.on('FORCE_REFRESH', () => {
      window.location.reload();
    });

    // Register event handlers
    events.forEach(e => {
      if (!e.key || !e.fn) return;
      if (!socket.hasListeners(e.key)) {
        socket.on(e.key, e.fn);
      } else {
        const existingFn = socket.listeners(e.key)[0];
        const combinedFn = (...args: any[]) => {
          existingFn(...args);
          e.fn(...args);
        };
        socket.off(e.key, existingFn);
        socket.on(e.key, combinedFn);
      }
    });

    return socket;
  }

  return { orgConnected, userConnected };
}
