import { useDriversFilter } from 'hooks/filters/useFilters';
import React from 'react';
import Filter from './Filter';

const DriverFilter = ({ updateFilters, filters, multiple, size }: any) => {
  const { data, isLoading } = useDriversFilter() as any;

  return (
    <Filter
      data={data}
      isLoading={isLoading}
      multiple={multiple}
      filterName='driver'
      label={multiple ? 'Drivers' : 'Driver'}
      filterKey={multiple ? 'driverIds' : 'driverId'}
      keyProperty='driverId'
      labelProperty='name'
      filters={filters}
      updateFilters={updateFilters}
      limitTags={multiple ? 0 : 1}
      size={size}
    />
  );
};

export default DriverFilter;
