import { RefreshRounded } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import ToMobileMessageStatusFilter from 'components/Filters/ToMobileMessageStatusFilter';
import ToMobileMessageTypeFilter from 'components/Filters/ToMobileMessageTypeFilter';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AssetFilter, DateFilter } from '../../../../components/Filters';

const ToMobileLogFilters = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ py: 0.5, mt: 2 }}>
      <Grid item xs={2}>
        <DateFilter {...props} sx={{ mr: 1 }} />
      </Grid>
      <Grid item xs={2}>
        <AssetFilter {...props} sx={{ ml: 1 }} multiple={true} />
      </Grid>
      <Grid item xs={2}>
        <ToMobileMessageTypeFilter {...props} sx={{ ml: 1 }} multiple={true} />
      </Grid>
      <Grid item xs={2}>
        <ToMobileMessageStatusFilter
          {...props}
          sx={{ ml: 0 }}
          multiple={true}
        />
      </Grid>
      {props?.onRefreshClicked && (
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Tooltip title={t('Refresh data')} arrow>
            <IconButton onClick={props?.onRefreshClicked}>
              <RefreshRounded />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

const ToMobileLogToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <ToMobileLogFilters {...props} />
    </WithUrlFilters>
  );
};

export default ToMobileLogToolbarWithUrlFilters;
