import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';

const CardComponent = ({
  Icon,
  Image,
  title,
  description,
  button,
  onClick,
  imgBackground,
  className,
  topRightIcon,
}: any) => {
  const CardIcon = ({ Icon }: any) => {
    return (
      <Typography
        sx={{
          fontSize: 12,
          position: 'absolute',
          right: '1rem',
        }}
        gutterBottom>
        <Icon sx={{ fontSize: 36, color: '#00a8ff' }} />
      </Typography>
    );
  };
  const CardImage = ({ Image, imgBackground = 'transparent' }: any) => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          backgroundColor: imgBackground,
          borderRadius: 25,
          padding: 5,
        }}>
        <Avatar src={Image} />
      </div>
    );
  };

  return (
    <Card sx={{ minWidth: '100%' }} className={className}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          {topRightIcon && topRightIcon}
          {Icon ? (
            <CardIcon Icon={Icon} />
          ) : Image ? (
            <CardImage Image={Image} imgBackground={imgBackground} />
          ) : (
            ''
          )}
          <Typography variant='h6' component='div'>
            {title}
          </Typography>
          <Typography
            sx={{
              mb: 1.5,
              fontSize: '.9rem',
              pr: '50px',
              minHeight: 86,
              maxHeight: 86,
              height: 86,
              overflow: 'hidden',
              display: '-webkit-box',
              webkitLineClamp: 4,
              lineClamp: 4,
              webkitBoxOrient: 'vertical',
            }}
            color='text.secondary'>
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size='small' endIcon={<ChevronRightIcon />}>
            {button}
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default CardComponent;
