/* eslint-disable react/jsx-key */
import React from 'react';
import MediaIcon from './MediaIcon';

const MediaIcons = ({ asset, callback }: any) => {
  return (
    <>
      {asset.vehicleMedia.map((el: any, i: number) => {
        return (
          <MediaIcon
            key={i}
            callback={() => {
              callback({
                ...asset,
                vehicleMedia: asset.vehicleMedia[i],
              });
            }}
          />
        );
      })}
    </>
  );
};

export default MediaIcons;
