import { parse } from 'path';

export const getVersion = (parsed: any) => {
  return getInfo(parsed)?.version;
};

export const contactUrl = (parsed: any) => {
  return getContact(parsed)?.url;
};

export const contactEmail = (parsed: any) => {
  return getContact(parsed)?.email;
};

export const contactName = (parsed: any) => {
  return getContact(parsed)?.name;
};

export const securitySchemes = (parsed: any) => {
  return getComponents(parsed)?.securitySchemes;
};

const getComponents = (parsed: any) => {
  return getSpec(parsed)?.components;
};

const getContact = (parsed: any) => {
  return getInfo(parsed)?.contact;
};

const getInfo = (parsed: any) => {
  return getSpec(parsed)?.info;
};

const getSpec = (parsed: any) => {
  return parsed?.schema;
};

export function schemaToExample(schema: any): any {
  if (!schema) return;
  if (schema.type === 'object' && schema.properties) {
    const result: { [key: string]: any } = {};
    for (const [key, value] of Object.entries(schema.properties)) {
      result[key] = schemaToExample(value);
    }
    return result;
  }

  if (schema.type === 'array' && schema.items) {
    return [schemaToExample(schema.items)];
  }

  if (schema.enum) {
    return schema.enum[0];
  }

  return schema.example || generateSampleValue(schema.type, schema.format);
}

function generateSampleValue(type: string, format?: string): any {
  switch (type) {
    case 'string':
      if (format === 'date-time') return '2023-01-01T12:00:00Z';
      if (format === 'uuid') return '123e4567-e89b-12d3-a456-426614174000';
      return 'string_example';
    case 'integer':
      return format === 'int32' ? 123 : 123456789;
    case 'number':
      return format === 'double' ? 123.45 : 67.89;
    case 'boolean':
      return true;
    default:
      return null;
  }
}
