/* eslint-disable max-len */
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import AutoComplete from 'components/AutoComplete';
import { GroupFilter, ReportTypeFilter } from 'components/Filters';
import GenericFilterDumb from 'components/Filters/GenericFilterDumb';
import ScheduleCronConverter from 'components/ScheduledReports/ScheduleCronConverter';
import ToolbarWithFilters from 'components/TableToolbar/WithUrlFilters';
import useUIContext from 'context/UIContext';
import { Field, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { useLocations } from 'hooks';
import { useContacts } from 'hooks/contacts/useContacts';
import {
  useAssetsFilterWithSearch,
  useDriversFilter,
} from 'hooks/filters/useFilters';
import {
  useCreateScheduledReport,
  useScheduledReport,
  useUpdateScheduledReport,
} from 'hooks/reports/useScheduledReport';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

const CreateScheduledReport = () => {
  const { id: scheduledReportId } = useParams();
  const { setFlashMessage } = useUIContext();
  const [queryParams] = useComplexSearchParams() as any;
  const [scheduleText, setScheduleText] = useState('');
  const [rruleString, setRruleString] = useState('');
  const [scheduleType, setScheduleType] = useState('firstDayOfMonth');
  const [inputValue, setInputValue] = React.useState('');
  const [filters, updateFilters] = useState<any>({});
  const { t } = useTranslation();
  const { data: scheduledReportData } = useScheduledReport(
    scheduledReportId
  ) as any;
  const { data: contactsData } = useContacts({ page: 1, pageSize: 100 }) as any;
  const {
    mutate: create,
    isSuccess,
    isError,
    error,
  } = useCreateScheduledReport();
  const { mutate: update, isSuccess: updateSuccess } =
    useUpdateScheduledReport();
  const [toggleGroupAssetDriver, setToggleGroupAssetDriver] = useState('group');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!queryParams) {
      if (queryParams?.groupIds?.length > 0) {
        delete queryParams.assetIds;
        delete queryParams.driverIds;
        delete queryParams.locationIds;
        delete queryParams.showAllTrips;
      }
      updateFilters(queryParams);
    }
  }, []);

  useEffect(() => {
    if (scheduledReportData) {
      updateFilters({
        scheduledReportType: scheduledReportData.reportType,
        ...scheduledReportData.filters,
      });
    }
  }, [scheduledReportData]);

  useEffect(() => {
    updateQueryParams(filters);
  }, [filters]);

  useEffect(() => {
    if (
      isError &&
      error?.response?.data?.message?.includes('unique_name_org_id')
    ) {
      setFlashMessage({
        message: t('scheduledReports.flashMessages.duplicate_name_error'),
        severity: 'error',
      });
    }
  }, [isError, error, setFlashMessage, t]);

  useEffect(() => {
    if (isSuccess) {
      setFlashMessage({
        message: t('scheduledReports.flashMessages.successfully_created'),
      });
      setTimeout(() => {
        navigate('/reports/scheduler');
      }, 1000);
    }
  }, [isSuccess, setFlashMessage, t, navigate]);

  useEffect(() => {
    if (updateSuccess) {
      setFlashMessage({
        message: t('scheduledReports.flashMessages.successfully_updated'),
      });
      setTimeout(() => {
        navigate('/reports/scheduler');
      }, 1000);
    }
  }, [updateSuccess, setFlashMessage, t, navigate]);

  const handleToggleGroupAssetDriver = (event: any, toggleValue: string) => {
    if (toggleValue !== null) {
      const data = {
        scheduledReportType: queryParams.scheduledReportType,
        reportTypes:
          queryParams.scheduledReportType === 'asset_log'
            ? queryParams.reportTypes
            : undefined,
        assetIds: undefined,
        locationIds: undefined,
        groupIds: undefined,
        driverIds: undefined,
        showAllTrips: undefined,
      };
      updateQueryParams(data);
      updateFilters(data);
      setToggleGroupAssetDriver(toggleValue);
    }
  };

  const updateQueryParams = (newFilters: any) => {
    navigate(
      `?${qs.stringify(
        { ...queryParams, ...newFilters },
        { arrayFormat: 'brackets', encode: false }
      )}`,
      {
        state: location.state,
        replace: true,
      }
    );
    if (newFilters?.assetIds) setToggleGroupAssetDriver('asset');
    else if (newFilters?.groupId) setToggleGroupAssetDriver('group');
    else if (newFilters?.driverIds) setToggleGroupAssetDriver('driver');
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const commonData = {
      name: values.name,
      reportType: values?.scheduledReportType?.id,
      contacts: values.contacts.map((c: any) => ({ contactId: c.id })),
      rrule: rruleString,
      scheduleType,
      filters: {
        reportTypes: queryParams.reportTypes,
        assetIds: queryParams.assetIds
          ? [...new Set(queryParams.assetIds)]
          : undefined,
        locationIds: queryParams.locationIds
          ? [...new Set(queryParams.locationIds)]
          : undefined,
        groupIds: queryParams.groupIds
          ? [...new Set(queryParams.groupIds)]
          : undefined,
        driverIds: queryParams.driverIds
          ? [...new Set(queryParams.driverIds)]
          : undefined,
        showAllTrips: queryParams.showAllTrips,
      },
    };

    if (scheduledReportId) {
      update({ scheduledReportId, data: commonData });
    } else {
      create(commonData);
    }
  };

  if (scheduledReportId && !scheduledReportData) {
    return <Skeleton />;
  }

  const initialValues = scheduledReportData
    ? {
        name: scheduledReportData.name,
        scheduledReportType: {
          id: scheduledReportData.reportType,
          value: t(scheduledReportData.reportType),
        },
        contacts: (scheduledReportData?.contacts || []).map((c: any) => ({
          id: c.contactId,
          value: c.email,
        })),
      }
    : {
        name: '',
        scheduledReportType: queryParams.scheduledReportType
          ? {
              id: queryParams.scheduledReportType,
              value: t(queryParams.scheduledReportType),
            }
          : undefined,
        contacts: [],
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    scheduledReportType: Yup.object()
      .shape({
        id: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      })
      .required('Required'),
    contacts: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required('Required'),
          value: Yup.string().email('Invalid email').required('Required'),
        })
      )
      .min(1, 'Required'),
  });

  return (
    <Box>
      <Formik
        enableReinitialize={false}
        validateOnMount={!!scheduledReportData}
        isInitialValid={!!scheduledReportData}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({
          values,
          errors,
          setFieldValue,
          submitForm,
          isSubmitting,
          isValid,
          initialValues,
        }) => {
          return (
            <Grid container>
              <Grid
                xs={4}
                sx={{ px: 2, borderRight: '1px solid rgba(255,255,255,0.25)' }}>
                <ScheduleCronConverter
                  setScheduleText={setScheduleText}
                  setRruleString={setRruleString}
                  setScheduleType={setScheduleType}
                  scheduledReportData={scheduledReportData}
                />
                <Divider sx={{ my: 2 }} />
                {contactsData?.contacts?.length > 0 && (
                  <Field
                    multiple
                    component={AutoComplete}
                    name='contacts'
                    textFieldProps={{
                      label: t('Contacts'),
                      placeholder: t('Contacts'),
                    }}
                    defaultValue={values?.contacts ?? []}
                    options={(contactsData?.contacts || []).map((d: any) => {
                      return {
                        id: d.contactId,
                        value: d.email,
                      };
                    })}
                    inputValue={inputValue}
                    onInputChange={(_: any, value: string) => {
                      setInputValue(value);
                    }}
                    onChange={(event: any, value: any) => {
                      if (Array.isArray(value)) {
                        setFieldValue('contacts', value);
                      } else {
                        setFieldValue('contacts', []);
                      }
                    }}
                    filterOptions={(options: any, params: any) => {
                      return options?.filter((o: any) =>
                        o.value.includes(params?.inputValue)
                      );
                    }}
                  />
                )}
              </Grid>
              <Grid xs={8} sx={{ px: 2 }}>
                <Typography variant='body1'>
                  You will receive a new report, {scheduleText}:
                </Typography>
                <Grid container gap={2}>
                  <Grid xs={12}>
                    <Field
                      component={FormikTextField}
                      label={t('Scheduled Report Name')}
                      name='name'
                      fullWidth
                      margin='normal'
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Field
                      component={AutoComplete}
                      multiple={false}
                      label={t('Scheduled Report Type')}
                      name='scheduledReportType'
                      value={values?.scheduledReportType}
                      disabled={!!scheduledReportData}
                      options={[
                        'asset_log',
                        'utilization',
                        'fuel',
                        'trips',
                        'location_activity',
                        'speeding',
                        'drivers',
                        'asset_status',
                        'local_speeding',
                      ].map(type => ({
                        id: type,
                        value: t(type),
                      }))}
                      fullWidth
                      onChange={(_: any, value: any) => {
                        setFieldValue('scheduledReportType', value);
                        const data = {
                          scheduledReportType: value.id,
                          reportTypes: undefined,
                          assetIds: undefined,
                          locationIds: undefined,
                          groupIds: undefined,
                          driverIds: undefined,
                          showAllTrips: undefined,
                        };
                        updateQueryParams(data);
                        updateFilters(data);
                      }}
                    />
                  </Grid>
                  <Grid xs={12} container gap={2}>
                    {['local_speeding', 'location_activity'].includes(
                      values?.scheduledReportType?.id
                    ) && (
                      <Grid xs={12}>
                        <GenericFilterDumb
                          filters={filters}
                          updateFilters={updateFilters}
                          multiple={true}
                          size='medium'
                          limitTags={5}
                          filterName='location'
                          label='Location'
                          keyProperty='locationId'
                          labelProperty='name'
                          useHookForData={useLocations}
                          responseDataField='locations'
                        />
                      </Grid>
                    )}
                    {values?.scheduledReportType?.id === 'asset_log' && (
                      <Grid xs={12}>
                        <ToolbarWithFilters fullWidth filters={filters}>
                          <ReportTypeFilter
                            multiple={true}
                            size='medium'
                            filters={filters}
                            updateFilters={(value: any) =>
                              updateFilters({
                                ...filters,
                                reportTypes: value,
                              })
                            }
                          />
                        </ToolbarWithFilters>
                      </Grid>
                    )}
                    {[
                      'utilization',
                      'fuel',
                      'speeding',
                      'drivers',
                      'local_speeding',
                    ].includes(values?.scheduledReportType?.id) && (
                      <Grid xs={12}>
                        <GroupFilter
                          multiple
                          size='medium'
                          filters={filters}
                          updateFilters={updateFilters}
                        />
                      </Grid>
                    )}
                    {['asset_log', 'trips', 'asset_status'].includes(
                      values?.scheduledReportType?.id
                    ) && (
                      <>
                        <Grid
                          md={12}
                          lg={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <ToggleButtonGroup
                            fullWidth
                            value={toggleGroupAssetDriver}
                            exclusive
                            onChange={handleToggleGroupAssetDriver}
                            disabled={!!scheduledReportData}
                            aria-label='text alignment'>
                            <ToggleButton
                              value='group'
                              aria-label='filter by groups'>
                              {t('Groups')}
                            </ToggleButton>
                            <ToggleButton
                              value='asset'
                              aria-label='filter by assets'>
                              {t('Assets')}
                            </ToggleButton>
                            {values?.scheduledReportType?.id === 'trips' && (
                              <ToggleButton
                                value='driver'
                                aria-label='filter by drivers'>
                                {t('Drivers')}
                              </ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        </Grid>
                        {toggleGroupAssetDriver === 'group' && (
                          <Grid xs={12}>
                            <GroupFilter
                              multiple
                              size='medium'
                              filters={filters}
                              updateFilters={updateFilters}
                            />
                          </Grid>
                        )}
                        {toggleGroupAssetDriver === 'asset' && (
                          <Grid xs={12}>
                            <GenericFilterDumb
                              filters={filters}
                              updateFilters={updateFilters}
                              multiple={true}
                              size='medium'
                              limitTags={5}
                              filterName='asset'
                              label='Assets'
                              keyProperty='assetId'
                              labelProperty='name'
                              useHookForData={useAssetsFilterWithSearch}
                            />
                          </Grid>
                        )}
                        {toggleGroupAssetDriver === 'driver' && (
                          <Grid xs={12}>
                            <GenericFilterDumb
                              filters={filters}
                              updateFilters={updateFilters}
                              multiple={true}
                              size='medium'
                              limitTags={5}
                              filterName='driver'
                              label='Drivers'
                              keyProperty='driverId'
                              labelProperty='name'
                              useHookForData={useDriversFilter}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    {['trips'].includes(values?.scheduledReportType?.id) && (
                      <>
                        <Grid
                          md={12}
                          lg={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            ml: 1,
                          }}>
                          <Tooltip
                            title={t('reports.show_all_trips_tooltip')}
                            arrow
                            placement='bottom'>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <>
                                    <Switch
                                      checked={filters?.showAllTrips}
                                      size='small'
                                      color='secondary'
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        updateFilters({
                                          ...scheduledReportData?.filters,
                                          showAllTrips: event.target.checked,
                                        });
                                      }}
                                    />
                                  </>
                                }
                                label={
                                  <Typography sx={{ ml: 1 }}>
                                    {t('reports.include_all_trips')}
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Box
                    sx={{ width: '100%' }}
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='center'>
                    {/* {JSON.stringify(errors)} */}
                    <Button
                      color='secondary'
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        Object.keys(errors).length > 0
                      }
                      onClick={submitForm}>
                      {scheduledReportId
                        ? t('Save Scheduled Report')
                        : t('Create Scheduled Report')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreateScheduledReport;
