import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import AutoComplete from 'components/AutoComplete';
import InfoHelper from 'components/Tooltip/InfoHelper';
import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { useDrivers } from 'hooks/drivers/useDrivers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DefaultDriverField = ({
  field,
  disabled,
  groupId,
  sx,
  size,
}: any) => {
  const { t } = useTranslation();
  const pageSize = 1000;
  const { data } = useDrivers(
    groupId ? { groupId, pageSize } : { pageSize }
  ) as any;
  const { values } = useFormikContext() as any;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (values && values.defaultDriver && values.defaultDriver.id) {
      setVisible(true);
    }
  }, [values]);

  useEffect(() => {
    if (!visible) {
      values[field.field] = null;
    } else {
      values[field.field] = {};
    }
  }, [visible]);

  if (!data) return <Skeleton />;

  return (
    <Box sx={{ mt: -2 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={visible}
              onChange={e => setVisible(e.target.checked)}
            />
          }
          label={
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
              Set Default Driver{' '}
              <InfoHelper
                label='Setting a default driver will associate all driver behaviour to this driver if a driver has not manually logged in. We recommend this even if using a manual driver login system to catch driver behaviour even in instances where a driver has not logged in.'
                sx={{ ml: 1 }}
              />
            </Typography>
          }
          sx={{ width: '100%', mb: 1 }}
        />
      </FormGroup>
      {visible === true && (
        <>
          <Field
            component={AutoComplete}
            value={values[field.field]}
            name={field?.field}
            disabled={disabled}
            size={size}
            sx={sx}
            textFieldProps={{
              label: t(field?.label),
              placeholder: t(field?.placeholder),
            }}
            renderOption={(
              props: any,
              option: any,
              state: any,
              ownerState: any
            ) => {
              const { key, ...optionProps } = props;
              if (option.id === 'new_driver') {
                return (
                  <Box
                    key={key}
                    {...optionProps}
                    sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: 'primary.main',
                      }}>
                      {option.value}
                    </Typography>
                  </Box>
                );
              }
              return (
                <Box
                  key={key}
                  {...optionProps}
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>{option.value}</Typography>
                </Box>
              );
            }}
            options={[
              { id: 'new_driver', value: '+ Add a new driver' },
              ...data?.drivers?.map((d: any) => {
                return {
                  id: d.driverId,
                  value: `${d.firstName} ${d.lastName ?? ''}`,
                };
              }),
            ]}
          />
          {values.defaultDriver?.id === 'new_driver' && (
            <Field
              component={FormikTextField}
              value={values[field.field]?.newDriverName}
              name='newDriverName'
              sx={{ width: '100%', mt: 2 }}
              type='text'
              label={'New Driver Name'}
              placeholder={`${values.name ? values.name : ''} (Default Driver)`}
              autoFocus={true}
              onChange={(e: any) => {
                values[field.field] = {
                  ...values[field.field],
                  newDriverName: e.target.value,
                };
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default DefaultDriverField;
