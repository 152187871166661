import { Skeleton } from '@mui/material';
import { useDashboardWidget } from 'hooks';
import moment from 'moment';
import React from 'react';
import {
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const LineChart = ({
  assetType,
  widgetType,
  dataKey,
  chartUnit,
}: {
  assetType: string;
  widgetType: string;
  dataKey: string;
  chartUnit: string;
}) => {
  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
  }) as any;

  if (!data || isLoading)
    return <Skeleton variant='rounded' width={'100%'} height={'100%'} />;

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <ResponsiveContainer width='80%' height='100%'>
        <RechartsLineChart
          data={data?.data.map((item: any) => ({
            ...item,
            name: item.date,
            unit: chartUnit,
          }))}>
          {/* <CartesianGrid strokeDasharray='3 3' /> */}

          <XAxis
            dataKey='name'
            // @ts-ignore
            tickFormatter={(value: any, index: number) =>
              value && !!moment(value)
                ? moment(value).format('dddd').substring(0, 3)
                : ''
            }
          />
          <YAxis />
          <Tooltip
            separator=''
            // @ts-ignore
            formatter={(value: any, name: any) => [`${value} ${chartUnit}`, '']}
            label={''}
            labelStyle={{ color: 'black' }}
            contentStyle={{ color: 'black' }}
            itemStyle={{ color: 'black' }}
          />
          <Line
            type='monotone'
            dataKey={dataKey}
            stroke='#ffffff'
            activeDot={{ r: 4 }}
          />
        </RechartsLineChart>
      </ResponsiveContainer>
      <div style={{ width: '20%', alignSelf: 'center', textAlign: 'center' }}>
        {data?.total && (
          <div style={{ fontSize: '4vh' }}>{data.total?.toLocaleString()}</div>
        )}
        <div>{chartUnit.charAt(0).toUpperCase() + chartUnit.slice(1)}</div>
      </div>
    </div>
  );
};

export default LineChart;
