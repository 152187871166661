import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { apiClient } from 'services/axios';

export function useMfaQr(): UseQueryResult {
  return useQuery('mfaQr', ({ signal }) => apiClient.get('mfa/setup/qr', { signal }));
}

export function useSubmitMfaCode(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'mfaCode',
    (variables: any) => apiClient.post(`/mfa/setup/verify-mfa-code`, variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mfaCode');
      },
    }
  );
}

export function useSubmitSmsCode(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'smsCode',
    (variables: any) => apiClient.post(`/mfa/setup/verify-sms-code`, variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('smsCode');
        queryClient.invalidateQueries('users/current');
      },
    }
  );
}

export function useDisableMfa(): any {
  const queryClient = useQueryClient();
  return useMutation('mfaDisable', (variables: any) => apiClient.post(`/mfa/disable`, variables), {
    onSuccess: () => {
      queryClient.invalidateQueries('mfaQr');
      queryClient.invalidateQueries('mfaDisable');
      queryClient.invalidateQueries('users/current');
    },
  });
}
