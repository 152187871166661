import { Box, Typography } from '@mui/material';
import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './style.scss';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Dashboard = ({ widgets }: { widgets: any }) => {
  return (
    <div style={{ width: 'calc( 100% - 120px )' }}>
      <ResponsiveGridLayout
        className='layout'
        draggableHandle='.dashboard-widget-drag-handle'
        rowHeight={200}
        layouts={{ lg: widgets.map((widget: any) => widget.structure) }}
        margin={{
          lg: [20, 20],
          md: [20, 20],
          sm: [20, 20],
          xs: [20, 20],
          xxs: [10, 10],
        }}
        // compactType={'horizontal'}
        breakpoints={{ lg: 1000, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>
        {widgets.map((item: any) => (
          <div className='widget-container' key={item.structure?.i}>
            <h3 className='dashboard-widget-drag-handle'>{item.title}</h3>
            <div className='widget-content'>
              <Box sx={{ height: item.legend ? '90%' : '100%' }}>
                {item.component}
              </Box>
              {item.legend && (
                <div style={{ marginTop: '0.5em', lineHeight: '1em' }}>
                  <Typography
                    variant={'caption'}
                    sx={{ color: 'rgba(255,255,255,0.5)' }}>
                    {item.legend}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

export default Dashboard;
