import React from 'react';
import { SvgIcon } from "@mui/material";

const AssetLogIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'large'}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.00103 0 0 1.00103 -.021 -.026)">
        <path
          d="M21.192 0.6H2.808c-1.224 0 -2.208 0.984 -2.208 2.208v18.36c0 1.224 0.984 2.208 2.208 2.208h18.36c1.224 0 2.208 -0.984 2.208 -2.208V2.808c0.024 -1.224 -0.96 -2.208 -2.184 -2.208zm-13.44 21.696H2.808c-0.624 0 -1.104 -0.504 -1.104 -1.104V17.976h6.024v4.32zm0 -5.424H1.704v-4.32h6.024v4.32zm0 -5.424H1.704v-4.32h6.024v4.32zM14.88 22.296H8.856v-4.32H14.88v4.32zm0 -5.424H8.856v-4.32H14.88v4.32zm0 -5.424H8.856v-4.32H14.88v4.32zm7.416 9.744c0 0.624 -0.504 1.104 -1.104 1.104H15.984v-4.32h6.312v3.216zm0 -4.32H15.984v-4.32h6.312v4.32zm0 -5.424H15.984v-4.32h6.312v4.32zm0 -5.424H1.704V2.808c0 -0.624 0.504 -1.104 1.104 -1.104h18.36c0.624 0 1.104 0.504 1.104 1.104v3.216z"
          stroke={color}
          strokeWidth="0.5px" />
      </g>
    </svg>
  </SvgIcon>
}

export default AssetLogIcon;