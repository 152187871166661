import { ShortHandLayer } from './types';

export default ({ id, source, color, size, opacity, dash }: ShortHandLayer) => {
  return {
    id,
    type: 'line',
    source,
    paint: {
      'line-color': '#00a8ff',
      'line-width': 3,
      'line-opacity': .9,
      'line-dasharray': [0, 4, 3],
    },
  };
};
