import { SOURCES } from './types';
import { Location } from 'types';
import GeoJSON from 'components/Map/constants/sources/GeoJSON';

export default (data: any[]) =>
  GeoJSON({
    id: SOURCES.STATIC_ASSET_PIPEMONITOR_LOCATIONS,
    data: {
      type: 'FeatureCollection',
      features: data?.map((item: any) => ({
          type: 'Feature',
          properties: {
            assetId: item?.assetId,
            assetType: item?.assetType,
            name: item?.name,
          },
          geometry: {
            type: 'Point',
            coordinates: [item?.position?.lon, item?.position?.lat],
          },
        }),
      ),
    },
  });
