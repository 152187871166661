import React from 'react';
import { SvgIcon } from "@mui/material";

const FuelCarbonIcon = ({ color = 'rgb(24, 174, 255)', size = 24 }: any) => {
  return <SvgIcon sx={{ color }} viewBox={`0 0 ${size} ${size}`} fontSize={'large'}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.469 6.818c-0.842 -0.309 -1.785 0.122 -2.104 0.96l-1.287 3.388c-0.295 -0.286 -0.686 -0.421 -1.095 -0.428a1.512 1.512 0 0 0 -1.092 0.498L12.879 15.633a5.011 5.011 0 0 0 -0.851 1.293 5.012 5.012 0 0 0 -0.851 -1.293L7.166 11.236a1.513 1.513 0 0 0 -1.093 -0.498c-0.41 0.007 -0.799 0.142 -1.095 0.428l-1.287 -3.388c-0.318 -0.838 -1.263 -1.269 -2.104 -0.96a1.645 1.645 0 0 0 -1.006 2.027l2.182 7.106c0.023 0.073 0.063 0.139 0.117 0.193l4.239 4.18V23.062c0 0.255 0.206 0.461 0.461 0.461h8.896a0.461 0.461 0 0 0 0.461 -0.461V20.323l4.239 -4.18c0.054 -0.054 0.095 -0.12 0.117 -0.193l2.182 -7.106a1.644 1.644 0 0 0 -1.006 -2.027ZM7.904 19.802l-4.294 -4.233 -2.148 -6.995a0.725 0.725 0 0 1 0.443 -0.892 0.727 0.727 0 0 1 0.925 0.423l1.738 4.575c0.049 0.17 0.128 0.335 0.239 0.485l2.402 3.281a0.46 0.46 0 0 0 0.644 0.099 0.46 0.46 0 0 0 0.099 -0.644L5.55 12.622a0.584 0.584 0 0 1 -0.074 -0.134 0.612 0.612 0 0 0 -0.019 -0.062l-0.008 -0.02a0.603 0.603 0 0 1 0.161 -0.569 0.618 0.618 0 0 1 0.442 -0.177 0.597 0.597 0 0 1 0.433 0.198l4.012 4.398A4.094 4.094 0 0 1 11.568 19.018v3.583H8.041V20.131c0 -0.123 -0.05 -0.242 -0.137 -0.328Zm14.69 -11.229 -2.148 6.995 -4.294 4.233c-0.088 0.086 -0.137 0.205 -0.137 0.328V22.601H12.49V19.018c0 -1.024 0.38 -2.006 1.071 -2.763l4.012 -4.398a0.598 0.598 0 0 1 0.433 -0.198 0.619 0.619 0 0 1 0.441 0.177c0.154 0.154 0.21 0.369 0.161 0.568l-0.008 0.021a0.524 0.524 0 0 0 -0.019 0.063c-0.019 0.046 -0.043 0.091 -0.074 0.133L16.105 15.902a0.46 0.46 0 0 0 0.099 0.644 0.46 0.46 0 0 0 0.644 -0.099l2.402 -3.281a1.538 1.538 0 0 0 0.239 -0.486l1.738 -4.574a0.726 0.726 0 0 1 0.925 -0.423 0.723 0.723 0 0 1 0.443 0.892Z"
        stroke={color}
        strokeWidth="0.5px" />
      <path
        d="M12.156 12.569a0.461 0.461 0 0 0 0.461 -0.461V5.844c1.849 0.512 3.486 0.398 4.877 -0.342C19.913 4.213 20.665 1.509 20.695 1.395a0.464 0.464 0 0 0 -0.048 -0.353 0.462 0.462 0 0 0 -0.285 -0.213C17.975 0.224 15.966 0.413 14.391 1.389c-1.82 1.129 -2.468 2.953 -2.636 3.543 -0.33 -0.459 -0.778 -0.927 -1.383 -1.302 -1.308 -0.812 -2.973 -0.97 -4.948 -0.469a0.465 0.465 0 0 0 -0.284 0.214c-0.062 0.107 -0.079 0.234 -0.048 0.353 0.026 0.095 0.649 2.34 2.66 3.411 0.685 0.365 1.441 0.547 2.261 0.547 0.534 0 1.096 -0.078 1.682 -0.232v4.656c0 0.255 0.206 0.461 0.461 0.461ZM14.888 2.166c1.247 -0.769 2.845 -0.955 4.758 -0.558 -0.303 0.758 -1.073 2.279 -2.592 3.084 -1.205 0.638 -2.658 0.714 -4.325 0.226 0.233 -0.648 0.838 -1.936 2.159 -2.751Zm-6.696 4.162c-1.16 -0.615 -1.777 -1.753 -2.044 -2.386 1.497 -0.294 2.75 -0.139 3.728 0.464 1.007 0.62 1.495 1.584 1.701 2.124 -1.304 0.366 -2.441 0.298 -3.385 -0.202Z"
        stroke={color}
        strokeWidth="0.5px" />
    </svg>
  </SvgIcon>
}

export default FuelCarbonIcon;