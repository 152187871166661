import { Location } from 'types';
import { SOURCES, GeoJSON } from 'components/Map';

export default (
  assetLocationData: Location[],
  zoomLevel: number = 14,
  cluster: boolean = true
) =>
  GeoJSON({
    id: SOURCES.AIS_REGION_VESSEL_LOCATION,
    data: {
      type: 'FeatureCollection',
      features: assetLocationData?.flatMap((location: any) => {
        return {
          type: 'Feature',
          properties: {
            callsign: location?.callsign,
            mmsi: location?.mmsi,
            name: location?.name,
            speed: parseFloat(location?.position?.speedOverGround) || 0,
            heading: parseFloat(location?.position?.courseOverGround) || 0,
            color: location?.color || null,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              location?.position?.location?.long || 0,
              location?.position?.location?.lat || 0,
            ],
          },
        };
      }),
    },
  });
