import FilterAutocomplete from 'components/ActionMenu/modules/ActivityFeed/ActivityFeedFilters/FilterAutocomplete';
import React, { useEffect } from 'react';

const Filter = ({
  updateFilters,
  filters,
  multiple,
  data,
  isLoading,
  filterKey,
  filterName,
  label,
  labelProperty,
  keyProperty,
  limitTags = 0,
  disableCloseOnSelect = null,
  limitOptions = 10,
  size,
}: any) => {
  const [state, setState] = React.useState<any>(multiple ? [] : null);

  useEffect(() => {
    if (filters && filterKey && data) {
      const filterValue = filters[filterKey];

      if (multiple) {
        const existing = data?.filter((item: any) =>
          filterValue?.includes(item[keyProperty])
        );

        setState(
          existing.map((item: any) => ({
            [keyProperty]: item[keyProperty],
            [labelProperty]: item[labelProperty],
          }))
        );
      } else {
        const existing = data.find(
          (item: any) => item[keyProperty] === filterValue
        );

        setState(
          existing
            ? {
                [keyProperty]: existing[keyProperty],
                [labelProperty]: existing[labelProperty],
              }
            : null
        );
      }
    }
  }, [filters, filterKey, multiple, data, keyProperty, labelProperty]);

  const handleChange = (
    filterType: string,
    event: React.SyntheticEvent,
    value: any
  ) => {
    if (!value) {
      setState(multiple ? [] : null);
      handleUpdateFilters(null);
      return;
    }

    if (multiple) {
      const selectedValues = value.map((i: any) => i[keyProperty] || i);
      setState(value);
      handleUpdateFilters(selectedValues);
    } else {
      const selectedValue = value[keyProperty] || value;
      setState(value);
      handleUpdateFilters(selectedValue);
    }
  };

  const handleUpdateFilters = (value: any) => {
    if (!updateFilters) return;
    updateFilters({
      ...filters,
      [filterKey]: value,
    });
  };

  return (
    <FilterAutocomplete
      fullWidth
      options={data}
      state={state}
      multiple={multiple}
      loading={isLoading}
      onChange={handleChange}
      filterName={filterName}
      label={label}
      keyProperty={keyProperty}
      labelProperty={labelProperty}
      limitTags={limitTags}
      disableCloseOnSelect={disableCloseOnSelect}
      limitOptions={limitOptions}
      size={size}
    />
  );
};

export default Filter;
