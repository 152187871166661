import MapboxDraw, { DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import { MapboxGeoJSONFeature } from "mapbox-gl";

const { constants } = MapboxDraw;

let selectedFeature: {
  source: string;
  id: number;
} | null = null;

const DrawPipelineMode: DrawCustomMode = {
  toDisplayFeatures: () => {}
}

DrawPipelineMode.onSetup = function(opts: any) {
  if (!opts) return;

  const { startingPoint, startProperties } = opts;

  const line = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: constants.geojsonTypes.LINE_STRING,
      coordinates: [startingPoint]
    }
  });

  this.addFeature(line);

  this.clearSelectedFeatures();
  MapboxDraw.lib.doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: constants.cursors.POINTER });
  this.activateUIButton(constants.types.LINE);
  // this.setActionableState({ trash: true });

  return {
    line,
    currentVertexPosition: 1, // Start at the next position after the starting point
    startProperties,
    validFeature: undefined,
  };
};

DrawPipelineMode.onClick = function(state, e: any) {
  let boundsFeature: any = null;
  this.map.queryRenderedFeatures(e.point).forEach((feature: any) => {
    if (feature.layer.id === 'static-asset-pipeline-connector-bounds') {
      boundsFeature = feature;
    }
  });
  if (boundsFeature) {
    const drawnFeature = {
      mode: 'draw_pipeline',
      features: [
        {
          type: 'Feature',
          properties: {
            assetFrom: state.startProperties?.assetId,
            assetTo: boundsFeature.properties?.assetId,
          },
          geometry: {
            type: 'LineString',
            coordinates: state.line.coordinates,
          },
        },
      ],
    };
    this.changeMode(
      MapboxDraw.constants.modes.SIMPLE_SELECT,
      {},
      { silent: true }
    );
    this.map.fire('draw.create', drawnFeature);
  }

  return state;
};

DrawPipelineMode.onMouseMove = function(state: any, e: any) {
  let boundsFeature: any = null;
  this.map.queryRenderedFeatures(e.point).forEach((feature: any) => {
    if (feature.layer.id === 'static-asset-pipeline-connector-bounds') {
      boundsFeature = feature;
    }
  });
  this.updateUIClasses({ mouse: constants.cursors.POINTER });
  if (boundsFeature !== null) {
    const assetId = boundsFeature.properties?.assetId;
    if (state.validFeature === null
      || state.validFeature !== assetId) {
      this.map.setPaintProperty(
        'gl-draw-line-active.hot',
        'line-color',
        'green'
      );
      selectedFeature = {
        source: boundsFeature.source,
        id: boundsFeature.id,
      };
      this.map.setFeatureState({
        source: selectedFeature.source,
        id: selectedFeature.id,
      }, {
        color: 'green'
      });
      const boundsCenter = JSON.parse(boundsFeature?.properties?.center);
      state.line.updateCoordinate(
        state.currentVertexPosition,
        boundsCenter.lon,
        boundsCenter.lat,
      );
      state.validFeature = assetId;
    }
  } else {
    if (state.validFeature === undefined || state.validFeature !== null) {
      this.map.setPaintProperty('gl-draw-line-active.hot', 'line-color', 'red');
      if (selectedFeature) {
        this.map.removeFeatureState({
          source: selectedFeature.source,
          id: selectedFeature.id,
        });
        selectedFeature = null;
      }
      state.validFeature = null;
    }
    state.line.updateCoordinate(
      state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat
    );
  }
}

DrawPipelineMode.onKeyUp = function(state, e) {
  if (MapboxDraw.lib.CommonSelectors.isEscapeKey(e)) {
    this.changeMode(
      MapboxDraw.constants.modes.SIMPLE_SELECT,
      {},
      { silent: true }
    );
  }
};

// Rest of the methods can be similar to DrawLineString
DrawPipelineMode.onTap = MapboxDraw.modes.draw_line_string.onTap;
DrawPipelineMode.onStop = MapboxDraw.modes.draw_line_string.onStop;
DrawPipelineMode.onTrash = MapboxDraw.modes.draw_line_string.onTrash;
DrawPipelineMode.toDisplayFeatures =
  MapboxDraw.modes.draw_line_string.toDisplayFeatures;

export default DrawPipelineMode;