import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

interface ParameterDetailsProps {
  param: {
    name: string;
    in: string;
    required?: boolean;
    description?: string;
    schema?: {
      type: string;
      format?: string;
    };
  };
}

const ParameterDetails: React.FC<ParameterDetailsProps> = ({ param }) => {
  if (!param) return <></>;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant='subtitle1'>
        {param.name} <Chip label={param.in} size='small' sx={{ ml: 1 }} />
        {param.required && (
          <Chip
            label='required'
            color='secondary'
            size='small'
            sx={{ ml: 1 }}
          />
        )}
      </Typography>
      {param.description && (
        <Typography variant='body2'>{param.description}</Typography>
      )}
      {param.schema && (
        <Box sx={{ mt: 1 }}>
          <Typography variant='body2'>
            Type: {param.schema.type}{' '}
            {param.schema.format ? `(${param.schema.format})` : ''}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ParameterDetails;
