import * as React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function SnackbarAlert({
  message,
  onClose,
  severity = 'success',
}: any) {
  return (
    <Snackbar
      open={!!message}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}
