import useUIContext from 'context/UIContext';
import { useTranslation } from 'react-i18next';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useRoles(): UseQueryResult {
  return useQuery('roles', () => apiClient.get('roles'));
}

export function useRole(variables?: {
  id: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['roles', variables],
    () => apiClient.get(`roles/${variables?.id}`),
    {
      enabled: !!variables?.id,
      staleTime: 0,
    }
  );
}

export function useAddRole(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'roles',
    (variables: any) =>
      apiClient.post('roles', {
        name: variables.name,
        description: variables.description,
        permissions: variables.permissions,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
      },
    }
  );
}

export function useEditRole(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'roles',
    (variables: any) =>
      apiClient.put(`roles/${variables.id}`, {
        name: variables.name,
        description: variables.description,
        permissions: variables.permissions,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
      },
    }
  );
}

export function useDeleteRole(): any {
  const queryClient = useQueryClient();
  const { setFlashMessage } = useUIContext();
  const { t } = useTranslation();
  return useMutation(
    'roles',
    (variables: any) => apiClient.delete(`roles/${variables.id}`),
    {
      onError: (e: any) => {
        setFlashMessage({
          message: e.response.data.message
            ? t(`errors.${e.response.data.message}`)
            : e.message,
          severity: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
      },
    }
  );
}
